
import React, { Component } from 'react';
import allInclusiveicon from '../assets/images/credit-card-icon.svg';
import saveMoreicon from  '../assets/images/shopping-bag-icon.svg';
import hassleFreeonboradingicon from  '../assets/images/verified-icon.svg';
import { withTranslation } from 'react-i18next';

class MonthlyRenting extends Component {

    render() {
        const { t } = this.props;
        return (
            <> 
            <section className='month-rental-box-section padding-100 grey-bg'>
                <div className='container'>
                    <div className='titleBorderWrap titleCenter'>
                        <h2>{t('How It Works.1')}</h2>
                        <p>{t('Dollar promises great prices for your choice of dream car.1')}</p>
                    </div>
                    <div className='month-rental-box-row'>
                        <div className='month-rental-box-col'>
                            <div className='month-rental-box'>
                                <img src={allInclusiveicon} alt="All-Inclusive"/> 
                                <h3>{t('Its quick simple and easy.1')}</h3>
                                <p>{t('Select your car delivery location and rent duration.1')}</p>
                            </div>
                        </div>
                        <div className='month-rental-box-col'>
                            <div className='month-rental-box'>
                                <img src={saveMoreicon} alt="Save More"/> 
                                <h3>{t('Effortless booking.1')}</h3>
                                <p>{t('Upload your digital documents and make the payment.1')}</p>
                            </div>
                        </div>
                        <div className='month-rental-box-col'>
                            <div className='month-rental-box'>
                                <img src={hassleFreeonboradingicon} alt="Hassle-Free Onboarding"/> 
                                <h3>{t('Seamless delivery.1')}</h3>
                                <p>{t('Get the car delivered to your preferred location.1')}</p>
                                <p>{t('Go on and drive near and far in your rented car.1')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
            )
        }
    }
    
    export default withTranslation()(MonthlyRenting);