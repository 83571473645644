import moment from 'moment';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import bestdeals from '../assets/images/best-deal.svg';
import fleets from '../assets/images/fleets.svg';
import locationicon from '../assets/images/location-icon.svg';
import { getCustomerId } from '../Common/Util';
import appstore from "../assets/images/app-store.png";
import googleplay from "../assets/images/google-play-store.png";


import "../assets/css/slick.css";
import Spinner from 'react-bootstrap/Spinner';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class BestDeals extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            errorMessage: ''
        })
    }




    onManageBookingClick = () => {
        const customerId = getCustomerId();
        if (customerId == "00000000-0000-0000-0000-000000000000") {

            this.props.history.push("findreservation");
        } else {

            this.props.history.push("/profile");
        }
    }

    onBookaServiceClick = ()=>{
        this.props.history.push("/bookservice");
    }

    onContactUsClick = ()=>{
        this.props.history.push("/contactus");
    }

    onMobileAppClick = () =>{
        
            this.setState({ errorMessage: 'Mobile App is under construction.  Check back soon.' });
            return false;
    }

    handleClose=()=>{
        this.setState({
            errorMessage: ""
        });
    }

    render() {

        const { t } = this.props;
        return (


            <section className="download-app-section">
                <div className="container">
                    <div className="download-app-wrap">
                        {/* <a className="download-function-anchor" title="Best Deals" onClick={this.onManageBookingClick}>
                            <img src={bestdeals} alt="Manage Booking" />
                            <span className="download-text">{t('Manage Booking.1')}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </a>
                        <a  className="download-function-anchor" title="Book a Service" onClick={this.onBookaServiceClick}>
                            <img src={fleets} alt="Fleets" />
                            <span className="download-text">{t('Book a Service.1')}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </a>
                        <a className="download-function-anchor" to="/contactus" onClick={this.onContactUsClick}>
                            <img src={locationicon} alt="Contact Support" />
                            <span className="download-text">{t('Contact Support.1')}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </a> */}
                        <div className="follow-us-strip-banner">
                            <h4>{t('Follow Us on.1')}</h4>
                            <ul className="list-unstyled social-media-footer">
                                <li>
                                    <a href="https://www.facebook.com/dollar.uae/">
                                        <i className="fa fa-facebook" aria-hidden="true"></i>
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="https://twitter.com/dollaruae">
                                        <i className="fa fa-twitter" aria-hidden="true"></i>
                                    </a>
                                </li> */}
                                <li>
                                    <a href="https://www.linkedin.com/company/dollar-rent-a-car-uae">
                                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/dollar.uae/">
                                        <i className="fa fa-instagram" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        
                        <div className="download-app-anchor d-none d-sm-block">
                        {t('Download App.1')}
                        <span>{t('For more mobility.1')}</span>                       
                        </div>
                        <a title="Google Play Store" className="download-store-app d-none d-sm-block" onClick ={this.onMobileAppClick}>
                            <img src={googleplay} alt="Google Play Store" />
                        </a>
                        <a title="App Store" class="download-store-app d-none d-sm-block"  onClick ={this.onMobileAppClick}>
                            <img src={appstore} alt="App Store"/>
                        </a>
                    </div>
                </div>

                <div className="col-xl-3">
                                    {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? 
                                    
                                    <Dialog
                                            open={this.state.errorMessage.length > 0}
                                            onClose={this.handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">{"Dollar"}</DialogTitle>
                                            <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                            {this.state.errorMessage}
                                            </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                            <Button onClick={this.handleClose} color="primary">
                                                {t('OK.1')}
                                            </Button>                                
                                            </DialogActions>
                                        </Dialog>
                                        : null}

                                    {this.state.loading == true ? <div style={{ verticalAlign: 'center' }}>
                                        <Spinner animation="border" />&nbsp;{this.state.loadingText}

                                    </div> : null}
                                </div>

            </section>
            

        );
    }
}

export default withTranslation()(withRouter(BestDeals));