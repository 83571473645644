import axios from 'axios';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import hoistStatics from 'hoist-non-react-statics';

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import DubaiHero from "../assets/images/Business/Location_option_2.jfif";

import path from "../assets/images/Path.svg";

import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage } from '../Common/Util';

import { withRouter } from 'react-router-dom';
import LocationTile from './LocationTile';
import phone from '../assets/images/phone-alt.svg';
import email from '../assets/images/email.svg';
import time from '../assets/images/time.svg';
import { Helmet } from 'react-helmet';
class LocationPage extends Component {
    constructor() {
        super();
        this.state = {
            allbranches: [],
            locations: [],
            locationsToShow: [],
            selectedLocation: '-1',

        }
    }



    containsLocation = (locations, id) => {
        var i = -0;
        for (i = 0; i < locations.length; i++) {
            if (locations[i].locationId.toString() == id.toString())
                return true;
        }
        return false;
    }

    componentDidMount() {
        const { t } = this.props;
        const { match: { params } } = this.props;

        if(localStorage.getItem('locationid')!='null' && localStorage.getItem('locationid')!=undefined)
        {
            params.index = localStorage.getItem('locationid');
        }

        if(params.index == undefined || params.index == 'null')
        {
            params.index = -1;
        }
       this.setState({selectedLocation:params.index});

        let lngParam = getLanguage();
        axios(process.env.REACT_APP_API + 'Branch/Get?apiKey=' + process.env.REACT_APP_API_KEY + `&lng=${lngParam}`).then((responce) => {

            const allbranches = responce.data;
            const locations = [];
            locations.push({
                "locationId": "-1",
                "name": t('All.1')

            });

            allbranches.forEach(branch => {
                if (!this.containsLocation(locations, branch.BranchType.BranchTypeID)) {
                    locations.push({
                        locationId: branch.BranchType.BranchTypeID,
                        name: branch.BranchType.Name
                    });
                }
            });
        
            this.setState({ locations: locations });
            this.setState({ allbranches: allbranches });

            
                if(params.index != undefined && params.index != -1)                
                {
                    this.onLocationFilterclick(params.index);
                }
                else
                {
                    this.setState({ locationsToShow: allbranches });
                }
           
            //this.setState({ locationsToShow: allbranches });
        });
    }



    

    onLocationFilterclick = (branchSelected) => {

        const locationsToShow = [];
        if (branchSelected == "-1") {

            this.setState({ locationsToShow: this.state.allbranches });

        } else {
            this.state.allbranches.forEach(branch => {

                if (branch.BranchType.BranchTypeID.toString() == branchSelected) {
                    locationsToShow.push(branch);

                };
            });

            this.setState({ locationsToShow: locationsToShow });
            this.setState({ selectedLocation: branchSelected });
        }
    }
    render() {

        const { t } = this.props;
        return (
            <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Find the Nearest Dollar Car Rental Locations in UAE</title>
                        <link rel="canonical" href="www.dollaruae.com/locations" />
                        <meta property="og:title" content="Find the Nearest Dollar Car Rental Locations in UAE"></meta>
                        <meta property="og:site_name" content="Dollar Car Rental"></meta>
                        <meta property="og:url" content="https://www.dollaruae.com/locations"></meta>
                        <meta property="og:description" content="Enjoy convenient access to our car rental points across major landmarks & attractions in Dubai, Abu Dhabi and Sharjah."></meta>
                        <meta property="og:type" content="business.business"></meta>
                        <meta property="og:image" content="https://www.dollaruae.com/static/media/Location_option_2.e23f43f0.jfif"></meta>
                        <meta name="title" content="Find the Nearest Dollar Car Rental Locations in UAE"></meta>
                        <meta name="description" content="Enjoy convenient access to our car rental points across major landmarks & attractions in Dubai, Abu Dhabi and Sharjah."></meta>
                        <meta property="og:type" content="website"></meta>
                        <meta property="og:url" content="https://www.dollaruae.com/locations"></meta>
                        <meta property="og:title" content="Find the Nearest Dollar Car Rental Locations in UAE"></meta>
                        <meta property="og:description" content="Enjoy convenient access to our car rental points across major landmarks & attractions in Dubai, Abu Dhabi and Sharjah."></meta>
                        <meta property="og:image" content="https://www.dollaruae.com/static/media/Location_option_2.e23f43f0.jfif"></meta>
                        <meta property="twitter:card" content="summary_large_image"></meta>
                        <meta property="twitter:url" content="https://www.dollaruae.com/locations"></meta>
                        <meta property="twitter:title" content="Find the Nearest Dollar Car Rental Locations in UAE"></meta>
                        <meta property="twitter:description" content="Enjoy convenient access to our car rental points across major landmarks & attractions in Dubai, Abu Dhabi and Sharjah."></meta>
                        <meta property="twitter:image" content="https://www.dollaruae.com/static/media/Location_option_2.e23f43f0.jfif"></meta>
                    </Helmet>
            <ListingHeader />
            <div className="minH100">
                <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
                    <div className="container-fluid">
                        <div className="box-shadow-white-box-a ">
                            <div className="">
                                <div className="row flex-reversea align-items-center">
                                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                                        <div className="pickup-location-box">
                                            
                                            <div className="pickup-location-flex">
                                                <div className="pickup-location-flex-content">
                                                    <h1 className="border-botom">{t('Dollar Car Rental Locations in the UAE.1')}</h1>
                                                    <h2 className="border-botom">{t('Car Rentals at Airport.1')} </h2>
                                                    <p className="font-16 text-grey mt-4">{t('Enjoy convenient access to our car rental counters across major landmarks & attractions in Dubai, Abu Dhabi, Sharjah, Al Ain.1')}</p>
                                                    <p className="font-16 text-grey mt-4">
                                                    {t('With our centralized booking systems, you can now pick up your vehicle at one counter and drop it off at another one seamlessly - with no confusion regarding your booking, paperwork, and such.1')}
                                                    </p>
                                                    <p className="font-16 text-grey mt-4">{t('Emirates like Dubai & Abu Dhabi are home to hundreds of tourist attractions and offer a wide variety of things to do in the UAE, especially as a tourist. Traveling between these multiple locations in public transportation can be time-consuming, less hygienic, and even less cost-effective when visiting as a group. All you need is a valid international driver’s permit, visa, and passport to ensure a more rewarding trip on wheels that are all yours.1')} </p>
                                                    <p className="font-16 text-grey mt-4">{t('Here’s a list of our key locations to rent a car across the UAE or even go for a quick lease.1')} </p>
                                                    <p className="font-16 text-grey mt-4">{t('Next time you are planning your big trip remember to book your Dollar rental car online. We make traveling a little less stressful by offering airport car rental locations at all the major airports. If you do not get to stop by our airport counters - no worries, we have plenty more around the UAE.1')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6  col-md-12 col-12"> 
                                        <div className="w-100 px-3">
                                            <img src={DubaiHero} alt={t('Dollar Locations.1')} className="img-fluid rounded-xl" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                {/* <section className="box-shadow-white-box-section">
                    <div className="container-fluid">
                        <div className="box-shadow-white-box-a">
                            <div className="">
                                <div className="text-center pad-30" id="location">
                                    <div className="row" id="i1">
                                        <div className="col-12">
                                            <div className="pickup-location-box">
                                                <h5 className="font-bold">{t('Find a Location.1')}</h5>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="pickup-location-box" id="i2">
                                                <h6 className="font-bold">UAE(Airport/City)Location</h6>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-7 col-md-12 col-12 mt-4 mt-sm-0" id="a1">
                                            <div className="item1 ">
                                                <input type="search" className="form  mt-0  form-control  input" placeholder="" aria-label="Search" aria-describedby="search-addon" />
                                                <div className="d-flex justify-content-end">

                                                    <img src={path} className="mr-2" width="10" /><p>{t('use my current location.1')}</p>

                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-12 col-lg-2 col-md-6 col-12 mb-2">
                                            <div className="item2">
                                                <a href="#" className="rounded-bttn rounded-bttn-transparent " title="Book &amp; Pay Later">
                                                    {t('Search.1')}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-3   col-md-6 col-12 mb-2">
                                            <div className="item3">
                                                <a href="" className="rounded-bttn color-red ml-3" title="Book &amp; Pay Online">
                                                    {t('Start Booking.1')}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                 */}
                <section className="box-shadow-white-box-section grey-bg pb-4">
                   
                        <div className="container-fluid">

                            <ul className="nav nav-pills mb-0 pb-0 product-nav-tabs" id="pills-tab" role="tablist">
                                {
                                    this.state.locations.map((loc) => {
                                        return (
                                            <li className="nav-item mr-2 ml-2" key={loc.locationId}>
                                                <a className={(this.state.selectedLocation == loc.locationId) ? "nav-link active" : "nav-link "} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home3" aria-selected="false" onClick={() => this.onLocationFilterclick(loc.locationId)}>{loc.name}</a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <div className="pt-0 pb-2">
                                <hr></hr>
                            </div>
                        </div>
                    
                </section>
                <div className="tab-content  grey-bg  pb-4" id="pills-tabContent">
                    <div className="tab-pane fade show active " id="pills-home3" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="box-shadow-white-box-section" id="partner-ab">
                            <div className="container-fluid">
                            <section className="box-shadow-white-box-section">
                        <div className="container-fluid">
                            <div className="row">
                            <div className="col-md-6 col-12 order-1 order-md-0">
                                { 
                                    this.state.locationsToShow.map((loc) => {
                                        return (<LocationTile key={loc.Identity.Id} loc={loc}/>
                                        )
                                    })
                                }


                                {/* <div className="box-shadow-white-box location-info-box">
                                    <div className="location-info-box-left">
                                        <h5 class="font-bold">Al Wahda Mall <a href="">More Info <i class="fa fa-angle-right"></i></a></h5>
                                        <span class="my-fonta">Al Wahda - Hazza Bin Zayed St - Old Airport Rd - Abu Dhabi</span>
                                        <span class="my-fonta">Al Wahda Mall- First Floor Extension</span>
                                        <ul>
                                            <li>
                                                <a href="tel:026419002">
                                                    <img src={phone} alt="Phone" class="img-fluid phonea"/>
                                                    <span class="img_text ml-2">02 641 9002</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="mailto:desiraevet@gmail.com">
                                                    <img src={email} alt="girl" class="img-fluid phone"/>
                                                    <span class="img_text ml-2">auh@dollaruae.ae</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="" class="d-flex  align-items-start">
                                                    <img src={time} alt="girl" class="img-fluid phoneaa"/>
                                                    <span class="img_text ml-2">Sun - Sat :10:00,21:00</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="location-info-box-right mt-3 text-right w-100">
                                        <button type="submit" class="rounded-bttn text-center">BOOK NOW</button>
                                    </div>
                                </div>
                                <div className="box-shadow-white-box location-info-box">
                                    <div className="location-info-box-left">
                                        <h5 class="font-bold">Al Wahda Mall <a href="">More Info <i class="fa fa-angle-right"></i></a></h5>
                                        <span class="my-fonta">Al Wahda - Hazza Bin Zayed St - Old Airport Rd - Abu Dhabi</span>
                                        <span class="my-fonta">Al Wahda Mall- First Floor Extension</span>
                                        <ul>
                                            <li>
                                                <a href="tel:026419002">
                                                    <img src={phone} alt="Phone" class="img-fluid phonea"/>
                                                    <span class="img_text ml-2">02 641 9002</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="mailto:desiraevet@gmail.com">
                                                    <img src={email} alt="girl" class="img-fluid phone"/>
                                                    <span class="img_text ml-2">auh@dollaruae.ae</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="" class="d-flex  align-items-start">
                                                    <img src={time} alt="girl" class="img-fluid phoneaa"/>
                                                    <span class="img_text ml-2">Sun - Sat :10:00,21:00</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="location-info-box-right mt-3 text-right w-100">
                                        <button type="submit" class="rounded-bttn text-center">BOOK NOW</button>
                                    </div>
                                </div> */}
                           
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="box-shadow-white-box p-0 location-info-map sticky-top">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.935173451481!2d55.30318541544933!3d25.239108336191556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f42d0e5588fdd%3A0x2d888bd5b0fda2d3!2sDollar%20Rent%20A%20Car%20-%20Dubai!5e0!3m2!1sen!2sin!4v1626793033857!5m2!1sen!2sin" width="100%" height="520" allowfullscreen="" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>


                                
                            </div>
                        </div>
                    </div>
                </div>
                </div>                 
            <ListingFooter />
            </>
        );
    }
}



export default hoistStatics(withTranslation()(GoogleApiWrapper({
    apiKey: ('AIzaSyD5B6KKLVWThFRg2oXcEYoFHMyRFoLexzo')
})(withRouter(LocationPage))), LocationPage);

