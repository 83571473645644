import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import hoistStatics from 'hoist-non-react-statics';
import { animateScroll as scroll  } from 'react-scroll';
import phpTrsRal from '../assets/images/Business/431848417.jfif';
class BusinessSolutionHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onMenuLinkClick = (path)=>{
      this.props.history.push(path);
      scroll.scrollToTop();
  }
   

    render() {
      const { t } = this.props;
        return (<>
            <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
               <div className="container-fluid" id="top" name="top">
                  <div className="box-shadow-white-box-a ">
                     <div className="">
                        <div className="row flex-reversea">
                           <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                              <div className="pickup-location-box ">
                                 
                                 <div className="pickup-location-flex">
                                    <div className="pickup-location-flex-content">
                                       <h1 className="border-botom">{t('Business Solutions.1')} </h1>
                                       <h2 className="border-botom">{t('We host UAE’s largest & widest fleet, for all your transportation needs.1')}  </h2>
                                       <p>{t('We believe, no road is long with the right company.1')}</p>
                                       <p className="text_p mt-3"> {t('And that is why, at Dollar Car Rental, we aim to be the leading and innovative provider of mobility & transportation solutions in the UAE.1')}</p>
                                 
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                              <img src={phpTrsRal} alt="Dollar Business Solution Banner" className="img-fluid" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
        </>);
    }
}

export default hoistStatics(withTranslation()(withRouter(BusinessSolutionHeader)), BusinessSolutionHeader);