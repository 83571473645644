import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import closeImg from "../../assets/images/closeX.svg";
import PromoImg from "../../assets/images/promo.svg";

class AddPromocode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: this.props.promoCode || "",
    };
    this.handlePromoCode = this.handlePromoCode.bind(this);
    this.handleSubmitCode = this.handleSubmitCode.bind(this);
  }

  handlePromoCode = (value) => {
    this.setState({ promoCode: value });
  };

  handleSubmitCode = () => {
    this.props.handlePromoCode(this.state.promoCode);
    this.props.handleClose();
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="overly-hidden-black-shade"></div>
        <div className="pop-up-small">
          <div className="header">
            <h6>{t("Add Promo Code.1")}</h6>
            <div className="close-btn">
              <img src={closeImg} alt="" onClick={this.props.handleClose} />
            </div>
          </div>
          <div className="input-section">
            <div className="input">
              <div className="icon">
                <img src={PromoImg} alt="" />
              </div>
              <input
                type="text"
                value={this.state.promoCode}
                placeholder="Put your Promo Code here"
                maxLength={12}
                minLength={1}
                onChange={(e) => this.handlePromoCode(e.target.value)}
              />
            </div>
            <button className="primary-button" onClick={this.handleSubmitCode}>
              {t("Submit.1")}
            </button>
          </div>
          <div className="footer-section">
            <p>
              {t(
                "Maximize your savings with our exclusive promo code! Simply apply it at checkout and watch the prices drop.1"
              )}
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(AddPromocode);
