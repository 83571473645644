import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs from "dayjs";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import buildingImg from "../../assets/images/building.svg";
import closeX from "../../assets/images/closeX.svg";
import plainImg from "../../assets/images/plain.svg";
import { positionMap } from "./constant";

class FlexiMontlyCalender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeList: [],
      pickUpCount: 0,
      pickUpPeriod: this.props.pickupPeriod,
      pickUpDate: props.pickUpDate,
      currentPickupTime: this.props.pickUpTime,
      isButtonActive: this.props.pickupPeriod,
      pickTimeRangeSlider: this.props.pickUpTime,
    };
  }

  handleTimeIncrement = () => {
    let splittedTime = this.state.currentPickupTime.split(":");
    let hours = parseInt(splittedTime[0]);
    let minutes = parseInt(splittedTime[1]);

    // Increment time by 30 minutes
    minutes += 30;
    if (minutes >= 60) {
      hours++;
      minutes = 0;
    }

    // Handle 12-hour format and wrapping around
    if (hours > 12) {
      hours = 1;
    }

    // Format hours and minutes to ensure 2 digits
    let formattedHours = (hours < 10 ? "0" : "") + hours;
    let formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    // Update current time
    const currentTime = formattedHours + ":" + formattedMinutes;
    this.updateCurrentPickupTime(currentTime);

    this.setState(
      {
        currentPickupTime: currentTime,
      },
      () => {
        this.props.updatePickUpTime(this.state.currentPickupTime);
        if (this.state.currentPickupTime === "12:00") {
          if (this.state.pickUpPeriod === "AM") {
            this.setState({
              isButtonActive: "PM",
              pickUpPeriod: "PM",
            });
          } else {
            this.setState({
              isButtonActive: "AM",
              pickUpPeriod: "AM",
            });
          }
        }
      }
    );
  };

  handleTimeDecrement = () => {
    let splittedTime = this.state.currentPickupTime.split(":");
    let hours = parseInt(splittedTime[0]);
    let minutes = parseInt(splittedTime[1]);

    // Decrement time by 30 minutes
    minutes -= 30;
    if (minutes < 0) {
      hours--;
      minutes = 30;
    }

    // Handle wrapping around to '12:30' after reaching '01:00'
    if (hours <= 0) {
      hours = 12;
    }

    // Format hours and minutes to ensure 2 digits
    let formattedHours = (hours < 10 ? "0" : "") + hours;
    let formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    // Update current time
    const currentTime = formattedHours + ":" + formattedMinutes;
    this.updateCurrentPickupTime(currentTime);
    this.setState(
      {
        currentPickupTime: currentTime,
      },
      () => {
        this.props.updatePickUpTime(this.state.currentPickupTime);
        if (this.state.currentPickupTime === "12:00") {
          if (this.state.pickUpPeriod === "AM") {
            this.setState({
              isButtonActive: "PM",
              pickUpPeriod: "PM",
            });
          } else {
            this.setState({
              isButtonActive: "AM",
              pickUpPeriod: "AM",
            });
          }
        }
      }
    );
  };

  // Function to set pickup period to AM
  handleSetAM = () => {
    this.setState({ pickUpPeriod: "AM" }, () => {
      this.props.handlePeriodChange(this.state.pickUpPeriod);
      this.setState({
        isButtonActive: "AM",
      });
    });
  };
  // Function to set pickup period to PM
  handleSetPM = () => {
    this.setState({ pickUpPeriod: "PM" }, () => {
      this.props.handlePeriodChange(this.state.pickUpPeriod);
      this.setState({
        isButtonActive: "PM",
      });
    });
  };

  componentDidMount() {
    this.getTimeSlots();
    this.updateCurrentPickupTime(this.props.pickUpTime);
  }

  getTimeSlots = () => {
    axios(
      process.env.REACT_APP_API +
        "Branch/GetTimeSlots?apiKey=" +
        process.env.REACT_APP_API_KEY
    ).then((response) => {
      this.setState({ timeList: response.data });
    });
  };

  formatDateString(dateString) {
    const [day, numericMonth, year] = dateString.split(" ");
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[parseInt(numericMonth, 10) - 1];

    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  }

  handlePickUpDateChange = (date) => {
    const formatedDate = this.formatDateString(
      dayjs(date).format("DD MM YYYY")
    );
    // const currentTime = this.state.timeList[this.state.pickUpCount] + " " + this.state.pickUpPeriod;
    this.setState({ pickUpDate: formatedDate }, () => {
      // This callback will be executed after the state has been updated
      this.props.updatePickUpDate(this.state.pickUpDate);
      this.props.updatePickUpTime(this.state.currentPickupTime);
    });
  };

  updateCurrentPickupTime = (timeString) => {
    this.setState({ pickTimeRangeSlider: timeString });
  };

  calculateLeftStyle = () => {
    const { pickTimeRangeSlider } = this.state;
    const leftPosition = positionMap[pickTimeRangeSlider] || 0;
    return `${leftPosition}%`;
  };

  handleSliderChange = (event) => {
    const newValue = parseFloat(event.target.value); // Parse float to handle 0.5 increments
    const hours = Math.floor(newValue); // Get the hour part
    const minutes = (newValue % 1) * 60; // Convert the decimal part to minutes
    const currentTime = new Date();
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0);

    // Format the time as "hh:mm"
    let formattedTime = currentTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    formattedTime = formattedTime.replace(/\s[AP]M/i, "");

    this.setState({
      pickTimeRangeSlider: formattedTime,
      currentTime: formattedTime,
    });
    this.setState({ currentPickupTime: formattedTime }, () => {
      this.props.updatePickUpTime(formattedTime);
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="pickupReturnDatePopup flexiMonthly">
        <div className="inner-wrapper">
          <div className="mob-header mob">
            <div className="back">
              <h6>{t("Select Pickup Location.1")}</h6>
              <div className="icon" onClick={this.props.closeCalendar}>
                <img src={closeX} alt="" id="datePopupBackBtn" />
              </div>
            </div>
          </div>
          <ul className="selectedLocations">
            <li>
              <div className="icon">
                <img
                  src={
                    this.props.selectedValue?.Name &&
                    this.props.selectedValue?.Name.includes("Airport")
                      ? plainImg
                      : buildingImg
                  }
                  alt=""
                />
              </div>
              <div className="inner-wrapper d-block">
                <h6>
                  {this.props?.selectedValue?.Name || "Select a location"}
                </h6>
                <h4>
                  {t("Pickup.1")} : <span>{this.props.pickUpDate}</span> |{" "}
                  <span>{this.props.pickUpTime}</span>
                </h4>
              </div>
            </li>
            {/* )} */}
          </ul>
          <div className="right-section-c">
            <div className="datePickerWrapper">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  disablePast
                  value={dayjs(this.state.pickUpDate)}
                  views={["day"]}
                  format="DD-MM-YYYY"
                  onChange={this.handlePickUpDateChange}
                />
              </LocalizationProvider>
            </div>

            <div className="time-section ">
              <div className="left column-c">
                <h6 className="web">{t("Pickup Date & Time.1")}</h6>
                <h6 className="mob">{t("Pickup.1")}</h6>
                <h5>
                  {" "}
                  <span id="pickupDate">
                    {this.state.pickUpDate.split(" ")[0]}
                  </span>{" "}
                  <span id="pickupMonth">
                    {this.state.pickUpDate.split(" ")[1]}
                  </span>{" "}
                  | {this.state.currentPickupTime} {this.state.pickUpPeriod}
                </h5>

                <div className="time-selection">
                  {/* time range slider */}
                  {/* <div className="time-slider">
                    <input
                      type="range"
                      min="0"
                      max="12"
                      step="1.5"
                      id="slider"
                      value={this.state.pickTimeRangeSlider || 1}
                      onChange={this.handleSliderChange}
                    />
                    <span
                      className={`timeLabel ${
                        this.calculateLeftStyle() === "87%" ? "end" : ""
                      }`}
                      id="sliderValue"
                      style={{
                        left: this.calculateLeftStyle(),
                      }}
                    >
                      {this.state.currentPickupTime}
                    </span>
                  </div> */}
                  {/* time range slider */}
                  <div className="custom-container">
                    <div className="time-counter">
                      <button type="button" onClick={this.handleTimeDecrement}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 12H19"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <span className="underline">
                        {" "}
                        {this.state.currentPickupTime}
                      </span>
                      <button
                        type="button"
                        onClick={() => this.handleTimeIncrement}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 mt-1 svg-hover"
                          onClick={() => this.handleTimeIncrement()}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="timeMode">
                      <button
                        className={`Button ${
                          this.state.isButtonActive === "AM" ? "active" : ""
                        }`}
                        size="sm"
                        onClick={this.handleSetAM}
                      >
                        AM
                      </button>
                      <button
                        className={`Button ${
                          this.state.isButtonActive === "PM" ? "active" : ""
                        }`}
                        size="sm"
                        onClick={this.handleSetPM}
                      >
                        PM
                      </button>
                    </div>

                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* show car button */}
        <div className="show-car-btn-mob mob">
          <button
            className="primary-button"
            onClick={this.props.searchClickHandler}
          >
            {t("Continue.1")}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FlexiMontlyCalender);
