import React, { Component } from 'react';
import cartype from '../assets/images/car-type-icon.svg';
import door from '../assets/images/door-icon.svg';
import luggage from '../assets/images/luggage-icon.svg';
import memory from '../assets/images/memory-icon.svg';
import person from '../assets/images/person-icon.svg';
import hoistStatics from 'hoist-non-react-statics';
import ac_yes from '../assets/images/ac-yes.svg';
import ac_no from '../assets/images/ac-no.svg';
import { withTranslation } from 'react-i18next';
class VehicleFeatureBox extends Component {
    render() {
        const { t } = this.props;
        return (
           <>
                <div className="feature-vehicle-box" id={this.props.model.VehicleModelID + "_box"}>
                        <div className="feature-icon" title={this.props.model.Passengers + t('Person Seats.1')}>
                            <img src={person} alt="Person" width="15" height="15" />
                            <span>{this.props.model.Passengers}</span>
                        </div>
                        <div className="feature-icon" title={this.props.model.Doors + t('Doors.1')}>
                            <img src={door} alt="Door" width="15" height="15" />
                            <span>{this.props.model.Doors}</span>
                        </div>  
                        <div className="feature-icon" title={(this.props.model.AutoTransmission)? t('Automatic.1'): t('Manual.1')}>
                            <img src={cartype} alt="Car Type" width="15" height="15" />
                            <span>{(this.props.model.AutoTransmission)?'A':'M'}</span>
                        </div>
                        <div className="feature-icon" title={this.props.model.Luggages + t('Luggage Space.1')}>
                            <img src={luggage} alt="Luggage" width="13" height="19" />
                            <span>{this.props.model.Luggages}</span>
                        </div>
                        <div className="feature-icon" title={this.props.model.Engine + t('Engine.1')}>
                            <img src={memory} alt="Memory" width="17" height="17" />
                            <span>{this.props.model.Engine}</span>
                        </div>
                        <div className="feature-icon" title={this.props.model.AC == true ? t('Yes.1') + ' ' + t('AC.1') : t('No.1') + ' ' + t('AC.1')}>
                            <img src={this.props.model.AC == true ? ac_yes : ac_no} alt="AC" width="17" height="17" />
                            <span>{t('AC.1')}</span>
                        </div>
                    </div>
            </>
        );
    }
}

export default hoistStatics(withTranslation()(VehicleFeatureBox), VehicleFeatureBox);