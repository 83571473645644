import axios from 'axios';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage } from '../Common/Util';
import ListingCard from '../ListingPage/ListingCard';
import moment from 'moment-timezone';
import OtherVehicleType from './OtherVehicleType';
import { Carousel, Modal } from 'react-bootstrap';
import SearchWidget from '../Hire/SearchWidget';

var qs = require('qs');

class VehicleByVehicleTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
            vehicalModels: [],
            
            searchParam: {

                Classifications: [],
                VehicleMakeID: "00000000-0000-0000-0000-000000000000",
                VehicleModelID: "00000000-0000-0000-0000-000000000000",
                SpecialOfferID: "00000000-0000-0000-0000-000000000000",
                ModelYear: 0,
                Passengers: 0,
                AutoTransmission: 0,
                CheckOutOn: "2021-04-20T15:51:00",
                CheckInOn: "2021-04-23T15:51:00",
                CheckOutBranchID: '',
                CheckInBranchID: '',
                DeliveryDetails: {
                    "Address": "",
                    "AddressJson": ""
                },
                PickupDetails: {
                    "Address": "",
                    "AddressJson": ""
                },

                CheckOutBranchID: "fdeb6d7b-132e-4c32-a5da-42a130d64d32",
                CheckInBranchID: "fdeb6d7b-132e-4c32-a5da-42a130d64d32",
                PromoCodeID: "00000000-0000-0000-0000-000000000000",
                PromoCode: "",
                DriverAge: 21,
                MinRentalRate: 0,
                MaxRentalRate: 0,
                RentalDays: 0,
                NoOfRecords: 0,
                TariffGroupID: "00000000-0000-0000-0000-000000000000"
            },
        }
    }
    componentDidMount = () => {
        const { match: { params } } = this.props;
        this.setState({ VehicleClassificationID: this.props.vehicleClassificationID });
        //this.getVehicleTypes(params.index);
        this.getVehicalModels(this.props.vehicleClassificationID);
        //this.getAllBranches();
    }

    getVehicalModels = (VehicleClassificationID) => {
        
        let searchParam =  {
            "Classifications":[VehicleClassificationID],
            "VehicleMakeID":"00000000-0000-0000-0000-000000000000",
            "VehicleModelID":"00000000-0000-0000-0000-000000000000",
            "SpecialOfferID":"00000000-0000-0000-0000-000000000000",
            "ModelYear":0,
            "Passengers":0,
            "AutoTransmission":0,
            "MinRentalRate":0.0,
            "MaxRentalRate":0.0,
            "RentalDays":0, 
            "NoOfRecords":0
         };

        var data = qs.stringify(searchParam);

        let lngParam = getLanguage();
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API + `SearchVehicleModel/GetAllVehicleModels?apiKey=${process.env.REACT_APP_API_KEY}&lng=${lngParam}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({ vehicalModels: response.data });
            })
            .catch((error) => {

            });
    }

   

    onSelectedVehicleIndexChange = (index, selectedVehicle) => {
        //Do not remove we need this

    }

    onBookNowHandler = () => {
      

        this.toogleVehicleType(true);
        // let pickupDate = moment(this.state.PickUpDate).format('MM/DD/YYYY  h:mm:ss A');
        // let returnDate = moment(this.state.ReturnDate).format('MM/DD/YYYY  h:mm:ss A');

        // var BookingInfo = [{
        //     "PickupLocationId": this.state.BranchId,
        //     "DestinationLocationId": this.state.BranchId,
        //     "PickUpDate": pickupDate,
        //     "ReturnDate": returnDate,
        //     "pickupLocationName": this.state.Name,
        //     "dropOffLocationName": this.state.Name,
        //     "pickupLocationImage": this.state.ImageStringe,
        //     "dropOffLocationImage": this.state.ImageStringe,
        //     "pickupLocationAddress": this.state.Address,
        //     "dropOffLocationAddress": this.state.Address,
        //     "pickupLocationMessage": this.state.Message,
        //     "dropOffLocationMessage": this.state.Message,
        //     "pickupLocationBranchDescription": this.state.Description,
        //     "dropOffLocationBranchDescription": this.state.Description,
        //     "promocode": ""
        // }];

        // localStorage.setItem('VehicleTypeParam', this.state.VehicleClassificationID);
        // localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
        // this.props.history.push("/booking");


    }
    render() {
        const { t } = this.props
        return (
this.state.VehicleClassificationID != -1 ? 
     (
         <div>
            <section className="offer-box-section grey-bg pb-0">
                <div className="container-fluid">
                    <div className="offer-box-wrapper mt-0 vehicle_type_wrap" >
                            {
                                this.state.vehicalModels.map((model, index) => {
                                    return (
                                    
                                        <ListingCard key={model.VehicleModelID} model={model} hideInclude={0} hideUpgradeOptions={1} showDiffPrice={0} searchParam={this.state.searchParam} vehicleIndex={index + 1} showBookNow={1} onBookNowClick={this.onBookNowHandler} onVehicleIndexChange={this.onSelectedVehicleIndexChange} selectedVehicleIndex={9999} />
                                    )
                                })
                            }
                    </div>
                </div>
            </section>
            </div>
     ) : null
    );
    }
}

export default withTranslation()(withRouter(VehicleByVehicleTypes));