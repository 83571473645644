
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import ListingHeader from '../Common/ListingHeader';
import Safe from "react-safe";

class PaymentProcessing extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount = () => {
        let params = queryString.parse(this.props.location.search);
      
        if (params.ref != null) {
                     
        axios(process.env.REACT_APP_API + 'NGeniusPaymentIntegration/CallBack?apiKey=' + process.env.REACT_APP_API_KEY + `&onlineRefNo=${params.ref}`).then((response) => {
            setTimeout(()=> {
                this.props.history.push(`/reservationconfirm/${response.data.Identity.Id}/1`);
                }, 5000); 
            });
        }

    const script = document.createElement("script");
    script.async = true;
    script.src = "gtag('event', 'conversion', { 'send_to': 'AW-10799020848/O23yCJqXqYMDELD2r50o', 'transaction_id': ''});";
    document.head.appendChild(script);
    }

    render() {

        const { t } = this.props;
        return (
            <>
                <Safe.script async src="https://www.googletagmanager.com/gtag/js?id=AW-1047500482"></Safe.script>
                <Safe.script>{
                `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-1047500482');`
                }
                </Safe.script>
                <Safe.script>{
                `gtag('event', 'conversion', {
                    'send_to': 'AW-1047500482/g0vTCPj5i4IYEMKtvvMD',
                    'value': 0.0,
                    'currency': 'AED'
                });`
                }
                </Safe.script>
                <ListingHeader></ListingHeader>
                {t('Processing the payment.1')}
            </>


        );
    }
}

export default withTranslation()(withRouter(PaymentProcessing));