import "../MonthlySubscription/Tabs/tabsStyle.css";
import axios from "axios";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ListingFooter from "../Common/ListingFooter";
import ListingHeader from "../Common/ListingHeader";
import { getLanguage } from "../Common/Util";
import ListingCard from "../ListingPage/ListingCard";
import moment from "moment-timezone";
import OtherVehicleType from "./OtherVehicleType";
import { Carousel, Modal } from "react-bootstrap";
import SearchWidget from "../Hire/SearchWidget";
import SearchWidgetNew from "../Hire/SearchWidgetNew";
import { animateScroll as scroll } from "react-scroll";
import { Helmet } from "react-helmet";
import StartBooking from "../MonthlySubscription/Tabs/StartBooking";
var qs = require("qs");

class VehicleTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicleTypesModel: {},
      otherVehicleTypesModel: [],
      vehicalModels: [],
      PickUpDate: Date().toLocaleString(),
      ReturnDate: Date().toLocaleString(),
      BranchId: "",
      VehicleClassificationID: "",
      Name: "",
      showVehicleType: false,
      ImageString: "",
      Address: "",
      Message: "",
      Description: "",
      searchParam: {
        Classifications: [],
        VehicleMakeID: "00000000-0000-0000-0000-000000000000",
        VehicleModelID: "00000000-0000-0000-0000-000000000000",
        SpecialOfferID: "00000000-0000-0000-0000-000000000000",
        ModelYear: 0,
        Passengers: 0,
        AutoTransmission: 0,
        CheckOutOn: "2021-04-20T15:51:00",
        CheckInOn: "2021-04-23T15:51:00",
        CheckOutBranchID: "",
        CheckInBranchID: "",
        DeliveryDetails: {
          Address: "",
          AddressJson: "",
        },
        PickupDetails: {
          Address: "",
          AddressJson: "",
        },

        CheckOutBranchID: "fdeb6d7b-132e-4c32-a5da-42a130d64d32",
        CheckInBranchID: "fdeb6d7b-132e-4c32-a5da-42a130d64d32",
        PromoCodeID: "00000000-0000-0000-0000-000000000000",
        PromoCode: "",
        DriverAge: 21,
        MinRentalRate: 0,
        MaxRentalRate: 0,
        RentalDays: 0,
        NoOfRecords: 0,
        TariffGroupID: "00000000-0000-0000-0000-000000000000",
      },
    };
  }
  componentDidMount = () => {
    scroll.scrollToTop();
    const {
      match: { params },
    } = this.props;

    if (
      localStorage.getItem("vehicleClassificationID") != "null" &&
      localStorage.getItem("vehicleClassificationID") != undefined
    ) {
      params.index = localStorage.getItem("vehicleClassificationID");
      localStorage.setItem("vehicleClassificationID", "");
    }

    if (params.index == undefined) {
      this.setState({ VehicleClassificationID: -1 });
      this.getVehicleTypes(-1);
      this.getVehicalModels(-1);
    } else {
      this.setState({ VehicleClassificationID: params.index });
      this.getVehicleTypes(params.index);
      this.getVehicalModels(params.index);
    }
  };

  toogleVehicleType = (val) => {
    this.setState({
      showVehicleType: val,
    });
  };
  getVehicleTypes = (VehicleClassificationID) => {
    let lngParam = getLanguage();
    axios(
      process.env.REACT_APP_API +
        "VehicleClassification/Get?apiKey=" +
        process.env.REACT_APP_API_KEY +
        `&lng=${lngParam}`
    ).then((response) => {
      const vehicleTypes = response.data;
      let selectedVehicleTypesModel = {};
      let otherVehicleTypesModel = [];

      vehicleTypes.forEach((type) => {
        if (type.VehicleClassificationID == VehicleClassificationID) {
          selectedVehicleTypesModel = type;
        } else {
          otherVehicleTypesModel.push(type);
        }
      });

      this.setState({ selectedVehicleTypesModel: selectedVehicleTypesModel });
      this.setState({ otherVehicleTypesModel: otherVehicleTypesModel });
    });
  };

  onImageClick = (Id) => {
    localStorage.setItem("vehicleClassificationID", Id);
    window.location = `/vehicletypes/${Id}`;
  };

  getVehicalModels = (VehicleClassificationID) => {
    let searchParam = {
      Classifications: [VehicleClassificationID],
      VehicleMakeID: "00000000-0000-0000-0000-000000000000",
      VehicleModelID: "00000000-0000-0000-0000-000000000000",
      SpecialOfferID: "00000000-0000-0000-0000-000000000000",
      ModelYear: 0,
      Passengers: 0,
      AutoTransmission: 0,
      MinRentalRate: 0.0,
      MaxRentalRate: 0.0,
      RentalDays: 0,
      NoOfRecords: 0,
    };

    var data = qs.stringify(searchParam);

    let lngParam = getLanguage();
    var config = {
      method: "post",
      url:
        process.env.REACT_APP_API +
        `SearchVehicleModel/GetAllVehicleModels?apiKey=${process.env.REACT_APP_API_KEY}&lng=${lngParam}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        this.setState({ vehicalModels: response.data });
      })
      .catch((error) => {});
  };

  onSelectedVehicleIndexChange = (index, selectedVehicle) => {
    //Do not remove we need this
  };

  onBookNowHandler = () => {
    this.toogleVehicleType(true);
  };

  handleBanner = (val) => {};

  handleHeaderActive = (va) => {};

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Choose Latest Cars & Models from Dollar Car Rental UAE</title>
          <meta
            name="title"
            content="Choose Latest Cars & Models from Dollar Car Rental UAE"
          ></meta>
          <meta
            name="description"
            content="Explore a range of latest and modern wheels for your every need. Rent a car now and save more."
          ></meta>
          <meta
            property="og:title"
            content="Choose Latest Cars & Models from Dollar Car Rental UAE"
          ></meta>
          <meta
            property="og:url"
            content="https://www.dollaruae.com/vehicletypes"
          ></meta>
          <meta
            property="og:description"
            content="Explore a range of latest and modern wheels for your every need. Rent a car now and save more."
          ></meta>
        </Helmet>
        <ListingHeader />
        {this.state.VehicleClassificationID != -1 ? (
          <section
            className="box-shadow-white-box-section grey-bg py-2"
            id="partner"
          >
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <h2 className="border-botom">
                          {this.state.selectedVehicleTypesModel.Name}
                        </h2>
                        {/* <h5 className="font-bold mb-3 mt-2 ">{this.state.selectedVehicleTypesModel.Name}</h5> */}
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <p class="font-18 text-grey">
                              {this.state.selectedVehicleTypesModel.Description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <img
                        src={
                          this.state.selectedVehicleTypesModel.Attachment !=
                          null
                            ? this.state.selectedVehicleTypesModel.Attachment
                                .ImageString
                            : ""
                        }
                        alt={t("Vehicle Types.1")}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        {this.state.VehicleClassificationID != -1 ? (
          <div>
            <section className="offer-box-section grey-bg pb-4">
              <div className="container-fluid">
                <div className="offer-box-wrapper mt-0 vehicle_type_wrap">
                  {this.state.vehicalModels.map((model, index) => {
                    return (
                      <ListingCard
                        key={model.VehicleModelID}
                        model={model}
                        hideInclude={0}
                        hideUpgradeOptions={1}
                        showDiffPrice={0}
                        searchParam={this.state.searchParam}
                        vehicleIndex={index + 1}
                        showBookNow={1}
                        onBookNowClick={this.onBookNowHandler}
                        onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                        selectedVehicleIndex={9999}
                      />
                    );
                  })}
                </div>
              </div>
            </section>
            <OtherVehicleType
              otherVehicleTypesModel={this.state.otherVehicleTypesModel}
              onImageClick={this.onImageClick}
            />
          </div>
        ) : (
          <div>
            <OtherVehicleType
              otherVehicleTypesModel={this.state.otherVehicleTypesModel}
              onImageClick={this.onImageClick}
            />
            <section className="offer-box-section grey-bg pb-4">
              <div className="container-fluid">
                <div className="offer-box-wrapper mt-0 vehicle_type_wrap">
                  {this.state.vehicalModels.map((model, index) => {
                    return (
                      <ListingCard
                        key={model.VehicleModelID}
                        model={model}
                        hideInclude={0}
                        hideUpgradeOptions={1}
                        showDiffPrice={0}
                        searchParam={this.state.searchParam}
                        vehicleIndex={index + 1}
                        showBookNow={1}
                        onBookNowClick={this.onBookNowHandler}
                        onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                        selectedVehicleIndex={9999}
                      />
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
        )}

        {this.state.showVehicleType ? (
          <div className="modal-dialog modal-xl modal-dialog-centered border-transparent-bg">
            <Modal
              className=""
              show={this.state.showVehicleType}
              onHide={() => this.toogleVehicleType(false)}
              dialogClassName="modal-dialog modal-xl border-transparent-bg modal-dialog-c"
            >
              {/* <SearchWidgetNew page={"H"} /> */}
              <section className="sort-c1 modal-c">
                <div className="inner-wrapper" style={{ background: "white" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="tab-content " id="nav-tabContent">
                          <StartBooking
                            manageBanner={this.handleBanner}
                            handleHeaderActive={this.handleHeaderActive}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Modal>
          </div>
        ) : null}
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(withRouter(VehicleTypes));
