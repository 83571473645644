import React, {Component} from 'react';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import errorIcon from '../assets/images/404PageNotFound.png';
import { withTranslation } from 'react-i18next';
class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {     
            messageToDisplay:'Oops! We can`t seem to find the page you`re looking for...        ',
        }
    }
  

    componentDidMount = () => {
        let messageToDisplay= 'Oops! We can`t seem to find the page you`re looking for...        ';
        let message =  localStorage.getItem('thanksmessage');
        if(message !==null && message.toString() !== ''){            
            messageToDisplay = message.toString();
        }
        localStorage.removeItem('thanksmessage');
        this.setState({messageToDisplay:messageToDisplay});
    }


    render() {
        const { t } = this.props;
        return (

            <div>
                <ListingHeader></ListingHeader>

                <div className="minH100 box-shadow-white-box-a my-0 background-grey">
                    <div className="container">
                        <div className="row pt-5 align-items-center justify-content-center ">
                            <div className="col-sm-12 col-lg-8 col-md-12 col-12">
                                <div id="reserve p-3 contact-us-quick-box">
                                    <div className="rental-steps-wrap ">
                                        <div className="pickup-location-box text-center">
                                            <img src={errorIcon} alt="Page not found!" className="mb-3"/>
                                            <h3 className="font-bold mb-2  ">{this.state.messageToDisplay}</h3>
                                            <div className="pickup-location-flex">
                                                <div className="pickup-location-flex-content">
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <br></br><br></br>
                                <div className="col-lg-12">
                                    <div className="p-3 contact-us-quick-box">
                                        <h5>{t('Do you have further Question.1')}?</h5>
                                        <p></p>
                                        <div className="contact-us-quick-call">
                                            <a className="phone-quick" href={"tel:+97143365065"}><i class="fa fa-phone"></i>+971 4 336 5065</a>
                                            <a className="email-quick" href="mailto:customer.care@dollaruae.ae">
                                                {t('Email address.1')}: customer.care@dollaruae.ae
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                    


                                </div>
                            </div>

                        </div>


                    </div>
                </div>
                <ListingFooter />
            </div>

        );
    }
}

export default withTranslation()(NotFound);