import axios from 'axios';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import whatsapp from "../assets/images/whatsapp.svg";
import VehicleFeatureBox from '../Common/VehicleFeatureBox';
import EmailPopup from '../Include/EmailPopup';
import popupinfo from "../assets/images/popup-info-btn.svg";
import soldout from "../assets/images/Sold-Out-Transparent.png";
import infothin from "../assets/images/iconmonstr-info-thin.svg";
import { getCurrency, getLanguage } from '../Common/Util';
import ViewIncludeLongText from './MonthlyViewIncludeLongText';
var qs = require('qs');


class MonthlyIncludePageInline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payNowRate: '',
            rackRate: '',
            whatsappNumber: "",
            contactNo: "",
            isCheked: true,
            paySectionValue: '',
            showEmail: false,
            includeSection: [],
            currencyCode: '',
            defaultSubscriptionCost: [],
            subscriptMonth: [],
            subscriptKm: [],
            subscriptKmNote: [],
            payNowRate: 0,
            payLaterRate: 0,
            selectedMonth: 0,
            selectedKM: 0,
            buttonText: 'Book Now',

        }
    }

    componentDidMount() {
        let lngParam = getLanguage();
        this.state.defaultSubscriptionCost = this.props.model.SubscriptionCharges;
        if (this.props.model.SubscriptionCharges != undefined) {
            this.filterSubscriptionCost();
        }
        this.getBookingSetting();
        this.checkPaymentMode();

        setTimeout(() => {
            this.getBookingIncludeSection(lngParam);
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        this.state.defaultSubscriptionCost = this.props.model.SubscriptionCharges;
        this.state.currencyCode = getCurrency();
        if (prevProps.model != this.props.model) {

            this.checkPaymentMode();

            let lngParam = getLanguage();
            this.getBookingIncludeSection(lngParam);
            if (this.props.model.SubscriptionCharges != undefined) {
                this.setState({ defaultSubscriptionCost: [] });
                this.state.defaultSubscriptionCost = this.props.model.SubscriptionCharges;
                this.filterSubscriptionCost();
            }
        }
    }

    filterSubscriptionCost = () => {
        const subMonth = [];
        const orderSubscriptionMonth = [].concat(this.state.defaultSubscriptionCost).sort((a, b) => a.Month < b.Month ? 1 : -1);
        if (orderSubscriptionMonth.length > 0) {
            orderSubscriptionMonth.forEach(months => {
                if (!this.containsMonth(subMonth, months.Month) && months.KmExtraRate == 0) {
                    subMonth.push(months);
                }
            })

            if (subMonth.length > 0) {
                var defaultMonth = subMonth[0];
                this.SetSubscriptionPlan(defaultMonth);
            }

            this.setState({ subscriptMonth: subMonth });
        }
    }

    SetSubscriptionPlan = (defaultMonth) => {
        if (this.state.subscriptKmNote.length > 0) {
            this.setState({ subscriptKmNote: [] });
        }
        var result1 = [];
        var displayMonth = 0;
        this.state.defaultSubscriptionCost.forEach(months => {
            var message = "";

            if (defaultMonth.Month == months.Month) {
                displayMonth = defaultMonth.Month;
                if (months.KmExtraRate == 0) {
                    message = "Included with Subscription";
                    this.setState({ selectedKM: months.Km });
                    this.setState({ payLaterRate: months.Rate });
                    this.setState({ payNowRate: months.PayNowRate });
                }
                else {
                    message = ""
                }
                this.setState({ selectedMonth: months.Month });

                var kmNote = {
                    km: months.Km,
                    note: message,
                    extraKm: months.KmExtraRate,
                    Rate: months.Rate,
                    PayNowRate: months.PayNowRate,
                }
                result1.push(kmNote);
            }
        })

        const monthlyKMAllowance = [].concat(result1).sort((a, b) => a.extraKm > b.extraKm ? 1 : -1);

        this.setState({ subscriptKmNote: monthlyKMAllowance })

        if (displayMonth > 1) {
            this.setState({ buttonText: "Subscribe for " + displayMonth + " Months" });
        }
        else {
            this.setState({ buttonText: "Subscribe for " + displayMonth + " Month" });
        }
    }

    containsMonth = (subMonth, months) => {
        var i = -0;
        for (i = 0; i < subMonth.length; i++) {
            if (subMonth[i].Month.toString() == months.toString())
                return true;
        }
        return false;
    }

    containsKm = (subKm, km) => {
        var i = -0;
        for (i = 0; i < subKm.length; i++) {
            if (subKm[i].Km.toString() == km.toString())
                return true;
        }
        return false;
    }

    checkPaymentMode = () => {
        if (this.props.model.PaymentMode == 0) {
            this.setState({
                isCheked: false,
            });
            this.setState({
                paySectionValue: "payletter"
            });
        } else {
            this.setState({
                isCheked: true,
            });
            this.setState({
                paySectionValue: "paynow"
            });
        }

    }

    getBookingIncludeSection = (lngParam) => {
        if (this.props.model.TariffGroup != null) {
            this.props.searchParam.VehicleModelID = this.props.model.VehicleModelID;
            this.props.searchParam.TariffGroupID = this.props.model.TariffGroup.TariffGroupID;
            this.props.searchParam.ModelYear = this.props.model.ModelYear;
            this.props.searchParam.SubscriptionKm = this.state.selectedKM;
            this.props.searchParam.SubscriptionMonth = this.state.selectedMonth;
            var data = qs.stringify(this.props.searchParam);
            var config = {
                method: 'post',
                url: process.env.REACT_APP_API + 'AddOn/GetBookingIncluded?apiKey=' + process.env.REACT_APP_API_KEY + `&lng=${lngParam}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.setState({ includeSection: response.data });
                })
                .catch((error) => {

                });

        }
    }

    toogleEmailPopup = (showHide) => {

        this.setState({
            showEmail: showHide
        });


    }

    onBookNowClickHandler = () => {
        this.props.onVehicleChange(this.props.model, this.state.paySectionValue, this.state.selectedMonth, this.state.selectedKM);
    }
    onCloseHandler = () => {
        this.props.onVehicleIndexChange(9999, {});
    }

    getBookingSetting = () => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API + 'BookingSetting/GetBookingSetting?apiKey=' + process.env.REACT_APP_API_KEY,
            headers: {}
        };

        axios(config)
            .then((response) => {

                this.setState({
                    whatsappNumber: response.data.BookingPortalInformation
                        .WhatsAppContactNo,
                    contactNo: response.data.BookingPortalInformation
                        .CallContactNo
                })
            })
            .catch((error) => {

            });
    }

    onChangeValue = (e) => {
        const isCheked = this.state.isCheked
        this.setState({
            isCheked: !isCheked,
            paySectionValue: e.target.value
        });
    }

    onSubscriptionMonthPlan = (onSubscriptionPlan) => {

        this.SetSubscriptionPlan(onSubscriptionPlan);

    }

    onSubscriptionKmPlan = (onSubscriptionPlan) => {
        this.setState({ selectedKM: onSubscriptionPlan.km });
        this.setState({ payLaterRate: onSubscriptionPlan.Rate });
        this.setState({ payNowRate: onSubscriptionPlan.PayNowRate });
    }

    toogleViewIncludeLongTextPopUp = (showHide, LongText) => {
        this.setState({
            showExtrasDetails: showHide
        });
        this.setState({
            LongTextShow: LongText
        });

    }

    render() {

        const { t } = this.props
        const payNowRatePrice = (this.state.payNowRate * 1.05).toFixed(2);
        const payLaterRate = (this.state.payLaterRate * 1.05).toFixed(2);
        return (

            this.props.model != null && this.props.model.VehicleImages != null ?
                <div id="carTemp1">
                    <div className="vehicle-box-detail-wrap monthly-vehicle-box-detail-wrap" id={this.props.model.VehicleModelID}>

                        <div className="vehicle-box-detail-left">
                            <div className='monthly-vehicle-box-detail-left'>
                                <div>
                                    <h4>{this.props.model.Name}</h4>
                                    <span className="similar-text">{t('or Similar.1')} | {this.props.model.VehicleClassification.Name}
                                        {this.props.model.ModelYear != null ? <> | <b> {t('Model Year.1')} - {this.props.model.ModelYear} </b>
                                        </> : null}

                                    </span>
                                    <button type="button" className="close" aria-label="Close">
                                        <span aria-hidden="true">
                                            <img src={popupinfo} alt="Info" width="40" height="40" />
                                        </span>
                                        <ul>
                                            <li onClick={() => this.toogleEmailPopup(true)}>
                                                <a title="mail" className="book-now-all book-now-email" >
                                                    <i className="fa fa-envelope-o" aria-hidden="true"></i>{t('Email.1')}
                                                </a>
                                            </li>
                                            <li>
                                                <a title="Contact the Supplier directly via Phone call at +97143365065" data-transitionid="84c36da3-cd10-422d-a6dd-2165a1251bfb" data-transitiontype="SpecialOffer" data-mediatype="PhoneCall" href={`tel:${this.state.contactNo}`} className="book-now-all book-now-phone">
                                                    <i className="fa fa-phone" aria-hidden="true"></i>{t('Call.1')}
                                                </a>
                                            </li>
                                            <li>
                                                <a title="Contact the Supplier directly via WhatsApp at +971529780591" data-transitionid="84c36da3-cd10-422d-a6dd-2165a1251bfb" data-transitiontype="SpecialOffer" data-mediatype="WhatsApp" target="_blank" href={`https://api.whatsapp.com/send?phone=${this.state.whatsappNumber}&text=Hi, I'm contacting you through dollar.ae - I would like to rent the listed WEEKEND DHAMAKA. Is it available?`} className="book-now-all book-now-whatsapp">
                                                    <img src={whatsapp} alt="Whatsapp" width="20" height="20" />{t('WhatsApp.1')}
                                                </a>
                                            </li>
                                        </ul>
                                    </button>
                                    <div>
                                        <VehicleFeatureBox model={this.props.model} />
                                    </div>
                                </div>
                                <div className="vehicle-detail-slider-wrap slick-initialized slick-slider">
                                    {
                                        (this.props.model.IsSoldOut == true) ? <>
                                            <span class="soldOutDiv vehicle-sold-out"><img alt="Sold-Out-Transparent" src={soldout} class="soldOut_img" /></span>
                                            <div style={{ textAlign: 'center' }}>
                                                <img
                                                    className="carouselImage"
                                                    src={this.props.model.VehicleImages.FrontImageAttachment.ImageString}
                                                    alt="First slide"
                                                />
                                            </div></>
                                            :
                                            <div className="slick-list draggable">
                                                <div style={{ textAlign: 'center' }}>
                                                    <img
                                                        className="carouselImage"
                                                        src={this.props.model.VehicleImages.FrontImageAttachment.ImageString}
                                                        alt="First slide"
                                                    />
                                                </div>
                                            </div>
                                    }
                                </div>

                                <ul class="check-box-listing">
                                    {(this.state.includeSection != null) ? this.state.includeSection.map((include) => {
                                        return (

                                            <li>
                                                <span>
                                                    {include.ShortText}
                                                    <a className="text-16 text-red font-bold d-block" onClick={() => this.toogleViewIncludeLongTextPopUp(true, include.LongText)}>
                                                        <img alt="Sold-Out-Transparent" src={infothin} />
                                                    </a>
                                                </span>
                                            </li>

                                        )
                                    }) : ""}
                                </ul>
                                <br></br>
                                {
                                    (this.props.model.Features.length > 0) ?
                                        <p className="vehicle-featuress-content">
                                            {t('Vehicle Features.1')}<br></br>
                                            {
                                                this.props.model.Features.map((feature, index) => {
                                                    return (
                                                        <label key={feature.Name} style={{ color: "grey" }}>{(index ? ', ' : '') + feature.Name}</label>
                                                    )
                                                })
                                            }

                                        </p>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="vehicle-box-detail-right">
                            <div className="vehicle-box-detail-right-content">
                                <p className="vehicle-featuress-content">
                                    {t('Subscription Plan.1')}<br></br></p>
                                <div className="offer-filter-wrap monthly-sub-wrap">
                                    {
                                        this.state.subscriptMonth.map((subscriptMonth) =>
                                            <div className={"what--new-box"} >
                                                <a className={this.state.selectedMonth == subscriptMonth.Month ? "what--new-link what-new-box-active" : "what--new-link"}
                                                    onClick={() => { this.onSubscriptionMonthPlan(subscriptMonth) }} >
                                                    <p>{subscriptMonth.Month} Months</p>
                                                    <div className="what-new-box-more-content" >
                                                        <p className='mb-0'>
                                                            {(subscriptMonth.PayNowRate * 1.05).toFixed(2)} {t('AED.1')}
                                                            <br></br> <span>{t('per month.1')}</span>
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    }
                                </div>
                                <p className="vehicle-featuress-content mt-4">
                                    {t('Monthly KM Allowance.1')}<br></br></p>
                                <div className="offer-filter-wrap monthly-sub-wrap">
                                    {
                                        this.state.subscriptKmNote.map((notes) =>
                                            <div className={"what--new-box"}   >
                                                <a className={this.state.selectedKM == notes.km ? "what--new-link what-new-box-active" : "what--new-link"}
                                                    onClick={() => { this.onSubscriptionKmPlan(notes) }}>
                                                    <div className="what-new-box-more-km" >
                                                        <p>{notes.km} {t('KM.1')}</p>
                                                    </div>
                                                    <div className="what-new-box-more-content" >
                                                        <p className='mb-0'>
                                                            {notes.note.length != 0 ? <><span>{notes.note}</span></> :
                                                                notes.extraKm < 0 ?
                                                                    <><span>{t('Included with Subscription.1')}</span></> :
                                                                    <><span>{t('Extra.1')} {notes.extraKm.toFixed(2)} {t('AED.1')}</span></>
                                                            }
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    }
                                </div>


                                {
                                    (this.props.model.Description.length > 0) ?
                                        <div className="included-wrapper-featured">
                                            <p className="vehicle-featuress-content">
                                                {t('Vehicle Description.1')}<br></br>

                                                <label style={{ color: "grey" }}>{this.props.model.Description}</label>

                                            </p>
                                        </div>
                                        : null
                                }

                                <div className="featured-rent-price-radio">
                                    <div className="featured-rent-radio">

                                        {
                                            this.props.model.PaymentMode == 2 || this.props.model.PaymentMode == 1 ?
                                                this.props.searchParam.ModifiedReservationID == '00000000-0000-0000-0000-000000000000' ?
                                                    <div className="featured-rent-box-radio">
                                                        <input type="radio" id="paynow" name="featured-rent-price-radio" value="paynow" checked={this.state.isCheked} onChange={this.onChangeValue} disabled={this.props.model.TariffGroup != null && !this.props.model.TariffGroup.IsOnFreeSale} style={{ cursor: this.props.model.TariffGroup != null && !this.props.model.TariffGroup.IsOnFreeSale ? "not-allowed" : "pointer" }} />
                                                        <label htmlFor="paynow" style={{ cursor: !this.props.model.TariffGroup.IsOnFreeSale ? "not-allowed" : "pointer" }}>
                                                            <span>{t('Pay Now.1')} <p>{this.state.currencyCode}</p>  <b> {payNowRatePrice}</b></span> |
                                                            <> {t('month.1')}</>
                                                            <p>{t('Save Money Instantly.1')}</p>
                                                        </label>
                                                    </div> : null : null
                                        }
                                        {
                                            this.props.model.PaymentMode == 0 || this.props.model.PaymentMode == 2 ?
                                                this.props.searchParam.ModifiedReservationID != '00000000-0000-0000-0000-000000000000' ?
                                                    <div className="featured-rent-box-radio">
                                                        <input type="radio" id="payletter" name="featured-rent-price-radio" value="payletter" checked={!this.state.isCheked} onChange={this.onChangeValue} style={{ cursor: "pointer" }} />
                                                        <label htmlFor="payletter" style={{ cursor: "pointer" }}>
                                                            <span>{t('Pay Later.1')} <p>{this.state.currencyCode}</p>  <b>  {payLaterRate}</b></span> |
                                                            <> {t('month.1')}</>
                                                            <p>{t('When you pick your keys.1')}</p>
                                                        </label>
                                                    </div> : this.props.model.PaymentMode == 0 || this.props.model.PaymentMode == 2 ?
                                                        <div className="featured-rent-box-radio">
                                                            <input type="radio" id="payletter" name="featured-rent-price-radio" value="payletter" checked={!this.state.isCheked} onChange={this.onChangeValue} style={{ cursor: "pointer" }} />
                                                            <label htmlFor="payletter" style={{ cursor: "pointer" }}>
                                                                <span>{t('Pay Later.1')} <p>{this.state.currencyCode}</p>  <b>  {payLaterRate}</b></span> |
                                                                <> {t('month.1')}</>
                                                                <p>{t('When you pick your keys.1')}</p>
                                                            </label>
                                                        </div> : null : null
                                        }

                                    </div>

                                </div>
                                <div className="book-now-all-bttns-wrap">
                                    {
                                        this.props.model.IsSoldOut ?
                                            this.props.model.IsCallToBook ?
                                                <a title="Book Now" className="book-now-btttn" style={{ cursor: "not-allowed" }}  >
                                                    {t('Call Now.1')} - 800 4710
                                                </a>
                                                :
                                                <a title="Book Now" className="book-now-btttn" style={{ cursor: "not-allowed" }} >
                                                    {t('Book Now.1')}
                                                </a>
                                            :
                                            (
                                                this.props.model.IsCallToBook ?
                                                    <a title="Book Now" className="book-now-btttn" href={"tel:+97143365065"} >
                                                        {t('Call Now.1')} - +971 4 336 5065
                                                    </a>
                                                    :
                                                    <a title="Book Now" className="book-now-btttn" onClick={this.onBookNowClickHandler} >
                                                        {this.state.buttonText}
                                                    </a>
                                            )

                                    }

                                </div>
                                <p className="tax-included" >Prices are inclusive of VAT.</p>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.showExtrasDetails ?
                            <div>
                                <div className="modal-dialog modal-xl modal-dialog-centered">
                                    <Modal show={this.state.showExtrasDetails} onHide={() => this.toogleViewIncludeLongTextPopUp(false)} dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap">
                                        <ViewIncludeLongText LongTextShow={this.state.LongTextShow} />
                                    </Modal>
                                </div>
                            </div>
                            : null
                    }
                    {
                        this.state.showEmail ?
                            <div className="modal-dialog modal-xl modal-dialog-centered">
                                <Modal show={this.state.showEmail} onHide={() => this.toogleEmailPopup(false)} dialogClassName="modal-dialog modal-xl modal-dialog-centered">
                                    <EmailPopup model={this.props.model} searchParam={this.props.searchParam} additionalParam={this.props.additionalParam} includeSection={this.state.includeSection} />
                                </Modal>
                            </div>
                            : null
                    }

                </div>
                : null


        );
    }
}



export default hoistStatics(withTranslation()(MonthlyIncludePageInline), MonthlyIncludePageInline);