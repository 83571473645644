import axios from 'axios';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import "../assets/css/slick.css";
import { getLanguage } from '../Common/Util';
import CustomSlide from './CustomSlide';
import LessFineNextArrow from './LessFineNextArrow';
import LessFinePrevArrow from './LessFinePrevArrow';
class VehicleClassifications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      vehicleTypes: [],
      ID: ''
    };
  }
  

  componentDidMount = () => {
    this.getVehicleTypes();
  }
  getAllBranches = (VehicleClassificationID) => {

    let lanParam = getLanguage();
    var config = {
      method: 'get',
      url: process.env.REACT_APP_API + 'Branch/Get?apiKey=' + process.env.REACT_APP_API_KEY + `&lng=${lanParam}`,
      headers: {}
    };

    axios(config)
      .then((response) => {
        if (response.data.length > 0) {

          this.getHoursToReady(response.data[0].Identity.Id, VehicleClassificationID, response.data[0].Name, response.data[0].Attachment.ImageString, response.data[0].Address,response.data[0].Message,response.data[0].Description);
        }

      })
      .catch((error) => {

      });

  }
  getHoursToReady = (BrachId, VehicleClassificationID, Name, Image, Address,Message,Description) => {

    var config = {
      method: 'get',
      url: process.env.REACT_APP_API + 'Branch/GetBranchHrsToGetReady?apiKey=' + process.env.REACT_APP_API_KEY + `&branchID=${BrachId}`,
      headers: {}
    };

    axios(config)
      .then((response) => {

        let pickupDate = moment(moment.tz(response.data.PickupOn,"Asia/Dubai")).format('MM/DD/YYYY  h:mm:ss A');
        let returnDate = moment(moment.tz(response.data.PickupOn,"Asia/Dubai")).format('MM/DD/YYYY  h:mm:ss A');

        var BookingInfo = [{
          "PickupLocationId": BrachId,
          "DestinationLocationId": BrachId,
          "PickUpDate": pickupDate,
          "ReturnDate": returnDate,
          "pickupLocationName": Name,
          "dropOffLocationName": Name,
          "pickupLocationImage": Image,
          "dropOffLocationImage": Image,
          "pickupLocationAddress": Address,
          "dropOffLocationAddress": Address,
          "pickupLocationMessage": Message,
          "dropOffLocationMessage": Message,
          "pickupLocationBranchDescription":  Description,
          "dropOffLocationBranchDescription":  Description,
          "promocode": ""
        }];

        localStorage.setItem('VehicleTypeParam', VehicleClassificationID);
        localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
        this.props.history.push("/booking");


      })
      .catch((error) => {

      });

  }
  onSeeAllCarsClick = (Id) => {

    this.getAllBranches(Id);

  }
  getVehicleTypes = () => {
    let lngParam = getLanguage();
    axios(process.env.REACT_APP_API + 'VehicleClassification/Get?apiKey=' + process.env.REACT_APP_API_KEY + `&lng=${lngParam}`).then((responce) => {

      const vehicleTypes = responce.data;
      this.setState({ vehicleTypes: vehicleTypes });
    });

  }

  onViewMoreClick = (Id) => {
    localStorage.setItem('vehicleClassificationID',Id);
    this.props.history.push(`/vehicletypes/${Id}`);
  }
  render() {
    const settings = {
   
      infinite: true,
      slidesToShow:3,
      slidesToScroll: 1,
      autoplay:true,
      dots: true,
      centerPadding: '0',
      centerMode: true,
      prevArrow: <LessFinePrevArrow />,
      nextArrow: <LessFineNextArrow />,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow:1,
            slidesToScroll: 1,
            centerMode: true,
          }
        }
      ]
    };
    const { t } = this.props;
    return (

          <Slider className="more-car-slider-wrap mb-0 slick-dotted" {...settings}>
            {this.state.vehicleTypes.map((vehicleType, index) => {
              return (
                <div className="more-cars-slider" key={vehicleType.VehicleClassificationID}>
                <div style={{ textAlign: 'center',alignItems:'center' }} 
                onClick={() => { this.onViewMoreClick(vehicleType.VehicleClassificationID) }}>
                  <img 
                    src={vehicleType.Attachment.ImageString}
                    alt="First slide"  style={{width:"572", height:"282"}}
                  />
                   {
                    vehicleType.Name == "Dollar Prestige" ?  <h4 className="text-red">{vehicleType.Name}</h4>
                   : <h4>{vehicleType.Name}</h4>
                  }
                
                <p>{vehicleType.ShortDetails}</p>
                <a className="link" title="Explore" onClick={() => { this.onViewMoreClick(vehicleType.VehicleClassificationID) }} >
                </a> 
                </div>
                </div>
              )
            })}

          </Slider>
    );
  }
}

export default withTranslation()(withRouter(VehicleClassifications));