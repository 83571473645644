
import axios from 'axios';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import "../assets/css/slick.css";



class PickupDateRespPopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPickupDate: Date().toLocaleString(),
            selectedReturnDate: Date().toLocaleString(),
            pickupTime: "09:00",
            returnTime: "09:00",
            timeList: [],
            rentalDays: 0
        }
    }
    onPickupBranchSelected = (selectedBranch) => {

    }


    componentDidMount() {
        this.getTimeSlots();
    }

    getTimeSlots = () => {
        axios(process.env.REACT_APP_API + 'Branch/GetTimeSlots?apiKey=' + process.env.REACT_APP_API_KEY).then((response) => {
            this.setState({ timeList: response.data });
        });
    }
    componentDidUpdate(prevProps) {

        if (prevProps.selectedPickupDate != this.props.selectedPickupDate) {
            this.setState({ selectedPickupDate: this.props.selectedPickupDate });
        }

        if (prevProps.selectedReturnDate != this.props.selectedReturnDate) {
            this.setState({ selectedReturnDate: this.props.selectedReturnDate });
        }

        if (prevProps.pickupTime != this.props.pickupTime) {
            this.setState({ pickupTime: this.props.pickupTime });
        }

        if (prevProps.returnTime != this.props.returnTime) {
            this.setState({ returnTime: this.props.returnTime });
        }
    }

    onChangePickUpDate = (startDate, endDate) => {
        let selectedPickupDate = moment(moment(startDate).format('MM/DD/YYYY') + " " + this.state.pickupTime);
        selectedPickupDate = moment(selectedPickupDate).format('MM/DD/YYYY  h:mm:ss A');

        if (endDate == null) {
            endDate = startDate;
        }

        let selectedReturnDate = moment(moment(endDate).format('MM/DD/YYYY') + " " + this.state.returnTime);
        selectedReturnDate = moment(selectedReturnDate).format('MM/DD/YYYY  h:mm:ss A');

        this.setState({ selectedPickupDate: selectedPickupDate });
        this.setState({ selectedReturnDate: selectedReturnDate });

        this.props.onChangePickupDate(selectedPickupDate);
        this.props.onChangeReturnDate(selectedReturnDate);
        this.getRentalDays(selectedPickupDate,selectedReturnDate);
    }

    getRentalDays = (selectedPickupDate,selectedReturnDate) => {
        axios(process.env.REACT_APP_API + 'Reservations/GetRentalDays?apiKey=' + process.env.REACT_APP_API_KEY + `&pickup=${selectedPickupDate}&dropoff=${selectedReturnDate}`)
            .then((response) => {
                this.setState({ rentalDays: response.data.RentalDays });
            });
    }

    onPickupTimeChange = (timeSlot) => {

        let selectedPickupDate = moment(moment(this.state.selectedPickupDate).format('MM/DD/YYYY') + " " + timeSlot);
        selectedPickupDate = moment(selectedPickupDate).format('MM/DD/YYYY  h:mm:ss A');

        let selectedReturnDate = moment(moment(this.state.selectedReturnDate).format('MM/DD/YYYY') + " " + timeSlot);
        selectedReturnDate = moment(selectedReturnDate).format('MM/DD/YYYY  h:mm:ss A');

        this.setState({ pickupTime: timeSlot });
        this.setState({ returnTime: timeSlot });

        this.setState({ selectedPickupDate: selectedPickupDate });
        this.setState({ selectedReturnDate: selectedReturnDate });
        this.props.onChangePickupDate(selectedPickupDate);
        this.props.onChangeReturnDate(selectedReturnDate);

        this.getRentalDays(selectedPickupDate,selectedReturnDate);
    }

    onRetunrTimeChange = (timeSlot) => {
        let selectedReturnDate = moment(moment(this.state.selectedReturnDate).format('MM/DD/YYYY') + " " + timeSlot);
        selectedReturnDate = moment(selectedReturnDate).format('MM/DD/YYYY  h:mm:ss A');

        this.setState({ returnTime: timeSlot });
        this.setState({ selectedReturnDate: selectedReturnDate });

        this.props.onChangeReturnDate(selectedReturnDate);
        this.getRentalDays(this.state.selectedPickupDate, selectedReturnDate);
    }

    onClosePopover = ()=>{
     
        var elementToHide = document.getElementsByClassName("dialog-date-picker open");
        if(elementToHide[0] != undefined){
            elementToHide[0].className = 'dialog-date-picker hide';
        }
        this.props.onClosePopover();
    }

    render() {

        const { t } = this.props;
      
        var minDate = moment(Date());
        return (
            this.props.searchWidgetMode == 10 ?
                <div className="pickup-dropdown-box-wrapper pickup-return-date-wrapper date-model-wrap">
                    <div className="pickup-dropdown-box-left">

                        <div className="StationHistory__wrapper">
                            <div className="StationHistory__stationName">
                                <div >
                                    <div className="pickup-location-input group-input">

                                        <RangeDatePicker
                                            startDate={new Date(moment(this.state.selectedPickupDate).year(), moment(this.state.selectedPickupDate).month(), moment(this.state.selectedPickupDate).date())}
                                            endDate={new Date(moment(this.state.selectedReturnDate).year(), moment(this.state.selectedReturnDate).month(), moment(this.state.selectedReturnDate).date())}

                                            onChange={(startDate, endDate) => this.onChangePickUpDate(startDate, endDate)}
                                            minDate={new Date(moment(minDate).year(), moment(minDate).month(), moment(minDate).date())}

                                            isOpen="true"
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="confirm-mobile-btnn">
                        <a className="primary-bttn" title="book" onClick={this.onClosePopover}>
                            {t('Confirm Date.1')}
                        </a>
                    </div>
                </div>
                : null


        );
    }
}


export default withTranslation()(withRouter(PickupDateRespPopover));