import React, { Component } from 'react';
import 'react-google-flight-datepicker/dist/main.css';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import axios from 'axios';
import QuoteRequest from './QuoteRequest';
import SpecialOfferBooking from './SpecialOfferBooking';
import { getLanguage } from '../Common/Util';
import moment from 'moment-timezone';
import SearchWidget from '../Hire/SearchWidget';
import SearchWidgetNew from '../Hire/SearchWidgetNew';
import { animateScroll as scroll } from 'react-scroll';
import StartBooking from '../MonthlySubscription/Tabs/StartBooking';

class BookingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            specialoffer: {},
            headerActive : true
        }
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;
        let lngParam = getLanguage();

        this.getSpecialOffer(params.index, lngParam);
        scroll.scrollToTop();
    }
    
    getSpecialOffer = (SpecialOfferID, lngParam) => {

        var config = {
            method: 'get',
            url: process.env.REACT_APP_API + `SpecialOffer/GetByID?apiKey=` + process.env.REACT_APP_API_KEY + `&specialOfferID=${SpecialOfferID}&lng=${lngParam}`,
            headers: {}
        };

        axios(config)
            .then((response) => {
                console.log(response.data);
                this.setState({
                    specialoffer: response.data
                });
            })
            .catch((error) => {
            });


    }

    handleBanner = (value) => {}

    handleHeaderActive = (value) => {
        if (window.innerWidth > 553) {
            this.setState({ headerActive: value });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
            {this.state.headerActive && <ListingHeader />}
                
                <div class="special-offer-page-wrap bg-grey minH100">
                    {this.state.headerActive &&   
                    <section className="box-shadow-white-box-section pt-3" id="partner">
                        <div className="container-fluid">
                            <div className="box-shadow-white-box-a ">
                                <div className="">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div class="special-offer-banner">
                                                <img src={(this.state.specialoffer != null && this.state.specialoffer.OfferAttachment != null) ? this.state.specialoffer.OfferAttachment.ImageString : ""} alt={t('Booking Details.1')} className="img-fluid" />
                                                {
                                                    (this.state.specialoffer != null && this.state.specialoffer.FavouriteNote != null && this.state.specialoffer.FavouriteNote != '') ?
                                                        <div class="discount-sticker">{(this.state.specialoffer != null && this.state.specialoffer.FavouriteNote != null && this.state.specialoffer.FavouriteNote != '') ? this.state.specialoffer.FavouriteNote : ''}</div>
                                                        : null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    }
                    <div className="position-relative search-widget-module">
                        {
                            this.state.specialoffer.BookingMode == 0
                            ?
                            
                            <section className="sort-c1 bookingDeatils">
                            <div className="inner-wrapper" style={{ background: "white" }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="tab-content " id="nav-tabContent">
                                      <StartBooking manageBanner={this.handleBanner} handleHeaderActive={this.handleHeaderActive}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                            :null
                        }
                    </div>
                                    

                    <section className="box-shadow-white-box-section pb-4">
                        <div className="container-fluid">
                            <div className="box-shadow-white-box mb-0" id="About">
                                <div className="row">
                                    <div className="col-lg-7 p-3">
                                        <h2 className="border-botom">{this.state.specialoffer.Name}</h2>
                                        <p className="text_p">{this.state.specialoffer.Description}</p>
                                        {
                                            // this.state.specialoffer.BookingMode == 1 &&
                                             this.state.specialoffer.EnableBookingDateValidation == true?
                                             <>
                                                    <div class="special-offer-start-end-date-wrap">
                                                        <div class="special-offer-start-end-date-col">
                                                            <h6 className="font-weight-bold">{t('Start Date.1')}</h6>
                                                            <p className="text_p mt-2">{moment(this.state.specialoffer.BookingFrom).format('DD MMM YYYY, h:mm A')}</p>
                                                        </div>
                                                        <div class="special-offer-start-end-date-col">
                                                            <h6 className="font-weight-bold">{t('End Date.1')}</h6>
                                                            <p className="text_p mt-2">{moment(this.state.specialoffer.BookingTo).format('DD MMM YYYY, h:mm A')}</p>
                                                        </div>
                                                    </div>
                                                </> : null
                                        }

                                        {
                                            this.state.specialoffer.TermsAndConditions != ''?
                                             <>
                                              <h6 className="font-weight-bold mt-4 text-center text-sm-left">{t('Terms & Conditions.1')}</h6>
                                              <p className="text_p text-center text-sm-left">{this.state.specialoffer.TermsAndConditions}</p>
                                             </> : null
                                        }

                                        </div>
                                    {
                                        this.state.specialoffer.BookingMode == 0 ?
                                            // <SpecialOfferBooking />
                                            null
                                            :
                                            <QuoteRequest SpecialOfferID={this.state.specialoffer.SpecialOfferID} />

                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <ListingFooter />
            </>
        );
    }
}

export default withTranslation()(withRouter(withRouter(BookingDetails)));