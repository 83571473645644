import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
class MonthlyViewIncludeLongText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LongTextShow: this.props.LongTextShow
        }

    }

    componentDidMount = () => {
        var htmlText = this.state.LongTextShow.replaceAll("text-indent:-.25in;mso-list:l3 level1 lfo4", "").replaceAll("text-indent:-.25in;mso-list:l2 level1 lfo3","").replaceAll("text-indent:-.25in;mso-list:l1 level1 lfo5","");
        this.setState({LongTextShow : htmlText});
    }

    render() {
        const { t } = this.props
        return (
            <div>
                {<Modal.Header closeButton >
                    <h4>{t('View Details.1')}</h4>
                </Modal.Header>}
                <Modal.Body>
                    {
                        this.props.IsTextWithHtml ?
                        <div className="font-18" dangerouslySetInnerHTML={ { __html: this.state.LongTextShow}}></div>
                        :
                        <p>{this.props.LongTextShow}</p>
                    }

                </Modal.Body>
            </div>
        );
    }
}
export default withTranslation()(MonthlyViewIncludeLongText);