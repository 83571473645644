import React, { Component } from 'react';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import privacypolicybanner from '../assets/images/privacy-policy-banner@2x.png';
class SecurityTips extends Component {
    render() {
        return (
            <>
            <ListingHeader/>
            <section className="box-shadow-white-box-section  " id="partner">
                <div className="container-fluid">
                    <div className="box-shadow-white-box-a ">
                        <div className="">
                            <div className="row flex-reversea">
                                <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                                    <div className="pickup-location-box">
                                        <h5 className="font-bold mb-3 mt-2 ">Security Tips</h5>
                                        <div className="pickup-location-flex">

                                            <div className="pickup-location-flex-content">
                                                <h3 className="mb-4">Lorem Ipsum is that it has a more-or-less</h3>
                                                <span>
                                                    Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                                    <img src={privacypolicybanner} alt="Dollar Security Tips" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="box-shadow-white-box-section">
        <div className="container-fluid">
            <div className="box-shadow-white-box">
               <div className="row">
                   <div className="col-lg-7 p-3">
                      <h5 className="font-weight-bold">Lorem Ipsum</h5>
                      <p className="text_p mt-4">Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now Lorem Ipsum is that it has Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now Lorem Ipsum is that it has</p>
                      <br/>
                       <h5 className="font-weight-bold">Lorem Ipsum</h5>
                      <p className="text_p mt-4">Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now Lorem Ipsum is that it has Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now Lorem Ipsum is that it has</p>
                      <br/>
                       <h5 className="font-weight-bold">Lorem Ipsum</h5>
                      <p className="text_p mt-4">Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now Lorem Ipsum is that it has Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now Lorem Ipsum is that it has</p>
                   </div>
                   <div className="col-lg-5">
                       <div className="p-3 bg_custom">
                           <h5 className="font-weight-bold">Lorem Ipsum</h5>
                           <br/>
                           <ul className="check-box-listing ">
                                <li>
                                    <span>
                                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    </span>
                                    
                                </li>
                                <br/>
                                <li>
                                    <span>
                                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    </span>
                                    
                                </li>
                                <br/>
                                <li>
                                    <span>
                                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    </span>
                                    
                                </li>
                                <br/>
                                <li>
                                    <span>
                                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    </span>
                                    
                                </li>
                                <br/>
                                <li>
                                    <span>
                                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                    </span>
                                    
                                </li>
                                <br/>
                                <li>
                                    <span>
                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    </span>
                                    
                                </li>
                                <br/>
                                <li>
                                    <span>
                                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    </span>
                                    
                                </li>
                                

                               
                               
                            </ul>
                       </div>
                   </div>
               </div>
               
            </div>
        </div>
    </section>
            <ListingFooter/>

        </>
        );
    }
}

export default SecurityTips;