import React, { Component } from 'react';
import {getCustomerId} from '../Common/Util';
import { getLanguage } from '../Common/Util';
import axios from 'axios';
import ListingHeader from '../Common/ListingHeader';
import ListingFooter from '../Common/ListingFooter';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import hoistStatics from 'hoist-non-react-statics';
import { animateScroll as scroll,scroller  } from 'react-scroll';
import Truck from "../assets/images/TuSimple_Self_Drving_Truck_4_copy.0.png";
import ZeroImage from "../assets/images/0x0.png";
import Group73 from "../assets/images/Group-733.png";

import commercialVehicle from "../assets/images/Business/CommercialVehicle2.png";
import speciallizedVehicle from "../assets/images/Business/2019ToyotaHiAce012.jpg";
import PersonaLeaseVehicle from '../assets/images/Business/personalLeasing.png'
import FleetManagementVehicle from "../assets/images/Business/fleetManagement.jpg";

class TransportFleetMenuBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onMenuLinkClick = (path)=>{
      this.props.history.push(path);
      scroll.scrollToTop();
  }
   

    render() {
        const { t } = this.props
        return (<>
            <section className="box-shadow-white-box-section grey-bg py-2">
               <div className="container-fluid">
                  <div className="box-shadow-white-box-a mt-0" id="partner">
                     <div className="pad-30">
                        <div className="row">
                           <div className="col-12">
                              <div className="pickup-location-box" >
                                 <h5 className="font-bold mb-3 text-center">{t('We host UAE’s largest & widest fleet, for all your transportation needs.1')}</h5>

                                 <p className="text-center font-18 text-grey">
                                   {t('Dollar Car Rental UAE takes pride in being associated with leading brands in the UAE like Pepsi, Mai Dubai, Pran Foods, NFPC, Aramex, Carrefour, etc. as their total transportation solutions partner, by helping them achieve reduced fleet management costs, improved fleet compliance, customized vehicle-builds, and other business-specific goals.1')}
                                 </p>
                              </div>
                           </div>
                           <div className="col-sm-12 col-lg-6" id= "truckrental" name="truckrental">
                              <img src={commercialVehicle} className="rounded-xl imageAdjust" alt="Commuercial Vehicle"/>
                              <div className="pickup-location-flex-content">
                                 <div className="mb-4 mt-2 d-flex align-items-center flex-wrap justify-content-between">
                                    <h3 className="text-sm-center">{t('Commercial Vehicles.1')}</h3>
                                    <a className="red-link-btn" onClick={() => this.onMenuLinkClick('/explorecorporatefleet')}>{t('View More.1')}<i class="fa fa-angle-right"></i></a>
                                 </div>   
                              </div>
                           </div>
                           <div className="col-sm-12 col-lg-6">
                              <img src={speciallizedVehicle}  className="rounded-xl imageAdjust" alt="Specialized Business Vehicles"/>
                              <div className="pickup-location-flex-content">
                                 <div className="mb-4 mt-2  d-flex align-items-center flex-wrap justify-content-between">
                                    <h3 className="text-sm-center">{t('Specialized Business Vehicles.1')}</h3>
                                    <a className="red-link-btn" onClick={() => this.onMenuLinkClick('/specializedvehicles')}>{t('View More.1')}<i class="fa fa-angle-right"></i></a>
                                 </div>
                              </div>
                           </div>
                           
                           <div className="col-sm-12 col-lg-6">
                              <img src={PersonaLeaseVehicle}  className="rounded-xl imageAdjust" alt="Personal Leasing"/>
                              <div className="pickup-location-flex-content">
                                 <div className="mb-4 mt-2  d-flex align-items-center flex-wrap justify-content-between">
                                    <h3 className="text-sm-center">{t('Personal lease.1')}</h3>
                                    <a className="red-link-btn" onClick={() => this.onMenuLinkClick('/personalleasing')}>{t('View More.1')}<i class="fa fa-angle-right"></i></a>
                                 </div>
                              </div>
                           </div>
                           <div className="col-sm-12 col-lg-6">
                              <img src={FleetManagementVehicle}  className="rounded-xl imageAdjust" alt="Fleet Management Solutions"/>
                              <div className="pickup-location-flex-content">
                                 <div className="mb-4 mt-2  d-flex align-items-center flex-wrap justify-content-between">
                                    <h3 className="text-sm-center">{t('Fleet Management Solutions.1')}</h3>
                                    <a className="red-link-btn" onClick={() => this.onMenuLinkClick('/fleetmanagementsolutions')}>{t('View More.1')}<i class="fa fa-angle-right"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {/* <Modal show={this.state.showContact} onHide={() => this.toogleContactPopup(false)} dialogClassName="modal-dialog modal-xl modal-dialog-centered">
                    <ContactPopup leasingEnquiry = {this.state.leasingEnquiry} />
                </Modal> */}
            </section>
        </>);
    }
}

export default hoistStatics(withTranslation()(withRouter(TransportFleetMenuBox)), TransportFleetMenuBox);