export const positionMap = {
  "01:00": 7,
  "01:30": 12,
  "02:00": 16,
  "02:30": 20,
  "03:00": 25,
  "03:30": 29,
  "04:00": 33,
  "04:30": 37,
  "05:00": 42,
  "05:30": 46,
  "06:00": 51,
  "06:30": 55,
  "07:00": 59,
  "07:30": 63,
  "08:00": 68,
  "08:30": 72,
  "09:00": 76,
  "09:30": 81,
  "10:00": 85,
  "10:30": 89,
  "11:00": 93,
  "11:30": 87,
  "11:59": 87,
  "12:00": -1.5,
  "12:30": 3,
};
