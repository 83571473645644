import axios from "axios";
import hoistStatics from "hoist-non-react-statics";
import moment from "moment-timezone";
import React, { Component } from "react";
import "react-google-flight-datepicker/dist/main.css";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { getLanguage } from "../Common/Util";
import StartBooking from "../MonthlySubscription/Tabs/StartBooking";

class RentalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allBranches: [],
      selectedPickupLocation: null,
      selectedDropLocation: null,
      //modifiedReservationID : '00000000-0000-0000-0000-000000000000'
    };
  }
  componentDidMount() {
    this.getBranches(this.props.pickupLocationName);
    this.getBranchesDrop(this.props.dropOffLocationName);

    // this.modifiedReservationID = this.props.modifiedReservationID;
  }
  getBranches = (branchName) => {
    let lngParam = getLanguage();
    axios(process.env.REACT_APP_API + "Branch/Get?apiKey=" + process.env.REACT_APP_API_KEY + `&lng=${lngParam}`).then((responce) => {
      const allBranches = responce.data;
      this.setState({
        allBranches: allBranches,
      });

      allBranches.forEach((branch) => {
        if (branch.Name.toString() == branchName.toString()) {
          this.setState({
            selectedPickupLocation: branch,
          });
        }
      });

      if (this.state.selectedPickupLocation == null || this.state.selectedPickupLocation == "undefined") {
        var selectedPickupLocation = allBranches[0];
        selectedPickupLocation.Identity.Id = "00000000-0000-0000-0000-000000000000";
        selectedPickupLocation.Name = this.props.pickupLocationName;
        this.setState({ selectedPickupLocation: selectedPickupLocation });
      }
    });
  };

  getBranchesDrop = (branchName) => {
    let lngParam = getLanguage();
    axios(process.env.REACT_APP_API + "Branch/Get?apiKey=" + process.env.REACT_APP_API_KEY + `&lng=${lngParam}`).then((responce) => {
      const allBranches = responce.data;
      this.setState({
        allBranches: allBranches,
      });

      allBranches.forEach((branch) => {
        if (branch.Name == branchName) {
          //alert('Select-->' +branch.Name );
          this.setState({
            selectedDropLocation: branch,
          });
        }
      });

      if (this.state.selectedDropLocation == null || this.state.selectedDropLocation == "undefined") {
        var selectedDropLocation = allBranches[0];
        selectedDropLocation.Identity.Id = "00000000-0000-0000-0000-000000000000";
        selectedDropLocation.Name = this.props.dropOffLocationName;
        this.setState({ selectedDropLocation: selectedDropLocation });
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.PickUpDate != this.props.PickUpDate) {
      this.setState({
        selectedPickupDate: moment(this.props.PickUpDate)._d,
      });

      this.setState({
        pickupTime: moment(this.props.PickUpDate).format("hh:mm"),
      });
    }
    if (prevProps.ReturnDate != this.props.ReturnDate) {
      this.setState({
        selectedReturnDate: moment(this.props.ReturnDate)._d,
      });

      this.setState({
        returnTime: moment(this.props.ReturnDate).format("hh:mm"),
      });
    }

    if (prevProps.promoCode != this.props.promoCode) {
      this.setState({
        promocode: this.props.promoCode,
      });
    }

    if (prevProps.showDetails != this.props.showDetails) {
      this.setState({
        showDetails: this.props.showDetails,
      });
    }

    if (prevProps.pickupLocationName != this.props.pickupLocationName) {
      this.getBranches(this.props.pickupLocationName);
    }

    if (prevProps.dropOffLocationName != this.props.dropOffLocationName) {
      this.getBranchesDrop(this.props.dropOffLocationName);
    }
  }

  handleBanner = () => {
    
  }

  render() {
    return (
      <div>
        {this.state.showDetails ? (
          <div className="listing-rental-details-wrapper listing-rental-details-wrapper-booking">
            {/* <SearchWidgetNew pickupLocation={this.state.selectedPickupLocation} dropLocation={this.state.selectedDropLocation} page={"L"} promoCode={this.props.promoCode} PickUpDate={this.props.PickUpDate}
                            modifiedReservationID={this.props.modifiedReservationID}
                            ReturnDate={this.props.ReturnDate} 
                             deliveryState = {this.props.deliveryState} deliveryAddress = {this.props.deliveryAddress}
                             returnState = {this.props.returnState} returnAddress = {this.props.returnAddress}
                            ></SearchWidgetNew> */}
            <section className="sort-c1 reservationHeight">
              <div className="inner-wrapper" style={{ background: "white" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="tab-content " id="nav-tabContent">
                        <StartBooking manageBanner={this.handleBanner} handleHeaderActive={this.props.handleHeaderActive}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : null}
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(RentalDetails)), RentalDetails);
