
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import ListingHeader from '../Common/ListingHeader';
import ListingFooter from '../Common/ListingFooter';
import LoginPopup from './LoginPopup';


class Login extends Component {
   constructor(props) {
      super(props);

   }

   render() {
      return (
         <>
            <ListingHeader />
            <LoginPopup></LoginPopup>
            <ListingFooter />
         </>
      );
   }
}

export default hoistStatics(withTranslation()(Login), Login);