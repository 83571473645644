import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { Component } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import ListingFooter from "../Common/ListingFooter";
import ListingHeader from "../Common/ListingHeader";
import { getLanguage } from "../Common/Util";
import "../MonthlySubscription/Tabs/tabsStyle.css";
import FindReservationImg from "../assets/images/Business/177914762.jpg";
import DriverDetailsIcon from "../assets/images/driver-details-icon.svg";
import envelopImg from "../assets/images/envelop.svg";
import hashImg from "../assets/images/hash.svg";
import FAQSection from "./FAQSection";

class FindReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationNo: "",
      email: "",
      loading: false,
      isActive: 0,
    };
  }

  componentDidMount() {
    this.handleSelect(0);
    scroll.scrollToTop();
  }

  handleSelect = (index) => {
    this.setState({ avaliableindex: index });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = () => {
    let lngParam = getLanguage();

    this.setState({
      errorMessage: "",
    });

    let valid = true;

    if (this.state.confirmationNo?.trim() == "") {
      if (lngParam == "en") {
        this.setState({
          errorMessage: "The confirmationNo field is required",
        });
      } else {
        //ar
        this.setState({
          errorMessage: "مطلوب مجال confirmationNo",
        });
      }

      return false;
    }
    if (this.state.email == undefined || this.state.email === "") {
      if (lngParam == "en") {
        this.setState({ errorMessage: "The email field is required" });
      } else {
        //ar
        this.setState({
          errorMessage: "The email field is required",
        });
      }

      return false;
    }
    return valid;
  };
  handleClose = () => {
    this.setState({
      errorMessage: "",
    });
  };
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isActive: 0 });
    if (this.validate()) {
      var axios = require("axios");
      this.setState({ loading: true });
      var config = {
        method: "get",
        url: process.env.REACT_APP_API + `Reservations/Get?apiKey=` + process.env.REACT_APP_API_KEY + `&reservationNo=${this.state.confirmationNo}&contactNo=&emailId=${this.state.email}`,
        headers: {},
      };

      axios(config)
        .then((response) => {
          this.setState({ loading: false });
          this.props.history.push(`/reservationmodify/${response.data.Identity.Id}/1`);
        })
        .catch((error) => {
          this.setState({ errorMessage: error.response.data.Message });
          this.setState({ loading: false });
        });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental UAE | View, Modify or Cancel your Booking</title>
          <meta name="description" content="Manage or modify your booking conveniently and have a hassle-free experience."></meta>
          <meta property="og:title" content="Dollar Car Rental UAE | View, Modify or Cancel your Booking"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/offers"></meta>
          <meta property="og:description" content="Manage or modify your booking conveniently and have a hassle-free experience."></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100 grey-bg py-2">
          <section className="box-shadow-white-box-section grey-bg pt-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="">
                  <div className="row flex-reversea mb-3">
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12 mt-4 mt-lg-0">
                      <div className="pb-2">
                        <h2 className="border-botom">{t("View, Modify, Cancel Reservation.1")}</h2>
                        <p>{t("Already got your Dollar Car Reserved.1")}</p>

                        <p className="text_p mt-4">
                          {t("Enter your confirmation number below to view it. Your confirmation number was printed at the top of the confirmation form when you reserved your car.1")}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <img src={FindReservationImg} alt="Dollar Reservation" className="img-fluid rounded-xl" />
                    </div>
                  </div>
                  <div className="box-title-icon-border d-flex align-items-center mb-4">
                    <span className="box-title-icon-rounded d-flex align-items-center justify-content-center">
                      <img src={DriverDetailsIcon} alt="Dollar Details" width="16" height="16" style={{ display: "block" }} />
                    </span>
                    <h4 className="text-16 text-red m-0">{t("Lookup Reservation.1")}</h4>
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="custom-input-type-wrap">
                        <label htmlFor="first-name">
                          {t("Confirmation Number.1")}
                        </label>
                        <input
                          type="text"
                          id="customer-name"
                          placeholder={t("Enter Confirmation Number.1")}
                          name="confirmationNo"
                          onChange={this.onChange}
                          value={this.state.confirmationNo}
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-6 col-12 mt-4 mt-sm-0">
                      <div className="custom-input-type-wrap">
                        <label htmlFor="last-name">{t("Email.1")}</label>
                        <input
                          type="text"
                          id="email-address"
                          placeholder={t("Enter Email.1")}
                          name="email"
                          onChange={this.onChange}
                          value={this.state.email}
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6 text-right div-bottom-alignment">
                      <div className="custom-input-type-wrap mt-4">
                        <a
                          className="primary-bttn primary-invert-bttn"
                          title="Submit"
                          onClick={() => this.onSubmit()}
                        >
                          {t("Find Reservation.1")}
                        </a>
                      </div>
                    </div>
                  </div> */}
                  <div className="" id="manageBooking" role="tabpanel" aria-labelledby="manageBookingtab">
                    <form onSubmit={(e) => this.onSubmit(e)}>
                      <div className="inner-wrapper find-reservation">
                        <div className="confirm-number inner-box">
                          <label htmlFor="confirmNumber">
                            <span>{t("Confirmation Number.1")}</span>
                          </label>
                          <div className={`input ${this.state.isActive === 1 ? "active" : ""}`} onClick={() => this.setState({ isActive: 1 })}>
                            <div className="icon">
                              <img src={hashImg} alt="" />
                            </div>
                            <input type="text" placeholder={t("Enter Confirmation Number.1")} id="confirmNumber" name="confirmationNo" onChange={this.onChange} value={this.state.confirmationNo} />
                          </div>
                        </div>
                        <div className="email">
                          <div className="inner-box">
                            <label htmlFor="email">
                              <span>{t("Email.1")}</span>
                            </label>
                            <div className={`input ${this.state.isActive === 2 ? "active" : ""}`} onClick={() => this.setState({ isActive: 2 })}>
                              <div className="icon">
                                <img src={envelopImg} alt="" />
                              </div>
                              <input type="email" placeholder={t("Enter Email.1")} id="email" name="email" onChange={this.onChange} value={this.state.email} />
                            </div>
                          </div>
                        </div>
                        <button type="submit" className="primary-button">
                          {t("Find Reservation.1")}
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="row">
                    <div className="col col-lg-6">
                      <div className="form-group">
                        {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                          <Dialog open={this.state.errorMessage.length > 0} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">{"Dollar"}</DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">{this.state.errorMessage}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={this.handleClose} color="primary">
                                {t("OK.1")}
                              </Button>
                            </DialogActions>
                          </Dialog>
                        ) : null}
                        {this.state.loading == true ? (
                          <div style={{ verticalAlign: "center" }}>
                            <Spinner animation="border" />
                            &nbsp;{t("Sending quote request.1")}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FAQSection></FAQSection>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(withRouter(withRouter(FindReservation)));
