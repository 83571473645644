import React, { Component } from 'react';
import Truck from "../assets/images/Business/Toyota hiace 3-1.jpg";
import Group73 from '../assets/images/Business/431848417.jfif';
import ZeroImage from '../assets/images/Business/394268698.jfif'
import { animateScroll as scroll  } from 'react-scroll';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withRouter } from 'react-router-dom';

class BusinessMobilityBox extends Component {
   onMenuLinkClick = (path)=>{
      this.props.history.push(path);
      scroll.scrollToTop();
  }


    render() {
      const { t } = this.props;
        return (<>
           
            <section className="box-shadow-white-box-section grey-bg py-2">
               <div className="container-fluid">
                  <div className="box-shadow-white-box-a mt-3" id="partner">
                     <div className="pad-30">
                        <div className="row">
                           <div className="col-12">
                              <div className="pickup-location-box" >
                                 <h5 className="font-bold mb-3">
                                    <center>{t('Budget-friendly Business Mobility.1')}
                                    </center></h5>

                                 <h6>
                                    <center>{t('Your keys to efficiency, savings & growth.1')}</center>
                                 </h6>
                              </div>
                           </div>
                           <div className="col-12 col-lg-4" id= "truckrental" name="truckrental">
                           <div className="fotter-link-box">
                              <img src={Truck}  className="rounded-xl" alt="Dollar Mobility 1" />
                              <div className="pickup-location-flex-content">
                                 <h3 className="mb-2 mt-2 w-100">{t('Fleet Management Solutions.1')}</h3>
                                 <span>{t('Dollar Car Rental is a leading Fleet Management Solutions provider in the UAE. We take pride in serving major brands in the country, who choose to trust us every single day with their mobility needs. This includes using our up-to-date expert guidance on the management of their fleet - acquisition, resale, maintenance, vehicle tracking, fuel, accident management & more.1')}</span>
                              </div>
                              <a className="red-link-btn mt-auto" alt={t('View More.1')} onClick={() => this.onMenuLinkClick('/fleetmanagementsolutions')}>View More<i class="fa fa-angle-right"></i></a>
                           </div>
                           </div>
                           <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                           <div className="fotter-link-box">
                              <img src={ZeroImage}  className="rounded-xl" alt="Dollar Mobility 2" />
                              <div className="pickup-location-flex-content">
                                 <h3 className="mb-2 mt-2 w-100">{t('Monthly Rentals.1')}</h3>
                                 <span>{t('Save more when you choose monthly car rentals over daily & weekly rentals. With our easy & flexible payment modes, enjoy monthly prices cheaper than conventional car purchasing budgets.1')}</span>
                                 <span>{t('Book Now or Contact us to know more or better yet visit any of our neighborhood locations to experience Dollar today.1')}</span>
                              </div>
                              <a className="red-link-btn mt-auto" onClick={() => this.onMenuLinkClick('/monthlyrentals')}>{t('View More.1')}<i class="fa fa-angle-right"></i></a>
                           </div>
                           </div>
                           <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                           <div className="fotter-link-box">
                              <img src={Group73}  className="rounded-xl" alt={t('Dollar Mobility 3.1')} />
                              <div className="pickup-location-flex-content">
                                 <h3 className="mb-2 mt-2 w-100">{t('Business Solutions.1')}</h3>
                                 <span>{t('Let our experts support you with scalable vehicle acquisitions and fleet management solutions to ensure significant cost savings, reduced administrative hassles, increased productivity, and risk mitigation within your business. Get personalized assessments & guidance on corporate rentals, fleet acquisition, resale, maintenance, vehicle tracking, fuel, accident management & more.1')}</span>
                              </div>
                              <a className="red-link-btn mt-auto" onClick={() => this.onMenuLinkClick('/business')}>{t('View More.1')}<i class="fa fa-angle-right"></i></a>
                           </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
                
        </>);
    }
}

export default hoistStatics(withTranslation()(withRouter(BusinessMobilityBox)), BusinessMobilityBox);