import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll,scroller  } from 'react-scroll';

class FindLocation extends Component {
    onFindLocationClick = () => {
        
        localStorage.setItem('locationid','null');
        this.props.history.push('/locations');
        scroll.scrollToTop();
    }

    render() {
        const { t } = this.props;
        return (
            <section className={this.props.isMobileView == false ? "find-location-section"
            : "bg-grey-location"} >
                <div className="container">
                    <div className="location-wrap text-center text-lg-left">
                        <div className="location-wrap-left">
                            <h1 className="border-botom" style={{color:'white'}}>Dollar Car Rental</h1>
                            <h2 className="border-botom">{t('Your Ultimate Car Rental Destination.1')}</h2>
                            <h5> {t('Locations across the UAE.1')}</h5>
                        </div>
                        <div className="location-wrap-right mt-4 mt-xl-0">
                            <a onClick={this.onFindLocationClick} title={t('Find a Location.1')} 
                            className="find-location-bttn">{t('Find a Location.1')}</a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation()(withRouter(FindLocation));