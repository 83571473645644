import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ListingFooter from "../Common/ListingFooter";
import ListingHeader from "../Common/ListingHeader";
import docattachment from "../assets/images/document-attachments-icon.svg";

class TerminationPolicy extends Component {
  constructor(props) {
    super(props);
  }

  onFooterMenuLinkClick = (path) => {
    this.props.history.push(path);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader></ListingHeader>
        <div className="mb-2 pt-5 minH100 bg-grey">
          <div className="terms-page">
            <div className="container">
              <div className="terms-title-wrap row">
                <div className="col-6">
                  <h2 className="border-botom">{t("Terms and Conditions.1")}</h2>
                  {/* <p></p> */}
                </div>
                <div className="col-6">
                  <div className="terms-tab-wrap">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="general-term-tab" data-toggle="tab" href="#general-term" role="tab" aria-controls="general-term" aria-selected="true">
                          {t("General Terms and Conditions.1")}
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pre-paid-term-tab" data-toggle="tab" href="#pre-paid-term" role="tab" aria-controls="pre-paid-term" aria-selected="false">
                          {t("Pre-Paid Terms and Conditions.1")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="general-term" role="tabpanel" aria-labelledby="general-term-tab">
                      <div className="tab-title mt-0">
                        <img src={docattachment} width="20" className="mr-3" alt={t("Dollar Documentation Required.1")}></img>
                        {t("Documentation Required.1")}
                      </div>
                      <div className="tab-term-content">
                        {/* <p>{t('All renters must be able to provide a valid driver’s license, passport with visa page and authorized credit card under the main Driver Name.1')}</p>
                                                <p>{t('All drivers must carry a locally accepted driving license.1')}</p> */}
                        <p>All renters must be able to provide a Physical valid driver’s license, Physical passport with visa page and authorized credit card under the main Driver Name.</p>
                        <h5>{t("UAE RESIDENTS.1")}</h5>
                        <p>
                          {t("A valid UAE driving license is a must. Please note that driving without a Valid UAE driving license when on residence or employment visa is a violation of the LAW.1")}
                        </p>
                        <h5>{t("NON-RESIDENTS.1")}</h5>
                        <p>
                          {" "}
                          {t(
                            "Visitors are encouraged to have an International Driving License/Permit prior from arrival to the UAE. Customers from the following countries can drive in the United Arab Emirates without an International Driving Permit (IDP); however, must hold the passport and driver’s license from the same country.1"
                          )}{" "}
                          <b>{t("GCC Countries.1")}</b>
                          {t("Saudi Arabia, Oman, Bahrain, Kuwait, Qatar.1")}
                          <b> {t("European Countries.1")}</b>{" "}
                          {t(
                            "Italy, Germany, Switzerland, Poland, Slovakia, Finland, Spain, Netherlands, Greece, Sweden, Belgium, Ireland, Turkey, Denmark, Portugal, Austria, France, United Kingdom, Norway, Romania, Luxemburg, Estonia, Latvia, Serbia, Hungary,  Lithuania, Cyprus, Iceland, Holland, Malta, Albania, Bulgaria, The Republic of Montenegro.1"
                          )}{" "}
                          <b>{t("North American Countries.1")}</b> {t("United States of America, Canada.1")}
                          <strong> {t("East Asian Countries.1")}</strong> {t("Japan, Australia, Singapore, Peoples Republic of China, South Korea, New Zealand, Hong Kong.1")}
                          <b> {t("African Country.1")}</b> {t("South Africa.1")}
                        </p>
                        <p>
                          {t(
                            "International or National Driving license in any other language other than Arabic and English should be translated into Arabic or English. The translation should be attested by the Embassy.1"
                          )}
                        </p>
                      </div>
                      <div className="tab-title ">{t("GENERAL TERMS AND CONDITIONS.1")}</div>
                      <div className="tab-term-content">
                        <h5>{t("Minimum Rental Period.1")}</h5>
                        <p>{t("Rental charges are on 24 hours basis.1")}</p>
                        <h5>{t("Age Restrictions.1")}</h5>
                        <p>{t("Minimum age requirement is 22 years1.1")}</p>
                        <p>{t("Minimum age requirement is 22 years.1")}</p>
                        <h5>{t("Insurance Excess.1")}</h5>
                        <p>{t("Deductible amount charged where hirer is at fault or provided a police report under hit and run case.1")}</p>
                        <p>
                          <strong>{t("AED 1500.00 (Mini to Standard Vehicles), AED 2500 to AED 5000 (Compact SUV to Dollar Prestige Vehicles).1")}</strong>
                        </p>
                        <h5>{t("Security Deposits.1")}</h5>
                        <p>
                          {t(
                            "A pre-authorization is taken from the hirer’s credit card at the time of rental. Pre-authorization amount is between 1500/-AED to 5000/-AED depending on the vehicle type. We keep pre-authorization amount for 14 Working Days from the Start Date of the Rental.1"
                          )}
                        </p>
                        <p>
                          {t(
                            "Credit card on the name of card holder must be available at pick up location. This is a mandatory requirement. The credit card must have enough funds to cover the security deposit.1"
                          )}
                        </p>
                        <p>
                          {t(
                            "Customers can avail the option of No Deposit for monthly rental with a one-time subscription fee. This offer is available to all residence customers only (Dubai & Sharjah Locations ONLY).1"
                          )}
                        </p>
                        <h5>{t("Method of Payment.1")}</h5>
                        <p>
                          {t("We accept all major.1")} <u>{t("Credit Cards.1")}</u>{" "}
                          {t(
                            "(MASTER, VISA, JCB, DINERS). Credit card should be valid for a minimum of 3 months from the day of rental and must be physically available at time of vehicle collection.1"
                          )}
                        </p>
                        <h5>{t("Return Charges.1")}</h5>
                        <p>
                          <strong>{t("Airport Return Charges.1")}</strong>
                        </p>
                        <p>{t("Airport Return Charges Description.1")}</p>
                        <p>
                          <strong>{t("Non Airport Return Charges.1")}</strong>
                        </p>
                        <p>{t("Non Airport Return Charges Description.1")}</p>
                        <p>
                          <strong>{t("Oman Off-hire/Return Charges.1")}</strong>
                        </p>
                        <p>{t("Oman Off-hire/Return Charges Description.1")}</p>
                        <h5>{t("One Way Fees.1")}</h5>
                        <p>{t("If you arrange to pick the car up in one location and drop it off in different Emirate One Way Fee will apply as below.1")}</p>
                        <p>{t("From Dubai & Northern Emirates Locations to Abu Dhabi & Al Ain Locations will be Dhs 210/- per one-way drop.1")}</p>
                        <p>{t("From Abu Dhabi & Al Ain Locations to Dubai & Northern Emirates Locations will be Dhs 210/- per one-way drop.1")}</p>
                        <p>{t("From Abu Dhabi to Al Ain will be Dhs 210/- per one-way drop.1")}</p>
                        <p>{t("From Al Ain to Abu Dhabi will be Dhs 210/- per one-way drop.1")}</p>

                        <h5>{t("Super Collision Damage Waiver (SCDW).1")}</h5>
                        <p>
                          {t("Reduces the excess insurance/liability amount to.1")} <strong>{t("ZERO.1")}</strong>
                        </p>
                        <h5>{t("Mileage.1")}</h5>
                        <p>
                          <strong>Abu Dhabi & Al Ain KM Policy</strong>{" "}
                        </p>
                        <p>
                          <strong>Mini to SUV Vehicles:</strong>
                        </p>
                        <p>Unlimited kilometers for DAILY and WEEKLY rentals.</p>
                        <p>5000kms for MONTHLY rentals, AED 0.35 fils for every extra km.</p>
                        <p>
                          <strong>Dollar Prestige Vehicles</strong>
                        </p>
                        <p>Limited kilometers for DAILY and WEEKLY rentals.</p>
                        <p>Daily: From 250kms up to 350kms</p>
                        <p>Weekly: From 1,750kms up to 2,100kms</p>
                        <p>Monthly: From 2,500kms up to 3,500kms</p>
                        <p>Up to 1 AED for every extra kms. </p>
                        <p>
                          <strong>Dubai & Sharjah KM Policy</strong>
                        </p>
                        <p>
                          <strong>Daily & Weekly Rentals</strong>
                        </p>
                        <p>Polestar 2: Daily 200Kms & 1AED for every extra kms.</p>
                        <p>Unlimited kilometers for DAILY and WEEKLY rentals. For rest of the vehicles</p>
                        <p>
                          <strong>Monthly</strong>
                        </p>
                        <p>Limited kilometers for MONTHLY rentals</p>
                        <p>From 2500kms up to 4000kms</p>
                        <p>Up to 1 AED for every extra kms. Depending on Vehicle Category</p>
                        <h5>{t("Monthly Rentals.1")}</h5>
                        <p>{t("Monthly rentals are calculated from 22 days & above rental period. Advance payment is mandatory.1")}</p>
                        <p>{t("Agreements opened on DAILY and WEEKLY rentals cannot be extended to MONTHLY contract.1")}</p>
                        <h5>{t("Personal Accident Insurance.1")}</h5>
                        <p>
                          {t("To add more security on your driving, we also offer Personal Accident Insurance, this can purchase at an additional cost which covers the driver and the passengers.1")}
                        </p>
                        <h5>{t("Driving License.1")}</h5>
                        <p>{t("All drivers must carry a locally accepted driving license valid UAE.1")}</p>
                        <h5>{t("Fuel Policy.1")}</h5>
                        <p>{t("The hirer should return the vehicle with the same fuel level as given by the company. If not, the hirer will be charged the cost of fuel plus a service charge.1")}</p>
                        <h5>{t("Salik (Toll Fee).1")}</h5>
                        <p>{t("All of our cars are installed with SALIK sticker which will be AED 5 Excl. VAT per passage at the SALIK toll gates.1")}</p>
                        <h5>{t("Late Return.1")}</h5>
                        <p>{t("Only 1 hour grace period is allowed. Beyond 1 hour, full-day charges as per the tariff card will be charged directly from the hirer.1")}</p>
                        <h5>{t("Additional Driver.1")}</h5>
                        <p>
                          {t("The hirer may put on an extra driver with minimal cost, provided that he fulfills all necessary requirements.1")} <strong>{t("Daily.1")}:</strong> {t("AED 21.00.1")}{" "}
                          <strong>{t("Weekly.1")}:</strong> {t("AED 126.00.1")} <strong>{t("Monthly.1")}:</strong> {t("Free of Cost.1")}
                        </p>
                        <h5>{t("Value Added Tax (VAT).1")}</h5>
                        <p>{t("Dollar Car Rental UAE reserves the right to add 5% VAT as confirmed and implemented by UAE Government with effect on Jan 2018.1")}</p>
                        <h5>{t("Oman Insurance.1")}</h5>
                        <p>
                          {t("Driving to Oman territory is allowed with prior purchase of an Oman Insurance Certificate along with the No Objection Letter available at the rental desk.1")}{" "}
                          <strong>{t("Oman Insurance charges are as follows (these rates are excluding the 5% VAT).1")}</strong>
                        </p>
                        <p>
                          {t("Note.1")} <strong>Abu Dhabi Rentals Can Issue Oman Insurance up to 31st December only.</strong>
                        </p>
                        <p>
                          <strong>Dubai and Sharjah Rentals are not Issuing Oman insurance until further notice.</strong>
                        </p>
                        <table className="mt-4">
                          <tr>
                            <th width="30%">{t("Car Type or Similar.1")}</th>
                            <th>{t("1-3 days.1")}</th>
                            <th>{t("7 days.1")}</th>
                            <th>{t("7+ days.1")}</th>
                            <th>{t("30 days.1")}</th>
                            <th>{t("Extra KM Charged.1")}</th>
                          </tr>
                          <tr>
                            <td>
                              {t(
                                "▪ Nissan Sunny ▪ Nissan Micra ▪ Kia Picanto ▪ Chevrolet Spark ▪ Hyundai Elantra ▪ Toyota Yaris ▪ Honda Civic ▪ Toyota Corolla ▪ Nissan Altima ▪ Toyota Camry ▪ Mazda 6 ▪ Kia Optima ▪ Nissan Kicks ▪ Hyundai Creta.1"
                              )}
                            </td>
                            <td>
                              {t("350 Dhs.1")}
                              <br></br> {t("(with 600 km for 3 days).1")}
                              <br></br>
                              {t("( Each Day extra + 75 Dhs/- ).1")}
                            </td>
                            <td>
                              {t("600 Dhs.1")}
                              <br></br> {t("( 250 Km per day ).1")}
                            </td>
                            <td>{t("75 Dhs.1")}</td>
                            <td>{t("1000 Dhs.1")}</td>
                            <td>{t("1 AED.1")}</td>
                          </tr>
                          <tr>
                            <td>{t("▪ Toyota Previa ▪ Toyota Fortuner ▪ Mazda CX-9 ▪ Mazda CX-5 ▪ Nissan X-Trail.1")}</td>
                            <td>
                              {t("450 Dhs.1")} <br></br>
                              {t("(with 600 km for 3 days).1")} <br></br> {t("(Each Day extra 100 Dhs/-).1")}
                            </td>
                            <td>
                              {t("750 Dhs.1")} <br></br>
                              {t("( 350 Km per day ).1")}
                            </td>
                            <td>{t("100 Dhs.1")}</td>
                            <td>{t("1500 Dhs.1")}</td>
                            <td>{t("1 AED.1")}</td>
                          </tr>
                          {/* <tr>
                                                        <td>Jeep Wrangler Sport	</td>
                                                        <td>500 Dhs/- <br></br>(with 600 km for 3 days) <br></br> (Each Day extra 150 Dhs/-)</td>
                                                        <td>850 Dhs/- <br></br>(250 km per day )</td>
                                                        <td>150 Dhs/-</td>
                                                        <td>N/A</td>
                                                        <td>1 AED</td>
                                                    </tr> */}
                        </table>
                        <p className="my-4">
                          {t("LIMITED MILEAGE provided when driving the car in Oman. Following vehicles are.1")}{" "}
                          <strong>
                            <u>{t("NOT.1")}</u>
                          </strong>{" "}
                          {t("allowed to be driven to Oman.1")}{" "}
                        </p>
                        <h5>{t("Off-Road Liability/Charges.1")}</h5>
                        <p>{t("Insurance does not cover off road usage of the vehicle and the customer will be liable for damage caused and charges arising out of such use.1")}</p>
                        <h5>{t("Vehicle License Fee.1")}</h5>
                        <p>
                          {t("A vehicle monitoring device fee also known as the Vehicle License Fee (VRF) will be charged as implemented/mandated by the Roads and Transport Authority of Dubai.1")}{" "}
                          <strong>{t("Daily.1")}:</strong> {t("AED 5.25.1")} <strong>{t("Weekly.1")}:</strong> {t("AED 31.50.1")} <strong>{t("Monthly.1")}:</strong> {t("AED 78.75.1")}
                        </p>
                        <h5>{t("Traffic Violations.1")}</h5>
                        <p>
                          {t(
                            "Drivers are expected to abide with the traffic rules and regulations. For every traffic fine incurred you will be charged 10% extra to cover administration charges and Knowledge Fee as imposed by UAE Government.1"
                          )}
                        </p>
                        <h5>{t("Airport Return Charges.1")}</h5>
                        <p>
                          {t(
                            "All vehicles off hired/returned in any of our airport counters will be charged an airport drop off fees. If you wish to avoid this drop off fees, rented vehicles can be returned to any non-airport branch locations between 08:00H to 17:00H (Saturday to Thursday). Presently the airport drop off fees is as follows: Dubai Airport Terminal 1- AED 52.50/-, Dubai Airport Terminal 3-AED 52.50/-, Sharjah Airport- AED 21/- and Abu Dhabi Airport- AED 52.50.1"
                          )}
                        </p>
                        <h5>{t("Non Airport Return.1")}</h5>
                        <p>{t("Drop-offs at any Non-Airport locations will be free of cost based on the working hours as mentioned on Locations.1")}</p>
                        <h5>{t("Oman Off-hire/Return Charges.1")}</h5>
                        <p>{t("Muscat/Sohar: AED 1050.00 ; Salalah: AED 1575.00.00.1")}</p>
                        <h5>{t("Value Added Assets.1")}</h5>
                        <p>
                          {t(
                            "Child Seats/Baby Seats, GPS and Wi-Fi Devices are available on request and subject to availability only. GPS Daily-AED 36.75/-, Car Seats Daily-AED 36.75, Wifi-Device Daily-AED 42.00.1"
                          )}
                        </p>
                        <p>{t("The hirer shall be fully responsible in case of loss, stolen or damages for the usage of Wi-Fi Service.1")}</p>
                        <p>
                          {t(
                            "Dollar Car Rental UAE is not liable for any errors, inaccuracies, omissions, or for any loss or damage suffered by users arising out of usage of any of the information published on any pages /maps on the GPS. The hirer is liable to pay for charges of AED 1000 for the loss of the Unit. In case of any damages all charges as determined by Dollar are payable.1"
                          )}
                        </p>
                        <h5>{t("Breakdown Procedure.1")}</h5>
                        <p>
                          {t(
                            "Please try to park the vehicle in safe and secure place. Try to know exactly where you are. Contact the nearest Dollar Car Rental UAE office or Toll Free 800-4710 (800 Dollar) and provide the details of breakdown type. A replacement vehicle will be sent. A Service Fee will apply for replacement of the spare tyre.1"
                          )}{" "}
                        </p>
                        <h5>{t("Early Return/ No Shows.1")}</h5>
                        <p>{t("There will be no refund for unused car rental days on a Prepaid/Pay On Arrival rental, once the rental has started.1")}</p>
                        <p>{t("There will be no refund if the customer fails to show up for a rental that has not been cancelled prior to the pickup date.1")}</p>
                        <h5>{t("Delivery/Collection charges.1")}</h5>
                        <p>{t("Delivery and Collection within the same emirates will be Dhs. 52.50.1")}</p>
                        <p>{t("Delivery and Collection in Different Emirate a one way fee will apply.1")}</p>
                        <p>{t("From Dubai & Northern Emirates Locations to Abu Dhabi & Al Ain Locations will be Dhs 210.00.1")}</p>
                        <p>{t("From Dubai & Sharjah to Northern Emirates will be Dhs 210.00/- (Vice Versa).1")}</p>
                        <p>{t("From Abu Dhabi & Al Ain Locations to Dubai & Northern Emirates Locations will be Dhs 420/-AED to 210/-AED.1")}</p>
                        <p>{t("From Abu Dhabi to Al Ain will be Dhs. 210.00.1")}</p>
                        <p>{t("From Al Ain to Abu Dhabi will beDhs. 210.00.1")}</p>
                        <h5>{t("Pick-Up and Drop-Offs inter-emirates/One Way Fees.1")}</h5>
                        <p>{t("If you arrange to pick the car up in one location and drop it off in different Emirate One Way Fee will apply as below.1")}</p>
                        <p>{t("From Dubai & Northern Emirates Locations to Abu Dhabi & Al Ain Locations will be Dhs 210.00/- per one-way drop.1")}</p>
                        <p>{t("From Abu Dhabi & Al Ain Locations to Dubai & Northern Emirates Locations will be Dhs 420/-AED to 210/-AED per one-way drop.1")}</p>
                        <p>{t("From Abu Dhabi to Al Ain will be Dhs 210.00/- per one-way drop.1")}</p>
                        <p>{t("From Al Ain to Abu Dhabi will be Dhs 210.00/- per one-way drop.1")}</p>
                        <h5>{t("Public Holidays.1")}</h5>
                        <p>{t("Dollar Car Rental UAE Downtown offices are closed on Public Holidays. All Airport counters are open 24/7.1")}</p>
                        <h5>{t("Discount rates on the website and on counter given cannot be clubbed with any Loyalty discounts or promotion running.1")}</h5>
                        <h5>{t("Russia and/or Russian banks cards.1")}</h5>
                        <p>
                          {t(
                            "As advised by international credit and debit card issuers, we would like to inform you that we are temporarily not accepting credit cards and debit cards issued from Russia and/or Russian banks. Hence, we would be unable to do any transaction nor put a Pre Auth block on these cards for the time being.1"
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pre-paid-term" role="tabpanel" aria-labelledby="pre-paid-term-tab">
                      <div className="tab-title mt-0">{t("PRE-PAID GENERAL TERMS AND CONDITIONS.1")}</div>
                      <div className="tab-term-content">
                        <h5>{t("General.1")}</h5>
                        <p>
                          {t(
                            "This online reservation facility does not constitute a contract for the supply of vehicle rental services. A contract will be entered into at the time of rental and will be governed by the laws of the country of rental.1"
                          )}
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <p>
                          {t(
                            "Dollar Car Rental UAE Prepaid rental booking showing a specified value will be accepted as a means of payment of the reservation charges at rental counters. The booking must be accompanied by a credit card both for security and to cover the charges not included in the booking value, such as fuel, extras, and excess in case of damage or loss of the rented vehicle. A pre-authorisation will be taken on the credit card to cover excess & other additional charges at the time of vehicle hire.1"
                          )}
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <p>
                          {t(
                            "Standard Dollar Car Rental UAE Terms and Conditions apply. All rentals may be subject to additional surcharges (e.g. for special equipment, additional drivers, young drivers, optional insurances, fuel, Salik, Traffic fine etc.).1"
                          )}
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <p>{t("All rentals are subject to an insurance excess, optional insurances are available to excess.1")}</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t("Amendments.1")}</h5>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t("(A) PAYMENT BY CREDIT CARD.1")}</h5>
                        <p>
                          {t(
                            "Credit cards are accepted for online payment. Please note the Credit card used online must be presented at time of pick up. Credit card on the name of card holder must be available at pick up location. This is a mandatory requirement. The credit card must have enough funds to cover the security deposit.1"
                          )}
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t("(B) PREPAID RATES ONLY.1")}</h5>
                        <p>{t("Dollar Car Rental UAE applies Cancellation and No Show / Lost Rental charges on all prepaid rates booked from www.dollaruae.com.1")}</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t("(C) Change of Reservation.1")}</h5>
                        <p>
                          1. Any change to a booking made online or through a Dollar Car Rental UAE Representative will be recalculated based on the availability and prices at the time the change is
                          made. This may be greater or lesser than the price originally booked. This applies to changes made to
                        </p>
                        <p>a. Drop off time and date | d. Vehicle group | e. All other additional products</p>
                        <p>2. Any modification for changes made online would have an amendment fee of AED: 52.50 if changes made with less than 48 hrs from Pick up Date</p>
                        <p>
                          3. The primary customer name and the pick-up location on a reservation cannot be changed. If you wish to change the primary name or the pick-up location, then you must cancel
                          the reservation (see below “Cancellations”) and make a new reservation
                        </p>
                        <p>
                          4. You will be asked to enter a valid credit card number at the end of a change to a prepaid reservation. This MUST be the same credit card that was used for the original
                          reservation. If you wish to change the credit card, then the original reservation must be canceled (see below “Cancellations”) and a new reservation to be made
                        </p>
                        <p>
                          5. Administration charges will not apply to amended bookings, unless the amendment significantly changes the original booking, in which case it will be subject to the
                          cancellation fee detailed below. All requests to amend a booking will be subject to normal availability of the requested vehicle. Prices may change between the date of the
                          original booking and the amended booking, in which case the later prices will apply
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t("Cancellation.1")}</h5>
                        <p>1. Cancellations of rentals with less than 48 hours’ notice from Pick up Date, Dollar Car Rental UAE will charge an Administration fee of AED: 105</p>
                        <p>2. Cancellations of rentals with more than 48 hours’ notice from Pick up Date are Free of Charge</p>
                        <p>3. Refunds will be made to the credit card that the original booking was made on within 21 working days</p>
                        <p>4. Any booking that is cancelled on the same day of pick-up due to invalid documents, etc., Dollar Car Rental UAE will charge an Administration fee of AED: 157.50</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t("Early Return/ No Shows.1")}</h5>
                        <p>1. There will be no refund for unused car rental days on a Prepaid/Pay On Arrival rental, once the rental has started</p>
                        <p>2. There will be no refund if the customer fails to show up for a rental that has not been cancelled prior to the pickup date</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t("Extensions.1")}</h5>
                        <p>
                          {t(
                            "If a rental needs to be extended once the car has been picked up, local rental rates on 25% discount from the normal tariff will apply for the additional days and the additional charges will be settled by customer’s credit card.1"
                          )}
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t("Cash.1")}</h5>
                        <p>{t("Cash as a form of payment is only accepted at the end of rental. At the beginning of rental a credit card is required for the deposit.1")}</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t("Late Arrival Policy.1")}</h5>
                        <p>
                          {t(
                            "If you make a reservation specifying a pick-up location and you do not arrive at the specified pick-up location for the rental within 4 hours of the specified pick-up time, (or if the location closes before then, by the locations closing time), the reservation will be treated as a No Show and charged accordingly.1"
                          )}
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t("Extras.1")}</h5>
                        <p>{t("Your extras, i.e. GPS, Baby seat etc. are payable at the rental location & is subject to availability.1")}</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t("Discount rates on the website and on counter given cannot be clubbed with any Loyalty discounts or promotion running.1")}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ListingFooter></ListingFooter>
      </>
    );
  }
}

export default withTranslation()(TerminationPolicy);
