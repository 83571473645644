
import axios from 'axios';
import { RangeDatePicker,SingleDatePicker } from 'react-google-flight-datepicker';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import "../assets/css/slick.css";



class ReturnDatePopoverNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPickupDate: Date().toLocaleString(),
            selectedReturnDate: Date().toLocaleString(),
            pickupTime: "09:00",
            returnTime: "09:00",
            timeList: [],
            rentalDays: 0
        }
    }
    onPickupBranchSelected = (selectedBranch) => {

    }


    componentDidMount() {
        this.getTimeSlots();
        this.setState({ selectedReturnDate: this.props.selectedReturnDate });
    }

    getTimeSlots = () => {
        axios(process.env.REACT_APP_API + 'Branch/GetTimeSlots?apiKey=' + process.env.REACT_APP_API_KEY).then((response) => {
            this.setState({ timeList: response.data });
        });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.selectedPickupDate != this.props.selectedPickupDate) {
            this.setState({ selectedPickupDate: this.props.selectedPickupDate });
        }

        if (prevProps.selectedReturnDate != this.props.selectedReturnDate) {
            this.setState({ selectedReturnDate: this.props.selectedReturnDate });
        }
    }

    onChangeReturnDate = (startDate, endDate) => {

        let pickupDateTime = new Date(this.state.selectedReturnDate);
        let pickupTime1 = pickupDateTime.getHours() + ":" + pickupDateTime.getMinutes()

        let selectedPickupDate = moment(moment(this.props.selectedReturnDate).format('MM/DD/YYYY') + " " + pickupTime1);
        selectedPickupDate = moment(this.state.selectedReturnDate).format('MM/DD/YYYY  h:mm:ss A');

        if (endDate == null) {
            endDate = startDate;
        }

        let selectedReturnDate = moment(moment(endDate).format('MM/DD/YYYY') + " " + pickupTime1);
        selectedReturnDate = moment(selectedReturnDate).format('MM/DD/YYYY  h:mm:ss A');

        this.setState({ selectedReturnDate: selectedReturnDate });

        this.props.onChangeReturnDate(selectedReturnDate);
        this.getRentalDays(selectedPickupDate,selectedReturnDate);
        this.props.clearPopOver();
    }

    getRentalDays = (selectedPickupDate,selectedReturnDate) => {
        axios(process.env.REACT_APP_API + 'Reservations/GetRentalDays?apiKey=' + process.env.REACT_APP_API_KEY + `&pickup=${selectedPickupDate}&dropoff=${selectedReturnDate}`)
            .then((response) => {
                this.setState({ rentalDays: response.data.RentalDays });
            });
    }

    onPickupTimeChange = (timeSlot) => {
        let selectedPickupDate = moment(moment(this.state.selectedPickupDate).format('MM/DD/YYYY') + " " + timeSlot);
        selectedPickupDate = moment(selectedPickupDate).format('MM/DD/YYYY  h:mm:ss A');

        let selectedReturnDate = moment(moment(this.state.selectedReturnDate).format('MM/DD/YYYY') + " " + timeSlot);
        selectedReturnDate = moment(selectedReturnDate).format('MM/DD/YYYY  h:mm:ss A');

        this.setState({ pickupTime: timeSlot });
        this.setState({ returnTime: timeSlot });

        this.setState({ selectedPickupDate: selectedPickupDate });
        this.setState({ selectedReturnDate: selectedReturnDate });
        this.props.onChangePickupDate(selectedPickupDate);
        this.props.onChangeReturnDate(selectedReturnDate);

        this.getRentalDays(selectedPickupDate,selectedReturnDate);
    }

    onRetunrTimeChange = (timeSlot) => {
        let selectedReturnDate = moment(moment(this.state.selectedReturnDate).format('MM/DD/YYYY') + " " + timeSlot);
        selectedReturnDate = moment(selectedReturnDate).format('MM/DD/YYYY  h:mm:ss A');

        this.setState({ returnTime: timeSlot });
        this.setState({ selectedReturnDate: selectedReturnDate });

        this.props.onChangeReturnDate(selectedReturnDate);
        this.getRentalDays(this.state.selectedPickupDate, selectedReturnDate);        
    }

    onHidePickupTimePopover=()=>{
        document.getElementById('body').className = '';        
    }

    render() {

        const { t } = this.props;
        var minDate = moment(Date());
        return (
            this.props.searchWidgetMode == 3 ?
                <div className="dateTimepickupBox">
                        <div className="StationHistory__wrapper">
                            <div className="StationHistory__stationName">
                                <div >
                                    <div className="pickup-location-input group-input">
                                         <DatePicker 
                                         selected={new Date(moment(this.props.selectedReturnDate).year(), moment(this.props.selectedReturnDate).month(), moment(this.props.selectedReturnDate).date())} 
                                         onChange={(date) => this.onChangeReturnDate(date,date)} 
                                         inline
                                         />
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                : null


        );
    }
}


export default withTranslation()(withRouter(ReturnDatePopoverNew));