
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import SuccessAlert from '../Common/AlertError/SuccessAlert';
import { getLanguage } from '../Common/Util';
import validator from 'validator'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SubscriberightImage from '../assets/images/subscribe-bg-car.png';

var qs = require('qs');
var axios = require('axios');


class Subscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
            email: '',
            errorMessage: '',
            successMessage: ''
        };
    }

    onChangeEmail = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    validateSubscribe = () => {
        let lngParam = getLanguage();

        this.setState({
            errorMessage: ""
        });

        let valid = true;

        if (this.state.email.trim() == '') {

            if (lngParam == "en") {
                this.setState({
                    errorMessage: "The email field is required", successMessage: ''
                });
            }
            else {
                //ar
                this.setState({
                    errorMessage: "مطلوب حقل البريد الإلكتروني", successMessage: ''
                });
            }
            
            return false;
        }
        
        if(!validator.isEmail(this.state.email)){
            if (lngParam == "en") {
                this.setState({
                    errorMessage: "The email field is invalid", successMessage: ''
                });
            }
            else {
                //ar
                this.setState({
                    errorMessage: "مطلوب حقل البريد الإلكتروني", successMessage: ''
                });
            }
            return false;
        }

        return valid;

    }

    onSubscribeSubmit = (e) => {
      
        e.preventDefault();
        if (this.validateSubscribe()) {           
            let data = qs.stringify({ "Name": "", "EmailId": this.state.email });
            let config = {
                method: 'post',
                url: process.env.REACT_APP_API + `NewsletterRequest/Post?apiKey=` + process.env.REACT_APP_API_KEY,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                   if(response.data !=null && response.data.Message != null  ) 
                   {
                       localStorage.setItem('thanksmessage',response.data.Message);
                   }
                   
                    this.props.history.push("/thankyou");
                })
                .catch((error) => {
                    this.props.history.push("/thankyou");
                });
        }
    }
    handleClose=()=>{
        this.setState({
            errorMessage: ""
        });
    }

    render() {
        
        const { t } = this.props;
        return (

            <section className="subscriber-red-section">
                <div className="container">
                    <div className="subscriber-container">
                        <div className="subscriber-text-left">
                            <h2 className='border-botom font-bold'>{t('Join Dollar RED Community.1')}</h2>
                            <p>{t('Exclusive Flash Deals, Surprise Giveaways, Early Bird Offers, Road Trip Destinations, Tips & Tricks right in your mailbox!.1')}</p>
                            <form >
                                <input type="text" placeholder={t('Enter Email Address.1')} name="email" id="email" value={this.state.email} onChange={this.onChangeEmail} />
                                <input id="submit" type="submit" onClick={this.onSubscribeSubmit} value={t('Submit.1')}></input>
                                {/* value={t('Join Now')}  */}
                            </form>
                        </div>
                        <div className='subscriber-text-right'>
                              <img src={SubscriberightImage} width={670} height={459}/>
                        </div>
                    </div>
                </div>
                            {this.state.successMessage != null && this.state.successMessage.length > 0 ? <SuccessAlert successMessage={this.state.successMessage} /> : null}
                            {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? 
                            <div className="custom-input-type-wrap">
                                <div class="container">
                                <Dialog
                                    open={this.state.errorMessage.length > 0}
                                    onClose={this.handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Dollar"}</DialogTitle>
                                    <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                    {this.state.errorMessage}
                                    </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={this.handleClose} color="primary">
                                        {t('OK.1')}
                                    </Button>                                
                                    </DialogActions>
                                </Dialog>
                                </div>
                            </div>
                            : null}
                       
               
            </section>

        );
    }
}

export default withTranslation()(withRouter(Subscribe));