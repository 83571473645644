import axios from 'axios';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Events, animateScroll as scroll, scroller } from 'react-scroll'
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getCurrency, getCustomerId, getLanguage, getUserInfo } from '../Common/Util';
//import IncludePageInline from '../Include/IncludePageInline';
import IncludePageInline from './MonthlIncludePageInline';
import ListingCard from './MonthlyListingCard';
import ListingPage2 from './MonthlyListingPage2';
import ListingPage3 from './MonthlyListingPage3';
import RentalDetails from './MonthlyRentalDetails';
import moment from 'moment-timezone';
import noResultImage from '../assets/images/icons8-spinner.gif';
import filterBy from '../assets/images/filter-by-icon.svg';
import sortBy from '../assets/images/sort-by-icon.svg';
var qs = require('qs');


class MonthlyListingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
            selectVehicleForBooking: {},
            vehicleTypeDefaultValue: 'Vehicle Type',
            vehicalModels: [],
            isFirstTimeLoad: true,
            minimumDriverAges: [],
            allVehicleTypes: [],
            allVehicleBrands: [],
            allNumberOfSeats: [],
            showStickHeader: false,
            selectedVehicleIndex: 9999,
            isActive: 0,
            columnCount: 4,
            showRentalDetails: false,
            step1: 1,
            paySectionValue: '',
            selectedVehicleType: "00000000-0000-0000-0000-000000000000",
            additionalParam: {
                pickupLocationName: '',
                dropOffLocationName: '',
                pickupLocationImage: '',
                dropOffLocationImage: '',
                pickupLocationAddress: '',
                dropOffLocationAddress: '',
                pickupLocationMessage: '',
                dropOffLocationMessage: '',
                pickupLocationBranchDescription: '',
                dropOffLocationBranchDescription: '',
                originalVehicleModelID: "00000000-0000-0000-0000-000000000000",
                originalTariffGroupID: "00000000-0000-0000-0000-000000000000",
            },
            searchParam: {
                SubscriptionMonth: 0,
                SubscriptionKm: 0,
                Classifications: [],
                VehicleMakeID: "00000000-0000-0000-0000-000000000000",
                VehicleModelID: "00000000-0000-0000-0000-000000000000",
                SpecialOfferID: "00000000-0000-0000-0000-000000000000",
                ModelYear: 0,
                Passengers: 0,
                AutoTransmission: 0,
                CheckOutOn: "2021-04-20T15:51:00",
                CheckInOn: "2021-04-23T15:51:00",
                CheckOutBranchID: '',
                CheckInBranchID: '',
                DeliveryDetails: {
                    "Address": "",
                    "AddressJson": "",
                    "StateName": ""
                },
                PickupDetails: {
                    "Address": "",
                    "AddressJson": "",
                    "StateName": ""
                },

                CheckOutBranchID: "fdeb6d7b-132e-4c32-a5da-42a130d64d32",
                CheckInBranchID: "fdeb6d7b-132e-4c32-a5da-42a130d64d32",
                PromoCodeID: "00000000-0000-0000-0000-000000000000",
                PromoCode: "",
                DriverAge: 21,
                MinRentalRate: 0,
                MaxRentalRate: 0,
                RentalDays: 0,
                NoOfRecords: 0,
                TariffGroupID: "00000000-0000-0000-0000-000000000000",
                PaymentStatus: 1,
                UpgradeModelId: "00000000-0000-0000-0000-000000000000",
                ModifiedReservationID: "00000000-0000-0000-0000-000000000000",
                ContactID: "00000000-0000-0000-0000-000000000000"
                // RentalIn :{

                // }
            },

            isChecked: false

        }
    }



    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener("resize", this.update);
        let lngParam = getLanguage();
        this.update();
        this.GetMinimumDriverAge();
        //this.GetAllVehicleType(lngParam);
        this.GetAllVehicleBrand(lngParam);
        this.GetAllNumberOfSeats(lngParam);
        this.GetVehicalModels();

        const userinfo = getUserInfo();
        if (userinfo !== null) {
            this.state.searchParam.ContactID = userinfo.CustomerID;
        }

        // setTimeout(function() { //Start the timer
        //     this.setState({isFirstTimeLoad: false}) //After 5 second, set render to true
        // }.bind(this), 5000)
        //alert(this.state.searchParam.ContactID);
    }

    update = () => {
        let columnCount = 4;

        if (window.innerWidth <= 530) {
            columnCount = 1;
        }
        if (window.innerWidth > 530 && window.innerWidth <= 767) {
            columnCount = 2;
        }
        if (window.innerWidth > 767 && window.innerWidth <= 1024) {
            columnCount = 3;
        }
        if (window.innerWidth > 991) {
            columnCount = 4;
        }

        this.setState({
            columnCount: columnCount
        });
    };

    GetMinimumDriverAge = () => {

        var config = {
            method: 'get',
            url: process.env.REACT_APP_API + 'TariffGroup/GetMinDriverAge?apiKey=' + process.env.REACT_APP_API_KEY,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    minimumDriverAges: response.data
                })


            })
            .catch(function (error) {

            });

    }

    GetAllVehicleType = (lngParam) => {
        const BookingInfo = JSON.parse(localStorage.getItem('BookingInfo'));
        var checkOutLocationId = this.state.searchParam.CheckOutBranchID;
        var checkInLocationId = this.state.searchParam.CheckInBranchID;

        var pickUpDate = this.state.searchParam.CheckOutOn;
        var returnDate = this.state.searchParam.CheckInOn;
        if (BookingInfo != null && BookingInfo != undefined) {
            checkOutLocationId = BookingInfo[0].CheckOutLocationId;
            checkInLocationId = BookingInfo[0].CheckInLocationId;
            pickUpDate = BookingInfo[0].PickUpDate;
            returnDate = BookingInfo[0].ReturnDate;
        }

        let paramDriversAge = this.state.searchParam.DriverAge;
        let paramBrandId = this.state.searchParam.VehicleMakeID;
        let paramVehicleType = [];
        var deliveryState = null;
        var deliveryAddress = null;
        var returnState = null;
        var returnAddress = null;
        if (BookingInfo != null && BookingInfo != undefined) {
            deliveryState = BookingInfo[0].deliveryState;
            deliveryAddress = BookingInfo[0].deliveryAddress;
            returnState = BookingInfo[0].returnState;
            returnAddress = BookingInfo[0].returnAddress;
        }

        let paramautoTransmission = this.state.searchParam.autoTransmission;
        let searchParam = {
            Classifications: paramVehicleType,
            VehicleMakeID: paramBrandId,
            VehicleModelID: "00000000-0000-0000-0000-000000000000",
            SpecialOfferID: "00000000-0000-0000-0000-000000000000",
            ModelYear: 0,
            Passengers: this.state.searchParam.Passengers,
            AutoTransmission: paramautoTransmission,
            CheckOutOn: pickUpDate,
            CheckInOn: returnDate,
            CheckOutBranchID: checkOutLocationId,
            CheckInBranchID: checkInLocationId,
            PromoCodeID: "00000000-0000-0000-0000-000000000000",
            PromoCode: this.state.searchParam.PromoCode,
            DriverAge: paramDriversAge,
            MinRentalRate: 0,
            MaxRentalRate: 0,
            RentalDays: 0,
            NoOfRecords: 50000,
            TariffGroupID: "00000000-0000-0000-0000-000000000000",
            ModifiedReservationID: this.state.searchParam.ModifiedReservationID,
            DeliveryDetails: {
                "Address": deliveryAddress,
                "AddressJson": "",
                "StateName": deliveryState,
            },
            PickupDetails: {
                "Address": returnAddress,
                "AddressJson": "",
                "StateName": returnState,
            },
        };

        var axios = require('axios');
        var data = qs.stringify(searchParam);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API + 'VehicleClassification/GetForSubscription?apiKey=' + process.env.REACT_APP_API_KEY + `&lng=${lngParam}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    allVehicleTypes: response.data
                })
            })
            .catch(function (error) {

            });
    }

    GetAllVehicleBrand = () => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API + 'VehicleMake/Get?apiKey=' + process.env.REACT_APP_API_KEY,
            headers: {}
        };

        axios(config)
            .then((response) => {
                this.setState({
                    allVehicleBrands: response.data
                })
            })
            .catch((error) => {

            });
    }

    GetAllNumberOfSeats = () => {


        var config = {
            method: 'get',
            url: process.env.REACT_APP_API + 'SearchVehicleModel/GetNoOfSeats?apiKey=' + process.env.REACT_APP_API_KEY,
            headers: {}
        };

        axios(config)
            .then((response) => {

                this.setState({
                    allNumberOfSeats: response.data
                })
            })
            .catch((error) => {

            });

    }


    /////////////////////////////////////// Get Vehical Models

    GetVehicalModels = () => {
        const BookingInfo = JSON.parse(localStorage.getItem('BookingInfo'));

        var CheckOutLocationId = null;
        var CheckInLocationId = null;
        var PickUpDate = null;
        var ReturnDate = null;
        var promocode = null;
        var pickupLocationName;
        var dropOffLocationName;
        var pickupLocationImage;
        var dropOffLocationImage;
        var pickupLocationAddress;
        var dropOffLocationAddress;
        var pickupLocationMessage;
        var dropOffLocationMessage;

        var pickupLocationBranchDescription;
        var dropOffLocationBranchDescription;


        let searchParam = this.state.searchParam;
        let additionalParam = this.state.additionalParam;

        var deliveryState = null;
        var deliveryAddress = null;
        var returnState = null;
        var returnAddress = null;

        if (BookingInfo != null && BookingInfo != undefined) {
            CheckOutLocationId = BookingInfo[0].CheckOutLocationId;
            CheckInLocationId = BookingInfo[0].CheckInLocationId;
            PickUpDate = BookingInfo[0].PickUpDate;
            ReturnDate = BookingInfo[0].ReturnDate;
            pickupLocationName = BookingInfo[0].pickupLocationName;
            dropOffLocationName = BookingInfo[0].dropOffLocationName;
            pickupLocationImage = BookingInfo[0].pickupLocationImage;
            dropOffLocationImage = BookingInfo[0].dropOffLocationImage;
            promocode = BookingInfo[0].promocode;
            pickupLocationAddress = BookingInfo[0].pickupLocationAddress;
            dropOffLocationAddress = BookingInfo[0].dropOffLocationAddress;

            pickupLocationBranchDescription = BookingInfo[0].pickupLocationBranchDescription;
            dropOffLocationBranchDescription = BookingInfo[0].dropOffLocationBranchDescription;

            pickupLocationMessage = BookingInfo[0].pickupLocationMessage;
            dropOffLocationMessage = BookingInfo[0].dropOffLocationMessage;

            deliveryState = BookingInfo[0].deliveryState;
            deliveryAddress = BookingInfo[0].deliveryAddress;
            returnState = BookingInfo[0].returnState;
            returnAddress = BookingInfo[0].returnAddress;

            if ((pickupLocationName == null || pickupLocationName == '') &&
                CheckOutLocationId == "00000000-0000-0000-0000-000000000000")
                dropOffLocationName = returnState + " " + returnAddress;

            if ((dropOffLocationName == null || dropOffLocationName == '') &&
                CheckInLocationId == "00000000-0000-0000-0000-000000000000")
                pickupLocationName = deliveryState + " " + deliveryAddress;

            searchParam.CheckOutBranchID = CheckOutLocationId;
            searchParam.CheckInBranchID = CheckInLocationId;

            searchParam.CheckInOn = ReturnDate;
            searchParam.CheckOutOn = PickUpDate;
            searchParam.PromoCode = promocode;

            searchParam.PickupDetails.StateName = returnState;
            searchParam.PickupDetails.Address = returnAddress;
            searchParam.DeliveryDetails.StateName = deliveryState;
            searchParam.DeliveryDetails.Address = deliveryAddress;

            additionalParam.pickupLocationName = dropOffLocationName;
            additionalParam.dropOffLocationName = pickupLocationName;

            additionalParam.pickupLocationImage = dropOffLocationImage;
            additionalParam.dropOffLocationImage = pickupLocationImage;

            additionalParam.pickupLocationAddress = dropOffLocationAddress;
            additionalParam.dropOffLocationAddress = pickupLocationAddress;

            additionalParam.pickupLocationMessage = dropOffLocationMessage;
            additionalParam.dropOffLocationMessage = pickupLocationMessage;

            additionalParam.pickupLocationBranchDescription = dropOffLocationBranchDescription;
            additionalParam.dropOffLocationBranchDescription = pickupLocationBranchDescription;


            if (BookingInfo[0].ModifiedReservationID != null && BookingInfo[0].ModifiedReservationID != "") {
                searchParam.ModifiedReservationID = BookingInfo[0].ModifiedReservationID;
                localStorage.removeItem('BookingInfo');

                setTimeout(() => {
                    this.setState({

                        showDetails: true
                    });
                }, 1000);
            }

            this.setState({ dropOffLocationImage: dropOffLocationImage });
            //Remove the object Here and Test it
            // localStorage.removeItem('BookingInfo');

        } else {
            CheckOutLocationId = this.state.searchParam.CheckOutBranchID;
            CheckInLocationId = this.state.searchParam.CheckInBranchID;
            PickUpDate = this.state.searchParam.CheckInOn;
            ReturnDate = this.state.searchParam.CheckOutOn;
            pickupLocationName = this.state.additionalParam.pickupLocationName;
            dropOffLocationName = this.state.additionalParam.dropOffLocationName;
            pickupLocationImage = this.state.additionalParam.pickupLocationImage;
            dropOffLocationImage = this.state.additionalParam.dropOffLocationImage;
        }

        const vehicleTypeParam = localStorage.getItem('VehicleTypeParam');
        let vehicleTypeSearch = [];
        if (vehicleTypeParam != null && vehicleTypeParam != undefined) {


            vehicleTypeSearch.push(vehicleTypeParam);
            searchParam.Classifications = vehicleTypeSearch;
            this.setState({
                vehicleTypeDefaultValue: vehicleTypeParam,

            });

            //
            setTimeout(() => {
                this.setState({

                    showDetails: true
                });
            }, 1000);

            localStorage.removeItem('VehicleTypeParam');

        }

        this.setState({ searchParam: searchParam });
        this.setState({ additionalParam: additionalParam });

        this.fetchVehicalModels(ReturnDate, PickUpDate, CheckOutLocationId, CheckInLocationId, this.state.searchParam.DriverAge, this.state.searchParam.VehicleMakeID, vehicleTypeSearch, this.state.searchParam.AutoTransmission, promocode, this.state.searchParam.Passengers,
            deliveryState, deliveryAddress, returnState, returnAddress);

    }

    onVehicleTypeChange = (e) => {
        let searchParam = this.state.searchParam;
        let classfications = [];
        if (e != "00000000-0000-0000-0000-000000000000") {
            classfications.push(e);
        }
        this.setState({
            selectedVehicleType: e
        })
        searchParam.Classifications = classfications;
        this.setState({
            searchParam: searchParam
        });

        this.setState({
            vehicleTypeDefaultValue: e
        });

        var deliveryState = null;
        var deliveryAddress = null;
        var returnState = null;
        var returnAddress = null;

        const BookingInfo = JSON.parse(localStorage.getItem('BookingInfo'));

        if (BookingInfo != null && BookingInfo != undefined) {
            deliveryState = BookingInfo[0].deliveryState;
            deliveryAddress = BookingInfo[0].deliveryAddress;
            returnState = BookingInfo[0].returnState;
            returnAddress = BookingInfo[0].returnAddress;
        }
        //call fetchVehicalModels with vehicle type  from e.target.value  and others from state
        this.fetchVehicalModels(this.state.searchParam.CheckInOn, this.state.searchParam.CheckOutOn, this.state.searchParam.CheckOutBranchID, this.state.searchParam.CheckInBranchID, this.state.searchParam.DriverAge, this.state.searchParam.VehicleMakeID, classfications, this.state.searchParam.AutoTransmission, this.state.searchParam.PromoCode, this.state.searchParam.Passengers, deliveryState, deliveryAddress, returnState, returnAddress)

    }

    onBrandTypeChange = (e) => {

        let searchParam = this.state.searchParam;
        searchParam.VehicleMakeID = e.target.value;
        this.setState({
            searchParam: searchParam
        });
        this.fetchVehicalModels(this.state.searchParam.CheckInOn, this.state.searchParam.CheckOutOn, this.state.searchParam.CheckInBranchID, this.state.searchParam.CheckOutBranchID, this.state.searchParam.DriverAge, e.target.value, this.state.searchParam.Classifications, this.state.searchParam.AutoTransmission, this.state.searchParam.PromoCode, this.state.searchParam.Passengers)

    }

    onSeatsChange = (e) => {
        let searchParam = this.state.searchParam;
        searchParam.Passengers = e.target.value;
        this.setState({
            searchParam: searchParam
        });
        this.fetchVehicalModels(this.state.searchParam.CheckInOn, this.state.searchParam.CheckOutOn, this.state.searchParam.CheckInBranchID, this.state.searchParam.CheckOutBranchID, this.state.searchParam.DriverAge, this.state.searchParam.VehicleMakeID, this.state.searchParam.Classifications, this.state.searchParam.AutoTransmission, this.state.searchParam.PromoCode, e.target.value)
    }


    onAutomatcChange = (e) => {

        let isChecked = this.state.isChecked;
        this.setState({ isChecked: !isChecked });
        let AutoTransmission = (!isChecked) ? 1 : 0;

        let searchParam = this.state.searchParam;
        searchParam.AutoTransmission = AutoTransmission;

        this.setState({
            searchParam: searchParam
        });
        this.fetchVehicalModels(this.state.searchParam.CheckInOn, this.state.searchParam.CheckOutOn, this.state.searchParam.CheckInBranchID, this.state.searchParam.CheckOutBranchID, this.state.searchParam.DriverAge, this.state.searchParam.VehicleMakeID, this.state.searchParam.Classifications, AutoTransmission, this.state.searchParam.PromoCode, this.state.searchParam.Passengers)

    }


    onDriverAgeChange = (e) => {

        let searchParam = this.state.searchParam;
        searchParam.DriverAge = e.target.value;
        this.setState({
            searchParam: searchParam
        });

        this.fetchVehicalModels(this.state.searchParam.CheckInOn, this.state.searchParam.CheckOutOn, this.state.searchParam.CheckInBranchID, this.state.searchParam.CheckOutBranchID, e.target.value, this.state.searchParam.VehicleMakeID, this.state.searchParam.Classifications, this.state.searchParam.AutoTransmission, this.state.searchParam.PromoCode, this.state.searchParam.Passengers)
    }
    onSortChange = (e) => {
        let vehicles

        if (e.target.value == "lowhigh") {
            vehicles = this.state.vehicalModels.sort((a, b) => (a.RentalCharges.DiscountedRate > b.RentalCharges.DiscountedRate) ? 1 : -1);
        }
        else if (e.target.value == "highlow") {
            vehicles = this.state.vehicalModels.sort((a, b) => (b.RentalCharges.DiscountedRate > a.RentalCharges.DiscountedRate) ? 1 : -1);
        }
        else {
            //mostpopular
            vehicles = this.state.vehicalModels.sort((a, b) => ((a.IsBestSeller ? 1 : 0) > (b.IsBestSeller ? 1 : 0)) ? 1 : -1);
        }
        this.setState({ vehicalModels: vehicles });
    }

    //Pass all parameters and call API
    fetchVehicalModels = (pickupDate, returnDate, checkOutLocationId, checkInLocationId, driversAge, brandId, vehicleType, autoTransmission, promocode, passengers, deliveryState, deliveryAddress, returnState, returnAddress) => {

        this.onSelectedVehicleIndexChange((9999, {}));
        let lngParam = getLanguage();

        let paramDriversAge = driversAge;
        if (driversAge == "Driver Age") {
            paramDriversAge = 21;
        }

        let paramBrandId = brandId;
        if (brandId == "Brand") {
            paramBrandId = '00000000-0000-0000-0000-000000000000';
        }

        let paramVehicleType = [];
        if (vehicleType.length > 0) {
            paramVehicleType = vehicleType[0];
            if (vehicleType[0] == "Vehicle Type") {
                paramVehicleType = [];
            }
        }


        let paramautoTransmission = autoTransmission;
        if (autoTransmission == "DefaultAutomatic") {
            paramautoTransmission = "0";
        }
        let customerId = getCustomerId();

        let searchParam = {
            Classifications: paramVehicleType,
            VehicleMakeID: paramBrandId,
            VehicleModelID: "00000000-0000-0000-0000-000000000000",
            SpecialOfferID: "00000000-0000-0000-0000-000000000000",
            ModelYear: 0,
            Passengers: passengers,
            AutoTransmission: paramautoTransmission,
            CheckOutOn: returnDate,
            CheckInOn: pickupDate,

            CheckOutBranchID: checkOutLocationId,
            DeliveryDetails: {
                "Address": deliveryAddress,
                "AddressJson": "",
                "StateName": deliveryState,
            },

            CheckInBranchID: checkInLocationId,
            PickupDetails: {
                "Address": returnAddress,
                "AddressJson": "",
                "StateName": returnState,
            },

            PromoCodeID: "00000000-0000-0000-0000-000000000000",
            PromoCode: promocode,
            DriverAge: paramDriversAge,
            MinRentalRate: 0,
            MaxRentalRate: 0,
            RentalDays: 0,
            NoOfRecords: 50000,
            TariffGroupID: "00000000-0000-0000-0000-000000000000",
            ModifiedReservationID: this.state.searchParam.ModifiedReservationID,
        };

        var axios = require('axios');
        var data = qs.stringify(searchParam);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API + 'MonthlySubscription/Get?apiKey=' + process.env.REACT_APP_API_KEY + `&lng=${lngParam}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                if (response.data != null && response.data.SearchVehicleModels.length > 0) {
                    this.setState({ vehicalModels: response.data.SearchVehicleModels });
                    this.setState({ allVehicleTypes: response.data.VehicleTypes })
                }
                else {
                    this.setState({ isFirstTimeLoad: false });
                }
            })
            .catch((error) => {

            });
    }

    toogleBookNowPopup = (step) => {
        this.setState({
            step1: step
        });
        this.setState({
            isActive: 1
        });

        if (step == 1) {
            this.setState({ showDetails: !this.state.showDetails });
        }

    }

    onUpgradeModelSelected = (selectedModel, originalVehicleModelID, originalTariffGroupID) => {

        let additionalParam = this.state.additionalParam;
        additionalParam.originalVehicleModelID = originalVehicleModelID;
        additionalParam.originalTariffGroupID = originalTariffGroupID;
        this.setState({
            additionalParam: additionalParam
        });
        this.onVehicleChange(selectedModel, "");
    }

    onVehicleChange = (selectedModel, paySelection, selectedMonth, selectedKM) => {

        this.setState({
            selectVehicleForBooking: selectedModel
        });

        this.setState({
            paySectionValue: paySelection
        });


        let searchParam = this.state.searchParam;

        searchParam.SubscriptionMonth = selectedMonth;
        searchParam.SubscriptionKm = selectedKM;

        if (paySelection == "paynow" || selectedModel.PaymentStatus == 1) {

            searchParam.PaymentStatus = 1;
        } else {
            searchParam.PaymentStatus = 0;
        }

        this.setState({
            searchParam: searchParam
        });
        this.toogleBookNowPopup(4);
        scroll.scrollToTop();

    }

    onContinueClickHandler = (searchParam) => {
        this.setState({
            searchParam: searchParam
        });

        this.toogleBookNowPopup(4);
        scroll.scrollToTop();
    }

    onSelectedVehicleIndexChange = (index, selectedVehicle) => {

        this.setState({
            selectedVehicleIndex: index,
            selectVehicleForBooking: selectedVehicle
        });
        if (selectedVehicle != null) {

            setTimeout(function () {
                scroller.scrollTo(selectedVehicle.VehicleModelID + "_box", {
                    smooth: 'easeInOutQuint'

                });
            }, 1000);
        }


    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let lastScrollY = window.scrollY;
        if (lastScrollY > 100) {
            this.setState({
                showStickHeader: true
            });
        }
        else {
            this.setState({
                showStickHeader: false
            });
        }
    }

    showBookingDetails = () => {

        this.setState({
            showDetails: !this.state.showDetails
        });
    }

    render() {
        const { t } = this.props;
        let remaining = this.state.vehicalModels.length % this.state.columnCount;

        return (
            <div className="grey-bg">
                <ListingHeader></ListingHeader>
                <div class="minH100  pay-2">
                    <section className={this.state.showStickHeader ? "rental-steps-section rental-steps-sticky" : "rental-steps-section"}>
                        <div className="container-fluid">
                            <ul className="rental-steps-wrap new--rental-steps-wrap">

                                <li className="active checked" onClick={() => this.toogleBookNowPopup(1)}>
                                    <a className="rental-step-dropdown" title="Rental Details" data-steps="1">
                                        <span className="rental-steps-title">{t('Location & Vehicle.1')}</span>
                                        <div className="rental-steps-content rental-steps-done">
                                            <div className="rental-detail-place">
                                                <div className="rental-detail-place-left" onClick={this.showBookingDetails}>
                                                    <span>{this.state.additionalParam.dropOffLocationName}</span>

                                                </div>
                                                <div className="rental-detail-place-right" onClick={this.showBookingDetails}>
                                                    <span>{this.state.additionalParam.pickupLocationName}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </a>

                                </li>
                                <li className={(this.state.step1 == 1 && this.state.showRentalDetails == false) ? 'active' : (this.state.step1 > 2) ? 'active checked rental-step-dropdown' : 'rental-step-dropdown'} onClick={() => this.toogleBookNowPopup(1)}>
                                    <a className="rental-step-dropdown" title="Vehicle Selection" data-steps="2">
                                        <span className="rental-steps-title">{t('Add-Ons & Documents.1')}</span>
                                        <div className="rental-steps-content rental-steps-done">
                                            <div className="rental-detail-place">
                                                <div className="rental-detail-place-left">
                                                    <span>{(this.state.selectVehicleForBooking != null) ? this.state.selectVehicleForBooking.Name : ""}</span>

                                                </div>
                                                <div className="rental-detail-place-right">
                                                    <span>{(this.state.selectVehicleForBooking != null) ? <>{t('or Similar.1')}</> : ""}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    {
                                        (this.state.selectVehicleForBooking == null) ?
                                            <div className="rental-steps-content rental-steps-current">
                                                {t('Select.1')}
                                            </div> : null
                                    }
                                </li>

                                <li className={this.state.step1 == 4 ? 'active' : 'rental-step-dropdown'} >
                                    <a className="rental-step-dropdown" title="Rental Details" data-steps="4">
                                        <span className="rental-steps-title">{t('Payment & Confirmation.1')}</span>

                                    </a>
                                    {
                                        (this.state.step1 == 4) ? <div className="rental-steps-content rental-steps-current">
                                            {t('Select.1')}
                                        </div> : null
                                    }

                                </li>
                                {/* <li className={this.state.step1 == 4 ? 'active' : 'rental-step-dropdown'} >
                                <a className="rental-step-dropdown" title="Check out" data-steps="4">
                                    <span className="rental-steps-title">Check out</span>
                                </a>
                                {
                                    (this.state.step1 == 4) ? <div className="rental-steps-content rental-steps-current">
                                        Select
                                    </div> : null
                                }
                            </li> */}
                            </ul>
                        </div>
                    </section>


                    {this.state.step1 == 1 ?
                        <div>

                            <RentalDetails key="rentalDetails1" showDetails={this.state.showDetails} PickUpDate={this.state.searchParam.CheckOutOn} ReturnDate={this.state.searchParam.CheckInOn} pickupLocationName={this.state.additionalParam.dropOffLocationName} dropOffLocationName={this.state.additionalParam.pickupLocationName} promoCode={this.state.searchParam.PromoCode} modifiedReservationID={this.state.searchParam.ModifiedReservationID}
                                deliveryState={this.state.searchParam.DeliveryDetails.StateName} deliveryAddress={this.state.searchParam.DeliveryDetails.Address}
                                returnState={this.state.searchParam.PickupDetails.StateName} returnAddress={this.state.searchParam.PickupDetails.Address}
                            />

                            <section className="offer-box-section mt-4">
                                <div className="container-fluid">
                                    <div className='choose-vehicle-title'>
                                        <h1 className='h4'>{t('Choose your wheels.1')}</h1>
                                        <div className='vehicle-type-box'>
                                            <img src={sortBy} />
                                            <select name="sort-by" className="custom-filter-box" onChange={this.onSortChange} defaultValue={'asc'}>
                                                <option key={1} disabled="" value="asc">{t('Sort by.1')}</option>
                                                <option value="lowhigh">{t('Low to High.1')}</option>
                                                <option value="highlow">{t('High to Low.1')}</option>
                                                <option value="mostpopular">{t('Best Sellers.1')}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="offer-filter-wrap">
                                        <div className={this.state.selectedVehicleType == "00000000-0000-0000-0000-000000000000" ? "what--new-box what--new-box-all active"
                                            : "what--new-box what--new-box-all"} value={this.state.vehicleTypeDefaultValue}>
                                            <a className="what--new-link" onClick={() => { this.onVehicleTypeChange("00000000-0000-0000-0000-000000000000") }}  >
                                                <p>{t('All.1')}</p>
                                            </a>
                                        </div>
                                        {
                                            this.state.allVehicleTypes.map((type) =>
                                                <div className={this.state.selectedVehicleType == type.VehicleClassification.VehicleClassificationID ? "what--new-box active" : "what--new-box"} value={this.state.vehicleTypeDefaultValue}>
                                                    <a className="what--new-link" onClick={() => { this.onVehicleTypeChange(type.VehicleClassification.VehicleClassificationID) }}  >
                                                        <p>{type.VehicleClassification.Name}</p>
                                                        <img src={type.VehicleClassification.Icon.ImageString} alt={type.Name} />
                                                        <div className="what-new-box-more-content" >
                                                            <p className='mb-0'>
                                                                {t('Starting from.1')}
                                                                <br></br> <span>{t('AED.1')} {(type.MinPrice * 1.05).toFixed(0)}</span>
                                                            </p>

                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        }

                                    </div>

                                </div>
                            </section>

                            <section className="offer-box-section">
                                <div className="container-fluid">
                                    <div className="offer-box-wrapper">
                                        {
                                            this.state.searchParam.PromoCode != '' ?
                                                <div className="row justify-content-end mt-4 w-100 no-gutters pr-3">
                                                    <div className="col-auto">
                                                        <div className="promocode-applied-box">{t('Promo code applied.1')}: <span>{this.state.searchParam.PromoCode}</span> </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.vehicalModels.length > 0 ?
                                                this.state.vehicalModels.map((model, index) => {
                                                    return ((index + 1) % this.state.columnCount != 0) ?
                                                        <ListingCard key={model.VehicleModelID + '_' + model.ModelYear} model={model} onUpgradeModelSelected={this.onUpgradeModelSelected} onVehicleChange={this.onVehicleChange} hideInclude={0} hideUpgradeOptions={0} showDiffPrice={0} searchParam={this.state.searchParam} additionalParam={this.state.additionalParam} vehicleIndex={index + 1} onVehicleIndexChange={this.onSelectedVehicleIndexChange} selectedVehicleIndex={this.state.selectedVehicleIndex} days={model.RentalDays} />
                                                        :
                                                        ((this.state.selectedVehicleIndex > ((index + 1) - this.state.columnCount)) && (this.state.selectedVehicleIndex <= (index + 1))) ?
                                                            <>
                                                                <ListingCard key={model.VehicleModelID + '_' + model.ModelYear} onUpgradeModelSelected={this.onUpgradeModelSelected} model={model} onVehicleChange={this.onVehicleChange} hideInclude={0} hideUpgradeOptions={0} showDiffPrice={0} searchParam={this.state.searchParam} additionalParam={this.state.additionalParam} vehicleIndex={index + 1} onVehicleIndexChange={this.onSelectedVehicleIndexChange} selectedVehicleIndex={this.state.selectedVehicleIndex} days={model.RentalDays} />

                                                                <IncludePageInline id="carTemp1" key={index} model={this.state.selectVehicleForBooking} onVehicleChange={this.onVehicleChange} hideInclude={0} hideUpgradeOptions={0} searchParam={this.state.searchParam} additionalParam={this.state.additionalParam} onVehicleIndexChange={this.onSelectedVehicleIndexChange}></IncludePageInline>
                                                            </>
                                                            :
                                                            <ListingCard key={model.VehicleModelID + '_' + model.ModelYear} onUpgradeModelSelected={this.onUpgradeModelSelected} model={model} onVehicleChange={this.onVehicleChange} hideInclude={0} hideUpgradeOptions={0} showDiffPrice={0} searchParam={this.state.searchParam} additionalParam={this.state.additionalParam} vehicleIndex={index + 1} onVehicleIndexChange={this.onSelectedVehicleIndexChange} selectedVehicleIndex={this.state.selectedVehicleIndex} days={model.RentalDays} />

                                                })
                                                :
                                                (this.state.isFirstTimeLoad == false ?
                                                    <div className="nodata-for-rent-section">
                                                        <h3>{t('Ohh ! No vehicles available for requested date.1')}</h3>
                                                        <p>{t('Please try to search for another date period or contact our customer care at 800 Dollar.1')}</p>
                                                    </div>
                                                    : <div className="nodata-for-rent-section">
                                                        <h3>{t('Searching for best rates for you, please wait.1')}</h3>
                                                        <br></br><br></br>
                                                        <img src={noResultImage} alt={t('Searching for Best rates for you.1')} style={{ width: '100px', height: '100px' }} />
                                                    </div>
                                                )
                                        }
                                        {

                                            ((remaining != 0) && (this.state.selectedVehicleIndex > this.state.vehicalModels.length - remaining)) ?
                                                <IncludePageInline key={remaining} model={this.state.selectVehicleForBooking} onVehicleChange={this.onVehicleChange} hideInclude={0} hideUpgradeOptions={0} searchParam={this.state.searchParam} additionalParam={this.state.additionalParam} onVehicleIndexChange={this.onSelectedVehicleIndexChange}></IncludePageInline>
                                                : null
                                        }


                                    </div>
                                </div>
                            </section>
                        </div> : null}
                    {this.state.step1 == 2 ?
                        <div>

                        </div> : null}
                    {this.state.step1 == 3 ?
                        <div>
                            <ListingPage2 selectVehicleForBooking={this.state.selectVehicleForBooking} searchParam={this.state.searchParam} additionalParam={this.state.additionalParam} onContinueClick={this.onContinueClickHandler} />
                        </div> : null}
                    {this.state.step1 == 4 ?
                        <div>
                            <ListingPage3 selectVehicleForBooking={this.state.selectVehicleForBooking} searchParam={this.state.searchParam} additionalParam={this.state.additionalParam} onUpgradeModelSelected={this.onUpgradeModelSelected} ></ListingPage3>
                        </div> : null}
                </div>
                <ListingFooter></ListingFooter>
            </div>


        );
    }
}

export default hoistStatics(withTranslation()(MonthlyListingPage), MonthlyListingPage);