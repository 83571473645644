/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { GoogleApiWrapper } from "google-maps-react";
import hoistStatics from "hoist-non-react-statics";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "./Tabs/tabsStyle.css";

import "bootstrap/dist/css/bootstrap.min.css";
import Banner from "./MonthlyBanner";
import FlexiMonthly from "./Tabs/FlexiMonthly";

var qs = require("qs");

class MonthlyBannerSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedWidget: false,
      imageString: "",
      BannerData: [],
    };
  }
  onFeedBackClick = () => {
    this.props.history.push("/feedback");
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.setState({ promocode: e.target.value });
  };
  componentDidMount() {
    this.getBanner();
  }

  getBanner = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API + "Banner/Get?apiKey=" + process.env.REACT_APP_API_KEY + "&imagePlacementIds=23",
      headers: {},
    };

    axios(config)
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            imageString: response.data[0].BannerAttachment.ImageString,
            BannerData: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onOutSideClick = () => {
    /*document.getElementById('body').className = '';*/
  };

  handleBanner = () => {
    
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <section className="banner-section" onClick={this.onOutSideClick}>
          <section className="sort-c1 manageHeight">
            <div className="inner-wrapper" style={{ background: "white" }}>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="tab-content " id="nav-tabContent">
                      <FlexiMonthly manageBanner={this.handleBanner} handleHeaderActive={this.props.handleHeaderActive}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Banner BannerData={this.state.BannerData}></Banner>
        </section>
        <a className="feedback-sticky" title="Feedback" onClick={this.onFeedBackClick}>
          {t("Feedback.1")}
        </a>
        <div className="info-bttn-sidesticky">
          <a href="tel:+911234567890">
            <i className="fa fa-phone"></i>
          </a>
          <a href="https://goo.gl/maps/yD7KLi9CU8eY2Aho8" target="_blank">
            <i className="fa fa-map-marker"></i>
          </a>
        </div>
      </>
    );
  }
}

export default hoistStatics(
  withTranslation()(
    GoogleApiWrapper({
      apiKey: "AIzaSyD5B6KKLVWThFRg2oXcEYoFHMyRFoLexzo",
    })(withRouter(MonthlyBannerSection))
  ),
  MonthlyBannerSection
);
