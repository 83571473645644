
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import pickUpicon from '../../src/assets/images/pick-up-icon.svg';
import pickUpiconDate from '../../src/assets/images/pick-up-date-icon.svg';


import { getLanguage } from '../Common/Util';

import PickupPopover from './PickupPopover';
import ReturnPopver from './ReturnPopver';
import PickupTimePopover from './PickupTimePopover';
import { th } from 'date-fns/locale';
import PickupDateRespPopover from './PickupDateRespPopover';
import PickupTimeRespPopover from './PickupTimeRespPopover';
import ReturnTimeRespPopover from './ReturnTimeRespPopover';
var qs = require('qs');


class SearchWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowSize:1024,
            isReturnAtOtherChecked: true,
            selectedPicupLocation: null,
            selectedDestinationLocation: null,
            selectedPickupDate: Date().toLocaleString(),
            selectedReturnDate: Date().toLocaleString(),
            errorMessage: '',
            timeType: 1,
            pickupTime: "09:00:00",
            returnTime: "09:00:00",
            locationsToShow: [],
            firstTimePickupBranch: true,
            firstTimePickupDate: false,
            firstTimePickupTime: false,
            firstTimeReturnDate: false,
            firstTimeReturnTime: false,
            overReturnBranch: {},
            promocode: '',
            searchWidgetMode: 0,
            showPopover: false,
            isMonthlyRental : false,
            deliveryStateName: null,
            deliveryAddress:'',
            pickupStateName: null,
            pickupAddress: ''
        }

    }

    componentDidUpdate(prevProps) {

       // alert(this.props.pickupLocation.Name);

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        this.setState({ promocode: e.target.value });
    }
    componentDidMount() {
      //  alert('load');
      window.addEventListener("resize", this.updateWindowSize);
      this.updateWindowSize();
        if (this.props.page == "L") {
            
            if (this.props.pickupLocation != null) {
                this.onPickupBranchSelectedF(this.props.pickupLocation);
            }

            if (this.props.dropLocation != null) {
                if (this.props.pickupLocation.Name == this.props.dropLocation.Name) {

                }
                else {
                    this.onReturnBranchSelected(this.props.dropLocation);
                    this.setState({ isReturnAtOtherChecked: false });
                }
            }
            if (this.props.promoCode != null && this.props.promoCode != undefined && this.props.promoCode != '') {
                this.setState({
                    promocode: this.props.promoCode
                });

            }

            this.setState({ ModifiedReservationID: this.props.ModifiedReservationID });

            this.onChangePickupDateF(moment(this.props.PickUpDate).format('MM/DD/YYYY  h:mm:ss A'));
            this.onChangeReturnDateF(moment(this.props.ReturnDate).format('MM/DD/YYYY  h:mm:ss A'));
        }

        //alert(this.props.isMonthlyRental);
        if(this.props.isMonthlyRental!=null)
           this.setState({isMonthlyRental:this.props.isMonthlyRental});
    }
    onChangePickupDate = (date) => {

        if (this.state.firstTimePickupDate == true && this.props.page == "L") {
            this.setState({
                firstTimePickupDate: false
            });
        } else {

            this.setState({ selectedPickupDate: date });
        }

    }

    onChangeReturnDate = (date) => {
        if (this.state.firstTimeReturnDate == true && this.props.page == "L") {

            this.setState({
                firstTimeReturnDate: false
            });
        }
        else {
            this.setState({ selectedReturnDate: date });
        }

    }
    onRetunrTimeChange = (time) => {

        if (this.state.firstTimePickupTime == true && this.props.page == "L") {
            this.setState({
                firstTimePickupTime: false
            });
        }
        else {
            this.setState({ returnTime: time });
        }


    }
    onPickupTimeChange = (time) => {

        if (this.state.firstTimeReturnTime == true && this.props.page == "L") {
            this.setState({
                firstTimeReturnTime: false
            });
        }
        else {
            this.setState({ pickupTime: time });
            this.setState({ returnTime: time });
        }



    }
    validateSearch = () => {
        //Validate

        let lngParam = getLanguage();
        this.setState({ errorMessage: "" });
        if (this.state.selectedPicupLocation === null || this.state.selectedPicupLocation === undefined) {

            if (lngParam == "en") {
                this.setState({ errorMessage: "Please select Pickup Location" });
            }
            else {
                //ar
                this.setState({ errorMessage: "الرجاء تحديد بيك اب الموقع" });
            }

            return false;
        }

        if (this.state.selectedDestinationLocation === null || this.state.selectedDestinationLocation === undefined) {

            if (lngParam == "en") {
                this.setState({ errorMessage: "Please select return Location" });
            }
            else {
                //ar
                this.setState({ errorMessage: "الرجاء تحديد return الموقع" });
            }
            return false;
        }
        
        if(moment(this.state.selectedReturnDate) < moment(this.state.selectedPickupDate))
        {
            this.setState({ errorMessage: "Please select valid return date & time." });
            return false;
        }

        return true;
    }

    // validateDateTimeDiff = (selectedPickupDate,selectedReturnDate) =>{

    //     var dateTimeDifference = Math.abs(moment(selectedReturnDate, 'YYYY-MM-DD HH:mm:ss') - moment(selectedPickupDate, 'YYYY-MM-DD HH:mm:ss'));
        
    //     if(dateTimeDifference > 0)
    //     {
    //         return true;
    //     }
        
    //     return false;
    // }

    searchClickHandler = () => {
        let lngParam = getLanguage();
        this.clearPopOver();
        if (this.validateSearch()) {
            
            var data = qs.stringify({
                "Classifications": [],
                "VehicleMakeID": "00000000-0000-0000-0000-000000000000",
                "VehicleModelID": "00000000-0000-0000-0000-000000000000",
                "SpecialOfferID": "00000000-0000-0000-0000-000000000000",
                "ModelYear": 0,
                "Passengers": 0,
                "AutoTransmission": 1,
                "CheckOutOn": moment(this.state.selectedPickupDate).format('DD MMM YYYY, h:mm A'),
                "CheckInOn": moment(this.state.selectedReturnDate).format('DD MMM YYYY, h:mm A'),
                "DeliveryDetails": {
                    //"Address": "",
                    "AddressJson": "",
                    "StateName": this.state.deliveryStateName,
                    "Address": this.state.deliveryAddress,
                },
                "PickupDetails": {
                    //"Address": "",
                    "AddressJson": "",
                    "StateName": this.state.pickupStateName,
                    "Address": this.state.pickupAddress,
                },
                "CheckOutBranchID": this.state.selectedPicupLocation.Identity.Id,
                "CheckInBranchID": this.state.selectedDestinationLocation.Identity.Id,
                "PromoCode": this.state.promocode,
                "DriverAge": 21,
                "MinRentalRate": 0,
                "MaxRentalRate": 50000,
                "RentalDays": 0,
                "NoOfRecords": 0,
                "ModifiedReservationID": this.props.modifiedReservationID,
                "isMonthlyBooking": this.state.isMonthlyRental
            });
            var config = {
                method: 'post',
                url: process.env.REACT_APP_API + `ReservationValidation/Validate?apiKey=${process.env.REACT_APP_API_KEY}&lng=${lngParam}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
            axios(config)
                .then((response) => {
                    if (response.data.IsValid === true) {
                        
                        var BookingInfo = [{
                            "PickupLocationId": this.state.selectedPicupLocation.Identity.Id,
                            "DestinationLocationId": this.state.selectedDestinationLocation.Identity.Id,
                            "PickUpDate": moment(this.state.selectedPickupDate).format('DD MMM YYYY, h:mm A'),
                            "ReturnDate": moment(this.state.selectedReturnDate).format('DD MMM YYYY, h:mm A'),
                            "pickupLocationName": this.state.selectedPicupLocation.Name,
                            "dropOffLocationName": this.state.selectedDestinationLocation.Name,
                            "pickupLocationImage": this.state.selectedPicupLocation.Attachment.ImageString,
                            "dropOffLocationImage": this.state.selectedDestinationLocation.Attachment.ImageString,
                            "pickupLocationAddress": this.state.selectedPicupLocation.Address,
                            "dropOffLocationAddress": this.state.selectedDestinationLocation.Address,
                            "pickupLocationMessage": this.state.selectedPicupLocation.Message,
                            "dropOffLocationMessage": this.state.selectedDestinationLocation.Message,
                            "pickupLocationBranchDescription": this.state.selectedPicupLocation.Description,
                            "dropOffLocationBranchDescription": this.state.selectedDestinationLocation.Description,

                            "deliveryState" :  this.state.deliveryStateName,
                            "deliveryAddress" : this.state.deliveryAddress,
                            "returnState" :  this.state.pickupStateName,
                            "returnAddress" : this.state.pickupAddress,


                            "promocode": this.state.promocode,
                            "ModifiedReservationID": this.props.modifiedReservationID,
                        }];

                        localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
                        window.location = "/booking";
                    } else {

                        this.setState({
                            errorMessage: response.data.Message
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        errorMessage: "Sometthing is not correct, please try again later."
                    });
                });
        }
    }
    handleClose = () => {
        this.setState({
            errorMessage: ""
        });
    }

    pickupDeliveryLocationSelected = (stateName, address) =>
    {
        this.setState({ deliveryStateName: stateName});      
        this.setState({ deliveryAddress: address});  

        this.setState({ pickupStateName: stateName});      
        this.setState({ pickupAddress: address}); 
    }


    returnDeliveryLocationSelected = (stateName, address) =>
    {
        this.setState({ pickupStateName: stateName});      
        this.setState({ pickupAddress: address}); 
    }

    onPickupBranchSelected = (selectedBranch) => {
        if (this.state.firstTimePickupBranch == true && this.props.page == "L") {

            this.setState({
                firstTimePickupBranch: false
            });

        } else {
            this.clearPopOver();
            this.setState({ selectedPicupLocation: selectedBranch });
            this.setState({ selectedDestinationLocation: selectedBranch });
            this.setState({ overReturnBranch: selectedBranch });

            var config = {
                method: 'get',
                url: process.env.REACT_APP_API + 'Branch/GetBranchHrsToGetReady?apiKey=' + process.env.REACT_APP_API_KEY + `&branchID=${selectedBranch.Identity.Id}&isMonthlyBooking=${this.state.isMonthlyRental}`,
                headers: {}
            };

            axios(config)
                .then((response) => {


                    this.setState({ selectedPickupDate: moment.tz(response.data.PickupOn, "Asia/Dubai") });
                    this.setState({ selectedReturnDate: moment.tz(response.data.ReturnOn, "Asia/Dubai") });
                  
                    this.setState({ pickupTime: moment.tz(response.data.PickupOn, "Asia/Dubai").format('HH:mm') });
                    this.setState({ returnTime: moment.tz(response.data.ReturnOn, "Asia/Dubai").format('HH:mm') });


                })
                .catch((error) => {

                });
        }

    }

    onReturnBranchSelected = (selectedBranch) => {
        this.clearPopOver();
        this.setState({ selectedDestinationLocation: selectedBranch });
    }



    onMouseOverReturnBranch = (branch) => {
        this.setState({ errorMessage: '' });
        this.setState({ overReturnBranch: branch });
    }

    onReturnAtPickUpLocationChange = e => {
        let isChecked = this.state.isReturnAtOtherChecked;
        this.setState({ isReturnAtOtherChecked: !isChecked });
    }

    onPickupLocationClick = (e) => {
        this.setState({ searchWidgetMode: 1 });
        this.togglePopOver();
       
        e.stopPropagation();
    }

    onReturnLocationClick = (e) => {
        this.setState({ searchWidgetMode: 2 });
        this.togglePopOver();
        e.stopPropagation();
    }

    onPickupTimeClick = (timeType,e) => {
        this.setState({
            searchWidgetMode: 3,
            timeType: timeType
        });
        this.togglePopOver();
        e.stopPropagation();
    }


    onPickupDateResClick = (timeType,e) => {
        let searchWidgetMode;
        if(this.state.windowSize < 770){
            searchWidgetMode = 10;

            var elementToHide = document.getElementsByClassName("dialog-date-picker hide");
            if(elementToHide[0] != undefined){
                elementToHide[0].className = 'dialog-date-picker open';
            }

        }else{
            searchWidgetMode = 3;
        }

       

        this.setState({
            searchWidgetMode: searchWidgetMode,
            timeType: timeType
        });

        this.togglePopOver();
        e.stopPropagation();
    }


    onPickupTimeResClick = (timeType,e) => {
        let searchWidgetMode;
        if(this.state.windowSize < 770){
            searchWidgetMode = 11;

        }else{
            searchWidgetMode = 3;
        }

       
        this.setState({
            searchWidgetMode: searchWidgetMode,
            timeType: timeType
        });

        this.togglePopOver();
        e.stopPropagation();
    }

    onReturnTimeResClick = (timeType,e) => {
        let searchWidgetMode;
        if(this.state.windowSize < 770){
            searchWidgetMode = 12;

        }else{
            searchWidgetMode = 3;
        }

        

        this.setState({
            searchWidgetMode: searchWidgetMode,
            timeType: timeType
        });

        this.togglePopOver();
        e.stopPropagation();
    }


    updateWindowSize = () => {
    

        this.setState({
            windowSize: window.innerWidth
        });
    };


    togglePopOver = ()=> {
        
        let showPopover = this.state.showPopover;
        let body = document.getElementById('body');
        console.log(body);
        if (body != null && body != undefined) {
            if (body.className == '') {
                body.className = 'toggle-serach-bar';
                this.setState({ showPopover: true });

            }
            else {
                body.className = '';
                this.setState({ showPopover: false });
            }
            
        }
    }

    clearPopOver() {
        document.getElementById('body').className = '';
        this.setState({ searchWidgetMode: 0 });
        this.setState({ showPopover: false });
    }

    //////////////////////////////////// First Time

    onPickupBranchSelectedF = (selectedBranch) => {

        this.clearPopOver();

        this.setState({ selectedPicupLocation: selectedBranch });
        this.setState({ selectedDestinationLocation: selectedBranch });
        this.setState({ overReturnBranch: selectedBranch });
    }


    onChangePickupDateF = (date) => {
        this.setState({ selectedPickupDate: date });
    }

    onChangeReturnDateF = (date) => {
        this.setState({ selectedReturnDate: date });
    }
    onRetunrTimeChangeF = (time) => {


        this.setState({ returnTime: time });



    }
    onPickupTimeChangeF = (time) => {


        this.setState({ pickupTime: time });




    }
    ///////////////////////


    render() {
// alert(this.props.modifiedReservationID);
        const { t } = this.props;
        return (

            <div className="new-banner-section-content">
                <div className="container">
                    {/* <h1>Book your wheels</h1> */}
                    
                            <div className="new-search-box-wrap">

                                {/* <div className='new-search--Box-wrap-row'>
                                    <div className='newsearchBoxwrap-Col'>
                                        <label className='titleLabel'>
                                            <img src={pickUpicon}/><span>Pick-up Location</span>
                                        </label>
                                        <div className='input-box-design'>
                                            <a className="dropdown-toggle" onClick={this.onPickupLocationClick} >
                                                {(this.state.selectedPicupLocation != null) ? this.state.selectedPicupLocation.Name : 'Select pick-up'}
                                            </a>
                                        </div>
                                    </div>
                                    <div className='newsearchBoxwrap-Col'>
                                        <div className='newsearchBoxwrap-comb-Col'>
                                            <div className='newsearchBoxwrap-comb-left'>
                                                <label className='titleLabel'>
                                                    <img src={pickUpiconDate}/><span>Pick-up Date</span>
                                                </label>
                                                <div className='input-box-designHalf'>
                                                    <a class="dropdown-toggle">15 Feb 2023</a>
                                                </div>
                                            </div>
                                            <div className='newsearchBoxwrap-comb-right'>
                                                <label className='titleLabel'>
                                                    <span>Pick-up Time</span>
                                                </label>
                                                <div className='input-box-designHalf'>
                                                    <a class="dropdown-toggle">11:00AM</a>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        
                                    </div>
                                    <div className='newsearchBoxwrap-Col'>
                                        <div className='newsearchBoxwrap-comb-Col'>
                                            <div className='newsearchBoxwrap-comb-left'>
                                                <label className='titleLabel'>
                                                    <img src={pickUpiconDate}/><span>Return Date</span>
                                                </label>
                                                <div className='input-box-designHalf'>
                                                    <a class="dropdown-toggle">15 Feb 2023</a>
                                                </div>
                                            </div>
                                            <div className='newsearchBoxwrap-comb-right'>
                                                <label className='titleLabel'>
                                                    <span>Return Time</span>
                                                </label>
                                                <div className='input-box-designHalf'>
                                                    <a class="dropdown-toggle">11:00AM</a>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        
                                    </div>
                                    <div className='newsearchBoxwrap-Col'>
                                        <div className='input-box-design'>
                                            
                                                <a class="dropdown-toggle" style={{ cursor: "pointer" }} onClick={this.onReturnLocationClick}>
                                                    {(this.state.selectedDestinationLocation != null) ? this.state.selectedDestinationLocation.Name : 'Same as Pickup'}
                                                </a>
                                        </div>
                                    </div>
                                    <div className='newsearchBoxwrap-Col'>
                                        <div className='newsearchBox-promocode-wrap'>
                                            <div className='newsearchBox-promocode-left'>
                                                <input type="text" id="promocode" name="promocode" placeholder="Promo Code" className='input-box-design'/>
                                            </div>
                                            <div className='newsearchBox-promocode-right'>
                                                <button className='search-bttn'>
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                    SEARCH
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className={!this.state.isReturnAtOtherChecked ? "return-pickup-location-wrap select-wrap-dropdown select-wrap-section-main double-input-wrapper" : "new-select-wrap-dropdown select-wrap-section-main new-double-input-wrapper"} >
                                    {
                                        this.props.modifiedReservationID != "00000000-0000-0000-0000-000000000000" &&
                                        this.props.modifiedReservationID != undefined ?
                                        <div className="select-wrap-section show-modal-popup" style={{ cursor: "pointer" }} onClick={this.onPickupLocationClick}  >
                                            <label style={{ cursor: "pointer" }} onClick={this.onPickupLocationClick}>
                                                {t('Pickup.1')}
                                            </label>
                                            <a className="dropdown-toggle" onClick={this.onPickupLocationClick} >
                                                {(this.state.selectedPicupLocation != null) ? this.state.selectedPicupLocation.Name : <>{t('Select pick-up.1')}</>}
                                            </a>
                                        </div> :  
                                        <div className="select-wrap-section show-modal-popup pick-up-location-modal" style={{ cursor: "pointer" }} onClick={this.onPickupLocationClick}>
                                            <label style={{ cursor: "pointer" }} onClick={this.onPickupLocationClick}> <img src={pickUpicon}/>{t('Pickup.1')}</label>
                                            <a className="dropdown-toggle" onClick={this.onPickupLocationClick}>
                                            <i className="fa fa-search" aria-hidden="true"></i> {(this.state.selectedPicupLocation != null) ? this.state.selectedPicupLocation.Name : <>{t('Select pick-up.1')}</>}
                                            </a>
                                        </div>
                                    }
                                    {
                                        !this.state.isReturnAtOtherChecked  ? this.props.modifiedReservationID != "00000000-0000-0000-0000-000000000000" &&
                                        this.props.modifiedReservationID != undefined ?
                                        <div class="select-wrap-section show-modal-popup" style={{pointerEvents: "none", opacity: "0.4"}} onClick={this.onReturnLocationClick}>
                                                <label style={{ cursor: "pointer" }} onClick={this.onReturnLocationClick}>{t('Return.1')}</label>
                                                <a class="dropdown-toggle" style={{ cursor: "pointer" }} onClick={this.onReturnLocationClick}>
                                                    {(this.state.selectedDestinationLocation != null) ? this.state.selectedDestinationLocation.Name : <>{t('Select return.1')}</>}
                                                </a>
                                            </div>
                                            :
                                            <div class="select-wrap-section show-modal-popup pick-up-location-modal new-return-location-wrap" style={{ cursor: "pointer" }} onClick={this.onReturnLocationClick}>
                                                <label style={{ cursor: "pointer" }} onClick={this.onReturnLocationClick}>{t('Return.1')}</label>
                                                <a class="dropdown-toggle" style={{ cursor: "pointer" }} onClick={this.onReturnLocationClick}>
                                                    {(this.state.selectedDestinationLocation != null) ? this.state.selectedDestinationLocation.Name : <>{t('Select return.1')}</>}
                                                </a>
                                            </div> : null
                                    }

                                    {
                                        !this.state.isReturnAtOtherChecked ? null :
                                        this.props.modifiedReservationID != "00000000-0000-0000-0000-000000000000" &&
                                        this.props.modifiedReservationID != undefined ?
                                            <div className="new-return-pickup-wrap custom-checkbox-2-wrap" style={{pointerEvents: "none", opacity: "0.4"}}>
                                                <input type="checkbox" id="return-pickup-location" checked={this.state.isReturnAtOtherChecked} onChange={this.onReturnAtPickUpLocationChange} />
                                                <span className="custom-checkbox-2"></span>
                                                <label htmlFor="return-pickup-location" >{t('Return at pickup location.1')}</label>
                                            </div>
                                            :<div className="new-return-pickup-wrap custom-checkbox-2-wrap">
                                                <input type="checkbox" id="return-pickup-location" checked={this.state.isReturnAtOtherChecked} onChange={this.onReturnAtPickUpLocationChange} />
                                                <span className="custom-checkbox-2"></span>
                                                <label htmlFor="return-pickup-location" >{t('Return at pickup location.1')}</label>
                                            </div>
                                    }


                                </div>
                                <div className='right-search-box-col-wrap'>
                                    <div className="pickup-date-select-wrap select-wrap-dropdown">
                                        <div className="pickup-return-date-wrap">
                                            <div className="pickup-return-date-left" style={{ cursor: "pointer" }} onClick={(e) => this.onPickupDateResClick(1,e)}>
                                                <label style={{ cursor: "pointer" }} onClick={(e) => this.onPickupDateResClick(1,e)}><img src={pickUpiconDate}/>{t('Pickup Date.1')}</label>
                                                <a className="dropdown-toggle" onClick={(e) => this.onPickupDateResClick(1,e)} >
                                                    {moment(this.state.selectedPickupDate).format("DD MMM YYYY")}
                                                </a>
                                                <a className="dropdown-toggle widget-time" onClick={(e) => this.onPickupTimeResClick(1,e)} >
                                                    {moment(this.state.selectedPickupDate).format("HH:mm")}
                                                </a>
                                            </div>
                                            <div className="pickup-return-date-right" style={{ cursor: "pointer" }} onClick={(e) => this.onPickupDateResClick(2,e)}>
                                                <label style={{ cursor: "pointer" }} onClick={(e) => this.onPickupDateResClick(2,e)}><img src={pickUpiconDate}/>
                                                    {t('Return Date.1')}</label>
                                                <a className="dropdown-toggle " id="return-date-dropdown" onClick={(e) => this.onPickupDateResClick(2,e)}>
                                                    {moment(this.state.selectedReturnDate).format("DD MMM YYYY")}
                                                </a>
                                                <a className="dropdown-toggle widget-time" id="return-date-dropdown" onClick={(e) => this.onReturnTimeResClick(2,e)}>
                                                    {moment(this.state.selectedReturnDate).format("HH:mm")}
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="promocode-box-wrap select-wrap-dropdown">
                                        <div className="search-box-wrap promocode-offer-wrap">
                                            <div className="select-wrap-dropdown">
                                                <label htmlFor="promocode">{t('Enter Promocode.1')}</label>
                                                <input type="text" id="promocode" name="promocode" onChange={this.onChange} value={this.state.promocode} />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="search-bttn" onClick={this.searchClickHandler} style={{ zIndex: "500" }}><i className="fa fa-search" aria-hidden="true"></i> {/* {t('Search.1')} */} </button>
                                    <div className="pickup-dropdown-box-design" >
                                    <PickupPopover onPickupBranchSelected={this.onPickupBranchSelected} searchWidgetMode={this.state.searchWidgetMode}
                                      pickupDeliveryLocationSelected={this.pickupDeliveryLocationSelected}
                                       deliveryState = {this.props.deliveryState} deliveryAddress = {this.props.deliveryAddress}
                                       />
                                    <ReturnPopver onReturnBranchSelected={this.onReturnBranchSelected} searchWidgetMode={this.state.searchWidgetMode}
                                    returnDeliveryLocationSelected={this.returnDeliveryLocationSelected}
                                    returnState = {this.props.returnState} returnAddress = {this.props.returnAddress}
                                    />

                                    
                                       
                                    <PickupDateRespPopover pickupBranch={this.state.selectedPicupLocation} returnBranch={this.state.selectedDestinationLocation}
                                        searchWidgetMode={this.state.searchWidgetMode} selectedPickupDate={this.state.selectedPickupDate}
                                        selectedReturnDate={this.state.selectedReturnDate}
                                        onChangePickupDate={this.onChangePickupDate}
                                        onChangeReturnDate={this.onChangeReturnDate}
                                        pickupTime={this.state.pickupTime}
                                        returnTime={this.state.returnTime}
                                        type={this.state.timeType}
                                        onClosePopover= {this.togglePopOver}
                                    />




                                    <PickupTimePopover pickupBranch={this.state.selectedPicupLocation} returnBranch={this.state.selectedDestinationLocation}
                                        searchWidgetMode={this.state.searchWidgetMode} selectedPickupDate={this.state.selectedPickupDate}
                                        selectedReturnDate={this.state.selectedReturnDate}
                                        onChangePickupDate={this.onChangePickupDate}
                                        onChangeReturnDate={this.onChangeReturnDate}
                                        pickupTime={this.state.pickupTime}
                                        returnTime={this.state.returnTime}
                                        type={this.state.timeType}
                                        
                                    />
                                   
                                    <PickupTimeRespPopover  pickupBranch={this.state.selectedPicupLocation} returnBranch={this.state.selectedDestinationLocation}
                                        searchWidgetMode={this.state.searchWidgetMode} selectedPickupDate={this.state.selectedPickupDate}
                                        selectedReturnDate={this.state.selectedReturnDate}
                                        onChangePickupDate={this.onChangePickupDate}
                                        onChangeReturnDate={this.onChangeReturnDate}
                                        pickupTime={this.state.pickupTime}
                                        returnTime={this.state.returnTime}
                                        type={this.state.timeType}
                                        onClosePopover= {this.togglePopOver}
                                    />


                                    <ReturnTimeRespPopover pickupBranch={this.state.selectedPicupLocation} returnBranch={this.state.selectedDestinationLocation}
                                        searchWidgetMode={this.state.searchWidgetMode} selectedPickupDate={this.state.selectedPickupDate}
                                        selectedReturnDate={this.state.selectedReturnDate}
                                        onChangePickupDate={this.onChangePickupDate}
                                        onChangeReturnDate={this.onChangeReturnDate}
                                        pickupTime={this.state.pickupTime}
                                        returnTime={this.state.returnTime}
                                        type={this.state.timeType}
                                        onClosePopover= {this.togglePopOver}
                                    />



                                   
                                </div>
                                </div>
                                

                              
                                
                            </div>

                            {this.state.errorMessage != null && this.state.errorMessage.length > 0 ?

                                <Dialog
                                    open={this.state.errorMessage.length > 0}
                                    onClose={this.handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Dollar"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {this.state.errorMessage}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleClose} color="primary">
                                            {t('OK.1')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                : null}
                        
                   
                </div>
            </div>

        );
    }
}

export default withTranslation()(withRouter(SearchWidget));