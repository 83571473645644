
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import BannerNextArrow from '../Hire/BannerNextArrow';
import BannerPrevArrow from '../Hire/BannerPrevArrow';

class MonthlyBanner extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        
        const settings = {
            infinite: true,
            autoplay:true,
            autoplaySpeed:10000,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots:false,
            prevArrow: <BannerPrevArrow/>,
            nextArrow: <BannerNextArrow/>,
      
        };
        return (

            <Slider className="banner-slider-section" {...settings}>
              
                { this.props.BannerData.map((banner, index) => {
                    return (
                        <div key={index}>
                            <img
                                src={banner.BannerAttachment.ImageString}
                                alt="First slide" width="100%" height="780px"
                            />
                        </div>
                    )
                })}
              
            </Slider>

        );
    }
}

export default withTranslation()(withRouter(MonthlyBanner));