
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';


class BannerNextArrow extends Component {
    constructor(props) {
        super(props);
       
    }
    render() {
       
          return (
            <a title="Next" className="slide-arrow next-arrow slick-arrow" onClick={this.props.onClick}><i className="fa fa-angle-right" aria-hidden="true"></i></a>
          );
    }
}

export default withTranslation()(withRouter(BannerNextArrow)); 