import React, { Component } from 'react';
import Slider from '@material-ui/core/Slider';
import {getCustomerId} from '../Common/Util';
import { getLanguage } from '../Common/Util';
import axios from 'axios';
import ListingHeader from '../Common/ListingHeader';
import ListingFooter from '../Common/ListingFooter';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import hoistStatics from 'hoist-non-react-statics';
import { animateScroll as scroll,scroller  } from 'react-scroll';
import Truck from "../assets/images/TuSimple_Self_Drving_Truck_4_copy.0.png";
import ZeroImage from "../assets/images/0x0.png";
import Group73 from "../assets/images/Group-733.png";
import { Modal } from 'react-bootstrap';
import ContactPopup from '../Common/ContactPopup';

class LongTermLease extends Component {
    constructor(props) {
        super(props);
        this.state = {
         sliderDafaultValue: 0,
         selectedVehicals: 0,
         selectedOption: 'convertible',
         selectedYear: '12',
         vehicleTypes: [],
         maxVehicles: 5,
           showContact: false,
           leasingEnquiry: {}
        }
    }
    componentDidMount = () => {
        
      this.getVehicleTypes();
      
      
   }

   onChange = (e) => {
  
      this.setState({
         [e.target.name]: e.target.value
      });


      this.setState({
         selectedOption: e.target.value
      })

   }
   
   onChangeYear = (e) => {
  
      this.setState({
         selectedYear: e.target.value
      })

   }
    getVehicleTypes = () => {      
      let lngParam = getLanguage();
      axios(process.env.REACT_APP_API + 'VehicleClassification/Get?apiKey=' + process.env.REACT_APP_API_KEY + `&lng=${lngParam}`).then((responce) => {

         const vehicleTypes = responce.data;
         this.setState({ vehicleTypes: vehicleTypes });
         if(vehicleTypes.length > 0)
         {
             this.setState({ 
                 selectedOption : vehicleTypes[0].Name });
         }
      });

   }

   
   toogleContactPopup=(show)=>{
      this.setState({
          showContact : show
      });
  }
   
   handleSliderChange = (event, newValue) => {
  
      this.setState({
         selectedVehicals: newValue
      });
   }

   onClick = () => {
  
      this.setState({
         showContact: true
      });
      let leasingEnquiry = {
         selectedVehicalType: this.state.selectedOption,
         selectedVehicalQunatity: this.state.selectedVehicals,
         selectedYear: this.state.selectedYear
      };
      this.setState({
         leasingEnquiry: leasingEnquiry
      });
   }


    render() {
        const { t } = this.props
        return (<>
            <section className="box-shadow-white-box-section grey-bg py-2" id="partner-a">
                    <div className="mt-4 mb-4 ">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="box-shadow-white-box-section" id="inquiry" name="inquiry">
                                <div className="container-fluid">
                                    <div className="box-shadow-white-box bg-grey">
                                        <div className="row" >
                                            <div className="col-xl-5 col-lg-8 col-md-12  col-sm-12 col-12">
                                            <div className="lease-left bottomAlignwrap">
                                                    <h3>{t('Long Term Car Rental.1')}</h3>
                                                    <p>
                                                    {t('Be wise, don’t compromise. Dollar Car Rental UAE like you own it! With monthly/yearly prices cheaper than conventional car purchasing budgets, Dollar promises great prices for your choice of dream car, without the hassles of frequent paperwork, maintenance & long-term financial commitments.1')}
                                                    </p>
                                                    <p>
                                                    {t('Instead, reap the benefits of up-to-date vehicle performance, fuel economy, 24x7 dedicated premium support, roadside & emergency assistance for the best price in the UAE.1')}
                                                        </p>
                                                    <p>
                                                    {t('Perfect for new residents, young professionals, and working families.1')}
                                                    </p>
                                            </div>
                                            </div>
                                            <div className="col-xl-7 col-md-12 col-lg-4 border-xl-left bor-0 col-sm-12 col-12 mt-md-0 mt-4">
                                            <div id="buttoncss">
                                                {
                                                <p className="mb-2"><b>{t('Vehicles Type.1')}</b></p>}

                                                <ul className="nos-vehicle-list" >
                                                    {
                                                        this.state.vehicleTypes.map((vehicleType) =>
                                                        <li key={vehicleType.VehicleClassificationID}>
                                                            <input type="radio" id={vehicleType.Name} name="noofveicles" value={vehicleType.Name} checked={this.state.selectedOption === vehicleType.Name} onChange={this.onChange}/>
                                                            <label htmlFor={vehicleType.Name}>
                                                                <img src={vehicleType.Icon.ImageString} alt={vehicleType.Name} />
                                                                <p>{vehicleType.Name}</p>
                                                            </label>
                                                        </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            <br/>
                                            <div id="buttoncss" className="min-129">
                                                <p className="mb-2 mt-2"><b>{t('Lease tenure.1')}</b></p>
                                                <ul className="nos-vehicle-list" >
                                                    <li>
                                                        <input type="radio" id="oneyear" name="noofveicles1" value={12} checked={this.state.selectedYear === "12"}  onChange={this.onChangeYear}/>
                                                        <label htmlFor="oneyear">
                                                        <p>{t('1 Year.1')}</p>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="twoyear" name="noofveicles1" value={24} checked={this.state.selectedYear === "24"} onChange={this.onChangeYear}/>
                                                        <label htmlFor="twoyear">
                                                        <p>{t('2 Year.1')}</p>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="threeyear" name="noofveicles1" value={36} checked={this.state.selectedYear === "36"} onChange={this.onChangeYear}/>
                                                        <label htmlFor="threeyear">
                                                        <p>{t('3 Year.1')}</p>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="fouryear" name="noofveicles1" value={48} checked={this.state.selectedYear === "48"} onChange={this.onChangeYear}/>
                                                        <label htmlFor="fouryear">
                                                        <p>{t('4 Year.1')}</p>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <label className="book-text text-black  d-flex justify-content-between">
                                                    <p className="mb-2 mt-5"><b>{t('Number of Vehicles.1')}</b></p>
                                                    <h6 className="font-weight-bold mt-5">{this.state.selectedVehicals}</h6>
                                                </label>
                                                <div className="range-slider txt-blue">
                                                    <Slider color="secondary"
                                                        max={this.state.maxVehicles}
                                                        step={1}
                                                        onChange={this.handleSliderChange}
                                                    />
                                                    <div className="d-flex justify-content-between pr-4">
                                                        <p>0</p>
                                                        <p>{this.state.maxVehicles}</p>
                                                    </div>
                                                </div>
                                            
                                            <div className="form-group ">
                                                <a title="Submit Lease Enquiry" className="submit-lease-enquiry" onClick={this.onClick}>{t('Submit Lease Enquiry.1')}</a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
            </section>
            <Modal show={this.state.showContact} onHide={() => this.toogleContactPopup(false)} dialogClassName="modal-dialog modal-xl modal-dialog-centered">
                    <ContactPopup leasingEnquiry = {this.state.leasingEnquiry} />
                </Modal>
        </>);
    }
}

export default hoistStatics(withTranslation()(withRouter(LongTermLease)), LongTermLease);