import React, { Component } from 'react';
import cartype from '../assets/images/car-type-icon.svg';
import dooricon from '../assets/images/door-icon.svg';
import luggage from '../assets/images/luggage-icon.svg';
import memory from '../assets/images/memory-icon.svg';
import personicon from '../assets/images/person-icon.svg';
import ac_yes from '../assets/images/ac-yes.svg';
import ac_no from '../assets/images/ac-no.svg';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
class VehicleFeatureBoxVertical extends Component {
    render() {
        const { t } = this.props

        return (
            <div>
                <ul className="feature-list text-black opacity-50 font-regular">
                                            <li title={this.props.model.Passengers + t('Person Seats.1')}>
                                                <img src={personicon} alt="Person" width="15" height="15" className="mr-2" /> {this.props.model.Passengers} {t('Person Seats.1')}
                                            </li>
                                            <li title={this.props.model.Doors + t('Doors.1')}>
                                                <img src={dooricon} alt="Door" width="15" height="15" className="mr-2" />{this.props.model.Doors} {t('Doors.1')}
                                            </li>
                                            <li title={(this.props.model.AutoTransmission) ?  t('Automatic.1'): t('Manual.1')}>
                                                <img src={cartype} alt="Car Type" width="15" height="15" className="mr-2" /> {(this.props.model.AutoTransmission) ? t('Automatic.1') : t('Manual.1')}
                                            </li>
                                            <li title={this.props.model.Luggages + t('Luggage Space.1')}>
                                                <img src={luggage} alt="Luggage" width="13" height="19" className="mr-2" />{this.props.model.Luggages} {t('Luggage Space.1')}
                                            </li>
                                            <li title={this.props.model.Engine + t('Engine.1')}>
                                                <img src={memory} alt="Memory" width="17" height="17" className="mr-2" />{this.props.model.Engine} {t('Engine.1')}
                                            </li>
                                            <li title={this.props.model.AC == true ? 'YES' : 'NO' + ' AC'}>
                                                <img src={this.props.model.AC == true ? ac_yes : ac_no} alt=" AC" width="17" height="17" className="mr-2" />{'  AC'}
                                            </li>
                                        </ul>
                
            </div>
        );
    }
}

export default hoistStatics(withTranslation()(VehicleFeatureBoxVertical), VehicleFeatureBoxVertical);