
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Slider1 from "react-slick";
import audi from "../assets/images/brands/audi.png";
import bmw from "../assets/images/brands/bmw.png";
import chevrolet from "../assets/images/brands/chevrolet.png";
import dodge from "../assets/images/brands/dodge.png";
import lexus from "../assets/images/brands/lexus.png";
import marchandez from "../assets/images/brands/merchandez.png";
import toyota from "../assets/images/brands/toyota.png";
import walkswagon from "../assets/images/brands/walkswagon.png";



class BrandSlider extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        const { t } = this.props;
        var settings = {
            infinite: true,
            slidesToShow: 7,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1480,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 481,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        };
       
        return (

        <section className="slider-brands-section ptb-50 bg-grey slider-arrows">
            <div className="container">
                <h2 className="border-botom">{t('Trusted Brands. Trusted Services.1')}</h2>
                <p className="mb-50">{t('Only the best from your favourite car rental.1')}</p>
                <Slider1 {...settings}>

                    <div className="slider-brands slick-slide slick-cloned" tabIndex="-1" data-slick-index="-3" id="" aria-hidden="true">
                        <div className="slider-brands-logo">
                            <img src={toyota} alt="Toyota" />
                        </div>
                        <span>{t('Toyota Rental in UAE.1')}</span>
                    </div>
                    <div className="slider-brands slick-slide slick-cloned" tabIndex="-1" data-slick-index="-2" id="" aria-hidden="true">
                        <div className="slider-brands-logo">
                            <img src={walkswagon} alt="Volkswagon" />
                        </div>
                        <span>{t('Volkswagon Rental in UAE.1')}</span>
                    </div>
                    <div className="slider-brands slick-slide slick-cloned" tabIndex="-1" data-slick-index="-1" id="" aria-hidden="true">
                        <div className="slider-brands-logo">
                            <img src={chevrolet} alt="Chevrolet" />
                        </div>
                        <span>{t('Chevrolet Rental in UAE.1')}</span>
                    </div>
                    <div className="slider-brands slick-slide slick-current slick-active" tabIndex="0" data-slick-index="0" aria-hidden="false">
                        <div className="slider-brands-logo">
                            <img src={dodge} alt="Dodge" width="60" height="62" />
                        </div>
                        <span>{t('Dodge Rental in UAE.1')}</span>
                    </div>
                    <div className="slider-brands slick-slide slick-active" tabIndex="0" data-slick-index="1" aria-hidden="false">
                        <div className="slider-brands-logo">
                            <img src={lexus} alt="Lexus" />
                        </div>
                        <span>{t('Lexus Rental in UAE.1')}</span>
                    </div>
                    <div className="slider-brands slick-slide slick-active" tabIndex="0" data-slick-index="2" aria-hidden="false">
                        <div className="slider-brands-logo">
                            <img src={audi} alt="Audi" />
                        </div>
                        <span>{t('Audi Rental in UAE.1')}</span>
                    </div>
                    <div className="slider-brands slick-slide slick-active" tabIndex="-1" data-slick-index="4" aria-hidden="true">
                        <div className="slider-brands-logo">
                            <img src={marchandez} alt="Mercedes" />
                        </div>
                        <span>{t('Mercedes Rental in UAE.1')}</span>
                    </div>
                    <div className="slider-brands slick-slide slick-cloned" tabIndex="-1" data-slick-index="11" id="" aria-hidden="true">
                        <div className="slider-brands-logo">
                            <img src={bmw} alt="BMW" />
                        </div>
                        <span>{t('BMW Rental in UAE.1')}</span>
                    </div>
                </Slider1>
            </div>
         </section>

        );
    }
}

export default withTranslation()(withRouter(BrandSlider));