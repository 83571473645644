import React, { Component } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage } from '../Common/Util';
import dollarprestigecars from '../assets/images/Business/Dollar Prestige Page.jpg';
import sp1 from '../assets/images/sp1.PNG';
import sp2 from '../assets/images/sp2.PNG';
import sp3 from '../assets/images/sp3.PNG';
import moment from 'moment-timezone';
import VehicleClassifications from '../Hire/VehicleClassifications';
import { animateScroll as scroll } from 'react-scroll';
import { withTranslation } from 'react-i18next';
import dollarPrestigeBookYourWheelPart1 from '../assets/images/Business/221715247.jfif'
import dollarPrestigeBookYourWheelPart2 from '../assets/images/Business/1906374694.jpg'
import dollarPrestigeBookYourWheelPart3 from '../assets/images/Business/593599952.jpg'
import { Helmet } from 'react-helmet';
var qs = require('qs');

class DollarPrestige extends Component {

  constructor(props) {
    super(props);
    this.state = {
      vehicalModels: [],
      PickUpDate: Date().toLocaleString(),
      ReturnDate: Date().toLocaleString(),
      BranchId: '',
      VehicleClassificationID: '',
      Name: '',
      ImageString: '',
      Address: '',
      Message: '',
      Description: '',
    }
  }

  onMenuLinkClick = (path)=>{
    this.props.history.push(path);
    scroll.scrollToTop();
}

  componentDidMount = () => {
    this.getVehicalModels();
    this.getAllBranches();
    scroll.scrollToTop();
  }

  getAllBranches = () => {
    let lanParam = getLanguage();
    var config = {
      method: 'get',
      url: process.env.REACT_APP_API + 'Branch/Get?apiKey=' + process.env.REACT_APP_API_KEY + `&lng=${lanParam}`,
      headers: {}
    };

    axios(config)
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            BranchId: response.data[0].Identity.Id, Name: response.data[0].Name, ImageString: response.data[0].Attachment.ImageString, Address: response.data[0].Address
            , Message: response.data[0].Message, Description: response.data[0].Description
          });
          this.getHoursToReady(response.data[0].Identity.Id);
        }

      })
      .catch((error) => {

      });

  }

  getHoursToReady = (BranchId) => {

    var config = {
      method: 'get',
      url: process.env.REACT_APP_API + 'Branch/GetBranchHrsToGetReady?apiKey=' + process.env.REACT_APP_API_KEY + `&branchID=${BranchId}`,
      headers: {}
    };

    axios(config)
      .then((response) => {

        let pickupDate = moment.tz(response.data.PickupOn, "Asia/Dubai").format('MM/DD/YYYY  h:mm:ss A');
        let returnDate = moment.tz(response.data.ReturnOn, "Asia/Dubai").format('MM/DD/YYYY  h:mm:ss A');

        this.setState({ PickUpDate: pickupDate });
        this.setState({ ReturnDate: returnDate });
      })
      .catch((error) => {

      });

  }

  getVehicalModels = () => {

    let searchParam = {
      "Classifications": ["fc8ac3c3-9ce7-41e7-9856-4aa0e09741d6"],
      "VehicleMakeID": "00000000-0000-0000-0000-000000000000",
      "VehicleModelID": "00000000-0000-0000-0000-000000000000",
      "SpecialOfferID": "00000000-0000-0000-0000-000000000000",
      "ModelYear": 0,
      "Passengers": 0,
      "AutoTransmission": 0,
      "MinRentalRate": 0.0,
      "MaxRentalRate": 0.0,
      "RentalDays": 0,
      "NoOfRecords": 0
    };

    var data = qs.stringify(searchParam);

    let lngParam = getLanguage();
    var config = {
      method: 'post',
      url: process.env.REACT_APP_API + `SearchVehicleModel/GetAllVehicleModels?apiKey=${process.env.REACT_APP_API_KEY}&lng=${lngParam}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    };

    axios(config)
      .then((response) => {
        this.setState({ vehicalModels: response.data });
      })
      .catch((error) => {

      });
  }

  onBookNowHandler = () => {

    let pickupDate = moment(this.state.PickUpDate).format('MM/DD/YYYY  h:mm:ss A');
    let returnDate = moment(this.state.ReturnDate).format('MM/DD/YYYY  h:mm:ss A');

    var BookingInfo = [{
      "PickupLocationId": this.state.BranchId,
      "DestinationLocationId": this.state.BranchId,
      "PickUpDate": pickupDate,
      "ReturnDate": returnDate,
      "pickupLocationName": this.state.Name,
      "dropOffLocationName": this.state.Name,
      "pickupLocationImage": this.state.ImageStringe,
      "dropOffLocationImage": this.state.ImageStringe,
      "pickupLocationAddress": this.state.Address,
      "dropOffLocationAddress": this.state.Address,
      "pickupLocationMessage": this.state.Message,
      "dropOffLocationMessage": this.state.Message,
      "pickupLocationBranchDescription": this.state.Description,
      "dropOffLocationBranchDescription": this.state.Description,
      "promocode": ""
    }];


    localStorage.setItem('VehicleTypeParam', "fc8ac3c3-9ce7-41e7-9856-4aa0e09741d6");
    localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
    window.location = "/booking";


  }

render() {
  const { t } = this.props;
    return (<>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>Arrive in Style with the Ultimate Premium Range of Vehicles with Dollar Car Rental UAE</title>
                    <link rel="canonical" href="www.dollaruae.com/dollar-prestige-vehicles" />
                    <meta property="og:title" content="Arrive in Style with the Ultimate Premium Range of Vehicles with Dollar Car Rental UAE"></meta>
                    <meta property="og:site_name" content="Dollar Car Rental"></meta>
                    <meta property="og:url" content="https://www.dollaruae.com/"></meta>
                    <meta property="og:description" content="Elevate your travel experience and explore a range of premium cars. Cruise around with the best-in-class vehicles. Rent your dream car now! "></meta>
                    <meta property="og:type" content="business.business"></meta>
                    <meta property="og:image" content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee08d93356c2d1.jpeg"></meta>
                    <meta name="title" content="Arrive in Style with the Ultimate Premium Range of Vehicles with Dollar Car Rental UAE"></meta>
                    <meta name="description" content="Elevate your travel experience and explore a range of premium cars. Cruise around with the best-in-class vehicles. Rent your dream car now! "></meta>
                    <meta property="og:type" content="website"></meta>
                    <meta property="og:url" content="https://www.dollaruae.com/dollar-prestige"></meta>
                    <meta property="og:title" content="Arrive in Style with the Ultimate Premium Range of Vehicles with Dollar Car Rental UAE"></meta>
                    <meta property="og:description" content="Elevate your travel experience and explore a range of premium cars. Cruise around with the best-in-class vehicles. Rent your dream car now! "></meta>
                    <meta property="og:image" content="https://www.dollaruae.com/static/media/Dollar%20Prestige%20Page.2c6ff83c.jpg"></meta>
                    <meta property="twitter:card" content="summary_large_image"></meta>
                    <meta property="twitter:url" content="https://www.dollaruae.com/dollar-prestige"></meta>
                    <meta property="twitter:title" content="Hire a Luxury car | Luxury Vehicles for rent | DollarCar Rental UAE"></meta>
                    <meta property="twitter:description" content="Elevate your travel experience and explore a range of premium cars. Cruise around with the best-in-class vehicles. Rent your dream car now! "></meta>
                    <meta property="twitter:image" content="https://www.dollaruae.com/static/media/Dollar%20Prestige%20Page.2c6ff83c.jpg"></meta>
                  </Helmet>
      <ListingHeader />
      <div className="minH100">
        <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
          <div className="container-fluid">
            <div className="box-shadow-white-box-a ">
              <div className="">
                <div className="row flex-reversea align-items-center">
                  <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                    <div className="pickup-location-box">

                      <div className="pickup-location-flex">

                        <div className="pickup-location-flex-content">
                          <h1 className="font-bold">{t('Luxury Vehicles for Rent.1')}</h1>
                          <h2 className="h4 font-regular" style={{fontFamily : 'product_sansregular'}}>{t('Book your Dollar Prestige Car Luxury Vehicles without the Luxury Price Tags.1')}</h2>
                          <p className="text_p text-black my-4">{t('Here’s your choice of vehicles for driving thrills on a winding highway. Make your selection on great vehicle dynamics, improved performance count & unrivaled street appeal for your wheels through the attractions within the world’s top cities.1')}
                          </p>
                          
                          <div className="oddEven-content pr-sm-5 mt-4">
                                        <h3 className="font-24">{t('Perfect for.1')}</h3>
                                        {/* <p className="font-18 text-grey pt-3">We’re talking;</p> */}
                                        <ul className="check-box-listing round-checkmark-wrap">
                                            <li className="align-items-center"><p className="font-18 text-black">{t('Date Nights.1')}</p></li>
                                            <li className="align-items-center mt-3"><p className="font-18 text-black">{t('Birthdays and Anniversaries.1')}</p></li>
                                            <li className="align-items-center mt-3"><p className="font-18 text-black">{t('Weddings, Receptions & Bachelor/ette Parties.1')}</p></li>
                                            <li className="align-items-center mt-3"><p className="font-18 text-black">{t('Concert Events, Theaters, Museums & Night Tours.1')}</p></li>
                                            <li className="align-items-center mt-3"><p className="font-18 text-black">{t('Luxury & Entertainment agencies.1')}</p></li>
                                            <li className="align-items-center mt-3"><p className="font-18 text-black">{t('Corporate, Celebrity, VIP Clientele.1')}</p></li>
                                            <li className="align-items-center mt-3"><p className="font-18 text-black">{t('All-day Business Trips.1')}</p></li>
                                        </ul>
                                    </div>
                          
                          <a onClick={() => this.onMenuLinkClick('/StartaReservation')} className="primary-bttn primary-invert-bttn mt-4" title={t('Book Now.1')}>
                            {t('Start the Reservation.1')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                    <img src={dollarprestigecars} alt={t('Dollar Reservation.1')} className="img-fluid rounded-xl" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
          <div className="container-fluid">
            <div className="box-shadow-white-box-a ">
              <div className="">
                <div className="row  flex-reversea align-items-center">
                  <div className="col-lg-4">
                    <div className="pickup-location-box pr-0">
                      <h5 className="font-bold mb-3 text-red">{t('Ready to travel in style?.1')}</h5>
                      <p>{t('Say hello to our signature collection.1')}</p>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="pickup-location-box">

                      <div className="pickup-location-flex">
                        <div className="pickup-location-flex-content ">
                          <p className="text_p text-black"> {t('With Dollar Prestige, you can drive away in a luxury car at an affordable rate. No matter what you have planned for your next trip – a getaway with friends, a monthly business trip, or an annual family vacation – you can now elevate your travel with one of the luxury models from the Dollar UAE Prestige Collection.1')} </p>
                          <p className="text_p mt-4 text-black"> {t('We’re talking incredible cars on incredible roads. Luxury Car Rentals is as easy as it is with any other car class we offer! The only specific requirement for our Dollar Prestige Collection is that you are at least 22 years or older.1')}</p>
                          <p className="text_p mt-4 text-black">{t('Dollar Prestige’s Fleet Portfolio boasts a curated collection of latest luxury models, that are available for rent all throughout our locations in the UAE. One gets to experience the best of these thrilling rides at the most affordable and best prices in the UAE. Gone are the days of longterm financial commitments, lengthy paperworks and expensive vehicle service maintenance. Today, with Dollar Prestige Luxury Rides, you can experience multiple luxury models for a fraction of their ownership hassles.1')}</p>
                          <p className="text_p mt-4 text-black"> {t('Contact us today to discover the Dollar Prestige Collection of exotic, luxury and super cars for rent in the UAE.1')} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6  col-md-12 col-sm-12 col-12 mt-4 mt-sm-0">
                              <img src={carrental} alt="" className="img-fluid" />
                           </div> */}
                </div>
               
              </div>
            </div>
          </div>
        </section>
        <section className="offer-box-section pb-4 grey-bg">
              <div className="container-fluid">
                  <div class="offer-box-wrapper mt-0 vehicle_type_wrap">
                    {
                      this.state.vehicalModels.map((model, index) => {
                        return (
                          <div key={index} class="vehicle-box-design">
                            <h3>{model.Name}</h3>
                            <span class="similar-text">{t('or Similar | Dollar Prestige.1')}</span>
                            <div className="vehicle-box-design-img-wrap">
                                <img src={model.VehicleImages.FrontImageAttachment.ImageString} alt="{model.Name}" class="vehicle-image-main" />
                            </div>
                            {/* {this.props.hideInclude == 0 ? <VehicleFeatureBox model={this.props.model} /> : ""} */}
                              {/*  <div className="prestige-car-box-content">
                                <p>{model.Description}</p>
                              </div> */}
                              
                              <div className="prestige-car-box-bttn text-center w-100">
                                <a className="vehicle-box-design-book" title="Book Now" onClick={this.onBookNowHandler}> {t('Book Now.1')} </a>
                              </div>
                            
                          </div>
                        )
                      })
                    }


                  </div>
                  </div>
            </section>


        <section className="subscriber-red-section">
          <div className="container">
            <div className="other-products-news-wrap">
                  <h2 className=''>{t('Book your wheels with dollar.1')}</h2>
                  <div className="row other-products-news-row">
                      <div className="col-lg-4 col-md-6 col-12" >
                        <div className="other-products-news-box">
                          <div className="other-products-news-image">
                              <img src={dollarPrestigeBookYourWheelPart1} alt="First Article" />
                              <div className="other-products-news-content">
                                <h3>{t('Join Dollar RED Community.1')}</h3>
                                <p>{t('Exclusive Flash Deals, Surprise Giveaways.1')}</p>
                              </div>
                          </div>
                          <div className="mt-3 text-center">
                            <a className='vehicle-box-design-book' onClick={() => this.onMenuLinkClick('/register')} >{t('SIGN UP.1')}</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0" >
                        <div className="other-products-news-box">
                          <div className="other-products-news-image">
                              <img src={dollarPrestigeBookYourWheelPart2} alt="Second Article" />
                              <div className="other-products-news-content">
                                <h3>{t('Monthly Rentals.1')}</h3>
                                <p>{t('Save more & Benefits.1')}</p>
                              </div>
                          </div>
                          <div className="mt-3 text-center">
                            <a className='vehicle-box-design-book' onClick={() => this.onMenuLinkClick('/monthlyrentals')} >{t('BOOK NOW.1')} </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0" >
                        <div className="other-products-news-box">
                          <div className="other-products-news-image">
                              <img src={dollarPrestigeBookYourWheelPart3} alt="Third Article" />
                              <div className="other-products-news-content">
                                <h3>{t('Dollar Hunt.1')}</h3>
                                <p>{t('365 days of offers & surprise deals.1')}</p>
                              </div>
                          </div>
                          <div className="mt-3 text-center">
                            <a className='vehicle-box-design-book' onClick={() => this.onMenuLinkClick('/offers')} >{t('SHOW OFFERS.1')}</a>
                          </div>
                        </div>
                      </div>
               
                  </div>
            </div>
          </div>
        </section>    
        <VehicleClassifications />
      </div>
      <br />
      <ListingFooter />
    </>);
  }
}

export default hoistStatics(withTranslation()(withRouter(DollarPrestige)), DollarPrestige);
// export default DollarPrestige;