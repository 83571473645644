import React, { Component } from 'react';
import cartype from '../assets/images/car-type-icon.svg';
import dooricon from '../assets/images/door-icon.svg';
import luggage from '../assets/images/luggage-icon.svg';
import memory from '../assets/images/memory-icon.svg';
import personicon from '../assets/images/person-icon.svg';
import ac_yes from '../assets/images/ac-yes.svg';
import ac_no from '../assets/images/ac-no.svg';
import schedule06 from '../assets/images/06-schedule.svg';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
class ConfirmationBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: {}
        }
    }

    componentDidMount = () => {
            setTimeout(()=> {
                this.setState({                    
                    Data : this.props.ConfirmationData
                });
            }, 1000);   
    }

    render() {
        const { t } = this.props

        return (
                        <div className="booking-detail-wrap pl-xl-4">
                                            <div className="box-title-icon-border d-flex align-items-center mb-4">
                                                <span className="box-title-icon-rounded">
                                                    <img src={schedule06} alt="Dollar Booking Details" width="18" height="18" />
                                                </span>
                                                <h4 className="text-16 text-red">{t('Booking Details.1')}</h4>
                                            </div>
                                            <ul className="booking-details-listing">
                                                {
                                                    (this.state.Data.RentalRateDetail != null) ? this.state.Data.RentalRateDetail.map((model) => {
                                                        return (<div key={model.Description}>
                                                            <li className="mb-3" >
                                                                <div className="booking-details-listing-left text-18 font-regular">
                                                                    {(this.state.Data.RentalRateDetail != null) ? model.Item : ""}<span className="text-16 d-block opacity-50 mt-1 font-regular">{(this.state.Data.RentalRateDetail != null) ? model.Description : ""}</span>
                                                                </div>
                                                                <div className="booking-details-listing-right">
                                                                    {t('AED.1')} <span>{(this.state.Data.RentalRateDetail != null) ? model.SubTotal.toFixed(2) : ""}</span>
                                                                </div>
                                                            </li>
                                                        </div>)
                                                    }) : ""
                                                }

                                                <li className="mb-3 pt-3 border-top" >
                                                    <div className="booking-details-listing-left text-18 font-hel-bold">
                                                        {t('Sub Total.1')}<span className="text-16 d-block opacity-50 mt-1 font-regular"></span>
                                                    </div>
                                                    <div className="booking-details-listing-right">
                                                        {t('AED.1')} <span>{(this.state.Data.SubTotal != null) ? this.state.Data.SubTotal.toFixed(2) : null}</span>
                                                    </div>
                                                </li>
                                                <li className="mb-3" >
                                                    <div className="booking-details-listing-left text-18 font-hel-bold">
                                                        {t('Tax Total.1')}<span className="text-16 d-block opacity-50 mt-1 font-regular"></span>
                                                    </div>
                                                    <div className="booking-details-listing-right">
                                                        {t('AED.1')} <span>{(this.state.Data.TotalTax != null) ? this.state.Data.TotalTax.toFixed(2) : null}</span>
                                                    </div>
                                                </li>
                                                <li className="mt-3 pt-2  border-top">
                                                    <div className="booking-details-listing-left text-18 font-hel-bold text-red">
                                                        {t('Grand Total.1')}
                                                    </div>
                                                    <div className="booking-details-listing-right text-red">
                                                        {t('AED.1')} <span className="h4">{(this.state.Data != null && this.state.Data.TotalAmount != null) ? this.state.Data.TotalAmount.toFixed(2) : ""}&#8236;</span>
                                                    </div>
                                                </li>

                                                {/* {
                                                    (this.state.Data.RentalRateDetail != null) ? this.state.Data.RentalRateDetail.map((model) => {
                                                        return (<div key={model.Description}>
                                                            <li className="mb-3" >
                                                                <div className="booking-details-listing-left text-18 font-regular">
                                                                    {(this.state.Data.RentalRateDetail != null) ? model.Item : ""}<span className="text-16 d-block opacity-50 mt-1 font-regular">{(this.state.Data.RentalRateDetail != null) ? model.Description : ""}</span>
                                                                </div>
                                                                <div className="booking-details-listing-right">
                                                                    {t('AED.1')} <span>{(this.state.Data.RentalRateDetail != null) ? model.SubTotal.toFixed(2) : ""}</span>
                                                                </div>
                                                            </li>
                                                        </div>)
                                                    }) : ""
                                                } */}

                                                {
                                                    (this.state.Data.ModificationCharge!= null && this.state.Data.ModificationCharge.AmountPaid > 0 &&
                                                        this.state.Data.CancellationCharge!= null && this.state.Data.CancellationCharge.AmountCancellationCharge == 0 ?
                                                    (
                                                <ul className="booking-details-listing">
                                                <li className="mt-3">
                                                    <div class="booking-details-listing-left text-18 font-hel-bold text-red">
                                                        {t('Paid Amount.1')}
                                                    </div>
                                                    <div class="booking-details-listing-right text-red">
                                                        {t('AED.1')} <span class="h4">{this.state.Data.ModificationCharge.AmountPaid.toFixed(2)}</span>
                                                    </div>
                                                </li>
                                                <li className="mt-3">
                                                    <div class="booking-details-listing-left text-18 font-hel-bold text-red">
                                                        {this.state.Data.ModificationCharge!= null && this.state.Data.ModificationCharge.AmountToPay > 0 ? <>{t('Pay at Counter.1')}</>: <>{t('Refund Amount.1')}</>}
                                                    </div>
                                                    <div class="booking-details-listing-right text-red">
                                                    {t('AED.1')} <span class="h4">{
                                                    (this.state.Data.ModificationCharge!= null) ?
                                                    this.state.Data.ModificationCharge.AmountToPay >0?
                                                    this.state.Data.ModificationCharge.AmountToPay.toFixed(2) 
                                                    :this.state.Data.ModificationCharge.AmountToRefund.toFixed(2)
                                                    : null
                                                    
                                                    }</span>
                                                    </div>

                                                </li> 
                                                </ul> )                                       
                                                : null)
                                            }


                                           {
                                             <ul className="booking-details-listing">
                                                {
                                                (this.state.Data.CancellationCharge!= null && this.state.Data.CancellationCharge.AmountCancellationCharge > 0 ? 
                                                <li className="mt-3">
                                                    <div class="booking-details-listing-left text-18 font-hel-bold text-red">
                                                        {t('Cancellation Charges.1')}
                                                    </div>
                                                    <div class="booking-details-listing-right text-red">
                                                        {t('AED.1')} <span class="h4">{this.state.Data.CancellationCharge.AmountCancellationCharge.toFixed(2)}</span>
                                                    </div>
                                                </li> : null)
                                                }
                                                {
                                                (this.state.Data.CancellationCharge!= null && this.state.Data.CancellationCharge.AmountToRefund > 0 ? 
                                                
                                                <li className="mt-3">
                                                    <div class="booking-details-listing-left text-18 font-hel-bold text-red">
                                                    {t('Refund Amount.1')}
                                                    </div>
                                                    <div class="booking-details-listing-right text-red">
                                                    {t('AED.1')} <span class="h4">{
                                                    (this.state.Data.CancellationCharge!= null) ?
                                                     this.state.Data.CancellationCharge.AmountToRefund.toFixed(2)
                                                    : null
                                                    }</span>
                                                    </div>
                                                </li> : null)
                                                }
                                                {
                                                    this.state.Data.CancellationCharge!= null &&
                                                    this.state.Data.CancellationCharge.AmountPaid >0 ?
                                                    <li className="mt-3">
                                                        <div class="booking-details-listing-left text-18 font-hel-bold text-red">
                                                        {t('Amount Paid.1')}
                                                        </div>
                                                        <div class="booking-details-listing-right text-red">
                                                        {t('AED.1')} <span class="h4">{
                                                        (this.state.Data.CancellationCharge!= null) ?
                                                        this.state.Data.CancellationCharge.AmountPaid.toFixed(2)
                                                        : null
                                                        }</span>
                                                        </div>
                                                    </li> : null
                                                }
                                            </ul>
                                        }
                                         </ul>
                                        </div>);
    }
}

export default hoistStatics(withTranslation()(ConfirmationBooking), ConfirmationBooking);