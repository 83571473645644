import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
class ViewExtrasDetails extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { t } = this.props;
        return (
            <div>
                {<Modal.Header closeButton >
                    <h4>{t('Details.1')}</h4>
                </Modal.Header>}
                <Modal.Body>
                    {
                        <p>{this.props.descriptionToShow}</p>
                    }
                </Modal.Body>
            </div>
        );
    }
}

export default withTranslation()(ViewExtrasDetails);