import axios from 'axios';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import "../assets/css/slick.css";
import { getLanguage } from '../Common/Util';
import CustomSlide from './CustomSlide';
import LessFineNextArrow from './LessFineNextArrow';
import LessFinePrevArrow from './LessFinePrevArrow';
import VehicleClassifications from './VehicleClassifications';
class LessFineSection extends Component {
  onShowAllVehicleTypeClick = () => {
      this.props.history.push('/vehicletypes');
  }
  render() {
    const { t } = this.props;
    return (
      <section className="less-fine-section more-slider-content-section">
        <div className="container">
          <div className='new-oddEven-title-wrap'>
            <div className='new-oddEven-title-left'>
              <h2>{t('Our Fleet. Your Keys.1')}</h2>
              <p>{t('Choose your wheels from UAE’s Largest & Widest Rental Fleet.1')}.</p>
            </div>
            <div className='new-oddEven-title-right'>
              <a className="read-more-link" title="View All" onClick={this.onShowAllVehicleTypeClick}>
                {t('View All.1')}
              </a>
            </div>
          </div>
       
          <VehicleClassifications></VehicleClassifications>

        </div>
      </section>

    );
  }
}

export default withTranslation()(withRouter(LessFineSection));