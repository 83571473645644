

import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import hoistStatics from 'hoist-non-react-statics';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import emailIcon from "../assets/images/email.svg";
import ViewExtrasDetails from '../ListingPage/ViewExtrasDetails';

import telephone from "../assets/images/phone.svg";
import timeIcon from "../assets/images/time.svg";
import phone from '../assets/images/phone-alt.svg';
import email from '../assets/images/email.svg';
import time from '../assets/images/time.svg';
import { withRouter } from 'react-router-dom';
import SearchWidget from '../Hire/SearchWidget';
import SearchWidgetNew from '../Hire/SearchWidgetNew';


class LocationTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBranchDescription :false,
            BranchDescription:'',
            isBooking : false,
            selectedPickupLocation:{
                Identity : {
                    Id : null
                },
                Name : null
            },
            picupDateTime :null,
            returnDate : null,
        }

    }
    onInfoClick=(locationSelected)=>{
      //  alert(locationSelected.Description);
        this.setState({
            showBranchDescription:true,
            BranchDescription : locationSelected.Description

        });
    }

    toogleDescriptionPopUp = (showHide) => {
        this.setState({
            showBranchDescription: showHide
        });
    }
    onBookNowClick = (locationSelected) => {

        var axios = require('axios');

        var config = {
            method: 'get',
            url: process.env.REACT_APP_API + 'Branch/GetBranchHrsToGetReady?apiKey=' + process.env.REACT_APP_API_KEY + `&branchID=${locationSelected.Identity.Id}`,
            headers: {}
        };

        axios(config)
            .then((response) => {
                let pickupDate = moment.tz(response.data.PickupOn, "Asia/Dubai").format('MM/DD/YYYY  h:mm:ss A');
                let returnDate = moment.tz(response.data.ReturnOn, "Asia/Dubai").format('MM/DD/YYYY  h:mm:ss A');

                var BookingInfo = [{
                    "PickupLocationId": locationSelected.Identity.Id,
                    "DestinationLocationId": locationSelected.Identity.Id,
                    "PickUpDate": pickupDate,
                    "ReturnDate": returnDate,
                    "pickupLocationName": locationSelected.Name,
                    "dropOffLocationName": locationSelected.Name,
                    "pickupLocationImage": locationSelected.Attachment.ImageString,
                    "dropOffLocationImage": locationSelected.Attachment.ImageString,
                    "pickupLocationAddress": locationSelected.Address,
                    "dropOffLocationAddress": locationSelected.Address,
                    "pickupLocationMessage": locationSelected.Message,
                    "dropOffLocationMessage": locationSelected.Message,
                    "pickupLocationBranchDescription": locationSelected.Description,
                    "dropOffLocationBranchDescription": locationSelected.Description,
                    "promocode": ''
                }];

                localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));

                //this.props.history.push("/booking");
                this.state.selectedPickupLocation = locationSelected;
                this.setState({
                    isBooking:true,
                    picupDateTime : pickupDate,
                    returnDate : returnDate
                });
            })
            .catch((error) => {

            });
    }

    toogleVehicleType =(val)=>{
        this.setState({
            isBooking:val
        });
    }

    render() {
        const { t } = this.props;
        return (

<div className="box-shadow-white-box location-info-box">
                                    <div className="location-info-box-left">
                                        <h5 class="font-bold">{this.props.loc.Name} <a class="text-red" onClick={() => this.onInfoClick(this.props.loc)}>{t('More Info.1')} <i class="fa fa-angle-right"></i></a></h5>
                                        <span class="my-fonta">{this.props.loc.Address}</span>
                                        <span class="my-fonta">{this.props.loc.Message}</span>
                                        <ul>
                                            <li>
                                                <a href={'tel:'+this.props.loc.ContactNo}>
                                                    <img src={phone} alt="Phone" class="img-fluid phonea"/>
                                                    <span class="img_text ml-2">{this.props.loc.TelephoneNo}</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={'mailTo:'+this.props.loc.EmailID}>
                                                    <img src={email} alt="email" class="img-fluid phone"/>
                                                    <span class="img_text ml-2">{this.props.loc.EmailID}</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="d-flex  align-items-start">
                                                    <img src={time} alt="girl" class="img-fluid phoneaa"/>
                                                    {/* <span class="img_text ml-2">Sun - Sat :10:00,21:00</span> */}
                                                    <span class="img_text ml-2">  {(this.props.loc.BranchOfficeTiming != null) ? 
                                                            this.props.loc.BranchOfficeTiming.BranchTimings != null ? 
                                                            this.props.loc.BranchOfficeTiming.BranchTimings.map((timing) => {
                                                                return (<>
                                                                    {timing != null ? <>
                                                                        {timing.DayString} : {timing.Shifts.map(shift => 
                                                                            { return(<>{shift}</>) }) }
                                                                    </> : null}
                                                                    <br /></>)
                                                            }) : null : null}</span>
                                                </a>  
                                            </li>
                                            <li>
                                                <div className="location-info-box-right mt-3 text-right">
                                                    <button type="submit" onClick={() => this.onBookNowClick(this.props.loc)} class="rounded-bttn text-center">{t('BOOK NOW.1')}</button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                       {
                    this.state.showBranchDescription?
                <div>
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <Modal show={this.state.showBranchDescription}  onHide={() => this.toogleDescriptionPopUp(false)} dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap">
                            <ViewExtrasDetails descriptionToShow={this.state.BranchDescription} />
                        </Modal>
                    </div>
                </div>
                    :null
                }

            {
                this.state.isBooking ?
                    <div className="modal-dialog modal-xl modal-dialog-centered border-transparent-bg">
                        <Modal show={this.state.isBooking} onHide={() => this.toogleVehicleType(false)} dialogClassName="modal-dialog modal-xl border-transparent-bg">
                        <SearchWidgetNew pickupLocation={this.state.selectedPickupLocation} 
                        dropLocation={this.state.selectedPickupLocation} 
                         PickUpDate={this.state.picupDateTime}
                         ReturnDate={this.state.returnDate}  
                         page={"L"}
                        />
                        </Modal>
                    </div>
                    : null
            }
        </div>

            // <div className="box-shadow-white-box p-30" >
            //     <div className="row">
            //                             <div className="col-xl-6 col-md-6 col-sm-12 col-12" id="my-a">
            //             <h5 className="font-bold mb-1 mt-3">{this.props.loc.Name}</h5>
            //             <span className="my-fonta">{this.props.loc.Address}</span><br/>
            //             <span className="my-fonta" style={{color:"red"}}>{this.props.loc.Message}</span>
            //             <div className="row">
            //                 <div className="col-lg-5  col-12 col-md-6 mt-1 ">
                            
            //                     {   
            //                         (this.props.loc.ContactNo != "")?
            //                         <p> <a href={`tel:${this.props.loc.ContactNo}`}>   <img src={phone} alt="girl" className="img-fluid phonea" />
            //                         <span className="img_text ml-2">
            //                             {this.props.loc.ContactNo}
            //                         </span></a></p>:null
            //                     }
                                
            //                     {
            //                         (this.props.loc.TelephoneNo != "") ? <>
            //                             <a href={`tel:${this.props.loc.TelephoneNo}`}>   <img src={telephone} alt="girl" className="img-fluid phonea" />
            //                                 <span className="img_text ml-2">
            //                                     {this.props.loc.TelephoneNo}
            //                                 </span></a><br></br></>:null
            //                   }
            //                 {
            //                     (this.props.loc.EmailID != "")?
            //                     <a href="mailto:desiraevet@gmail.com"> <img src={emailIcon} alt="girl" className="img-fluid phone" />
            //                         <span className="img_text ml-2">
            //                             {this.props.loc.EmailID}
            //                         </span>
            //                     </a>
            //                     :null
            //                 }
            //                  <button type="submit" className="rounded-bttn rounded-bttn-transparent mt-3 text-center width-100" onClick={() => this.onInfoClick(this.props.loc)}>Info</button>
                                
            //                 </div>
            //                 <div className="col-lg-7  border-xl-left col-12 col-md-6 mt-1 ">

            //                     <a href="" className="d-flex  align-items-start">   <img src={timeIcon} alt="girl" className="img-fluid phoneaa" />
            //                         <span className="img_text ml-2">
            //                             {this.props.loc.BranchOfficeTiming.Timings.map(time => {
            //                                 return (<>
            //                                     {time}<br />
            //                                 </>);
            //                             })
            //                             }
            //                         </span></a></div></div>

            //         </div>

            //         <div className="col-xl-3 col-md-3 col-sm-12 col-12">

            //             <div className="img-details-wrap card" style={{ height: '140px' }}
            //             >
            //                 {
            //                     this.props.loc != null?
            //                     <Map google={this.props.google} zoom={14}
            //                     initialCenter={{
            //                         lng: this.props.loc.Latitude, lat: this.props.loc.Longitude
            //                     }}
            //                 >
            //                     <Marker
            //                         name={'Your position'}
            //                         position={{ lng: this.props.loc.Latitude, lat: this.props.loc.Longitude }}
            //                         icon={{
            //                             url: 'http://dl.wexoz.com/static/media/Group1045.png',

            //                         }}
            //                     />
            //                     <InfoWindow></InfoWindow>
            //                 </Map>
            //                     :null
            //                 }

                            
            //             </div>
            //             <button type="submit" className="rounded-bttn rounded-bttn-transparent mt-3 text-center width-100" onClick={() => this.onBookNowClick(this.props.loc)}>{t('BOOK NOW.1')}</button>
            //         </div>
            //     </div>
            //     {
            //         this.state.showBranchDescription?
            //     <div>
            //         <div className="modal-dialog modal-xl modal-dialog-centered">
            //             <Modal show={this.state.showBranchDescription}  onHide={() => this.toogleDescriptionPopUp(false)} dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap">
            //                 <ViewExtrasDetails descriptionToShow={this.state.BranchDescription} />
            //             </Modal>
            //         </div>
            //     </div>
            //         :null
            //     }

            // </div>
        );
    }
}


export default hoistStatics(withTranslation()(GoogleApiWrapper({
    apiKey: ('AIzaSyD5B6KKLVWThFRg2oXcEYoFHMyRFoLexzo')
})(withRouter(LocationTile))), LocationTile);