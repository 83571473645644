import React, { Component } from 'react';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import globalpartner from '../assets/images/Business/1010860.jpg';
import awardwinnig from '../assets/images/award-winning.png';
import largestwidest from '../assets/images/Business/2019-Toyota-Hiace.jpg';
import redCarpet from '../assets/images/dollar-red-carpet.png';
import dollarHunt from '../assets/images/Business/hiac_cc_1903_1006.jpg';
import ContactUsBox from './ContactUsBox';
import { animateScroll as scroll,scroller  } from 'react-scroll'
import BusinessMobilityBox from './BusinessMobilityBox';
import { withTranslation } from 'react-i18next';
import specializedVehiclePart2 from '../assets/images/Business/1789307906.jfif'
import specializedVehiclePart4 from '../assets/images/Business/262164383.jfif'
import VehicleByVehicleTypes from './VehicleByVehicleTypes';
import { Helmet } from 'react-helmet';
class SpecializedVehicles extends Component {

    componentDidMount = () => {
        scroll.scrollToTop();
     }

    render() {
        const { t } = this.props;
        return (<>
                   <Helmet>
                        <meta charSet="utf-8" />
                        <title>Specialized Vehicle Services for Mobility & Transportation solutions in UAE</title>
                        <link rel="canonical" href="www.dollaruae.com/specialized-vehicles" />
                        <meta property="og:title" content="Specialized Vehicle Services for Mobility & Transportation solutions in UAE"></meta>
                        <meta property="og:site_name" content="Dollar Car Rental"></meta>
                        <meta property="og:url" content="https://www.dollaruae.com/specialized-vehicles"></meta>
                        <meta property="og:description" content="Your one stop for budget-friendly business mobility solutions. From acquisition to maintainance and more  - we specialise in providing the complete package for your business needs. "></meta>
                        <meta property="og:type" content="business.business"></meta>
                        <meta property="og:image" content="https://www.dollaruae.com/static/media/1010860.45059592.jpg"></meta>
                        <meta name="title" content="Specialized Vehicle Services for Mobility & Transportation solutions in UAE"></meta>
                        <meta name="description" content="Your one stop for budget-friendly business mobility solutions. From acquisition to maintainance and more  - we specialise in providing the complete package for your business needs. "></meta>
                        <meta property="og:type" content="website"></meta>
                        <meta property="og:url" content="https://www.dollaruae.com/specialized-vehicles"></meta>
                        <meta property="og:title" content="Specialized Vehicle Services for Mobility & Transportation solutions in UAE"></meta>
                        <meta property="og:description" content="Your one stop for budget-friendly business mobility solutions. From acquisition to maintainance and more  - we specialise in providing the complete package for your business needs. "></meta>
                        <meta property="og:image" content="https://www.dollaruae.com/static/media/1010860.45059592.jpg"></meta>
                        <meta property="twitter:card" content="summary_large_image"></meta>
                        <meta property="twitter:url" content="https://www.dollaruae.com/specialized-vehicles"></meta>
                        <meta property="twitter:title" content="Specialized Vehicle Services for Mobility & Transportation solutions in UAE"></meta>
                        <meta property="twitter:description" content="Your one stop for budget-friendly business mobility solutions. From acquisition to maintainance and more  - we specialise in providing the complete package for your business needs. "></meta>
                        <meta property="twitter:image" content="https://www.dollaruae.com/static/media/1010860.45059592.jpg"></meta>
                  </Helmet>
            <ListingHeader />
            <div className="minH100 bg-grey">
            <section className="py-5 bg-grey">
                    <div className="container">
                    <div className="border-titlec-content mb-5">
                        <h1 className="mb-1">{t('Specialized Vehicle Services.1')}</h1>
                        <h2 className="mb-1">{t('Budget-friendly Business Mobility.1')}</h2>
                        <p className="font-18 text-grey">{t('Fleet Range as Unique as your Brand.1')}</p>
                        </div>
                        <div className="width-75 py-5 mx-auto">
                            <div className="row align-items-center">
                                <div className="col-md-5 col-12">
                                    <img src={globalpartner} alt={t('Specialized Vehicles.1')}  className="rounded-xl"></img>
                                </div>
                                <div className="col-md-7 col-12">
                                    <div className="oddEven-content pl-md-3 pl-0 pt-4 pt-md-0">
                                        <h3 className="font-24">{t('We believe, no road is long with the right company.1')}</h3>
                                        <p className="font-18 text-grey pt-3">{t('And that is why, at Dollar Car Rental, we aim to be the leading and innovative provider of mobility & transportation solutions in the UAE.1')} </p>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center pt-5">
                                <div className="col-md-7 col-12 order-1 order-md-0">
                                    <div className="oddEven-content pr-md-3 pr-0">
                                        <p className="font-18 text-grey pt-3">{t('Dollar Car Rental UAE takes pride in being associated with leading brands in the UAE like Pepsi, Mai Dubai, Pran Foods, NFPC, Aramex, Carrefour, etc. as their total transportation solutions partner, by helping them achieve reduced fleet management costs, improved fleet compliance, customized vehicle-builds, and other business-specific goals.1')}</p>
                                    </div>
                                </div>
                                <div className="col-md-5 col-12">
                                    <img src={specializedVehiclePart2} alt={t('Dollar Care Rental.1')} className="rounded-xl"></img>
                                </div>
                            </div>
                            <div className="row align-items-center pt-5">
                                <div className="col-md-5 col-12">
                                    <img src={largestwidest}  alt={t('Dollar Customizable.1')} className="rounded-xl"></img>
                                </div>
                                <div className="col-md-7 col-12">
                                    <div className="oddEven-content pl-md-3 pl-0 pt-4 pt-md-0">
                                        {/* <h3 className="font-24">Largest & Widest</h3> */}
                                        <p className="font-18 text-grey pt-3">{t('Customizable vehicular modifications for business-specific requirements are one of our key fortes. Based on the needs of a large number of our clients, we have expanded and increased our focus on ‘specialized vehicles’, targeted towards the transport/logistics/distribution companies and commercial fleet owners in the UAE. We now offer these vehicles on a long-term lease as per your unique business-specific requirements.1')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center pt-5">
                                <div className="col-md-7 col-12 order-1 order-md-0">
                                    <div className="oddEven-content pr-md-3 pr-0">
                                        {/* <h3 className="font-24">Dollar RED Carpet Services</h3> */}
                                        <p className="font-18 text-grey pt-3">{t('Our award-winning services ensure you benefit from expert guidance from our sales consultants, who also have the industry experience, equipment and product knowledge specific to your business needs. With easy access to specialized vehicles, project-based, seasonal or peak demands are concerns long gone. Our fleet portfolio also ranges wide enough to cater to last minute requirements & prospective opportunities for your business.1')}</p>
                                    </div>
                                </div>
                                <div className="col-md-5 col-12">
                                    <img src={specializedVehiclePart4} alt={t('Specialized Vehicle 4.1')} className="rounded-xl"></img>
                                </div>
                            </div>
                            <div className="row align-items-center pt-5">
                                <div className="col-md-5 col-12">
                                    <img src={dollarHunt} alt={t('Dollar Specialized Vehicle.1')}  className="rounded-xl"></img>
                                </div>
                                <div className="col-md-7 col-12">
                                    <div className="oddEven-content pl-md-3 pl-0 pt-4 pt-md-0">
                                        {/* <h3 className="font-24">Dollar Hunt</h3> */}
                                        <p className="font-18 text-grey pt-3">{t('All our vehicles are modern, reliable & adaptable, making them a wise choice for businesses that involve transportation of expensive and perishable goods.1')}</p>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </section>
                <BusinessMobilityBox></BusinessMobilityBox>
                <VehicleByVehicleTypes vehicleClassificationID="f2a4bbb4-bfd8-4285-bbfc-df5b00567571"></VehicleByVehicleTypes>
                <ContactUsBox subHeading ={t('To know more on specialized vehicles for your business, contact us today.1')} heading ={t('We look forward to serving you.1')}></ContactUsBox>


            </div>
            <ListingFooter />
        </>);
    }
}

export default withTranslation()(SpecializedVehicles);