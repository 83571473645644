import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
class ViewIncludeLongText extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { t } = this.props
        return (
            <div>
                {<Modal.Header closeButton >
                    <h4>{t('View Details.1')}</h4>
                </Modal.Header>}
                <Modal.Body>
                    {
                        this.props.IsTextWithHtml ?
                        <div dangerouslySetInnerHTML={ { __html: this.props.LongTextShow}}></div>
                        :
                        <p>{this.props.LongTextShow}</p>
                    }

                </Modal.Body>
            </div>
        );
    }
}
export default withTranslation()(ViewIncludeLongText);