import axios from "axios";
import { GoogleApiWrapper } from "google-maps-react";

import hoistStatics from "hoist-non-react-statics";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ViewExtrasDetails from "../ListingPage/ViewExtrasDetails";

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { getLanguage } from "../Common/Util";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "react-bootstrap/Spinner";
import "../assets/css/slick.css";

class PickupPopover extends Component {
  constructor(props) {
    super(props);
    this.inputgmap = React.createRef();

    this.state = {
      allBranches: [],
      overPickupBranch: {},
      branchTypes: [],
      baseBranches: [],
      searchBox: {},
      showBranchDescription: false,
      branchDescription: "",
      deliveryState: "SELECT",
      deliveryAddress: "",
      allStates: [],
      isYourLocation: false,
      windowSize: 1024,
      isMobileView: false,
    };
  }

  onPickupBranchSelected = (selectedBranch) => {
    this.props.pickupDeliveryLocationSelected("", "");
    this.props.onPickupBranchSelected(selectedBranch);
  };

  onPickupBranchMouseOver = (branch) => {
    this.setState({ overPickupBranch: branch });
  };
  toogleDescriptionPopUp = (showHide) => {
    this.setState({
      showBranchDescription: showHide,
    });
  };

  onPickupLocationClick = (e) => {
    this.setState({ searchWidgetMode: 1 });
    this.togglePopOver();

    e.stopPropagation();
  };

  componentDidMount() {
    this.getBranches();
    this.getStates();

    if (
      this.props.deliveryState != null &&
      this.props.deliveryState != undefined
    )
      this.setState({ deliveryState: this.props.deliveryState });
    if (
      this.props.deliveryAddress != null &&
      this.props.deliveryAddress != undefined
    )
      this.setState({ deliveryAddress: this.props.deliveryAddress });

    window.addEventListener("resize", this.updateWindowSize);
    this.updateWindowSize();
  }
  updateWindowSize = () => {
    this.setState({
      windowSize: window.innerWidth,
    });
    this.setState({ isMobileView: false });
    if (window.innerWidth < 770) {
      this.setState({ isMobileView: true });
    }
  };
  onPlacesChanged = () => {
    this.searchBox.getPlaces();
  };
  componentDidUpdate(prevProps) {}

  containsLocation = (locations, id) => {
    var i = -0;
    for (i = 0; i < locations.length; i++) {
      if (locations[i].locationId.toString() == id.toString()) return true;
    }
    return false;
  };

  onInfoClick = (locationSelected) => {
    this.setState({
      showBranchDescription: true,
      branchDescription: locationSelected.Description,
    });
  };

  getStates = () => {
    axios(
      process.env.REACT_APP_API +
        "DeliveryPickup/GetStates?apiKey=" +
        process.env.REACT_APP_API_KEY
    ).then((responce) => {
      const allStates = responce.data;
      if (allStates.length > 0) {
        this.setState({
          allStates: allStates,
        });
      }
    });
  };

  getBranches = () => {
    let lngParam = getLanguage();
    axios(
      process.env.REACT_APP_API +
        "Branch/Get?apiKey=" +
        process.env.REACT_APP_API_KEY +
        `&lng=${lngParam}`
    ).then((responce) => {
      const allBranches = responce.data;

      if (allBranches.length > 0) {
        const locations = [];
        allBranches.forEach((branch) => {  
          if (
            !this.containsLocation(locations, branch.BranchType.BranchTypeID)
          ) {
            locations.push({
              locationId: branch.BranchType.BranchTypeID,
              name: branch.BranchType.Name,
              imageString: branch.BranchType.Attachment.ImageString,
            });
          }
        });
        this.setState({ branchTypes: locations });
        this.setState({ baseBranches: allBranches });
      }

      this.setState({
        allBranches: allBranches,
      });
    });
  };

  pickupDeliveryLocationChange = (event) => {
    this.setState({ deliveryState: event.target.value });
    var hasBranch = false;
    if (this.state.allBranches.length > 0) {
      this.state.allBranches.forEach((branch) => {
        if (branch.CountryState.StateName == event.target.value) {
          hasBranch = true;
          this.setState({ overPickupBranch: branch });
        }
      });
      if (!hasBranch) {
        this.setState({ overPickupBranch: this.state.allBranches[0] });
      }
    }
  };

  onChange = (e) => {
    this.setState({ deliveryAddress: e.target.value });
    if (e.target.value.length > 0) {
      if (this.state.allBranches.length > 0) {
        const newBaseLocationList = [];
        this.state.allBranches.forEach((branch) => {
          if (
            branch.Name.toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            newBaseLocationList.push(branch);
          }
        });
        if (newBaseLocationList.length > 0) {
          this.setState({ branchTypes: this.state.branchTypes });
          this.setState({ allBranches: newBaseLocationList });
        }
      }
    } else {
      this.setState({ allBranches: [] });
      this.setState({ allBranches: this.state.baseBranches });
    }
  };

  setClickHandler = (event) => {
    if (this.state.deliveryState != "SELECT") {
      if (
        this.state.deliveryAddress == null ||
        this.state.deliveryAddress == ""
      ) {
        this.setState({ errorMessage: "Please enter the address." });
        return;
      }
      //this.state.overPickupBranch.Identity.Id = "00000000-0000-0000-0000-000000000000";
      this.state.overPickupBranch.Name =
        this.state.deliveryState + "-" + this.state.deliveryAddress;
      this.state.overPickupBranch.Address = this.state.deliveryAddress;
      this.state.overPickupBranch.CountryState.StateName =
        this.state.deliveryState;
      this.props.onPickupBranchSelected(this.state.overPickupBranch);
      this.props.pickupDeliveryLocationSelected(
        this.state.deliveryState,
        this.state.deliveryAddress
      );
    } else {
      this.props.pickupDeliveryLocationSelected("", "");
      this.setState({ errorMessage: "Please select the Emirates." });
      return;
    }
  };

  onHidePickupPopover = () => {
    document.getElementById("body").className = "";
    this.props.clearPopOver();
  };

  handleClose = () => {
    this.setState({
      errorMessage: "",
    });
  };

  setIsOurCountries = (event) => {
    this.setState({
      isYourLocation: false,
    });
  };

  setIsYourLocation = (event) => {
    this.setState({
      isYourLocation: true,
    });
  };

  render() {
    const { t } = this.props;
    const style = {
      width: "100%",
      height: "100%",
    };
    return this.props.searchWidgetMode == 1 ? (
      <div
        className={
          this.state.isYourLocation == true
            ? "pickup-dropdown-box-wrapper common-model-wrap yourLocation-wrap yourLocation--box"
            : "pickup-dropdown-box-wrapper common-model-wrap yourLocation-wrap"
        }
      >
        <a className="close-model-select" onClick={this.onHidePickupPopover}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <polygon
              fill="#191919"
              fill-rule="nonzero"
              points="84 73.237 81.763 71 68.5 84.263 55.237 71 53 73.237 66.263 86.5 53 99.763 55.237 102 68.5 88.737 81.763 102 84 99.763 70.737 86.5"
              transform="translate(-53 -71)"
            ></polygon>
          </svg>
        </a>
        <div className="pickup-dropdown-box-left">
          <div className="NearByLocation__currentLocation">
            <button
              className={
                this.state.isYourLocation == false
                  ? "set-bttn"
                  : "set-bttn-gray"
              }
              onClick={this.setIsOurCountries}
              style={{ zIndex: "500" }}
            >
              {t("Our Counters.1")}
            </button>

            <button
              className={
                this.state.isYourLocation == true ? "set-bttn" : "set-bttn-gray"
              }
              onClick={this.setIsYourLocation}
              style={{ zIndex: "500" }}
            >
              {t("Deliver to me.1")}
            </button>
          </div>

          {this.state.isYourLocation == true ? (
            <div className="NearByLocation__currentLocation">
              {/* <input id ="inputgmap" type="text" placeholder="Need a delivery? Search Location here."/> */}
              {/* <label style={{ cursor: "pointer" }} >Need a Delivery? Select Emirates and enter Delivery address.</label> */}
              <div className="width-col want-delivery-wrap-input want-delivery-wrap-select ">
                <select
                  id="pickup-delivery-location"
                  onChange={this.pickupDeliveryLocationChange}
                  value={this.state.deliveryState}
                >
                  <option value="SELECT">{t("Select City.1")}</option>
                  {this.state.allStates.map((state) => {
                    return (
                      <>
                        <option value={state.StateName}>
                          {state.StateName}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div className="width-col want-delivery-wrap-input">
                {/* <label style={{ cursor: "pointer" }} ></label> */}
                <input
                  class="form-control"
                  id="deliveryAddress"
                  name="deliveryAddress"
                  type="text"
                  onChange={this.onChange}
                  value={this.state.deliveryAddress}
                  placeholder={t("Enter your address.1")}
                />
              </div>
              <button
                className="set-bttn"
                onClick={this.setClickHandler}
                style={{ zIndex: "500" }}
              >
                {t("SUBMIT.1")}
              </button>
              <a
                title="Close"
                className="ml-3 close-info"
                onClick={this.onHidePickupPopover}
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </a>
              {/* <GSearchBar /> */}
              {/* <img src="./assets/images/location-current.svg" alt="" />
                                                        Use Your Current Location */}
            </div>
          ) : (
            <>
              <div className="width-col want-delivery-wrap-input">
                {/* <label style={{ cursor: "pointer" }} ></label> */}
                <input
                  class="form-control"
                  id="deliveryAddress"
                  name="deliveryAddress"
                  type="text"
                  onChange={this.onChange}
                  value={this.state.deliveryAddress}
                  placeholder={t("Search Dollar Counters here.1")}
                />
                <a
                  title="Close"
                  className="ml-3 close-info"
                  onClick={this.onHidePickupPopover}
                >
                  close
                </a>
              </div>
              <div className="StationHistory__wrapper">
                <div className="StationHistory__stationName">
                  <div className="StationHistory__stationInfo">
                    {this.state.branchTypes.map((branchtype) => {
                      return (
                        <div key={branchtype.locationId}>
                          <div className="StationHistory__title">
                            <img
                              src={branchtype.imageString}
                              alt="Dollar Branch"
                            />{" "}
                            {branchtype.name}
                            {this.state.allBranches.map((branch) => {
                              return branch.BranchType.BranchTypeID ==
                                branchtype.locationId ? (
                                <>
                                  <div key={branch.Identity.Id}>
                                    <div
                                      className="StationHistory__title1"
                                      onClick={() =>
                                        this.onPickupBranchSelected(branch)
                                      }
                                      onMouseOver={() => {
                                        this.onPickupBranchMouseOver(branch);
                                      }}
                                    >
                                      {branch.Name}
                                    </div>
                                  </div>
                                </>
                              ) : null;
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {
          // this.state.isMobileView == false ?
          this.state.isYourLocation == false ? (
            <div className="pickup-dropdown-box-right">
              <div className="StationDetails__wrapper">
                <h3 className="StationDetails__headline">
                  {this.state.overPickupBranch.Name}
                  <a
                    class="text-red"
                    onClick={() =>
                      this.onInfoClick(this.state.overPickupBranch)
                    }
                  >
                    {t("More Info.1")} <i class="fa fa-angle-right"></i>
                  </a>
                </h3>
                {/* <a   title="Close" className="ml-3 close-info" onClick={this.onHidePickupPopover}>
                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                </a>   */}
                <p className="StationDetails__subline">
                  {this.state.overPickupBranch.Address}
                </p>
                <p
                  className="StationDetails__subline"
                  style={{ color: "#C8102E", opacity: "1" }}
                >
                  {this.state.overPickupBranch.Message}
                </p>
                <div className="StationDetails__openings">
                  <div className="StationDetails__openingHours">
                    <div className="OpeningHours__wrapper">
                      <div className="OpeningHours__openingHours">
                        <div className="OpeningHours__openingDay">
                          <div className="OpeningHours__times">
                            {this.state.overPickupBranch.BranchOfficeTiming !=
                            null
                              ? this.state.overPickupBranch.BranchOfficeTiming
                                  .BranchTimings != null
                                ? this.state.overPickupBranch.BranchOfficeTiming.BranchTimings.map(
                                    (timing) => {
                                      return (
                                        <>
                                          {timing != null ? (
                                            <>
                                              {timing.DayString} :{" "}
                                              {timing.Shifts.map((shift) => {
                                                return <>{shift}</>;
                                              })}
                                            </>
                                          ) : null}
                                          <br />
                                        </>
                                      );
                                    }
                                  )
                                : null
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="StationInfo__mapWrapper">
                <iframe
                  src={this.state.overPickupBranch.GoogleLocationURL}
                  width="100%"
                  height="250"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>

                <a
                  title="Close"
                  className="ml-3 close-info"
                  onClick={this.onHidePickupPopover}
                >
                  {t("close.1")}
                </a>
              </div>
            </div>
          ) : null
          // : null
        }

        {this.state.showBranchDescription ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <Modal
                show={this.state.showBranchDescription}
                onHide={() => this.toogleDescriptionPopUp(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
              >
                <ViewExtrasDetails
                  descriptionToShow={this.state.branchDescription}
                />
              </Modal>
            </div>
          </div>
        ) : null}

        <div className="col-xl-3">
          {this.state.errorMessage != null &&
          this.state.errorMessage.length > 0 ? (
            <Dialog
              open={this.state.errorMessage.length > 0}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Dollar"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.state.errorMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  {t("OK.1")}
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}

          {this.state.loading == true ? (
            <div style={{ verticalAlign: "center" }}>
              <Spinner animation="border" />
              &nbsp;{this.state.loadingText}
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  }
}

export default hoistStatics(
  withTranslation()(
    GoogleApiWrapper({
      apiKey: "AIzaSyD5B6KKLVWThFRg2oXcEYoFHMyRFoLexzo",
    })(withRouter(PickupPopover))
  ),
  PickupPopover
);
