import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import appstore from '../assets/images/app-store.png';
import playstore from '../assets/images/google-play-store.png';


class SocialMediaBox extends Component {
    render() {
        const { t } = this.props
        return (
<section className="box-shadow-white-box-section grey-bg py-2">
                    <div className="container-fluid">
                        <div className="box-shadow-white-box bg-red">
                            <div className="socialMediaSection">
                                <div className="try-our-app-section">
                                    <h3 className="text-white">{t('Try Our App.1')}</h3>
                                    <div className="try-our-app-img-download">
                                        <a className="app-store-download">
                                            <img src={appstore} alt="App Store"></img>
                                        </a>
                                        <a className="app-store-download">
                                            <img src={playstore} alt="Play Store"></img>  
                                        </a>
                                    </div>

                                </div>
                                <div className="follow-us-section">
                                <h3 className="text-white">{t('Follow Us On.1')}</h3>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/dollar.uae/" target="_blank">
                                            <i class="fa fa-facebook" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="https://twitter.com/dollaruae" target="_blank">
                                            <i class="fa fa-twitter" aria-hidden="true"></i>
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="https://www.linkedin.com/company/dollar-rent-a-car-uae" target="_blank">
                                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/dollar.uae/" target="_blank">
                                            <i class="fa fa-instagram" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>

        );
    }
}

export default withTranslation()(withRouter(withRouter(SocialMediaBox)));