import dayjs from "dayjs";
import moment from "moment";
import React, { Component } from "react";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import { withTranslation } from "react-i18next";
import buildingImg from "../../assets/images/building.svg";
import closeImg from "../../assets/images/closeX.svg";
import plainImg from "../../assets/images/plain.svg";
import "./daterangepicker.css";
import "./googleDatePicker.css";
import "./tabsStyle.css";
import { positionMap } from "./constant";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { branchInstance } from "../../axios/axiosInstance";

class CalanderPopUp extends Component {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();
    this.state = {
      pickUpDate: this.props.stateValues.pickUpDate,
      returnDate: this.props.stateValues.returnDate,
      timeList: [],
      timeList1: [],
      pickUpCount: 0,
      currentPickupTime: this.props.stateValues.pickUpTime,
      currentReturnTime: this.props.stateValues.returnTime,
      returnCount: 0,
      dayBetween: this.props.stateValues.dayBetweenState || 3,
      pickTimeRangeSlider: this.props.stateValues.pickUpTime,
      returnTimeRangeSlider: this.props.stateValues.returnTime,
      pickupPeriod: this.props.stateValues.pickUpPeriod || "AM",
      returnPeriod: this.props.stateValues.returnPeriod || "AM",
      isPickUpPeriodActive: this.props.stateValues.pickUpPeriod,
      isReturnPeriodActive: this.props.stateValues.returnPeriod,
      activeColor: 3,
      activeCalendar: true,
      size: window.innerWidth,
      currentFunction: "pickUp",
      focusReturn: false,
      isFirefox: false,
      trackDate: 1,
      range: {
        startDate: new Date(this.props.stateValues.pickUpDate),
        endDate: new Date(this.props.stateValues.returnDate),
        key: "selection", // specify the key
      },
    };
  }

  formatDateString(dateString) {
    const [day, numericMonth, year] = dateString.split(" ");

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = months[parseInt(numericMonth, 10) - 1];

    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  }

  updateDayBetween = () => {
    const { pickUpDate, returnDate } = this.state;

    const dayBetween = moment(returnDate, "DD MMM YYYY").diff(
      moment(pickUpDate, "DD MMM YYYY"),
      "days"
    );
    if (dayBetween > 0) {
      this.setState({ dayBetween }, () => {
        this.props.dayBetween(dayBetween);
      });
    } else {
      this.setState({ dayBetween: 0 }, () => {
        this.props.dayBetween(0);
      });
    }
  };

  componentDidMount() {
    // console.log(this.props.stateValues.activeColor);
    if (this.props.stateValues.activeColor === 4) {
      this.setState({ focusReturn: true });
    }

    this.getTimeSlots();

    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Firefox") > -1) {
      this.setState({ isFirefox: true });
    }

    this.updateCurrentPickupTime(
      this.props.stateValues.pickUpTime,
      "pickUpTime"
    );
    this.updateCurrentPickupTime(
      this.props.stateValues.returnTime,
      "returnTime"
    );
  }

  getTimeSlots = () => {
    

    branchInstance
      .get("/timeSlots")
      .then((response) => {
        this.setState({ timeList: response.data, timeList1: response.data });
      })
      .catch((error) => {
        console.error("Error fetching time slots:", error);
      });
  };

  handleTimeIncrement12 = (pickup) => {
    if (pickup) {
      let splittedTime = this.state.currentPickupTime.split(":");
      let hours = parseInt(splittedTime[0]);
      let minutes = parseInt(splittedTime[1]);

      // Increment time by 30 minutes
      minutes += 30;
      if (minutes >= 60) {
        hours++;
        minutes = 0;
      }

      // Handle 12-hour format and wrapping around
      if (hours > 12) {
        hours = 1;
      }

      // Format hours and minutes to ensure 2 digits
      let formattedHours = (hours < 10 ? "0" : "") + hours;
      let formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

      // Update current time
      const currentTime = formattedHours + ":" + formattedMinutes;

      this.updateCurrentPickupTime(currentTime, "pickUpTime");

      this.setState(
        {
          currentPickupTime: currentTime,
        },
        () => {
          this.props.handlePickTime(this.state.currentPickupTime);
          if (this.state.currentPickupTime === "12:00") {
            if (this.state.pickupPeriod === "AM") {
              this.setState({
                isPickUpPeriodActive: "PM",
                pickupPeriod: "PM",
              });
            } else {
              this.setState({
                isPickUpPeriodActive: "AM",
                pickupPeriod: "AM",
              });
            }
          }
        }
      );
    } else {
      let splittedTime = this.state.currentReturnTime.split(":");
      let hours = parseInt(splittedTime[0]);
      let minutes = parseInt(splittedTime[1]);

      // Increment time by 30 minutes
      minutes += 30;
      if (minutes >= 60) {
        hours++;
        minutes = 0;
      }

      // Handle 12-hour format and wrapping around
      if (hours > 12) {
        hours = 1;
      }

      // Format hours and minutes to ensure 2 digits
      let formattedHours = (hours < 10 ? "0" : "") + hours;
      let formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

      // Update current time
      const currentTime = formattedHours + ":" + formattedMinutes;
      this.updateCurrentPickupTime(currentTime, "returnTime");

      this.setState(
        {
          currentReturnTime: currentTime,
        },
        () => {
          this.props.handleReturnTime(this.state.currentReturnTime);
          if (this.state.currentReturnTime === "12:00") {
            if (this.state.returnPeriod === "AM") {
              this.setState({
                isReturnPeriodActive: "PM",
                returnPeriod: "PM",
              });
            } else {
              this.setState({
                isReturnPeriodActive: "AM",
                returnPeriod: "AM",
              });
            }
          }
        }
      );
    }
  };

  changeDateRangeState = (item) => {
    this.setState({
      range: item.selection,
    });

    const formatedPickupDate = this.formatDateString(
      dayjs(item.selection.startDate).format("DD MM YYYY")
    );

    // if (this.state.focusReturn) {
    //   this.props.returnDate(formatedPickupDate);
    //   this.setState({ returnDate: formatedPickupDate }); // if it need for focus return
    //   return;
    // }

    this.props.pickUpDate(formatedPickupDate);
    this.setState({ pickUpDate: formatedPickupDate });

    const formatedReturnDate = this.formatDateString(
      dayjs(item.selection.endDate).format("DD MM YYYY")
    );

    this.props.returnDate(formatedReturnDate);
    this.setState({ returnDate: formatedReturnDate });
  };

  //12 hours handleTimeDecrement
  handleTimeDecrement12 = (pickup) => {
    if (pickup) {
      let splittedTime = this.state.currentPickupTime.split(":");
      let hours = parseInt(splittedTime[0]);
      let minutes = parseInt(splittedTime[1]);

      // Decrement time by 30 minutes
      minutes -= 30;
      if (minutes < 0) {
        hours--;
        minutes = 30;
      }

      // Handle wrapping around to '12:30' after reaching '01:00'
      if (hours <= 0) {
        hours = 12;
      }

      // Format hours and minutes to ensure 2 digits
      let formattedHours = (hours < 10 ? "0" : "") + hours;
      let formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

      // Update current time
      const currentTime = formattedHours + ":" + formattedMinutes;

      this.updateCurrentPickupTime(currentTime, "pickUpTime");

      this.setState(
        {
          currentPickupTime: currentTime,
        },
        () => {
          this.props.handlePickTime(this.state.currentPickupTime);
          if (this.state.currentPickupTime === "12:00") {
            if (this.state.pickupPeriod === "AM") {
              this.setState({
                isPickUpPeriodActive: "PM",
                pickupPeriod: "PM",
              });
            } else {
              this.setState({
                isPickUpPeriodActive: "AM",
                pickupPeriod: "AM",
              });
            }
          }
        }
      );
    } else {
      let splittedTime = this.state.currentReturnTime.split(":");
      let hours = parseInt(splittedTime[0]);
      let minutes = parseInt(splittedTime[1]);

      // Decrement time by 30 minutes
      minutes -= 30;
      if (minutes < 0) {
        hours--;
        minutes = 30;
      }

      // Handle wrapping around to '12:30' after reaching '01:00'
      if (hours <= 0) {
        hours = 12;
      }

      // Format hours and minutes to ensure 2 digits
      let formattedHours = (hours < 10 ? "0" : "") + hours;
      let formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

      // Update current time
      const currentTime = formattedHours + ":" + formattedMinutes;
      this.updateCurrentPickupTime(currentTime, "returnTime");

      this.setState(
        {
          currentReturnTime: currentTime,
        },
        () => {
          this.props.handleReturnTime(this.state.currentReturnTime);
          if (this.state.currentReturnTime === "12:00") {
            if (this.state.returnPeriod === "AM") {
              this.setState({
                isReturnPeriodActive: "PM",
                returnPeriod: "PM",
              });
            } else {
              this.setState({
                isReturnPeriodActive: "AM",
                returnPeriod: "AM",
              });
            }
          }
        }
      );
    }
  };

  handlePeriodToAM = (pickup) => {
    if (pickup) {
      this.setState(
        {
          pickupPeriod: "AM",
        },
        () => {
          this.props.handlePickUpPeriod(this.state.pickupPeriod);
          this.setState({
            isPickUpPeriodActive: "AM",
          });
        }
      );
    } else {
      this.setState(
        {
          returnPeriod: "AM",
        },
        () => {
          this.props.handleReturnPeriod(this.state.returnPeriod);
          this.setState({
            isReturnPeriodActive: "AM",
          });
        }
      );
    }
  };

  handlePeriodToPM = (pickup) => {
    if (pickup) {
      this.setState(
        {
          pickupPeriod: "PM",
        },
        () => {
          this.props.handlePickUpPeriod(this.state.pickupPeriod);
          this.setState({
            isPickUpPeriodActive: "PM",
          });
        }
      );
    } else {
      this.setState(
        {
          returnPeriod: "PM",
        },
        () => {
          this.props.handleReturnPeriod(this.state.returnPeriod);
          this.setState({
            isReturnPeriodActive: "PM",
          });
        }
      );
    }
  };

  updateCurrentPickupTime = (timeString, time) => {
    if (time === "pickUpTime") {
      this.setState({ pickTimeRangeSlider: timeString });
    } else if (time === "returnTime") {
      this.setState({ returnTimeRangeSlider: timeString });
    }
  };

  calculateLeftStyle = () => {
    const { pickTimeRangeSlider } = this.state;
    const leftPosition = positionMap[pickTimeRangeSlider] || 0;
    return `${leftPosition}%`;
  };

  calculateRightStyle = () => {
    const { returnTimeRangeSlider } = this.state;
    const leftPosition = positionMap[returnTimeRangeSlider] || 0;
    return `${leftPosition}%`;
  };

  handleSliderChangeLeft = (event) => {
    const newValue = parseFloat(event.target.value);
    const hours = Math.floor(newValue);
    const minutes = (newValue - hours) * 60; // Adjusted the calculation to get minutes
    const currentTime = new Date();
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0);

    // Format the time as "hh:mm"
    let formattedTime = currentTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    formattedTime = formattedTime.replace(/\s[AP]M/i, "");
    // Update the state with the formatted time
    this.setState({
      pickTimeRangeSlider: formattedTime,
      currentTime: formattedTime,
    });

    // Call the callback function with the formatted time
    this.setState({ currentPickupTime: formattedTime }, () => {
      this.props.handlePickTime(formattedTime);
    });
  };

  handleSliderChangeRight = (event) => {
    const newValue = parseFloat(event.target.value); // Parse float to handle 0.5 increments
    const hours = Math.floor(newValue); // Get the hour part
    const minutes = (newValue % 1) * 60; // Convert the decimal part to minutes
    const currentTime = new Date();
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0);

    // Format the time as "hh:mm"
    let formattedTime = currentTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    formattedTime = formattedTime.replace(/\s[AP]M/i, "");

    this.setState({
      returnTimeRangeSlider: formattedTime,
      currentTime: formattedTime,
    });
    this.setState({ currentReturnTime: formattedTime }, () => {
      this.props.handleReturnTime(formattedTime);
    });
  };

  handlePickUpDateChange = (date) => {
    const formatedDate = this.formatDateString(
      dayjs(date).format("DD MM YYYY")
    );

    this.props.pickUpDate(formatedDate);
    this.setState({ pickUpDate: formatedDate });
  };

  handleReturnDateChange = (date) => {
    const formatedDate = this.formatDateString(
      dayjs(date).format("DD MM YYYY")
    );

    this.props.returnDate(formatedDate);
    this.setState({ returnDate: formatedDate });
  };
  onDateChange(startDate, endDate) {
    if (this.state.focusReturn) {
      let dateToSave = null;

      const newTrackDate = this.state.trackDate === 1 ? 2 : 1;
      this.setState({ trackDate: newTrackDate });

      if (startDate !== null && this.state.trackDate === 2) {
        dateToSave = this.formatDateString(
          dayjs(startDate).format("DD MM YYYY")
        );
      }
      if (endDate !== null && this.state.trackDate === 1) {
        dateToSave = this.formatDateString(dayjs(endDate).format("DD MM YYYY"));
      }

      if (dateToSave !== null) {
        this.props.returnDate(dateToSave);
        this.setState({
          returnDate: dateToSave,
        });
      }
      this.updateDayBetween();
      return;
    }

    this.setState({ activeColor: this.state.activeColor === 3 ? 4 : 3 });

    this.props.handleDateInputColor(this.state.activeColor);

    if (startDate !== null) {
      const formatedStartDate = this.formatDateString(
        dayjs(startDate).format("DD MM YYYY")
      );

      this.props.pickUpDate(formatedStartDate);
      this.setState({ pickUpDate: formatedStartDate });
    }

    if (endDate !== null) {
      const formatedEndDate = this.formatDateString(
        dayjs(endDate).format("DD MM YYYY")
      );
      this.props.returnDate(formatedEndDate);
      this.setState({ returnDate: formatedEndDate });
    }

    this.updateDayBetween();
  }

  render() {
    const { t } = this.props;
    const marginTopStyle = { paddingTop: window.innerWidth < 500 ? "5vw" : "" };
    const paddingBottomStyle = {
      paddingBottom: window.innerWidth < 500 ? 0 : "",
    };

    const popupStyle = this.state.isFirefox
      ? { scrollbarWidth: "8px", scrollbarColor: "#E31A37 #ffffff" }
      : {};

    return (
      <>
        <div className="overly-hidden"></div>
        <div className="pickupReturnDatePopup">
          <div className="inner-wrapper">
            <div className="mob-header mob">
              <div className="back">
                <h6>{t("Pickup Date & Return Date.1")}</h6>
                <div className="icon">
                  <img
                    src={closeImg}
                    alt=""
                    id="datePopupBackBtn"
                    onClick={() => this.props.hanldePopUpDate(0)}
                  />
                </div>
              </div>
            </div>
            <ul className="selectedLocations">
              <li>
                <div className="icon">
                  <img
                    src={
                      this.props.stateValues.pickUpLocation &&
                      this.props.stateValues.pickUpLocation.includes("Airport")
                        ? plainImg
                        : buildingImg
                    }
                    alt=""
                  />
                </div>
                <div className="inner-wrapper d-block">
                  <h6>
                    {this.props.stateValues.pickUpLocation ||
                      "Select pick up location"}
                  </h6>
                  <h4>
                    {t("Pickup.1")} :{" "}
                    <span>
                      {moment(
                        this.props.stateValues.pickUpDate,
                        "DD MMM YYYY"
                      ).format("DD MMM YYYY")}
                    </span>{" "}
                    |{" "}
                    <span>
                      {this.state.currentPickupTime} {this.state.pickupPeriod}
                    </span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img
                    src={
                      this.props.stateValues.pickUpLocation &&
                      this.props.stateValues.pickUpLocation.includes("Airport")
                        ? plainImg
                        : buildingImg
                    }
                    alt=""
                  />
                </div>
                <div className="inner-wrapper d-block">
                  <h6>
                    {this.props.stateValues.returnLocation ||
                      "Select return location"}
                  </h6>
                  <h4>
                    Return :{" "}
                    <span>
                      {moment(
                        this.props.stateValues.returnDate,
                        "DD MMM YYYY"
                      ).format("DD MMM YYYY")}
                    </span>{" "}
                    |{" "}
                    <span>
                      {" "}
                      {this.state.currentReturnTime} {this.state.returnPeriod}
                    </span>
                  </h4>
                </div>
              </li>
              {/* )} */}
            </ul>

            <div className="right-section-c" style={popupStyle}>
              <div className="datePickerWrapper">
                {/* mobile Calander */}
                <DateRange
                  showMonthAndYearPickers={false}
                  onChange={this.changeDateRangeState}
                  ranges={[this.state.range]}
                  minDate={new Date()}
                  className="mobileReactDatePicker"
                />

                {/* web Calander */}
                <RangeDatePicker
                  minDate={dayjs()}
                  dateFormat="D"
                  startDate={this.state.pickUpDate}
                  endDate={this.state.returnDate}
                  dayFormat="MMM YYYY"
                  monthFormat="MMM YYYY"
                  disabled={false}
                  startWeekDay="monday"
                  onChange={(startDate, endDate) =>
                    this.onDateChange(startDate, endDate)
                  }
                  isOpen="true"
                />
              </div>

              <div className="mob-stick-wrapper">
                <div className="total-days-c">
                  <h6>
                    <span id="totaldays">{this.state.dayBetween}</span>{" "}
                    {t("days.1")}
                  </h6>
                </div>
                {this.state.activeCalendar || this.state.size > "500" ? (
                  <div className="time-section">
                    <div className="left column-c" style={marginTopStyle}>
                      <div className="d-flex d-sm-block align-items-center">
                        <h6 className="web">{t("Pickup Date & Time.1")}</h6>
                        <h6 className="mob">{t("Pickup.1")}</h6>
                        <h5>
                          {" "}
                          <span id="pickupDate">
                            {moment(this.state.pickUpDate, "DD MMM").format(
                              "DD MMM"
                            )}
                          </span>{" "}
                          | {this.state.currentPickupTime}{" "}
                          {this.state.pickupPeriod}
                        </h5>
                      </div>
                      <div className="time-selection">
                        {/* <div className="time-slider">  //future updates
                          <input
                            type="range"
                            min="0"
                            max="12"
                            step="0.5"
                            id="slider"
                            value={this.state.pickTimeRangeSlider || 1}
                            onChange={this.handleSliderChangeLeft}
                          />
                          <span
                            className={`timeLabel ${
                              this.calculateLeftStyle() === "87%" ? "end" : ""
                            }`}
                            id="sliderValue"
                            style={{ left: this.calculateLeftStyle() }}
                          >
                            {this.state.currentPickupTime}
                          </span>
                        </div> */}
                        <div className="custom-container">
                          <div className="time-counter">
                            <button
                              type="button"
                              onClick={() => this.handleTimeDecrement12(true)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M5 12H19"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                            <span>{this.state.currentPickupTime}</span>
                            <button
                              type="button"
                              onClick={() => this.handleTimeIncrement12(true)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12 5V19"
                                  stroke="#231F20"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5 12H19"
                                  stroke="#231F20"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                          <div className="timeMode">
                            <button
                              className={`Button ${
                                this.state.isPickUpPeriodActive === "AM"
                                  ? "active"
                                  : ""
                              }`}
                              size="sm"
                              onClick={() => this.handlePeriodToAM(true)}
                            >
                              AM
                            </button>
                            <button
                              className={`Button ${
                                this.state.isPickUpPeriodActive === "PM"
                                  ? "active"
                                  : ""
                              }`}
                              size="sm"
                              onClick={() => this.handlePeriodToPM(true)}
                            >
                              PM
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="left column-c" style={paddingBottomStyle}>
                      <div className="d-flex d-sm-block">
                        <h6 className="web">{t("Return Date & Time.1")}</h6>
                        <h6 className="mob">{t("Return.1")}</h6>
                        <h5>
                          {" "}
                          <span id="returnDate">
                            {moment(this.state.returnDate, "DD MMM").format(
                              "DD MMM"
                            )}
                          </span>{" "}
                          | {this.state.currentReturnTime}{" "}
                          {this.state.returnPeriod}
                        </h5>
                      </div>
                      <div className="time-selection">
                        {/* return slider */}
                        {/* <div className="time-slider">
                          <input
                            type="range"
                            min="0"
                            max="12"
                            step="0.5"
                            id="slider"
                            value={this.state.returnTimeRangeSlider || 1}
                            onChange={this.handleSliderChangeRight}
                          />
                          <span
                            className={`timeLabel ${
                              this.calculateRightStyle() === "87%" ? "end" : ""
                            }`}
                            id="sliderValue"
                            style={{ left: this.calculateRightStyle() }}
                          >
                            {this.state.currentReturnTime}
                          </span>
                        </div> */}
                        <div className="custom-container">
                          <div className="time-counter">
                            <button
                              type="button"
                              onClick={() => this.handleTimeDecrement12(false)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M5 12H19"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                            <span>{this.state.currentReturnTime}</span>
                            <button
                              type="button"
                              onClick={() => this.handleTimeIncrement12(false)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12 5V19"
                                  stroke="#231F20"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5 12H19"
                                  stroke="#231F20"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                          <div className="timeMode">
                            <button
                              className={`Button ${
                                this.state.isReturnPeriodActive === "AM"
                                  ? "active"
                                  : ""
                              }`}
                              size="sm"
                              onClick={() => this.handlePeriodToAM(false)}
                            >
                              AM
                            </button>
                            <button
                              className={`Button ${
                                this.state.isReturnPeriodActive === "PM"
                                  ? "active"
                                  : ""
                              }`}
                              size="sm"
                              onClick={() => this.handlePeriodToPM(false)}
                            >
                              PM
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="show-car-btn-mob mob">
            <button
              className="primary-button"
              onClick={this.props.searchClickHandler}
            >
              {t("Continue.1")}
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(CalanderPopUp);
