import axios from 'axios';
import hoistStatics from 'hoist-non-react-statics';
import moment from 'moment';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Switch from 'react-input-switch';
import protection from '../assets/images/choose-your-protection-icon.svg';
import { getCustomerId, getLanguage } from '../Common/Util';
import VehicleFeatureBoxVertical from '../Common/VehicleFeatureBoxVertical';
import ViewExtrasDetails from './MonthlyViewExtrasDetails';
import ViewIncludeLongText from './MonthlyViewIncludeLongText';
import ViewInsuranceDetails from './MonthlyViewInsuranceDetails';
import ViewRentalDetails from './MonthlyViewRentalDetails';
var qs = require('qs');

class MonthlyListinPage2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            insuranceList: [],
            ancillaryList: [],
            allowedKm: '',
            currencyCode: '',
            calculationDetails: [],
            isChecked: false,
            seat: 1,
            selectedInsurnace: '',
            selectedAncillary: '',
            PAI: false,
            SCDW: false,
            CDW: false,
            showInsuranceViewDetails: false,
            showRentalDetails: false,
            showExtrasDetails: false,
            extrasDesriptionToShow: '',
            insuranceDesriptionToShow: '',
            selectedAncillaryList:[],
            includeSection:[],
            LongTextShow:'',
            RateType:'',
            RateTypeAncillary:''
        }
    }

    componentDidMount() {        
        let lngParam = getLanguage();        
        this.getAllInsurance(lngParam);
        this.getAllAncillary(lngParam);
        //this.getCompanySetting();
        this.getRateCalculation(this.state.PAI, this.state.CDW, this.state.SCDW,this.state.selectedAncillaryList);
        this.getBookingIncludeSection(lngParam);

    }
    getBookingIncludeSection = (lngParam)=>{
        
          var data = qs.stringify(this.props.searchParam);
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API+'AddOn/GetBookingIncluded?apiKey='+process.env.REACT_APP_API_KEY+`&lng=${lngParam}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
            data: data
        };

        axios(config)
            .then( (response) =>{
                this.setState({includeSection:response.data});
            })
            .catch( (error) =>{
                
            });


    }
    toogleViewInsurancePopUp = (showHide, insuranceDescriptionToShow) => {

        this.setState({
            showInsuranceViewDetails: showHide
        });
        this.setState({
            insuranceDesriptionToShow: insuranceDescriptionToShow
        })


    }
    toogleViewRentalPopUp = (showHide) => {
        this.setState({
            showRentalDetails: showHide
        });
    }
    toogleViewIncludeLongTextPopUp = (showHide,LongText)=>{    
        this.setState({
            showExtrasDetails: showHide
        });
        this.setState({
            LongTextShow: LongText
        });

    }

    toogleViewExtrasPopUp = (showHide, extrasDesriptionToShow) => {
     
        this.setState({
            showExtrasDetails: showHide
        });
        this.setState({
            LongTextShow: extrasDesriptionToShow
        });


    }



  

    getAllInsurance = (lngParam) => {

        let searchParam = this.props.searchParam;
        searchParam.VehicleModelID = this.props.selectVehicleForBooking.VehicleModelID;
        searchParam.TariffGroupID = this.props.selectVehicleForBooking.TariffGroup.TariffGroupID;
        searchParam.CustomerID = getCustomerId();

        var data = qs.stringify(searchParam);

        let url = ``;
        url = process.env.REACT_APP_API + `AddOn/GetInsurance?apiKey=` + process.env.REACT_APP_API_KEY + `&lng=${lngParam}`;
        var config = {
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              data:data
        };

        axios(config)
            .then((response) => {

                this.setState({
                    insuranceList: response.data
                });
              

            })
            .catch((error) => {

            });

    }



    getAllAncillary = (lngParam) => {

        let searchParam = this.props.searchParam;
        searchParam.VehicleModelID = this.props.selectVehicleForBooking.VehicleModelID;
        searchParam.TariffGroupID = this.props.selectVehicleForBooking.TariffGroup.TariffGroupID;
        searchParam.CustomerID = getCustomerId();

        var data = qs.stringify(searchParam);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API+'AddOn/GetAncillary?apiKey='+process.env.REACT_APP_API_KEY+`&lng=${lngParam}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
            data: data
        };

        axios(config)
            .then( (response) =>{
                this.setState({
                    ancillaryList: response.data
                });
                

                let selectedAncillary = this.state.selectedAncillary;
                let selectedAncillaryList = this.state.selectedAncillaryList;
      
                response.data.forEach((item)=>{

                    if(item.SelectionType == 1 && item.IsCompulsoryOnRental == true){
                        selectedAncillary = selectedAncillary + ',' + item.AncillaryTariffID;
                        selectedAncillaryList.push({AncillaryID:item.AncillaryTariffID,Qty:1});
                    }
                    if(item.SelectionType == 2 && item.IsCompulsoryOnRental == true){
                    
                        selectedAncillaryList.push({AncillaryID:item.AncillaryTariffID,Qty:1});
                    }
                }
                );
                this.setState({ selectedAncillary: selectedAncillary });
                this.setState({ selectedAncillaryList: selectedAncillaryList });
                this.getRateCalculation(this.state.PAI, this.state.CDW, this.state.SCDW,selectedAncillaryList);
            })
            .catch( (error) =>{
               
            });

    }


    // getCompanySetting = () => {
    //     var config = {
    //         method: 'get',
    //         url: process.env.REACT_APP_API + `BookingSetting/GetCompanySetting?apiKey=` + process.env.REACT_APP_API_KEY,
    //         headers: {}
    //     };

    //     axios(config)
    //         .then((response) => {

    //             this.setState({
    //                 currencyCode: response.data.CurrencyCode
    //             })

    //         })
    //         .catch((error) => {

    //         });
    // }

    getRateCalculation = (isPIA, isCDW, isSCDW,selectedAncillaryList) => { 
       
        let searchParam = this.props.searchParam;
        searchParam.VehicleModelID = this.props.selectVehicleForBooking.VehicleModelID;
        searchParam.TariffGroupID = this.props.selectVehicleForBooking.TariffGroup.TariffGroupID;
        searchParam.IsPAISelected = isPIA;
        searchParam.IsCDWSelected = isCDW;
        searchParam.IsSCDWSelected = isSCDW;
        searchParam.AncillaryIn = selectedAncillaryList;
        searchParam.CustomerID = getCustomerId();

        var data = qs.stringify(searchParam);
        let url = ``;
        url =process.env.REACT_APP_API+'Reservations/GetCalculation?apiKey='+process.env.REACT_APP_API_KEY;
        var config = {
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
            data: data
        };

        axios(config)
            .then((response) => {
                
                this.setState({

                    calculationDetails: response.data
                });
                
               
            })
            .catch((error) => {

            });

    }
    getSelectedAncillary = (e, id) => {
        let selectedAncillary = this.state.selectedAncillary;
        let selectedAncillaryList = this.state.selectedAncillaryList;

        if (!selectedAncillary.includes(id)) {
            //Check
            selectedAncillary = selectedAncillary + ',' + id;
            selectedAncillaryList.push({AncillaryID:id,Qty:1});
            this.setState({ selectedAncillary: selectedAncillary });
            this.setState({ selectedAncillaryList: selectedAncillaryList });
            this.setState({
                [id]: true

            });
           
            this.getRateCalculation(this.state.PAI, this.state.CDW, this.state.SCDW,selectedAncillaryList);
        }
        else {
            //Uncheck

            let newSelectedAncillaryArray = [];
          
            let selectedAncillaryArray = selectedAncillary.split(',').forEach((item) => {
                if (item == id) {

                } else {
                    newSelectedAncillaryArray.push(item);
                }
            });
            let newSelectedAncillary = newSelectedAncillaryArray.join(',');
            this.setState({ selectedAncillary: newSelectedAncillary });

            let newSelectedAncillaryList = [];
            this.state.selectedAncillaryList.forEach((item)=>{
                if(item.AncillaryID == id){

                }else{
                    newSelectedAncillaryList.push(item);
                }
            });
            this.setState({ selectedAncillaryList: newSelectedAncillaryList });
            this.setState({
                [id]: false
            });
            this.getRateCalculation(this.state.PAI, this.state.CDW, this.state.SCDW,newSelectedAncillaryList);
        }
        
    }

    getSelectedTyp1Ancillary =(e)=>{
      
      
        let selectedAncillaryList = this.state.selectedAncillaryList;

        let newSelectedAncillaryList = [];
        this.state.selectedAncillaryList.forEach((item)=>{
            if(item.AncillaryID == e.target.id){

            }else{
                newSelectedAncillaryList.push(item);
            }
        });
        if(e.target.value != 0){
            newSelectedAncillaryList.push({AncillaryID:e.target.id,Qty:e.target.value});
        }
        this.setState({ selectedAncillaryList: newSelectedAncillaryList });
        this.getRateCalculation(this.state.PAI, this.state.CDW, this.state.SCDW,newSelectedAncillaryList);

    }
    getSelectedInsurance = (e, type) => {     
       
        let selectedInsurnace = this.state.selectedInsurnace;

        if (!selectedInsurnace.includes(type)) {
            //Check
            selectedInsurnace = selectedInsurnace + ',' + type;

            this.setState({ selectedInsurnace: selectedInsurnace });
           
            //Call Get Rate Calculation
            let typeProt = '';
            if (type == 0) {

                typeProt = "PAI";
                this.getRateCalculation(true, this.state.CDW, this.state.SCDW,this.state.selectedAncillaryList);
            }
            else if (type == 1) {
                typeProt = "CDW";

                //Check if CDW is selected and uncheck it
                this.setState({SCDW:false});

                let newSelectedInsurnaceArray = [];
                let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
                    if (2 == item) {
    
                    } else {
                        newSelectedInsurnaceArray.push(item);
                    }
                });
                let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
                this.setState({ selectedInsurnace: newSelectedInsurnace });
                this.getRateCalculation(this.state.PAI, true, false,this.state.selectedAncillaryList);

                
            }
            else if (type == 2) {
                typeProt = "SCDW";
               
                //Check if CDW is selected and uncheck it
                this.setState({CDW:false});

                let newSelectedInsurnaceArray = [];
                let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
                    if (1 == item) {
    
                    } else {
                        newSelectedInsurnaceArray.push(item);
                    }
                });
                let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
                this.setState({ selectedInsurnace: newSelectedInsurnace });
                this.getRateCalculation(this.state.PAI, false, true,this.state.selectedAncillaryList);
           
            }

            this.setState({
                [typeProt]: true
            });

           

        } else {

            //Uncheck
            let typeProt = '';
            let newSelectedInsurnaceArray = [];
            //alert(selectedInsurnace);
            let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
                if (item == type) {

                } else {
                    newSelectedInsurnaceArray.push(item);
                }
            });
            let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
            //alert(newSelectedInsurnace);
            this.setState({ selectedInsurnace: newSelectedInsurnace });
           

            if (type == 0) {
                typeProt = "PAI";
                this.getRateCalculation(false, this.state.CDW, this.state.SCDW,this.state.selectedAncillaryList);
            }
            else if (type == 1) {
                typeProt = "CDW";
                this.getRateCalculation(this.state.PAI, false, this.state.SCDW,this.state.selectedAncillaryList);
            }
            else if (type == 2) {
                typeProt = "SCDW";
                this.getRateCalculation(this.state.PAI, this.state.CDW, false,this.state.selectedAncillaryList);
            }

            this.setState({
                [typeProt]: false
            });

           

        }
    }
    removeSeat = () => {

        const seat = this.state.seat;
        if (seat > 1) {
            const counter = seat - 1;
            this.setState({
                seat: counter
            });
        }
    }
    addSeat = () => {

        const seat = this.state.seat;
        if (seat < 3) {
            const counter = seat + 1;
            this.setState({
                seat: counter
            });
        }


    }

    onContinueClick = () => {
        this.props.onContinueClick(this.props.searchParam);
    }

    
    render() {
       
        const { t } = this.props
        var startDate = moment(this.props.searchParam.CheckOutOn);
        var endDate = moment(this.props.searchParam.CheckInOn);

        return (
            <>
                <div>
                    <section className="box-shadow-white-box-section">
                        <div className="container-fluid">
                            <div className="box-shadow-white-box">
                                <div className="pickup-location-box--wrap">
                                    <div className="row">
                                        <div className="col-sm-6 col-12">
                                            <div className="pickup-location-box">
                                                <span className="pickup-sm-title">{t('Pickup Location.1')}</span>
                                                <div className="pickup-location-flex">
                                                    <div className="pickup-location-flex-img">
                                                        <img src={this.props.additionalParam.dropOffLocationImage} alt="Dollar Pickup Location" style={{ width: '70px', height: '70px' }} />
                                                    </div>
                                                    <div className="pickup-location-flex-content">
                                                        <h3>{this.props.additionalParam.dropOffLocationName}</h3>
                                                        <address>
                                                            {this.props.additionalParam.dropOffLocationAddress}<br></br>
                                                            {moment(this.props.searchParam.CheckOutOn).format("DD MMM YYYY HH:mm")}
                                                        </address>

                                                        <address style={{color:"red"}}>
                                                            {this.props.additionalParam.dropOffLocationMessage}
                                                            <a title="View Details" className="text-16 text-red font-bold d-block" onClick={() => this.toogleViewInsurancePopUp(true, this.props.additionalParam.dropOffLocationBranchDescription)}>
                                                                {t('View Details.1')}
                                                            </a>
                                                        </address>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12 mt-4 mt-sm-0">
                                            <div className="pickup-location-box">
                                                <span className="pickup-sm-title">{t('Return Location.1')}</span>
                                                <div className="pickup-location-flex">
                                                    <div className="pickup-location-flex-img">
                                                        <img src={this.props.additionalParam.pickupLocationImage} alt="Dollar Pickup Location" style={{ width: '70px', height: '70px' }} />
                                                    </div>
                                                    <div className="pickup-location-flex-content">
                                                        <h3>{this.props.additionalParam.pickupLocationName}</h3>
                                                        <address>
                                                            {this.props.additionalParam.pickupLocationAddress}<br></br>
                                                            {moment(this.props.searchParam.CheckInOn).format("DD MMM YYYY HH:mm")}
                                                        </address>
                                                        <address style={{color:"red"}}>
                                                            {this.props.additionalParam.pickupLocationMessage}
                                                            <a title="View Details" className="text-16 text-red font-bold d-block" onClick={() => this.toogleViewInsurancePopUp(true, this.props.additionalParam.pickupLocationBranchDescription)}>
                                                                {t('View Details.1')}
                                                            </a>
                                                            
                                                        </address>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="box-shadow-white-box-section">
                        <div className="container-fluid">
                            <div className="box-shadow-white-box">
                                <div className="row">
                                    <div className="col-xl-4 col-md-4 col-sm-6 col-12">
                                        <h5 className="font-bold mb-1">{this.props.selectVehicleForBooking.Name}</h5>
                                        <p className="text-16 opacity-50 text-black">{t('or Similar.1')} | {this.props.selectVehicleForBooking.VehicleClassification.Name}</p>
                                        <div className="img-details-wrap">
                                            <img src={this.props.selectVehicleForBooking.VehicleImages.FrontImageAttachment.ImageString} alt="Dollar Vehicle Classification" width="389" height="245" />
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-md-3 col-sm-6 col-12  mt-sm-0 mt-4">
                                  
                                        <div className="w-50 mx-auto">
                                        <h3 className="text-16 text-red mb-3">{t('Total.1')} {this.props.selectVehicleForBooking.RentalDays} {t('days.1')}</h3> 
                                            <h5 className="text-16 text-red font-bold mb-3">{t('Features.1')}</h5>
                                            
                                            <VehicleFeatureBoxVertical model={this.props.selectVehicleForBooking}/>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-5 col-sm-7 col-12 mt-md-0 mt-4">
                                        <div className="included-wrapper-featured mt-0 pt-0 border-0">
                                            <h5 className="text-16 text-red font-bold mb-3">{t('Included.1')}</h5>
                                            {(this.state.includeSection!=null)?this.state.includeSection.map((include)=>{
                                                return(<div key={include.ShortText}>
                                                <ul className="check-box-listing">
                                                <li>
                                                    <span>
                                                     
                                                        {include.ShortText}   
                                                        <a  className="text-16 text-red font-bold d-block" onClick={() => this.toogleViewIncludeLongTextPopUp(true, include.LongText)}>
                                                                {t('Details.1')}
                                                        </a>                                              
                                                    </span>
                                                   
                                                    
                                                    
                                                </li>
                                               
                                              
                                              
                                            </ul>
                                                </div>)
                                            }):""}
                                            
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-md-12 col-sm-3 col-12 mt-md-0 mt-4">
                                        <ul className="included-wrapper-featured-pricing text-16 text-blue text-right">
                                            <li>
                                              
                                                {t('AED.1')} <span className="font-bold h4 text-blue">{(this.state.calculationDetails.RentalTotal != null)?this.state.calculationDetails.RentalTotal.toFixed(2):null}</span> 
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="box-shadow-white-box-section">
                        <div className="container-fluid">
                            <div className="box-shadow-white-box">
                                <div className="box-title-icon-border d-flex align-items-center mb-4">
                                    <span className="box-title-icon-rounded mt-0">
                                        <img src={protection} alt="Dollar Protection" width="13" height="16" />
                                    </span>
                                    <h4 className="text-16 text-red">{t('Choose Your Protection.1')}</h4>
                                </div>
                                <div className="row">
                                    {
                                        this.state.insuranceList.map(insurance => {
                                           

                                            return (

                                                <div className="col-xl-3 col-md-4 col-12 border-right" key={insurance.InsuranceType}>

                                                    <div class="view-details-box-wrap custom-switch custom-control w-100 px-3">

                                                        <label className="custom-control-label1 w-100" htmlFor="personal-accident-insurance">
                                                            <h5 className="text-18">
                                                                {insurance.Name}
                                                            </h5>

                                                            <span className="text-16 font-bold opacity-50 d-block my-1">
                                                                {this.state.currencyCode}{ " " + insurance.Rate} {insurance.RateType == 3 ? '/Rental': insurance.RateType == 0 ?'/Day':insurance.RateType == 1 ? '/Week' : insurance.RateType == 2 ? '/Month': ""}
                                                            </span>
                                                            
                                                            <a title="View Details" className="text-16 text-red font-bold d-block" onClick={() => this.toogleViewInsurancePopUp(true, insurance.Description)}>
                                                                {t('View Details.1')}
                                                            </a>

                                                        </label>
                                                        <Switch
                                                                className={this.state.selectedInsurnace.includes( insurance.InsuranceType)? "custom-switch-wrap custom-switch-active-wrap" : "custom-switch-wrap"} value={this.state.selectedInsurnace.includes( insurance.InsuranceType) ? insurance.InsuranceType : ""}
                                                                on={insurance.InsuranceType}
                                                                onChange={(e) => this.getSelectedInsurance(e, insurance.InsuranceType,insurance.RateType)}

                                                                
                                                            />
                                                    </div>
                                                </div>


                                            )
                                        })
                                    }

                                    <div className="col-xl-2 col-12 ml-auto">
                                        <div className="text-16 text-blue text-right d-flex justify-xl-content-center justify-content-end mt-3 mt-xl-0">
                                            {t('AED.1')}&nbsp;<span className="font-bold h4 text-black">{(this.state.calculationDetails.InsuranceTotal !=null)?this.state.calculationDetails.InsuranceTotal.toFixed(2):null}</span> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="box-shadow-white-box-section">
                        <div className="container-fluid">
                            <div className="box-shadow-white-box">
                                <div className="box-title-icon-border d-flex align-items-center mb-4">
                                    <span className="box-title-icon-rounded mt-0">
                                        <img src={protection} alt="Dollar Extas" width="13" height="16" />
                                    </span>
                                    <h4 className="text-16 text-red">{t('Extras.1')}</h4>
                                </div>
                                <div className="row mb-3">

                                    {
                                        this.state.ancillaryList.map(ancillary => {
                                         
                                            return (<>
                                                       
                                                <div className="col-xl-5 col-md-6 col-12" key={ancillary.AncillaryTariffID}>
                                                    <div className="extra-box-range-wrap">
                                                        <div className="extra-box-range-box mr-3">
                                                            <img src={ancillary.Attachment.ImageString} width="27" alt="Dollar Ancillary" />
                                                        </div>
                                                        <div className="extra-box-range-box-content">
                                                            <h5 className="text-18">
                                                                {ancillary.Name}
                                                                
                                                            </h5>
                                                            <span className="text-16 font-bold opacity-50 d-block my-1">
                                                                {this.state.currencyCode}{ " " + ancillary.Rate} {ancillary.RateType == 3 ? '/Rental': ancillary.RateType == 0 ?'/Day':ancillary.RateType == 1 ? '/Week' : ancillary.RateType == 2 ? '/Month': ""}
                                                            </span>
                                                            <a title="View Details" className="text-16 text-red font-bold d-block" onClick={() => this.toogleViewExtrasPopUp(true, ancillary.Description)}>
                                                                {t('View Details.1')}
                                                            </a>
                                                        </div>
                                                        <div className="input-quantity ml-auto">

                                                            {
                                                                ancillary.SelectionType == 1 ?
                                                                    <Switch
                                                                        className={this.state.selectedAncillary.includes(ancillary.AncillaryTariffID) ? "custom-switch-wrap custom-switch-active-wrap":"custom-switch-wrap"} 
                                                                        // value="1"
                                                                        // on="on"
                                                                        value={this.state.selectedAncillary.includes(ancillary.AncillaryTariffID) ? ancillary.AncillaryTariffID : ""}
                                                                        on={ancillary.AncillaryTariffID}
                                                                        onChange={(e) => this.getSelectedAncillary(e, ancillary.AncillaryTariffID)}
                                                                        disabled ={ancillary.IsCompulsoryOnRental}
                                                                        title={t("You can't change this ancillary.1")}

                                                                    />
                                                                    : <select name="ancillary-type" className="baby-seat-select-box" id={ancillary.AncillaryTariffID} onChange={(e)=>this.getSelectedTyp1Ancillary(e)} >
                                                                        {
                                                                            ancillary.IsCompulsoryOnRental == false ? <option value="0" >{t('0.1')}</option> : null
                                                                        }
                                                                        <option  value="1">{t('1.1')}</option>
                                                                        <option value="2" >{t('2.1')}</option>
                                                                        <option value="3" >{t('3.1')}</option>
                                                                     
                                                                    </select>}

                                                        </div>
                                                    </div>

                                                </div>

                                            </>)
                                        })
                                    }
                                    <div className="col-xl-2 col-12 ml-auto">
                                        <div className="text-16 text-blue text-right mt-3 mt-xl-0">
                                            {t('AED.1')}&nbsp;<span className="font-bold h4 text-blue">{(this.state.calculationDetails.AncillaryTotal != null)?this.state.calculationDetails.AncillaryTotal.toFixed(2):null}</span> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="grandtotal-section">
                        <div className="container-fluid">
                            <div className="grand-total-wrap  bg-red">
                                <div className="row justify-content-end align-items-center">
                                    <div className="col-xl-2 col-md-2 col-sm-4 col-12 text-sm-left text-right">
                                        <p className="text-white text-18">{t('Grand Total.1')}</p>
                                    </div>
                                    <div className="col-xl-2 col-md-3 col-sm-4 col-12 mt-sm-0 mt-3">
                                        <div className="text-16 text-white text-right">
                                            {t('AED.1')}&nbsp;<span className="font-bold h4 text-white">{(this.state.calculationDetails.TotalAmount != null)?this.state.calculationDetails.TotalAmount.toFixed(2):null}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <a title="Continue" className="continue-bttn" onClick={this.onContinueClick}>
                                    {t('Continue.1')}
                                </a>
                            </div>
                        </div>
                    </section>
                </div>
                {
                    this.state.showInsuranceViewDetails?
                    <div>
                        <div className="modal-dialog modal-xl modal-dialog-centered ">
                            <Modal show={this.state.showInsuranceViewDetails} onHide={() => this.toogleViewInsurancePopUp(false)} dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap">
                                <ViewInsuranceDetails insuranceDesriptionToShow={this.state.insuranceDesriptionToShow} />
                            </Modal>
                        </div>
                    </div>
                    :null
                }
               {
                   this.state.showRentalDetails?
                   <div>
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <Modal show={this.state.showRentalDetails} onHide={() => this.toogleViewRentalPopUp(false)} dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap">
                            <ViewRentalDetails />
                        </Modal>
                    </div>
                </div>
                   :null
               }
                {
                    this.state.showExtrasDetails?
                <div>
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <Modal show={this.state.showExtrasDetails} onHide={() => this.toogleViewExtrasPopUp(false)} dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap">
                            <ViewExtrasDetails descriptionToShow={this.state.extrasDesriptionToShow} />
                        </Modal>
                    </div>
                </div>
                    :null
                }
                {
                    this.state.showExtrasDetails?
                <div>
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <Modal show={this.state.showExtrasDetails} onHide={() => this.toogleViewIncludeLongTextPopUp(false)} dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap">
                            <ViewIncludeLongText LongTextShow={this.state.LongTextShow} />
                        </Modal>
                    </div>
                </div>
                    :null
                }
                
            </>
        );
    }
}



export default hoistStatics(withTranslation()(MonthlyListinPage2), MonthlyListinPage2);