import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getLanguage } from '../Common/Util';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


var qs = require('qs');

class SpecialOfferBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            specialoffer: {},
            selectedPickupDate: null,
            selectedReturnDate: null,
            allBranches: [],
            selectedPicupLocation: null,
            selectedDestinationlocation: null,
            rentalDays: '',
            alert_message: "",
            loading: false,
            errorMessage: '',
            timeList: [],
            promocode: '',
            pickupTime: "09:00",
            returnTime: "09:00",


        }
    }

    getTimeSlots = () => {
        axios(process.env.REACT_APP_API + 'Branch/GetTimeSlots?apiKey=' + process.env.REACT_APP_API_KEY).then((response) => {
            this.setState({ timeList: response.data });
        });
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onPickupTimeChange = (e) => {

        this.setState({ pickupTime: e.target.value });
        this.setState({ returnTime: e.target.value });
    }

    onChangePickUpDate = (update) => {
        //console.log(update);
        this.setState({ selectedPickupDate: update[0] });
        this.setState({ selectedReturnDate: update[1] });

    }

    onChangeReturnDate = (date) => {
        this.setState({ selectedReturnDate: date });
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;
        let lngParam = getLanguage();

        this.getAllBranches(lngParam);

        this.getTimeSlots();

        this.setState({
            SpecialOfferID: params.index
        });

        this.setState({
            pickupTime: moment(this.props.PickUpDate).format("HH:mm")
        });
    }


    getAllBranches = (lngParam) => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API + 'Branch/Get?apiKey=' + process.env.REACT_APP_API_KEY + `&lng=${lngParam}`,
            headers: {}
        };

        axios(config)
            .then((response) => {
                this.setState({
                    allBranches: response.data.sort((a, b) => (a.BranchType.Name > b.BranchType.Name) ? -1 : 1)
                });

                if (response.data.length > 0) {

                    this.onChangePickUplocation(null, response.data[0]);
                }
            })
            .catch((error) => {
            });
    }


    onChangePickUplocation = (event, value) => {

        this.setState({ selectedPicupLocation: value });
        this.setState({ selectedDestinationlocation: value });


        var config = {
            method: 'get',
            url: process.env.REACT_APP_API + `Branch/GetBranchHrsToGetReady?apiKey=` + process.env.REACT_APP_API_KEY + `&branchID=${value.Identity.Id}`,
            headers: {}
        };

        axios(config)
            .then((response) => {
                console.log();
                this.setState({ selectedPickupDate: moment(response.data.PickupOn)._d });
                this.setState({ selectedReturnDate: moment(response.data.ReturnOn)._d });




                this.setState({ pickupTime: moment.tz(response.data.PickupOn, "Asia/Dubai").format('hh:mm') });
                this.setState({ returnTime: moment.tz(response.data.ReturnOn, "Asia/Dubai").format('hh:mm') });

            })
            .catch((error) => {

            });

    }


    onChangeDropUpLocation = (event, value) => {

        this.setState({
            selectedDestinationlocation: value
        });

    }

    onInputChange = (e) => {
        this.setState({ rentalDays: e.target.value });
    }

    validateBooking = () => {
        //Validate      
        let lngParam = getLanguage();

        if (this.state.selectedPicupLocation === null || this.state.selectedPicupLocation === undefined) {

            if (lngParam == "en") {
                this.setState({ errorMessage: "Please select Pickup Location" });
            }
            else {
                //ar
                this.setState({ errorMessage: "الرجاء تحديد بيك اب الموقع" });
            }

            return false;
        }

        if (this.state.selectedDestinationlocation === null || this.state.selectedDestinationlocation === undefined) {

            if (lngParam == "en") {
                this.setState({ errorMessage: "Please select Return Location" });
            }
            else {
                //ar
                this.setState({ errorMessage: "الرجاء تحديد return الموقع" });
            }

            return false;
        }
        if (this.state.promocode === undefined || this.state.promocode === '') {

            if (lngParam == "en") {
                this.setState({ errorMessage: "Please enter promocode" });
            }
            else {
                //ar
                this.setState({ errorMessage: "الرجاء إدخال بالشفرة الترويجية اعتمادا" });
            }

            return false;
        }

        return true;
    }

    handleClose = () => {
        this.setState({
            errorMessage: ""
        });
    }
    onBookNowClick = (SpecialOfferID) => {

        const { match: { params } } = this.props;
        let lngParam = getLanguage();


        if (this.validateBooking()) {
            let selectedPickupDate = moment(moment(this.state.selectedPickupDate).format('MM/DD/YYYY') + " " + this.state.pickupTime);
            selectedPickupDate = moment(selectedPickupDate).format('MM/DD/YYYY  h:mm:ss A');

            let selectedReturnDate = moment(moment(this.state.selectedReturnDate).format('MM/DD/YYYY') + " " + this.state.returnTime);
            selectedReturnDate = moment(selectedReturnDate).format('MM/DD/YYYY  h:mm:ss A');

            var data = qs.stringify({
                "Classifications": [],
                "VehicleMakeID": "00000000-0000-0000-0000-000000000000",
                "VehicleModelID": "00000000-0000-0000-0000-000000000000",
                "SpecialOfferID": "00000000-0000-0000-0000-000000000000",
                "ModelYear": 0,
                "Passengers": 0,
                "AutoTransmission": 1,
                "CheckOutOn": moment(this.state.selectedPickupDate).format('DD MMM YYYY, h:mm A'),
                "CheckInOn": moment(this.state.selectedReturnDate).format('DD MMM YYYY, h:mm A'),
                "DeliveryDetails": {
                    "Address": "",
                    "AddressJson": ""
                },
                "PickupDetails": {
                    "Address": "",
                    "AddressJson": ""
                },
                "CheckOutBranchID": this.state.selectedPicupLocation.Identity.Id,
                "CheckInBranchID": this.state.selectedDestinationlocation.Identity.Id,
                "PromoCode": this.state.promocode,
                "DriverAge": 21,
                "MinRentalRate": 0,
                "MaxRentalRate": 50000,
                "RentalDays": 0,
                "NoOfRecords": 0
            });
            var config = {
                method: 'post',
                url: process.env.REACT_APP_API + `ReservationValidation/Validate?apiKey=${process.env.REACT_APP_API_KEY}&lng=${lngParam}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
            axios(config)
                .then((response) => {
                    if (response.data.IsValid === true) {

                        var BookingInfo = [{
                            "PickupLocationId": this.state.selectedPicupLocation.Identity.Id,
                            "DestinationLocationId": this.state.selectedDestinationlocation.Identity.Id,
                            "PickUpDate": moment(this.state.selectedPickupDate).format('DD MMM YYYY, h:mm A'),
                            "ReturnDate": moment(this.state.selectedReturnDate).format('DD MMM YYYY, h:mm A'),
                            "pickupLocationName": this.state.selectedPicupLocation.Name,
                            "dropOffLocationName": this.state.selectedDestinationlocation.Name,
                            "pickupLocationImage": this.state.selectedPicupLocation.Attachment.ImageString,
                            "dropOffLocationImage": this.state.selectedDestinationlocation.Attachment.ImageString,
                            "pickupLocationAddress": this.state.selectedPicupLocation.Address,
                            "dropOffLocationAddress": this.state.selectedDestinationlocation.Address,
                            "pickupLocationMessage": this.state.selectedPicupLocation.Message,
                            "dropOffLocationMessage": this.state.selectedDestinationlocation.Message,
                            "pickupLocationBranchDescription": this.state.selectedPicupLocation.Description,
                            "dropOffLocationBranchDescription": this.state.selectedDestinationlocation.Description,
                            "promocode": this.state.promocode
                        }];

                        localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
                        window.location = "/booking";

                    } else {
                        this.setState({
                            errorMessage: response.data.Message
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        errorMessage: "Error while booking"
                    });
                });
        }
    }

    render() {
        const { t } = this.props;
        return (

            <div className="col-lg-5  bg_custom  book-deal-content-section" id="book">
                <div className="px-3  ">
                    <h5 className="font-weight-bold pt-3">{t('Book a deal.1')}</h5>
                    <div className="form-group mt-4 pickup-drop-location-wrap">
                        <label htmlFor="exampleInputEmail1" className="book-text"><h6 className="font-weight-bold">{t('Pickup Location.1')}</h6></label>

                        <Autocomplete

                            id="grouped-demo1"
                            value={this.state.selectedPicupLocation}
                            options={this.state.allBranches}
                            groupBy={(option) => option.BranchType.Name}
                            getOptionLabel={(option) => option.Name}
                            style={{ width: "fit-content" }}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={this.onChangePickUplocation}
                            className="select-wrap-dropdown"

                        />


                    </div>
                    <div className="form-group pickup-drop-location-wrap">
                        <label htmlFor="exampleInputEmail1" className="book-text"><h6 className="font-weight-bold">{t('Return Location.1')}</h6></label>

                        <Autocomplete

                            id="grouped-demo2"
                            value={this.state.selectedDestinationlocation}
                            options={this.state.allBranches}
                            groupBy={(option) => option.BranchType.Name}
                            getOptionLabel={(option) => option.Name}
                            style={{ width: "fit-content" }}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={this.onChangeDropUpLocation}
                            className="select-wrap-dropdown"

                        />


                    </div>
                </div>
                {/* className="mt-0"  */}
                <div className="px-3 pickup-dropoff-date-wrap1">
                    <div className="row" >
                        <div className="col col-lg-12">
                            <div className="form-group " >
                                <h6 className="font-weight-bold mb-2">{t('Pickup & Drop off Date.1')}</h6>


                                <DatePicker
                                    selectsRange={true}
                                    startDate={this.state.selectedPickupDate}
                                    endDate={this.state.selectedReturnDate}
                                    onChange={(update) => {
                                        this.onChangePickUpDate(update);
                                    }}
                                    isClearable={true}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="row" >
                        <div className="col col-lg-12">
                            <div className="form-group " >
                                <div className="pickup-location-input group-input pickup-drop-time-wrap">
                                    <label htmlFor="pickup-date-time" class=" mb-2">
                                        <h6 className="font-weight-bold">
                                            {t('Pickup & Drop off Time.1')}
                                        </h6>
                                    </label>
                                    <select name="pickupTime" className="time-box" id="pickup-date-time" onChange={this.onPickupTimeChange} value={this.state.pickupTime} >
                                        {
                                            this.state.timeList.map((timeSlot) => {
                                                return (
                                                    <option value={timeSlot}>{timeSlot}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    &nbsp;
                                    <select name="returnTime" className="time-box" id="pickup-date-time" onChange={this.onChange} value={this.state.returnTime} >
                                        {
                                            this.state.timeList.map((timeSlot) => {
                                                return (
                                                    <option value={timeSlot}>{timeSlot}</option>
                                                )
                                            })
                                        }

                                    </select>

                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="form-group pickup-date-time mb-2 promo-code-wrap">
                                <label htmlFor="exampleInputEmail1" className="book-text special-offer-pickup-location ml-0 pl-0 mb-2"><h6 className="font-weight-bold">{t('Promo Code.1')}</h6></label><span style={{ color: 'red' }}>*</span>
                                <input className="form-control" id="promocode" name="promocode" type="text" onChange={this.onChange} value={this.state.promocode} placeholder={t('Promo Code.1')} />

                            </div>
                        </div>
                    </div>

                    <a className="rounded-bttn rounded-bttn-transparent w-40 text-center" title="book" onClick={() => this.onBookNowClick(this.state.specialoffer.SpecialOfferID)}>{t('BOOK NOW.1')}</a>
                    <div className="col-md-12">

                        {this.state.errorMessage != null && this.state.errorMessage.length > 0 ?
                            <Dialog
                                open={this.state.errorMessage.length > 0}
                                onClose={this.handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Dollar"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {this.state.errorMessage}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose} color="primary">
                                        {t('OK.1')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            : null}

                    </div>
                </div>
            </div>

        );
    }
}

export default withTranslation()(withRouter(SpecialOfferBooking));