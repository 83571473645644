import React, { Component } from 'react';
import { Modal, Spinner, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getLanguage } from '../Common/Util';
import ErrorAlert from '../Common/AlertError/ErrorAlert';
import DollarImage from '../assets/images/login-Image@2x.png';
import ConfirmationBooking from '../Common/ConfirmationBooking';
class ReservationCancelPopUp extends Component {
    constructor(props) {
        super(props);
        this.state={
            remark:'',
            reasonId:0,
            errorMessage:'',
            loading: false,
            Reasons:[],
            ReasonID:0,
            Data: {},
        }
        
    }
    onReasonChange = (e) => {      
        this.setState({
            ReasonID: e.target.value
        });
    }

    componentDidMount(){

        this.handleSelect(0);

        var axios = require('axios');

        var config = {
            method: 'get',
            url: process.env.REACT_APP_API+'Reason/Get?apiKey='+process.env.REACT_APP_API_KEY,
            headers: {}
        };

        axios(config)
            .then( (response) =>{
                this.setState({Reasons: response.data});
            })
            .catch( (error) =>{
                
            });
        this.setState({                    
                Data : this.props.ConfirmationData
        });
    }

    handleSelect = (index) => {
        this.setState({ avaliableindex: index });
    }

    validate = () => {
        //Validate      
        let lngParam = getLanguage();
        let valid = true;
        if (this.state.remark.trim() == '') {

            if (lngParam == "en") {
                this.setState({ errorMessage: "The remark field is required" });
            }
            else {
                //ar
                this.setState({ errorMessage: "مطلوب حقل الملاحظات" });
            }
            
            return false;
        }
        if (this.state.ReasonID == 0) {

            if (lngParam == "en") {
                this.setState({ errorMessage: "The reason is required" });
            }
            else {
                //ar
                this.setState({ errorMessage: "The reason is required" });
            }
            
            return false;
        }
        
    return valid
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onCancelClick = ()=>{
        if(this.validate()){        
        var axios = require('axios');
        var config = {
            method: 'post',            
            url: process.env.REACT_APP_API +'Reservations/CancelReservation?apiKey='+process.env.REACT_APP_API_KEY + `&reservationId=${this.props.ReservationConfirmId}&remark=${this.state.remark}&reasonID=${this.state.ReasonID}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
        };
        this.setState({
            loading: true
        });
        axios(config)
            .then( (response) =>{
                if(response.data.IsValid == true){
                    this.setState({
                        loading: false
                    });
                    localStorage.setItem("cancelInformation", JSON.stringify(response.data))
                    window.location.reload();
                }
                
            })
            .catch( (error) =>{
               
            });
        }
    }
      
    render() {

        const { t } = this.props;
        return (
            <div>
           
            <div id="LoginFormPopup" className=" login-container">            
                <div className="login-first-row p-4">  
                <div className='row'>                     
                       <div className='col-12'>
                            <Modal.Header className="p-0 border-0 modal-header" closeButton>
                                <h2 class="border-botom">{t('Cancel Reservation.1')}</h2>
                            </Modal.Header>
                        </div>
                        <div className="col-6 login-card">
                        <div className="row pt-3">
                            <div className="col-12 faq-wrapper">
                            <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                            <div className="box-shadow-white-box py-3 px-4">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a style={{ color: '#C8102E' }} className="accordion-toggle collapsed font-bold" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" onClick={() => { this.handleSelect(0) }}>
                                                    {t('Cancellation Policy.1')}
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseThree" className="panel-collapse collapse">
                                            <div className="panel-body tab-term-content">
                                            <p>
                                               1. Cancellations of rentals with less than 48 hours’ notice from Pick up Date, Dollar Car Rental UAE will charge an Administration fee of AED: 105
                                                </p>
                                                <p>
                                               2. Cancellations of rentals with more than 48 hours’ notice from Pick up Date are Free of Charge
                                                </p>
                                                <p>
                                               3. Refunds will be made to the credit card that the original booking was made on within 21 working days
                                                </p>
                                                <p>
                                                4. Any booking that is cancelled on the same day of pick-up due to invalid documents, etc., Dollar Car Rental UAE will charge an Administration fee of AED: 157.50
                                                </p>                        
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                            </div>


                                        <div className="col-12">
                                            <div className="form-group custom-input-type-wrap">
                                                <label><h6 class="font-weight-bold mb-0 text-black">{t('Reason for Cancellation.1')}</h6></label>
                                                <select name="seviceType" className="filter-box" defaultValue={'Reason for Cancellation'} onChange={this.onReasonChange}>
                                                <option value="Reason for Cancellation">{t('Reason for Cancellation.1')}</option>
                                                    {
                                                        this.state.Reasons.map((reason) =>
                                                            <option key={reason.ReasonID} value={reason.ReasonID} >{reason.Title}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group custom-input-type-wrap">
                                                <label><h6 class="font-weight-bold mb-0 text-black">{t('More Details.1')}</h6></label>
                                                <textarea className="form-control" id="remark" maxLength="256" name="remark" tabIndex="9" type="text" onChange={this.onChange} value={this.state.remark} />

                                            </div>
                                        </div>
                                        <div className="col-12">
                                        {this.state.loading == false ? <a title="Cancel" className="book-vehicle-name-btn mw-100 text-center" onClick={this.onCancelClick}>{t('Confirm Cancellation.1')}</a>
                                            : null}
                                    </div><br/>
                                    <div className="col-12">

                                    {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? <ErrorAlert errorMessage={this.state.errorMessage} /> : null}
                                        {this.state.loading == true ? <div style={{ verticalAlign: 'center' }}>
                                            <Spinner animation="border" />&nbsp; {t('Cancelling the reservation.1')}
                                        </div> : null}
                                    </div>                                       
                            </div>
                        </div>
                        <div className="col-6 login-card">
                            <ConfirmationBooking ConfirmationData={this.state.Data} />
                        </div>
                    </div>
                </div>
                </div>
            
        </div>
        );
    }
}

export default withTranslation()(withRouter(withRouter(ReservationCancelPopUp)));