
export function getUserInfo(){
    if (localStorage.getItem('userinfo') !== null) {
        const userinfo = JSON.parse(localStorage.getItem('userinfo'));
        return userinfo;

    }else{
        return null;
    }
}


export function getCustomerId() {
    const userInfo = getUserInfo();   
    if (userInfo == null) {

        return "00000000-0000-0000-0000-000000000000";
    }
    else {
        return userInfo.CustomerID;
    }
}


export function getLanguage(){
    const language = localStorage.getItem("i18nextLng");
    let lngParam = language == "UAE" ? "ar" : "en";
    return lngParam;
}

export function getCancellationInformation(){
    if (localStorage.getItem('cancelInformation') !== null) {
        const cancellationInfo = JSON.parse(localStorage.getItem('cancelInformation'));
        return cancellationInfo;

    }else{
        return null;
    }
}

export function getClearCancellationInformation(){
        localStorage.setItem('cancelInformation',null);
}

export function getCurrency(){
    if (localStorage.getItem('currency') !== null) {
        return localStorage.getItem("currency");
    }
    else{
        var axios = require('axios');
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API + `BookingSetting/Get?apiKey=` + process.env.REACT_APP_API_KEY,
            headers: {}
        };

        axios(config)
            .then((response) => {
                localStorage.setItem('currency',response.data.CurrencyCode)
                return localStorage.getItem("currency");
            })
            .catch((error) => {

            });

    }
}
