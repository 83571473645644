import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { getLanguage } from "../../Common/Util";
import buildingImg from "../../assets/images/building.svg";
import closeXImg from "../../assets/images/closeX.svg";
import mapPinImg from "../../assets/images/map-pin.svg";
import plainImg from "../../assets/images/plain.svg";
import "./tabsStyle.css";
import { branchInstance } from "../../axios/axiosInstance";

class PickUpPopup extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      selectedValue: {
        Name: "",
        Address: "",
        GoogleLocationURL: "",
        DayString: "",
        Shifts: [],
      },
      allBranches: [],
      branchTypes: [],
      baseBranches: [],
      searchQuery: this.props.searchQuery || "",
      size: window.innerWidth,
      isActive: false,
      isComponentMounted: false,
      popupScroll: 0,
      isFirefox: false,
    };
    this.popUpRef = createRef();
  }

  containsLocation = (locations, id) => {
    var i = -0;
    for (i = 0; i < locations.length; i++) {
      if (locations[i]?.locationId?.toString() === id?.toString()) return true;
    }
    return false;
  };

  getBranches = async () => {
    // let lngParam = getLanguage();

    branchInstance
      .get("all") // need to add the country code as query
      .then((responce) => {
        console.log(responce);
        if (this.state.isComponentMounted) {
          const allBranches = responce.data.result;

          if (allBranches.length > 0) {
            const locations = [];
            allBranches.forEach((branch) => {
              if (
                !this.containsLocation(
                  locations,
                  branch?.branchType?.branchTypeID
                )
              ) {
                locations.push({
                  locationId: branch.branchType.branchTypeID,
                  name: branch.branchType.name,
                  imageString: branch.branchType.attachment.imageString,
                });
              }
            });

            this.setState({ branchTypes: locations });
            this.setState({ baseBranches: allBranches });
          }
          this.setState(
            {
              allBranches: allBranches,
            },
            () => {
              // this.prioritizeData();
            }
          );

          const {
            Name,
            Address,
            GoogleLocationURL,
            BranchOfficeTiming: {
              BranchTimings: [{ DayString, Shifts }],
            },
          } = allBranches[0];

          this.handleMouseEnter(
            Name,
            Address,
            GoogleLocationURL,
            DayString,
            Shifts
          );
        }
      })
      .catch((error) => {
        console.log("Error fetching branches", error);
      });
  };

  prioritizeData() {
    const priorityLevels = {
      "airport locations": 1,
      "1. airport locations": 1,
      "free delivery areas": 2,
      "2. free delivery areas": 2,
      "dubai branches": 3,
      "3. dubai branches": 3,
      "abu dhabi": 4,
      "4. abu dhabi": 4,
      "sharjah branches": 5,
      "5. sharjah branches": 5,
      "al ain": 6,
      "6. al ain": 6,
    };

    // Convert branch type to lowercase
    const getPriority = (branch) => {
      const branchType = branch.branchType.name.toLowerCase();
      return (
        priorityLevels[branchType] ||
        priorityLevels[branchType.replace(/^\d+\.\s*/, "")]
      );
    };

    // Sort data based on priority levels
    const sortedData = this.state.allBranches.slice().sort((a, b) => {
      const priorityA = getPriority(a);
      const priorityB = getPriority(b);
      return priorityA - priorityB;
    });

    this.setState({ allBranches: sortedData });
  }

  componentDidMount() {
    this.setState({ isComponentMounted: true });
    this.inputRef.current.focus();
    this.getBranches();
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Firefox") > -1) {
      this.setState({ isFirefox: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.setState({ searchQuery: this.props.searchQuery });
    }

    if (this.props.popUpScrollPosition !== prevProps.popUpScrollPosition) {
      this.setState({ popupScroll: this.props.popUpScrollPosition });
    }

    if (this.state.popupScroll !== prevState.popupScroll) {
      if (this.popUpRef.current) {
        this.popUpRef.current.scrollTop = this.state.popupScroll;
      }
    }
  }

  componentWillUnmount() {
    this.setState({ isComponentMounted: false });
  }

  handleScroll = (event) => {
    event.stopPropagation();
  };

  handleSelectedValue = (Name) => {
    this.props.onSelectValue(Name);
  };

  handleMouseEnter = (Name, Address, GoogleLocationURL, DayString, Shifts) => {
    this.setState({
      selectedValue: { Name, Address, GoogleLocationURL, DayString, Shifts },
    });
  };

  render() {
    const { t } = this.props;
    const popupStyle = this.state.isFirefox
      ? { scrollbarWidth: "8px", scrollbarColor: "#E31A37 #ffffff" }
      : {};
    return (
      <>
        <div
          className="pickupReturnLocationPopup"
          style={{ height: this.props.handleHeight === 10 ? 0 : "auto" }}
        >
          <div className="mob-header mob">
            <div className="back">
              <h6>{t("Select Pickup Location.1")}</h6>
            </div>
            <div className="section-input-section">
              <div
                className={`input ${this.state.isActive && "active"}`}
                onClick={() => this.setState({ isActive: true })}
              >
                <div className="icon" style={{ marginBottom: 0 }}>
                  <img src={mapPinImg} alt="" />
                </div>
                <input
                  type="search"
                  defaultValue={this.props.searchInputBoxValue}
                  placeholder="Airport, City or Address"
                  ref={this.inputRef}
                  id="pickupAndReturnLocation"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ searchQuery: e.target.value });
                  }}
                />
                <div className="close">
                  <img
                    src={closeXImg}
                    alt=""
                    onClick={() => this.props.handlePopUpAirport(0)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="deliver-to-me-mob mob">
            <h6>{t("Want to delivery to you?.1")}</h6>
            <button onClick={() => this.props.handleChooseDelivery(true, 0)}>
              {t("Deliver to me.1")}
            </button>
          </div>
          <div className="inner-wrapper">
            <div className="left-section">
              {/* ref={this.popUpRef} scroll ref if it need to be used */}
              <ul
                className="selectedLocations"
                ref={this.popUpRef}
                style={popupStyle}
              >
                {this.state.allBranches
                  .filter((item) =>
                    item.name
                      .toLowerCase()
                      .includes(this.state.searchQuery.toLowerCase())
                  )
                  .map((item) => (
                    <li
                      key={item.identity.id}
                      onMouseEnter={() =>
                        this.handleMouseEnter(
                          item.name,
                          item.address,
                          item.googleLocationUrl
                          // item.branchOfficeTiming?.BranchTimings[0]?.DayString,  //not getting the value
                          // item.branchOfficeTiming?.BranchTimings[0]?.Shifts      //not getting the value
                        )
                      }
                      onClick={() => this.handleSelectedValue(item)}
                    >
                      <div className="icon" style={{ marginBottom: 3 }}>
                        <img
                          src={
                            item?.Name?.includes("Airport")
                              ? plainImg
                              : buildingImg
                          }
                          alt=""
                        />
                      </div>
                      <h6 className="drop-val">{item.name}</h6>
                    </li>
                  ))}
              </ul>

              <div className="bottom-section">
                <h6>
                  {t("Want to delivery to you?.1")}{" "}
                  <a onClick={() => this.props.handleChooseDelivery(true, 0)}>
                    {t("Choose deliver to me.1")}
                  </a>
                </h6>
              </div>
            </div>
            {this.state.size > 563 && (
              <div className="right-section-c">
                <div className="location-address">
                  <div className="icon">
                    <img
                      src={
                        this.state.selectedValue.Name.includes("Airport")
                          ? plainImg
                          : buildingImg
                      }
                      alt=""
                    />
                  </div>
                  <h6 id="updatePickedLocation">
                    <span id="selectedPickupLocation">
                      {this.state.selectedValue.Name}
                    </span>{" "}
                  </h6>

                  <span>{this.state.selectedValue.Address}</span>
                  <span style={{ display: "flex", gap: 2 }}>
                    {this.state.selectedValue.DayString}{" "}
                    {this.state.selectedValue?.Shifts?.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </span>
                </div>
                <div className="iframe-wrapper">
                  <iframe
                    title="map"
                    src={this.state.selectedValue.GoogleLocationURL}
                    width="100%"
                    height="600"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="show-car-btn-mob mob">
            <button
              className="primary-button"
              onClick={this.props.searchClickHandler}
            >
              {t("Continue.1")}
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(PickUpPopup);
