
import axios from 'axios';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage } from '../Common/Util';
import { animateScroll as scroll,scroller  } from 'react-scroll'

class News extends Component {
    constructor(props) {
        super(props);
        this.state={
            news :{}           
        }
    }
   
    componentDidMount = () => {
        const { match: { params } } = this.props;          
        let lngParam = getLanguage();
        this.getNews(params.index,lngParam);     
        scroll.scrollToTop(); 
    }

    getNews=(newsId ,lngParam)=>
    {
     
        var config = {
            method: 'get',            
            url:process.env.REACT_APP_API +'News/GetByID?apiKey='+ process.env.REACT_APP_API_KEY +`&newsID=${newsId}&lng=${lngParam}`,
            headers: {}
        };

        axios(config)
            .then( (response) =>{                
                this.setState({
                    news:response.data
                });
            })
            .catch( (error) => {                
            });
    }
    
    render() {    
        const { t } = this.props  
        return (
            <>
             <ListingHeader/>
               <div className="minH100 bg-grey">  
               <section className="know-us-page pt-5 pb-3 bg-grey">
                <div className="container">
                            <div>
                                <div className="">
                                <div className="row">
                        <div className="col-lg-7">
                        <div className="border-titlec-content mb-md-5 mb-4">
                        <h4 className="mb-1">{this.state.news.Title}</h4>
                        {
                                        (this.state.news.SourceUrl == "")?
                                        null:
                                        <a title={t('News Source.1')} href={this.state.news.SourceUrl} target="_blank"  class="text-link">{t('News Source.1')}</a>
                                    }     
                    </div>
                            <div>
                                <div className="font-18" dangerouslySetInnerHTML={{__html: this.state.news.Description}} />
                                {/* <p className="font-18 text-grey">Dollar Car Rental is a leading Fleet Management Solutions provider in the UAE. We take pride in serving major brands in the country, who choose to trust us every single day with their mobility needs. This includes using our up-to-date expert guidance on the management of their fleet - acquisition, resale, maintenance, vehicle tracking, fuel, accident management & more.</p>
                                <p className="font-18 text-grey mt-4">We understand how important it is to build a highly efficient fleet and the role it plays while growing one’s business. That is why, our sales consultants work on customized solutions for your business, that are tailor-made to your unique requirements. Our proven approach includes evaluating fleet performance and identifying opportunities for continuous enhancements & efficiency.</p> */}
                            </div>
                        </div>
                        <div className="col-lg-5 mt-4 mt-lg-0">
                           <div className="">
                              {/* <img src={fleetManagementSolutionPart1} className="rounded-xl"></img> */}
                              <img src={(this.state.news != null && this.state.news.Attachment != null)?this.state.news.Attachment.ImageString:""} alt={t('Dollar News.1')} className="img-fluid rounded-xl" />
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>




                
                {/* <section className="box-shadow-white-box-section grey-bg py-2">
                        <div className="container-fluid">
                            <div className="box-shadow-white-box-a ">
                                <div className="">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <img src={(this.state.news != null && this.state.news.Attachment != null)?this.state.news.Attachment.ImageString:""} alt="" className="img-fluid rounded-xl" />
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                
                <section className="box-shadow-white-box-section grey-bg pb-4">
                    <div className="container-fluid">
                        <div className="box-shadow-white-box mb-0" id="About">
                            <div className="row">
                                <div className="col-12 p-3">
                                    
                                   
                                    <h5 className="font-weight-bold border-bottom-red">{this.state.news.Title}</h5> 
                                    {
                                        (this.state.news.SourceUrl == "")?
                                        null:
                                        <a title="News Source" href={this.state.news.SourceUrl} target="_blank"  class="text-link">News Source</a>
                                    }                                 
                                   
                                    <div className="text_p" dangerouslySetInnerHTML={{__html: this.state.news.Description}} />
                                        
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                </div>
             <ListingFooter/>
            </>
        );
    }
}

export default hoistStatics(withTranslation()(News), News);