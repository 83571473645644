import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
var qs = require('qs');

class OtherVehicleType extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    

    onImageClick=(VehicleClassificationID)=>{
        this.props.onImageClick(VehicleClassificationID);
    }
    
    render() {
        const { t } = this.props
        return (<>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Choose Latest Cars & Models from Dollar Car Rental UAE</title>
                        <meta name="title" content="Choose Latest Cars & Models from Dollar Car Rental UAE"></meta>
                        <meta name="description" content="Explore a range of latest and modern wheels for your every need. Rent a car now and save more."></meta>
                        <meta property="og:title" content="Choose Latest Cars & Models from Dollar Car Rental UAE"></meta>
                        <meta property="og:url" content="https://www.dollaruae.com/vehicletypes"></meta>
                        <meta property="og:description" content="Explore a range of latest and modern wheels for your every need. Rent a car now and save more."></meta>
                    </Helmet>
            <section className="offer-box-section grey-bg pb-4 other-vehicle-type-wrapper">
                <div className="container-fluid">
                    <h1 className="border-botom">{t('Rent Luxury Vehicles.1')}</h1>
                    <h2 className="border-botom"> {t('Our Fleet. Your Keys.1')}</h2>
                    <div className="row">
                        {(this.props.otherVehicleTypesModel != null) ? this.props.otherVehicleTypesModel.map((type) => {
                            return (

                                <div key={type.VehicleClassificationID} className="col-lg-4 col-md-6 col-12" >
                                    <div className="what-new-box" key={type.VehicleClassificationID}>
                                        <h4>{type.Name}</h4>
                                        <p>{type.ShortDetails}</p>
                                        <div className="what-new-img-wrap">
                                            <img src={type.Attachment.ImageString} alt="First Article" width="364" height="244" style={{ cursor: 'pointer' }} onClick={() => this.onImageClick(type.VehicleClassificationID)} />
                                        </div>
                                    </div>
                                </div>

                            )
                        }) : ""}
                    </div>
                </div>
            </section>
           
        </>);
    }
}

export default withTranslation()(withRouter(OtherVehicleType));