import axios from "axios";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { getLanguage } from "../../Common/Util";
import CheckedRed from "../../assets/images/CheckedRed.svg";
import calendarImg from "../../assets/images/calendarTab.svg";
import closeImg from "../../assets/images/closeX.svg";
import mapPin from "../../assets/images/map-pin.svg";
import promoImg from "../../assets/images/promo.svg";
import AddPromocode from "./AddPromocode";
import CalanderPopUp from "./CalanderPopUp";
import PickUpPopup from "./PickUpPopup";
import "./tabsStyle.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import ChooseDelivery from "./ChooseDelivery";
import createAxiosInstance from "../../axios/axiosInstance";

var qs = require("qs");

class StartBooking extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const returnDate = new Date(today);
    returnDate.setDate(returnDate.getDate() + 3);
    this.state = {
      selectedValue: null,
      selectedValue1: null,
      popUpAirport: 0,
      dayBetween: 3,
      pickUpDate: moment().format("DD MMM YYYY"),
      returnDate: moment().add(3, "days").format("DD MMM YYYY"),
      pickUpTime: "09:00",
      returnTime: "09:00",
      pickUp24: "09:00",
      return24: "09:00",
      pickUpPeriod: "AM",
      returnPeriod: "PM",
      DatePopUp: 0,
      checkBoxReturn: true,
      openPromoCodeModal: false,
      errorMessage: "",
      promoCode: "",
      pickupAddress: "",
      deliveryStateName: "",
      deliveryAddress: "",
      chooseDevliveryPopUp: false,
      pickUpTime12: "09:00",
      returnTime12: "09:00",
      isActive: 0,
      size: window.innerWidth,
      searchQuery: "",
      deliveryFocus: "PickUp",
      isComponentMounted: false,
      popUpScrollPosition: 0,
      handleHeight: 0,
      inputValue: '',
      isFull: false,
    };
    this.popUpRef = createRef();
  }

  // handleChange = (event) => {
  //   const inputValue = event.target.value;
  //   this.setState({
  //     selectedValue: { name: inputValue },
  //     searchQuery: inputValue,
  //   });
  //   this.checkOverflow(); // Check for overflow after updating the input value
  // };

  // checkOverflow = () => {
  //   const input = this.inputRef.current;
  //   if (input.scrollWidth > input.clientWidth) {
  //     this.setState({ isFull: true });
  //   } else {
  //     this.setState({ isFull: false });
  //   }
  // };

  componentDidMount() {
    this.setState({ isComponentMounted: true });
    if (this.divElement) {
      this.divElement.addEventListener("scroll", this.handleScroll);
      document.addEventListener("mousedown", this.handleClickOutside);
    }

    const currentURL = window.location.href;
    const value = localStorage.getItem("BookingInfo");

    if (value && currentURL.includes("vehicle-listing")) {
      const bookingInfo = JSON.parse(value);

      this.setState({ selectedValue: bookingInfo[0] });
      const updatedSelectedValue = {
        ...bookingInfo[0],
        Name: bookingInfo[0]?.pickupLocationName,
      };

      const pickupDate = bookingInfo[0]?.PickUpDate;
      const pickUpPeriod = pickupDate.slice(-2);
      const dateTimeParts = pickupDate.split(", ");

      let time = dateTimeParts[1];
      time = time.slice(0, -3);

      const returnDate = bookingInfo[0]?.ReturnDate;
      const returnPeriod = returnDate.slice(-2);
      const dateTimeParts1 = returnDate.split(", ");

      let time1 = dateTimeParts1[1];
      time1 = time1.slice(0, -3);

      this.setState({
        pickUpTime: time,
        returnTime: time1,
        pickUpPeriod: pickUpPeriod,
        returnPeriod: returnPeriod,
      });

      if (
        bookingInfo[0]?.dropOffLocationName !==
        bookingInfo[0]?.pickupLocationName
      ) {
        this.setState({ checkBoxReturn: false }, () => {
          const updatedSelectedValue1 = {
            ...bookingInfo[0],
            Name: bookingInfo[0]?.dropOffLocationName,
          };
          this.setState({ selectedValue1: updatedSelectedValue1 });
        });
      }

      this.setState({ selectedValue: updatedSelectedValue });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (window.innerWidth !== prevState.size) {
      this.setState({ size: window.innerWidth });
    }
  }

  componentWillUnmount() {
    if (this.divElement) {
      this.divElement.removeEventListener("scroll", this.handleScroll);
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
    this.setState({ isComponentMounted: false });
  }

  handleClickOutside = (event) => {
    if (
      this.popUpRef.current &&
      !this.popUpRef.current.contains(event.target)
    ) {
      const inputBox = document.getElementById("dateInputBox");
      const inputBox1 = document.getElementById("dateInputBox1");

      const pickupLocation = document.getElementById("pickupLocation");
      const returnLocationInputBox = document.getElementById(
        "returnLocationInputBox"
      );
      if (
        (pickupLocation && pickupLocation.contains(event.target)) ||
        (returnLocationInputBox &&
          returnLocationInputBox.contains(event.target))
      ) {
        // if click on pickup location input box
        this.setState({ handleHeight: 0 });
        return;
      }

      if (
        (inputBox1 && inputBox1.contains(event.target)) ||
        (inputBox && inputBox.contains(event.target))
      ) {
        setTimeout(() => {
          this.setState({ handleHeight: 10 });
        }, 300);
        return;
      }

      this.props.manageBanner(false);
      this.setState({ chooseDevliveryPopUp: false, isActive: 0 });
      document.body.style.overflow = "unset";

      setTimeout(() => {
        if (this.state.isComponentMounted) {
          this.setState({ DatePopUp: 0, handleHeight: 0 }, () => {
            this.setState({ popUpAirport: 0 });
          });
          if (this.state.popUpAirport === 0 && this.state.DatePopUp === 0) {
            this.props.handleHeaderActive(true);
          }
        }
      }, 300);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  handlePickTime = (time) => {
    this.setState({ pickUpTime: time });
  };

  handleReturnTime = (time) => {
    this.setState({ returnTime: time });
  };

  handlePickUpPeriod = (period) => {
    this.setState({ pickUpPeriod: period });
  };

  handleReturnPeriod = (period) => {
    this.setState({ returnPeriod: period });
  };

  convertTo12HourFormat = (time24) => {
    // Splitting the input time string into hours and minutes
    var timeSplit = time24.split(":");
    var hours = parseInt(timeSplit[0]);
    var minutes = parseInt(timeSplit[1]);

    // Determine AM or PM
    var period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (00:00)

    // Format minutes to ensure always 2 digits
    minutes = (minutes < 10 ? "0" : "") + minutes;

    // Construct the 12-hour time string
    var time12 = hours + ":" + minutes + " ";

    return [time12, period];
  };

  convertTo24HourFormat = (time12) => {
    // Splitting the input time string into hours, minutes, and period
    let timeSplit = time12.split(/:| /);
    let hours = parseInt(timeSplit[0]);
    let minutes = parseInt(timeSplit[1]);
    let period = timeSplit[2];

    // Convert hours to 24-hour format
    if (period === "PM" && hours !== 12) {
      hours = hours + 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0; // Midnight (12:00 AM)
    }

    // Format hours and minutes to ensure always 2 digits
    hours = (hours < 10 ? "0" : "") + hours;
    minutes = (minutes < 10 ? "0" : "") + minutes;

    // Construct the 24-hour time string
    let time24 = hours + ":" + minutes;

    return time24;
  };

  handlePopUpAirport = (index) => {
    this.props.manageBanner(true);
    if (this.state.size > 575.98) {
      document.body.style.overflow = "hidden";
    }

    if (index === 1) {
      this.setState({ isActive: 1 });
    } else if (index === 2) {
      this.setState({ isActive: 2 });
    }
    this.setState({ popUpAirport: index, DatePopUp: 0 });
    this.props.handleHeaderActive(false);
  };

  concatTimeAndDate() {
    let selectedPickupDate =
      moment(this.state.pickUpDate).format("DD MMM YYYY") +
      ", " +
      this.state.pickUpTime +
      this.state.pickUpPeriod;

    let selectedReturnDate =
      moment(this.state.returnDate).format("DD MMM YYYY") +
      ", " +
      this.state.returnTime +
      this.state.returnPeriod;

    return {
      selectedPickupDate,
      selectedReturnDate,
    };
  }

  handleSelectedValue = async (value, index) => {
    this.setState({ isActive: 0, searchQuery: "" });
    document.body.style.overflow = "unset";
    this.props.handleHeaderActive(true); // to show the header
    if (this.state.popUpAirport === 1 || index) {
      if (index) {
        if (this.state.deliveryFocus === "PickUp") {
          console.log("value", value);
          this.setState({ selectedValue: value });
        } else if (this.state.deliveryFocus === "Return") {
          this.setState({ selectedValue1: value });
        }
      } else {
        this.setState({ selectedValue: value });
      }

      if (this.state.checkBoxReturn === true) {
        this.setState({ selectedValue1: value });
      }

      // const axiosInstance = createAxiosInstance(
      //   process.env.REACT_APP_BRANCH_API_KEY,
      //   process.env.REACT_APP_BRANCH_CLIENT_KEY,
      //   process.env.REACT_APP_BRANCH_API_URL           //new api implementation for branch api
      // );

      // axiosInstance
      //   .get(
      //     `hrsToGetReady?stateCode=${value.countryState.stateCode}&branchId=${value.identity.id}`
      //   )
      //   .then((response) => {
      //     console.log(response.data);
      //   })
      //   .catch((error) => {
      //     console.log("error fetching rental days", error);
      //   });

      var config = {
        method: "get",
        url:
          process.env.REACT_APP_API +
          "Branch/GetBranchHrsToGetReady?apiKey=" +
          process.env.REACT_APP_API_KEY +
          `&branchID=${value.identity.id}&isMonthlyBooking=${this.state.isMonthlyRental}`,
        headers: {},
      };

      axios(config)
        .then((response) => {
          this.setState({
            pickUpDate: moment(response.data.PickupOn).format("DD MMM YYYY"),
          });

          this.setState({
            returnDate: moment(response.data.ReturnOn).format("DD MMM YYYY"),
          });

          this.setState({
            pickUp24: moment
              .tz(response.data.PickupOn, "Asia/Dubai")
              .format("HH:mm"),
          });

          this.setState({
            return24: moment
              .tz(response.data.ReturnOn, "Asia/Dubai")
              .format("HH:mm"),
          });

          const [time12, period] = this.convertTo12HourFormat(
            this.state.pickUp24
          );
          const [returnTime12, returnPeriod] = this.convertTo12HourFormat(
            this.state.return24
          );
          this.setState({
            pickUpTime: time12,
          });
          this.setState({ pickUpPeriod: period });

          this.setState({
            returnTime: returnTime12,
          });
          this.setState({ returnPeriod: returnPeriod });
        })
        .catch((error) => {});
    } else if (this.state.popUpAirport === 2) {
      this.setState({ selectedValue1: value });
    }

    this.setState({ popUpAirport: 0 });
  };

  hanldePopUpDate = (value, index) => {
    this.props.manageBanner(true);
    if (index === 3) {
      this.setState({ isActive: 3 });
    } else if (index === 4) {
      this.setState({ isActive: 4 });
    }
    this.props.handleHeaderActive(false);
    this.setState({ DatePopUp: value, popUpAirport: 0 }, () => {
      if (this.state.size > 575.98) {
        document.body.style.overflow = "hidden";
      }
    });
  };

  handlePickUpDate = (value) => {
    this.setState({ pickUpDate: value });
  };

  handleReturnDate = (value) => {
    this.setState({ returnDate: value });
  };

  handleCheckBoxChange = (event) => {
    this.setState(
      {
        checkBoxReturn: event.target.checked,
      },
      () => {
        if (event.target.checked) {
          this.setState({ selectedValue1: this.state.selectedValue });
        } else {
          this.setState({ selectedValue1: " " });
        }
      }
    );
  };

  togglePromoCodeModal = () => {
    this.setState((prev) => ({
      openPromoCodeModal: !prev.openPromoCodeModal,
    }));
  };

  validateSearch = () => {
    //Validate
    let lngParam = getLanguage();
    this.setState({ errorMessage: "" });
    if (this.state.selectedValue === null) {
      if (lngParam === "en") {
        this.setState({ errorMessage: "Please select Pickup Location" });
      } else {
        //ar
        this.setState({ errorMessage: "الرجاء تحديد بيك اب الموقع" });
      }

      return false;
    }

    if (
      this.state.checkBoxReturn === false &&
      this.state.selectedValue1 === " "
    ) {
      this.setState({ errorMessage: "Please select Return Location" });
      return false;
    }

    if (
      this.state.pickUpDate &&
      this.state.returnDate &&
      this.state.returnDate < this.state.pickUpDate
    ) {
      this.setState({
        errorMessage: "Please select valid return date & time.",
      });
      return false;
    } else {
    }

    return true;
  };

  searchClickHandler = () => {
    let lngParam = getLanguage();
    if (this.validateSearch()) {
      var checkOutID = "00000000-0000-0000-0000-000000000000";
      var checkInID = "00000000-0000-0000-0000-000000000000";
      // if((this.state.pickupStateName == "" || this.state.pickupStateName == "") ||
      // (this.state.pickupStateName == null || this.state.pickupStateName == null))
      // {
      //     checkInID = this.state.selectedDestinationLocation.Identity.Id;
      // }
      // if((this.state.deliveryStateName == "" || this.state.deliveryAddress == "") ||
      // (this.state.deliveryStateName == null || this.state.deliveryAddress == null))
      // {
      // }
      checkOutID = this.state.selectedValue?.Identity?.Id;
      const { selectedPickupDate, selectedReturnDate } =
        this.concatTimeAndDate();

      var data = qs.stringify({
        Classifications: [],
        VehicleMakeID: "00000000-0000-0000-0000-000000000000",
        VehicleModelID: "00000000-0000-0000-0000-000000000000",
        SpecialOfferID: "00000000-0000-0000-0000-000000000000",
        ModelYear: 0,
        Passengers: 0,
        AutoTransmission: 1,
        CheckOutOn: selectedPickupDate,
        CheckInOn: selectedReturnDate,
        DeliveryDetails: {
          AddressJson: "",
          StateName: this.state.deliveryStateName,
          Address: this.state.deliveryAddress,
        },
        PickupDetails: {
          AddressJson: "",
          StateName: "", //this.state.pickupStateName,
          Address: "", //this.state.pickupAddress,
        },
        CheckOutBranchID: checkOutID,
        CheckInBranchID: checkInID,
        PromoCode: this.state.promoCode,
        DriverAge: 21,
        MinRentalRate: 0,
        MaxRentalRate: 50000,
        RentalDays: 0,
        NoOfRecords: 0,
        // "ModifiedReservationID": this.props.modifiedReservationID,
        isMonthlyBooking: this.state.isMonthlyRental,
      });

      var config = {
        method: "post",
        url:
          process.env.REACT_APP_API +
          `ReservationValidation/Validate?apiKey=${process.env.REACT_APP_API_KEY}&lng=${lngParam}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      axios(config)
        .then((response) => {
          if (response.data.IsValid === true) {
            var BookingInfo = [
              {
                CheckOutLocationId: checkOutID,
                CheckInLocationId: checkInID,
                PickUpDate: selectedPickupDate,
                ReturnDate: selectedReturnDate,
                pickupLocationName: this.state.selectedValue.name,
                dropOffLocationName: this.state.selectedValue1.name,
                pickupLocationImage:
                  this.state.selectedValue.Attachment.ImageString,
                dropOffLocationImage:
                  this.state.selectedValue1.Attachment.ImageString,
                pickupLocationAddress: this.state.selectedValue.Address,
                dropOffLocationAddress: this.state.selectedValue1.Address,
                pickupLocationMessage: this.state.selectedValue.Message,
                dropOffLocationMessage: this.state.selectedValue1.Message,
                pickupLocationBranchDescription:
                  this.state.selectedValue.Description,
                dropOffLocationBranchDescription:
                  this.state.selectedValue1.Description,

                deliveryState: this.state.deliveryStateName,
                deliveryAddress: this.state.deliveryAddress,
                // "returnState" :  this.state.pickupStateName,
                // "returnAddress" : this.state.pickupAddress,

                promocode: this.state.promocode,
                ModifiedReservationID: this.props.modifiedReservationID,
              },
            ];
            localStorage.setItem("BookingInfo", JSON.stringify(BookingInfo));
            window.location = "/vehicle-listing";
          } else {
            this.setState({
              errorMessage: response.data.Message,
            });
          }
        })
        .catch((error) => {
          this.setState({
            errorMessage: "Something is not correct, please try again later.",
          });
        });
    }
  };

  handleClose = () => {
    this.setState({
      errorMessage: "",
    });
  };

  handlePromoCode = (val) => {
    this.setState({ promoCode: val });
  };

  handleChooseDelivery = (isOpen, value) => {
    if (this.state.popUpAirport === 1) {
      this.setState({ deliveryFocus: "PickUp" });
    } else if (this.state.popUpAirport === 2) {
      this.setState({ deliveryFocus: "Return" });
    }
    this.setState({ chooseDevliveryPopUp: isOpen, popUpAirport: value });
  };

  chooseDeliveryValue = (stateName, address) => {
    this.setState({ deliveryState: stateName });
    this.setState({ deliveryAddress: address });
  };

  handleDateInputColor = (value) => {
    this.setState({ isActive: value });
  };

  onPickupBranchSelected = (data) => {
    this.setState({ popUpAirport: 1 });
    this.handleSelectedValue(data, true);
  };

  handleScroll = (event) => {
    const scrollPosition = this.divElement.scrollTop;
    if (
      this.popUpRef.current &&
      !this.popUpRef.current.contains(event.target)
    ) {
      this.setState({ popUpScrollPosition: scrollPosition });
    }
  };

  dayBetween = (value) => {
    this.setState({ dayBetween: value });
  };

  render() {
    const { t } = this.props;
    const {
      popUpAirport,
      selectedValue,
      checkBoxReturn,
      openPromoCodeModal,
      selectedValue1,
       
    } = this.state;
 

    return (

      
      <>
        <div
          className="tab-pane fade active show"
          id="booking"
          role="tabpanel"
          aria-labelledby="bookingTab"
        >
          <form action="">
            <div className="inner-wrapper">
              <div  className= "pick-return-location inner-box">
                <label
                  htmlFor="pickupAndReturnLocation"
                  style={{ marginBottom: "0px" }}
                >
                  {this.state.size > "575.98" ? (
                    <span>Pickup & Return Location</span>
                  ) : (
                    <span>Pickup Location</span>
                  )}

                  <span className="web same-return-location">
                    <input
                      type="checkbox"
                      id="sameReturnLocation"
                      readOnly
                      checked={this.state.checkBoxReturn}
                      onChange={this.handleCheckBoxChange}
                    />
                    <label htmlFor="sameReturnLocation">
                      {t("Same Return Location.1")}
                    </label>
                  </span>
                </label>

                <div className="input-wrapper">
                  <div
                    className={`input ${this.state.isActive === 1 && "active"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.handlePopUpAirport(1);
                    }}
                  >
                    <div className="icon">
                      <img src={mapPin} alt="" />
                    </div>
                    <input
                      type="text"
                      placeholder="Airport, City or Address"
                      id="pickupLocation"
                      autoComplete="off"
                      autoCorrect="off"
                      maxLength={210}
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          selectedValue: {
                            ...prevState.selectedValue,
                            name: e.target.value,
                          },
                          searchQuery: e.target.value,
                        })); 
                      }}
                      value={selectedValue?.name || ""}
                    />
                    {selectedValue?.name && this.state.popUpAirport === 1 && (
                      <button
                        className="close-btn"
                        onClick={(e) => {
                          e.stopPropagation();

                          this.setState({ selectedValue: null }, () => {
                            if (this.state.checkBoxReturn) {
                              this.setState({ selectedValue1: null });
                            }
                          });
                        }}
                      >
                        <img src={closeImg} alt="" />
                      </button>
                    )}
                  </div>

                  {!checkBoxReturn && (
                    <div
                      className={`input returnLocation ${
                        this.state.isActive === 2 && "active"
                      }`}
                      id="returnLocationInputBox"
                      onClick={() => this.handlePopUpAirport(2)}
                    >
                      <div className="icon">
                        <img src={mapPin} alt="" />
                      </div>
                      <input
                        value={selectedValue1?.name || ""}
                        autoComplete="off"
                        autoCorrect="off"
                        maxLength={210}
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        placeholder="Airport, City or Address"
                        id="returnLocation"
                        readOnly={checkBoxReturn}
                        onChange={(e) => {
                          this.setState((prevState) => ({
                            selectedValue1: {
                              ...prevState.selectedValue1,
                              name: e.target.value,
                            },
                            searchQuery: e.target.value,
                          }));
                        }}
                      />
                      {selectedValue1?.name &&
                        this.state.popUpAirport === 2 && (
                          <button
                            className="close-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.setState({ selectedValue1: null });
                            }}
                          >
                            <img src={closeImg} alt="X" />
                          </button>
                        )}
                    </div>
                  )}
                </div>
              </div>

              <span className="same-pick-up-location-mob mob inner-box same-return-location">
                <input
                  type="checkbox"
                  id="sameReturnLocationMob"
                  checked={this.state.checkBoxReturn}
                  onChange={this.handleCheckBoxChange}
                />
                <label htmlFor="sameReturnLocationMob">
                  {t("Same Return Location.1")}
                </label>
              </span>
              {!checkBoxReturn && (
                <div
                  className="inner-box returnLocation-mob"
                  style={{ marginTop: "12px" }}
                  onClick={() => this.handlePopUpAirport(2)}
                >
                  <span className="returnLocationSpan">Return Location</span>
                  <div className="input">
                    <div className="icon">
                      <img src={mapPin} alt="" />
                    </div>
                    <input
                      type="text"
                      value={selectedValue1?.name || ""}
                      placeholder="Airport, City or Address"
                      autoComplete="off"
                      autoCorrect="off"
                      id="pickupLocation"
                      readOnly={checkBoxReturn}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              )}

              <div className="pickup-date">
                <input
                  type="text"
                  name="pickUpReturnDate"
                  defaultValue="01/01/2018 "
                  id="pickUpReturnDate"
                />

                <div className="inner-box">
                  <label htmlFor="pickupAndReturn">
                    <span>{t("Pickup Date & Time.1")}</span>
                  </label>
                  <div
                    className={`input ${this.state.isActive === 3 && "active"}`}
                    id="dateInputBox1"
                    onClick={() => this.hanldePopUpDate(1, 3)}
                  >
                    <div className="icon">
                      <img src={calendarImg} alt="" />
                    </div>
                    <input
                      type="text"
                      placeholder="14 Feb 2023"
                      id="pickup"
                      readOnly
                      value={
                        this.state.pickUpDate +
                        ` |  ${this.state.pickUpTime} ${this.state.pickUpPeriod}`
                      }
                    />
                  </div>
                </div>

                <div className="inner-box">
                  <label htmlFor="pickupAndReturn">
                    <span>{t("Return Date & Time.1")}</span>
                  </label>
                  <div
                    className={`pr input ${
                      this.state.isActive === 4 && "active"
                    }`}
                    id="dateInputBox"
                    onClick={() => this.hanldePopUpDate(2, 4)}
                  >
                    <div className="icon">
                      <img src={calendarImg} alt="" />
                    </div>
                    <input
                      type="text"
                      placeholder="14 Mar 2023"
                      id="return"
                      readOnly
                      value={
                        this.state.returnDate +
                        ` | ${this.state.returnTime} ${this.state.returnPeriod}`
                      }
                    />
                  </div>
                </div>
              </div>

              <button
                type="button"
                className="primary-button showCarBtn"
                onClick={this.searchClickHandler}
              >
                {t("Show Cars.1")}
              </button>
            </div>
          </form>
        </div>

        <div
          className="overlayPopUpLocation"
          style={{
            zIndex: popUpAirport || this.state.DatePopUp ? "9" : "-2",
            width: popUpAirport || this.state.DatePopUp ? "" : "auto",
          }}
          ref={(div) => {
            this.divElement = div;
          }}
        >
          <div
            style={{
              height: popUpAirport || this.state.DatePopUp ? "5000px" : "auto",
            }}
          ></div>
        </div>

        {/* pop section */}
        {popUpAirport !== 0 && (
          <div ref={this.popUpRef}>
            {" "}
            <PickUpPopup
              onSelectValue={this.handleSelectedValue}
              searchQuery={this.state.searchQuery}
              handleChooseDelivery={this.handleChooseDelivery}
              handlePopUpAirport={this.handlePopUpAirport}
              handleHeight={this.state.handleHeight}
              searchInputBoxValue={
                popUpAirport === 1 ? selectedValue?.name : selectedValue1?.name
              }
              searchClickHandler={this.searchClickHandler}
              popUpScrollPosition={this.state.popUpScrollPosition}
            />{" "}
          </div>
        )}

        {/* promo code */}
        <div className="promo-code">
          {this.state.promoCode.length === 0 ? (
            <div className="promo-code p-0">
              <span>{t("Have promo code?.1")}</span>
              <button id="promo-code-btn" onClick={this.togglePromoCodeModal}>
                <div className="icon" style={{ marginBottom: 0 }}>
                  <img src={promoImg} alt="" />
                </div>
                <span>{t("Add Promo Code.1")}</span>
              </button>
            </div>
          ) : (
            <div className="promo-code p-0">
              <div className="checked-icon">
                <img src={CheckedRed} alt="" />
              </div>
              <button id="promo-code-btn" onClick={this.togglePromoCodeModal}>
                <div className="icon" style={{ marginBottom: 0 }}>
                  <img src={promoImg} alt="" />
                </div>
                <span>{this.state.promoCode}</span>
              </button>
              <span className="mr-0">({t("Added Promo Code.1")})</span>
              <button
                className="removeBtn"
                onClick={() => this.setState({ promoCode: "" })}
              >
                {t("Remove.1")}
              </button>
            </div>
          )}
        </div>

        {openPromoCodeModal && (
          <AddPromocode
            promoCode={this.state.promoCode}
            handleClose={this.togglePromoCodeModal}
            handlePromoCode={this.handlePromoCode}
          />
        )}

        {/* date */}
        {this.state.DatePopUp !== 0 && (
          <div ref={this.popUpRef}>
            <CalanderPopUp
              handleDateInputColor={this.handleDateInputColor}
              pickUpDate={this.handlePickUpDate}
              returnDate={this.handleReturnDate}
              handlePickTime={this.handlePickTime}
              handleReturnTime={this.handleReturnTime}
              handlePickUpPeriod={this.handlePickUpPeriod}
              handleReturnPeriod={this.handleReturnPeriod}
              hanldePopUpDate={this.hanldePopUpDate}
              searchClickHandler={this.searchClickHandler}
              dayBetween={this.dayBetween}
              stateValues={{
                pickUpLocation: this.state.selectedValue?.name,
                returnLocation: this.state.selectedValue1?.name,
                pickUpPeriod: this.state.pickUpPeriod,
                returnPeriod: this.state.returnPeriod,
                pickUpTime: this.state.pickUpTime,
                returnTime: this.state.returnTime,
                pickUpDate: this.state.pickUpDate,
                returnDate: this.state.returnDate,
                activeColor: this.state.isActive,
                dayBetweenState: this.state.dayBetween,
              }}
            />
          </div>
        )}

        {/* Choose delivery to me */}
        {this.state.chooseDevliveryPopUp && (
          // <div ref={this.popUpRef}>
          <ChooseDelivery
            address={this.state.deliveryAddress}
            onPickupBranchSelected={this.onPickupBranchSelected}
            chooseDeliveryValue={this.chooseDeliveryValue}
            handleChooseDelivery={this.handleChooseDelivery}
          />
          //  </div>
        )}

        {/* Error */}
        {this.state.errorMessage != null &&
        this.state.errorMessage.length > 0 ? (
          <Dialog
            open={this.state.errorMessage.length > 0}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ textAlign: "center" }}
            >
              {"Dollar"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                onClick={this.handleClose}
                variant="outlined"
                color="error"
                style={{
                  background: "#E31A37",
                  color: "white",
                  justifyContent: "center",
                  width: "100%",
                  margin: "0px 47px",
                }}
              >
                {t("OK.1")}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    );
  }
}

export default withTranslation()(StartBooking);
