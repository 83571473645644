import React, { Component } from 'react';
import {getCustomerId} from '../Common/Util';
import { withTranslation } from 'react-i18next';
import {  withRouter } from 'react-router-dom';
import {Link} from 'react-scroll';


import corporaterental from '../assets/images/login-Image.png';


class TopLeftMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    
    onLeftMenuLinkClick = (path) => {
        this.props.leftMenuClick();
        this.props.history.push(path);
    }

    onLeftMenuLinkClickWithParam = (path,param) => {
        this.props.leftMenuClick();
        this.props.history.push(path,param);
    }

    onFindReservationClick = (path) => {
        const customerId = getCustomerId();
        if(customerId == "00000000-0000-0000-0000-000000000000"){
            this.props.leftMenuClick();
            this.props.history.push(path);
        } else  {
            this.props.leftMenuClick();
            this.props.history.push("/profile");
        }
    }

    onNewLetterClick =()=>{
        this.props.leftMenuClick();
    }
    onFlexiMonthlyClick = () => {
        this.props.leftMenuClick();
        this.props.history.push("/fleximonthly");

    }

    render() {
        const { t } = this.props

        return (
            <div className="top-left-menu-wrapper">
                <div className="header-left-menu-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="submenu-wrap-box">
                                    <div className="title-nav">{t('Reservations.1')}</div>
                                    <ul className="submenu-list-wrap">
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/start-a-reservation')} className="submenu-links">
                                                {t('Start a Reservation.1')}
                                        </a>
                                        </li>
                                        <li>
                                            <a onClick={() => this.onFindReservationClick('/findreservation')} className="submenu-links" title="View, Modify, Cancel a Reservtion.">
                                            {t('View, Modify, Cancel.1')}
                                        </a>
                                        </li>
                                        <li >
                                            <a className="submenu-links" title="Flexi Monthly" onClick={this.onFlexiMonthlyClick}>
                                            {t('Flexi Monthly.1')}
                                            </a>
                                        </li>    
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/requestreceipt')} className="submenu-links">
                                                {t('Request a Receipt.1')}
                                        </a>
                                        </li>
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/contactus')} className="submenu-links">
                                                {t('Support Zone.1')}
                                        </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="submenu-wrap-box">
                                    <div className="title-nav">{t('Our Services.1')}</div>
                                    <ul className="submenu-list-wrap">
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/dollar-xpress')} className="submenu-links">
                                            {t('DollarXpress.1')}
                                        </a>
                                        </li>
                                        
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/dollar-prestige')} href="" className="submenu-links">
                                                {t('Dollar Prestige.1')}
                                        </a>
                                        </li>
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/rental-addons')} className="submenu-links">
                                                {t('Rental Add-Ons.1')}
                                        </a>
                                        </li>
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/long-term-leasing')} className="submenu-links">
                                                {t('Long-Term Leasing.1')}
                                        </a>
                                        </li>
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/book-service')} className="submenu-links">
                                            {t('Book a Service.1')}
                                        </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="submenu-wrap-box">
                                    <div className="title-nav">{t('For Business.1')}</div>
                                    <ul className="submenu-list-wrap">
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/explore-fleet')} className="submenu-links">
                                                {t('Explore Fleet.1')}
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/fleet-management-solutions')} className="submenu-links">
                                                {t('Fleet Management Solutions.1')}
                                        </a>
                                        </li>
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/specialized-vehicles')} className="submenu-links">
                                                {t('Specialized Vehicles.1')}
                                        </a>
                                        </li>
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/explore-corporate-fleet')} className="submenu-links">
                                                {t('Explore Corporate Fleet.1')}
                                        </a>
                                        </li>
                                        <li>
                                            <a onClick={() => this.onLeftMenuLinkClick('/business-solutions')} className="submenu-links">
                                                {t('Business Solutions.1')}
                                        </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="submenu-wrap-box">
                                    {/* <img src={corporaterental} alt="" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withRouter(TopLeftMenu));