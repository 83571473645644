
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import BannerNextArrow from './BannerNextArrow';
import BannerPrevArrow from './BannerPrevArrow';





class Banner extends Component {
    constructor(props) {
        super(props);

    }

    onBannerClick = (evnt) =>{
        if(!this.props.manageBanner){
            if(!this.props.manageBanner){
                window.open(evnt, '_blank');
            }
        }
    }

    render() {
        
        const settings = {
            infinite: true,
            autoplay:true,
            autoplaySpeed:10000,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots:false,
            prevArrow: <BannerPrevArrow/>,
            nextArrow: <BannerNextArrow/>,      
        };
        return (

            <Slider className="banner-slider-section" {...settings}>
                { this.props.BannerData.map((banner, index) => {
                    return (
                        <div key={index} onClick={() => { this.onBannerClick(banner.NavigationUrl) }}>
                           
                            <img
                                src={banner.BannerAttachment.ImageString} 
                                alt="First slide" width="100%" height="780px"
                            />
                            
                        </div>
                    )
                })}              
            </Slider>

        );
    }
}

export default withTranslation()(withRouter(Banner));