
import React, { Component } from 'react';
import starRating from '../assets/images/star-rating.svg';
import { withTranslation } from 'react-i18next';

class MonthlyTestimonials extends Component {

    render() {
        const { t } = this.props;
        return (
            <> 
                <section className='monthlyTestimonial-section padding-100'>
                    <div className='container'>
                        <div className='titleBorderWrap'>
                            <h2>{t('Testimonials.1')}</h2>
                            {/* <p></p> */}
                        </div>
                        <div className='monthly-card-blog-left-row card-blog-left-row'>
                            <div className='card-blog-left-col'>
                                <div className='card-blog-left-box'>
                                    <div className='monthlyTestimonial-Boxslider-wrap'>
                                        <div className='monthlyTestimonial-Boxslider'>
                                            <div className='starRating-wrap'>
                                                <img src={starRating} alt="Star"/> 
                                                <img src={starRating} alt="Star"/> 
                                                <img src={starRating} alt="Star"/> 
                                                <img src={starRating} alt="Star"/> 
                                                <img src={starRating} alt="Star"/> 
                                            </div>
                                            <p>{t('Testimonials1.1')}</p>
                                            <b>- Harry</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-blog-left-col'>
                                <div className='card-blog-left-box'>
                                <div className='monthlyTestimonial-Boxslider-wrap'>
                                    <div className='monthlyTestimonial-Boxslider'>
                                        <div className='starRating-wrap'>
                                            <img src={starRating} alt="Star"/> 
                                            <img src={starRating} alt="Star"/> 
                                            <img src={starRating} alt="Star"/> 
                                            <img src={starRating} alt="Star"/> 
                                            <img src={starRating} alt="Star"/> 
                                        </div>
                                        <p>{t('Testimonials2.1')}</p>
                                        <b>- Melissa</b>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className='card-blog-left-col'>
                                <div className='card-blog-left-box'>
                                <div className='monthlyTestimonial-Boxslider-wrap'>
                                    <div className='monthlyTestimonial-Boxslider'>
                                        <div className='starRating-wrap'>
                                            <img src={starRating} alt="Star"/> 
                                            <img src={starRating} alt="Star"/> 
                                            <img src={starRating} alt="Star"/> 
                                            <img src={starRating} alt="Star"/> 
                                            <img src={starRating} alt="Star"/> 
                                        </div>
                                        <p>{t('Testimonials3.1')}</p>
                                        <b>- Jakob</b>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
            
            </>
            )
        }
    }
    
    export default withTranslation()(MonthlyTestimonials);