import React, { Component } from 'react';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import BrandSlider from '../Hire/BrandSlider';
import { withTranslation } from 'react-i18next';
import globalpartner from '../assets/images/global-road-trip-partner.png';
import awardwinnig from '../assets/images/award-winning.png';
import VehicleClassifications from '../Hire/VehicleClassifications';
import { animateScroll as scroll } from 'react-scroll';
import exploreFleetPart1 from '../assets/images/Business/1488301004.jfif'
import exploreFleetPart2 from '../assets/images/Business/596189672.jfif'
import { Helmet } from 'react-helmet';

class ExploreFleet extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         otherVehicleTypesModel: [],
    //     }
    // }
    componentDidMount = () => {
        // this.getVehicleTypes();
        scroll.scrollToTop();
    }
    // getVehicleTypes = (VehicleClassificationID) => {
    //     let lngParam = getLanguage();
    //     axios(process.env.REACT_APP_API + 'VehicleClassification/Get?apiKey=' + process.env.REACT_APP_API_KEY + `&lng=${lngParam}`).then((response) => {

    //         const vehicleTypes = response.data;
         
    //         let otherVehicleTypesModel = [];

    //         vehicleTypes.forEach(type => {

    //             otherVehicleTypesModel.push(type);

    //         });


          
    //         this.setState({ otherVehicleTypesModel: otherVehicleTypesModel });

    //     });
    // }

        render() {
            const { t } = this.props;
            return (<>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{t('Explore car rental destination | Dollar Car Rental UAE.1')} </title>
                    <link rel="canonical" href="www.dollaruae.com/explore-fleet" />
                    <meta property="og:title" content="Explore car rental destination | Dollar Car Rental UAE"></meta>
                    <meta property="og:site_name" content="Dollar Car Rental" />
                    <meta property="og:url" content="https://www.dollaruae.com/" />
                    <meta property="og:description" content="Explore car rental destinations in UAE - Dollar Car Rental offers a wide range of great premium rental cars that includes SUVs, sedans, hatchbacks, economy & luxury rentals." />
                    <meta property="og:type" content="business.business" />
                    <meta property="og:image" content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg" />
                    <meta name="title" content="Explore carrental destination | Dollar Car Rental UAE" />
                    <meta name="description" content="Explore car rental destinations in UAE - Dollar Car Rental offers a wide range of great premium rental cars that includes SUVs, sedans, hatchbacks, economy & luxury rentals." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.dollaruae.com/explorefleet" />
                    <meta property="og:title" content="Explore car rental destination | Dollar Car Rental UAE" />
                    <meta property="og:description" content="Explore car rental destinations in UAE - Dollar Car Rental offers a wide range of great premium rental cars that includes SUVs, sedans, hatchbacks, economy & luxury rentals." />
                    <meta property="og:image" content="https://www.dollaruae.com/static/media/1488301004.1db0b700.jfif" />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://www.dollaruae.com/explorefleet" />
                    <meta property="twitter:title" content="Explore car rental destination | Dollar Car Rental UAE" />
                    <meta property="twitter:description" content="Explore car rental destinations in UAE - Dollar Car Rental offers a wide range of great premium rental cars that includes SUVs, sedans, hatchbacks, economy & luxury rentals." />
                    <meta property="twitter:image" content="https://www.dollaruae.com/static/media/1488301004.1db0b700.jfif"></meta>
                    </Helmet>
                <ListingHeader />


                <div className="minH100 exploreFleet">

                <section className="py-5 bg-grey">
                    <div className="container">
                    <div className="border-titlec-content mb-5">
                        <h1 className="mb-1">{t('Experience only the best at your ultimate car rental destination.1')}</h1>
                        <h2 className="mb-1">{t('Our Fleet Your Keys Trusted Brands Trusted Services.1')}</h2>
                        <p className="font-18 text-grey">{t('Choose your wheels from UAE’s Largest & Widest Rental Fleet.1')}</p>
                        </div>
                        <div className="pb-5">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-12">
                                    <img src={exploreFleetPart2} className="rounded-xl" alt="Dollar Fleet"></img>
                                </div>
                                <div className="col-lg-6 col-12  mt-4 mt-lg-0">
                                    <div className="oddEven-content">
                                        <h3 className="font-24">{t('Experience only the best at your ultimate car rental destination.1')}</h3>
                                        <p className="font-18 text-grey pt-3"> {t('At Dollar Car Rental UAE, it is easy to find a rental car that suits your budget and your style.1')} </p>
                                        <p className="font-18 text-grey pt-3"> {t('Dollar offers a large selection of high-quality, well-maintained, and latest models of rental vehicles designed to make getting to your destination comfortable and fun. All vehicles in our fleet are non-smoking, and each year we add more and more fuel-efficient vehicles, which means even more savings for you. In the UAE, Dollar Car Rental offers a wide range of great rental cars that includes SUVs, sedans, hatchbacks, economy & luxury rentals.1')} </p> 
                                        <p className="font-18 text-grey pt-3">{t('No matter what kind of vehicle fits your needs, Dollar has just what you’re looking for at a great rate, backed by excellent service.1')}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-5  align-items-center">
                                <div className="col-lg-6 col-12 order-1 order-lg-0 mt-4 mt-lg-0">
                                    <div className="oddEven-content">
                                        <p className="font-18 text-grey">{t('Feel free to reach out to us for the season’s latest competitive rates, offers, and deals from Dollar Car Rental UAE towards a vehicle of your choice and budget.1')} </p>
<p className="font-18 text-grey pt-3">{t('Not sure which vehicle model to go for? Look for the detail indicators against each vehicle listing to help understand its seating capacity, luggage capacity, engine specs, auto/manual, and more. Nevertheless, our representatives would be happy to assist you in finding your best pocket-friendly fit.1')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <img src={exploreFleetPart1} className="rounded-xl" alt="Dollar Explore Fleet"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <VehicleClassifications className="bg-grey"/>
                </div>
                <br />
                <BrandSlider/>
                <ListingFooter />
            </>);
        }
    }

    export default withTranslation()(ExploreFleet);