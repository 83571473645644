
import React, { Component } from 'react';
import CopyRightFooter from '../Common/CopyRightFooter';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import BannerSection from './MonthlyBannerSection';
import FindLocation from '../Hire/FindLocation';
import NewsIndex from '../News/NewsIndex';
import Subscribe from '../Hire/Subscribe';
import MonthlyRenting from './MonthlyRenting'
import MonthlyFeatures from './MonthlyFeatures'
import MonthlyTestimonials from './MonthlyTestimonials'
import { animateScroll as scroll } from 'react-scroll'
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
class MonthlySubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headerActive: true
        }
    
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    handleHeaderActive = (value) => {
        if (window.innerWidth > 553) {
        this.setState({headerActive: value});
        }
    }
    
    render() {
        const { t } = this.props;
        
        return (
            <>
                <Helmet>
                    <title>Monthly Car Rental - Explore unmissable deals, offers and discounts</title>
                    <meta property="og:title" content="Monthly Car Rental - Explore unmissable deals, offers and discounts"  />
                    <meta property="og:site_name" content="Dollar Car Rental UAE" />
                    <meta property="og:url" content="https://www.dollaruae.com/fleximonthly" />
                    <meta property="og:description" content="Dollar Car Rental offers best deals on monthly car rentals in Dubai and across UAE. Explore exclusive offers, unmissable deals and book your next rental today." />
                    <meta property="og:type" content="video.other" />
                    <meta property="og:image" content="http://admin.dollaruae.com//DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/vehicleclassification/SUV_VehicleClassificationAttachment9f583cca-7b4e-4a9f-a60e-3cd466879550.png" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@dollaruae" />
                    <meta name="twitter:title" content="Monthly Car Rental - Explore unmissable deals, offers and discounts" />
                    <meta name="twitter:description" content="Dollar Car Rental offers best deals on monthly car rentals in Dubai and across UAE. Explore exclusive offers, unmissable deals and book your next rental today." />
                    <meta name="twitter:image" content="http://admin.dollaruae.com//DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/vehicleclassification/SUV_VehicleClassificationAttachment9f583cca-7b4e-4a9f-a60e-3cd466879550.png" /> 
                    <link rel="canonical" href="https://www.dollaruae.com/fleximonthly" />
                </Helmet>
                {this.state.headerActive && <Header />}
                <BannerSection handleHeaderActive={this.handleHeaderActive}/>
                <MonthlyRenting />
                <MonthlyFeatures />
                <MonthlyTestimonials />
                <Subscribe />
                <NewsIndex />
                <FindLocation />
                <Footer />
                {/* <CopyRightFooter /> */}
            </>
        )
    }
}

export default withTranslation()(MonthlySubscription);