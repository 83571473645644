import axios from 'axios';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import ErrorAlert from './AlertError/ErrorAlert';
import {  withRouter } from 'react-router-dom';
import { getLanguage } from '../Common/Util';
import DollarImage from '../assets/images/login-Image@2x.png';
import validator from 'validator';
var qs = require('qs');
class ContactPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showContact: false,
            errorMessage:'',
            companyname:'',
            fullName:'',
            email:'',
            Contactno:'',
            description:'',
            deliveryState: 'SELECT',
            allStates: []
        }
    }

    componentDidMount() {
        this.getStates();
    }

    _onButtonClick() {
        this.setState({
            showComponent: true,
        });
    }

    handleModal() {
        this.setState({ show: !this.state.show })
    }

    toogleContactPopup = (showHide) => {
        this.setState({ showContact: showHide })

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    ValidateContactInfo = () => {      
        let lngParam = getLanguage();
        this.setState({errorMessage:''});
        const valid = true;      

        if (this.state.fullName.trim() =='') {   
            if(lngParam == "en")
            {
                this.setState({errorMessage:'Please fill the Full name'});
            }
            else
            {
              //ar
              this.setState({errorMessage:'يرجى ملء الاسم الكامل'});
            }         
            
            return false;
        }
        if (this.state.email.trim() =='') { 
            if(lngParam == "en")
            {
                this.setState({errorMessage:'Please fill the Email'});
            }
            else
            {
              //ar
              this.setState({errorMessage:'الرجاء تعبئة البريد الإلكتروني'});
            }          
            
            return false;
        }
        if(!validator.isEmail(this.state.email)){
            if (lngParam == "en") {
                this.setState({
                    errorMessage: "The email field is invalid"
                });
            }
            else {
                //ar
                this.setState({
                    errorMessage: "مطلوب حقل البريد الإلكتروني"
                });
            }
            return false;
        }
      
        if (this.state.Contactno.trim() =='') {  
            if(lngParam == "en")
            {
                this.setState({errorMessage:'Please fill the Contact no'});
            }
            else
            {
              //ar
              this.setState({errorMessage:'يرجى ملء رقم الاتصال'});
            }          
            
            return false;
        }
        if (this.state.deliveryState === undefined || this.state.deliveryState === '' 
        || this.state.deliveryState === 'SELECT'  || this.state.deliveryState === 'select' ) {

            if (lngParam == "en") {
                this.setState({ errorMessage: 'Please select the state.' });
            }
            else {
                //ar
                this.setState({ errorMessage: 'الرجاء تحديد الولاية.' });
            }

            return false;
        }

        // if (this.state.companyname.trim() =='') {   
        //     if(lngParam == "en")
        //     {
        //         this.setState({errorMessage:'Please fill the Company name'});
        //     }
        //     else
        //     {
        //       //ar
        //       this.setState({errorMessage:'الرجاء تعبئة اسم الشركة'});
        //     }        
           
        //     return false;
        // }
        if (this.state.description.trim() =='') {  
            if(lngParam == "en")
            {
                this.setState({errorMessage:'Please fill the Description'});
            }
            else
            {
              //ar
              this.setState({errorMessage:'يرجى ملء الوصف'});
            }         
            
            return false;
        }

        return valid;

    }

    onSendClick = () => {
        var QuoteRequestType =0;
        if(this.props.leasingEnquiry.selectedQuoteRequestType != undefined)
        {
            QuoteRequestType = this.props.leasingEnquiry.selectedQuoteRequestType;
        }
        this.setState({ loading: true });
        if (this.ValidateContactInfo()) {
            var data = qs.stringify(
                {
                    "CompanyName": this.state.companyname,
                    "PersonalInformation":
                    {
                        "Title": null,
                        "FullName": this.state.fullName,
                        "FirstName": null,
                        "LastName": null,
                        "ContactNo": this.state.Contactno,
                        "DOB": null,
                        "Email": this.state.email,
                        "Address": null
                    },
                    "Description": this.state.description,
                    "SpecialOfferID": "00000000-0000-0000-0000-000000000000",
                    "QuoteRequestDetails":
                        [{
                            "QuoteRequestFor": QuoteRequestType,
                            "Vehicle": this.props.leasingEnquiry.selectedVehicalType,
                            "Quantity":this.props.leasingEnquiry.selectedVehicalQunatity,
                            "MileageDescription": "",
                            "ExpectedStartOn": "",
                            "CustomerRemark": this.state.description,
                            "Duration": this.props.leasingEnquiry.selectedYear,
                            "AddEditId": 1
                        }],
                    "StateID": this.state.deliveryState
                }

            );

            var config = {
                method: 'post',
                url: process.env.REACT_APP_API+ `QuoteRequest/Post?apiKey=${process.env.REACT_APP_API_KEY}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                this.setState({ loading: false });
                if(response.data !=null && response.data.Message != null  ) 
                {
                    localStorage.setItem('thanksmessage',response.data.Message);
                }
                  this.props.history.push("/thankyou");
                })
                .catch((error) => {
                    this.setState({errorMessage:'Error while sending quote request'});
                });
        }else{
            this.setState({ loading: false });
        }
    }

    
    getStates =() => {
        axios(process.env.REACT_APP_API + 'BookingSetting/GetStates?apiKey=' + process.env.REACT_APP_API_KEY ).then((responce) => {
        const allStates = responce.data;
            if(allStates.length>0)
            {
                this.setState({
                    allStates: allStates
                });
            }
        });
    }

    
    LocationStateChange = (event) =>{
        this.setState({ deliveryState :  event.target.value});
    }

    render() {
        const { t } = this.props;
    return (
            <>
            <div>
                <div className="container-fluid" id="LoginFormPopup">
                <div className=" login-container p-0">
                
                    <div className="row  login-first-row">
                        <div className="col-12">
                            <div className=" login-card upgrade-option-modal-content">
                                <div className="form-login form-quote-request my-0">
                                <div>
                                    <Modal.Header closeButton >
                                    
                                        <h2 className="border-botom" id="exampleModalLongTitle">{t('Quote Request.1')}</h2>
                                    </Modal.Header>
                                </div>
                                <div className="row pt-3">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label><h6 class="font-weight-bold mb-0 text-black">{t('Customer Name.1')}&nbsp;<span className="text-red-alert">*</span></h6></label>
                                            <input className="form-control pl-3" id="fullName" name="fullName" type="text" onChange={this.onChange} value={this.state.fullName} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label><h6 class="font-weight-bold mb-0 text-black">{t('Email Id.1')}&nbsp;<span className="text-red-alert">*</span></h6> </label>
                                            <input className="form-control pl-3" id="email" name="email" type="text" onChange={this.onChange} value={this.state.email} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label><h6 class="font-weight-bold mb-0 text-black">{t('Contact No.1')}&nbsp;<span className="text-red-alert">*</span></h6></label>
                                            <input className="form-control pl-3" id="Contactno" name="Contactno" type="text" onChange={this.onChange} value={this.state.Contactno} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label><h6 class="font-weight-bold mb-0 text-black">{t('Company Name.1')}</h6></label>
                                            <input className="form-control pl-3" id="companyname" maxLength="256" name="companyname" tabIndex="9" type="text" onChange={this.onChange} value={this.state.companyname} />
                                        </div>
                                    </div>
{/* 
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Vehicle type</label>
                                            <input className="form-control" id="Vehicletype" maxLength="256" name="Vehicletype" tabIndex="9" type="text"   value={this.props.leasingEnquiry.selectedVehicalType}  readOnly/>

                                        </div>
                                    </div> */}


                                   

                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Quantity</label>
                                            <input className="form-control" id="Quantity" maxLength="256" name="Quantity" tabIndex="9" type="text"   value={this.props.leasingEnquiry.selectedVehicalQunatity}  readOnly/>

                                        </div>
                                    </div> */}

                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Duration</label>
                                            <input className="form-control" id="Duration" maxLength="256" name="Duration" tabIndex="9" type="text"   value={this.props.leasingEnquiry.selectedYear}  readOnly/>

                                        </div>
                                    </div> */}

                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <div className="custom-input-select-wrap">
                                            <label><h6 class="font-weight-bold mb-0 text-black">State&nbsp;<span className="text-red-alert">*</span></h6></label>
                                            <select id="pickup-delivery-location" onChange={this.LocationStateChange} value={this.state.deliveryState}>                   
                                                        <option value="SELECT">{t('SELECT.1')}</option>
                                                            {this.state.allStates.map((state) => {
                                                                return (
                                                                        <>
                                                                                <option value={state.StateID}>{state.Name}</option>
                                                                        </> 
                                                                )
                                                            })}
                                                </select>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label><h6 class="font-weight-bold mb-0 text-black">{t('Description.1')}&nbsp;<span className="text-red-alert">*</span></h6></label>
                                            <textarea  className="form-control pl-3" id="description" maxLength="256" name="description" tabIndex="9" type="text" onChange={this.onChange} value={this.state.description} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 offset-md-6 text-right">
                                        {this.state.loading == false ? <button className="rounded-bttn rounded-bttn-transparent w-40 text-center" onClick={this.onSendClick}>{t('Submit.1')}</button> : null}
                                    </div>
                                    <div className="col-md-12">
                                        {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? <ErrorAlert errorMessage={this.state.errorMessage} /> : null}
                                        {this.state.loading == true ? <div style={{ verticalAlign: 'center' }}>
                                            <Spinner animation="border" />&nbsp;{t('Sending quote request.1')}
                                        </div> : null}
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </>


        );




    }
}

export default hoistStatics(withTranslation()(withRouter(ContactPopup)), ContactPopup);
